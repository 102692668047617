import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { BreachesByUserIDBody, BreachesByUserIDErrRes, BreachesByUserIDRes } from './Model'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: BreachesByUserIDRes
  error: BreachesByUserIDErrRes
}
const initialState: InitialState = {
  loading: false,
  response: {} as BreachesByUserIDRes,
  error: { message: '' } as BreachesByUserIDErrRes,
}

export const fetchBreachesByUserID = createAsyncThunk<BreachesByUserIDRes, BreachesByUserIDBody>(
  'breachesByUserID/fetchBreachesByUserID',
  async (reqParam: BreachesByUserIDBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return await setupInterceptorsTo(axios)
      .get(Url.backEndURL + `/api/breachuservendors?user_id=${reqParam.userId}`, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            'Something went wrong',
        ),
      )
  },
)

const breachesByUserID = createSlice({
  name: 'breachesByUserID',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBreachesByUserID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchBreachesByUserID.fulfilled,
      (state, action: PayloadAction<BreachesByUserIDRes>) => {
        state.loading = false
        state.response = action.payload
        state.error = {} as BreachesByUserIDErrRes
      },
    )
    builder.addCase(fetchBreachesByUserID.rejected, (state, action) => {
      state.loading = false
      state.response = {} as BreachesByUserIDRes
      state.error.message = action.payload!
    })
  },
})

export default breachesByUserID.reducer
