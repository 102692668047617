
import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { HideCommentResponse, HideCommentErrorResponse, HideCommentReq } from './Model'

type InitialState = {
  loading: boolean
  response: HideCommentResponse
  succssMessage: string
  error: HideCommentErrorResponse
}
const initialState: InitialState = {
  loading: false,
  response: {} as HideCommentResponse,
  succssMessage: '',
  error: { message: '' } as HideCommentErrorResponse,
}

export const hideComment = createAsyncThunk<
  HideCommentResponse,
  HideCommentReq
>(
  'hideComment/hideComment',
  (payload, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return setupInterceptorsTo(axios)
      .post(url.backEndURL + `/api/accountcomment/${payload.id}/hide_comment`, payload.body, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(error.response.data.message || error || 'Something went wrong'),
      )
  },
)

const HideCommentSlice = createSlice({
  name: 'hideComment',
  initialState,
  reducers: {
    hideCommentClearMsg: (state) => {
      state.succssMessage = ''
      state.error = {} as HideCommentErrorResponse
    },
    hideCommentClearRes: (state) => {
      state.succssMessage = ''
      state.error = {} as HideCommentErrorResponse
      state.response = {} as HideCommentResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hideComment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      hideComment.fulfilled,
      (state, action: PayloadAction<HideCommentResponse>) => {
        state.loading = false
        state.response = action.payload
        state.succssMessage =
        action?.payload?.succssMessage !== undefined
          ? action?.payload?.succssMessage !== ''
            ? action?.payload?.succssMessage
            : 'Comment hide successful'
          : 'Comment hide successful'
        state.error = {} as any
      },
    )
    builder.addCase(hideComment.rejected, (state, action) => {
      state.loading = false
      state.response = {} as HideCommentResponse
      state.error.message = action.error.message || 'Something went wrong'
    })
  },
})
export const { hideCommentClearMsg, hideCommentClearRes } = HideCommentSlice.actions
export default HideCommentSlice.reducer
