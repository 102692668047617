import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium'
import React from 'react'

export default function MUIDGCustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton
        slotProps={{ button: { variant: 'text', style: { color: '#000000' } } }}
      />
      <GridToolbarFilterButton
        slotProps={{ button: { variant: 'text', style: { color: '#000000' } } }}
      />
      <GridToolbarDensitySelector
        slotProps={{ button: { variant: 'text', style: { color: '#000000' } } }}
      />
      <GridToolbarExport slotProps={{ button: { variant: 'text', style: { color: '#000000' } } }} />
    </GridToolbarContainer>
  )
}
