import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { DocumentListReq, Result } from './Model'

type InitialState = {
    loading: boolean
    documentList: Result
    error: string
}
const initialState: InitialState = {
    loading: false,
    documentList: {} as Result,
    error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchDocumentList = createAsyncThunk<Result, DocumentListReq>('documentList/fetchDocumentList', (reqParam: DocumentListReq) => {
    let headers = {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
        .get(url.backEndURL + `/api/accountdocument?account=${reqParam.documentId}`, { headers })
        .then((response) => response.data)
})

const documentListSlice = createSlice({
    name: 'documentList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchDocumentList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchDocumentList.fulfilled, (state, action: PayloadAction<Result>) => {
            state.loading = false
            state.documentList = action.payload
            state.error = ''
        })
        builder.addCase(fetchDocumentList.rejected, (state, action) => {
            state.loading = false
            state.documentList = {} as Result
            state.error = action.error.message || 'Something went wrong'
        })
    },
})

export default documentListSlice.reducer
