import { Box } from '@mui/material'
import * as React from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5percent from '@amcharts/amcharts5/percent'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { useAppSelector } from 'reduxStore/hooks'
am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)
interface FilteredDept {
  value: number
  category: string
}

export default function VendorSpendDeptDetails() {
  const venDeptDetails = useAppSelector((state) => state.vendorDeptDetails)

  let modifiedArray = [] as FilteredDept[]

  React.useEffect(() => {
    if ('vendorSpends' in venDeptDetails && venDeptDetails.vendorSpends.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      modifiedArray = venDeptDetails.vendorSpends.map((item) => {
        return {
          value: Number(item.cost),
          category: item.vendor_name + ' : $' + item.cost,
        }
      })
    }

    /* Chart code */
    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_element
    let root = am5.Root.new('chartSpendDetailsDiv')
    var modal = am5.Modal.new(root, {
      content: 'The Vendor Spend Department Details chart has no data',
    })
    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)])


    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        layout: root.verticalLayout,
        endAngle: 300,
      }),
    )

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: 'value',
        categoryField: 'category',
        endAngle: 300,
      }),
    )

    // Set data
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Setting_data
    series.data.setAll(modifiedArray)
    if (modifiedArray.length === 0) {
      modal.open()
    } else {
      modal.close()
    }
    // Create legend
    // https://www.amcharts.com/docs/v5/charts/percent-charts/legend-percent-series/
    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.percent(50),
        x: am5.percent(50),
        marginTop: 15,
        marginBottom: 15,
      }),
    )

    legend.data.setAll(series.dataItems)

    // Play initial series animation
    // https://www.amcharts.com/docs/v5/concepts/animations/#Animation_of_series
    series.appear(1000, 500)
    return () => root.dispose()
  }, [venDeptDetails])
  return (
    <Box>
      <div id="chartSpendDetailsDiv" style={{ width: '100%', height: '100vh' }}>
      </div>
    </Box>
  )
}
