import * as React from 'react'
import { Grid, Box, Stack } from '@mui/material'
import UserDetails from 'pages/Account/UserDetails/UserDetails'
import CompanyDetails from 'pages/Account/CompanyDetails/CompanyDetails'
import CompanyUsers from 'pages/Account//CompanyUsers/CompanyUsers'
import PaymentList from 'pages/Account/Payments/PaymentList'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { fetchCustomerDetails } from 'reduxStore/customer/customer/customerSlice'
import { fetchCustomerPaymentList } from 'reduxStore/customer/payment/customerPaymentList/customerPaymentListSlice'
import { fetchCompanyUserList } from 'reduxStore/customer/companyUser/companyUserList/companyUserListSlice'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
import UserPreference from './UserPreference'
import { DemoPreference } from './DemoPreference'
import PageTitle from 'components/PageTitle'

export default function AccountDetails() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const updateAccountDetailsById = useAppSelector((state) => state.updateCustomerDetailsById)
  const toggleCompanyUserByID = useAppSelector((state) => state.toggleCompanyUserByID)

  let checkUserCategory = JSON.parse(localStorage.getItem('demoUser')!)

  React.useEffect(() => {
    dispatch(fetchCustomerDetails(Token!))
    dispatch(fetchCustomerPaymentList(Token!))
    dispatch(fetchCompanyUserList(Token!))
    dispatch(fetchAlerts(Token!))
  }, [dispatch, Token])

  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      updateAccountDetailsById !== undefined
        ? 'response' in updateAccountDetailsById
          ? updateAccountDetailsById.response !== undefined
            ? 'message' in updateAccountDetailsById.response
              ? dispatch(fetchCustomerDetails(Token!))
              : null
            : null
          : null
        : null
    }
  }, [updateAccountDetailsById, Token, dispatch])

  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      toggleCompanyUserByID !== undefined
        ? 'toggleCompanyUserByIDRes' in toggleCompanyUserByID
          ? toggleCompanyUserByID.toggleCompanyUserByIDRes !== undefined
            ? 'status' in toggleCompanyUserByID.toggleCompanyUserByIDRes
              ? dispatch(fetchCustomerDetails(Token!))
              : null
            : null
          : null
        : null
    }
  }, [toggleCompanyUserByID, Token, dispatch])

  return (
    <Box sx={{ flexGrow: 1 }} p={2}>
      <PageTitle title={'Account Details'} />
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          spacing={2}
          pb={2}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <UserDetails />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <CompanyDetails />
          </Grid>
        </Grid>
        <Box pb={2}>
          <UserPreference />
        </Box>
        <Box pb={2}>
          <CompanyUsers />
        </Box>
        <Box pb={2}>
          <PaymentList />
        </Box>
        <Box>
          {checkUserCategory !== null ? checkUserCategory ? <DemoPreference /> : null : null}
        </Box>
      </Stack>
    </Box>
  )
}
