import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material'
import { isSubsSectionNameAvailable } from 'components/Utility'
import * as React from 'react'

interface SubscriptionDetailsTotalItem {
  title: string
  id: number
  subtitle: string
  isSelected: boolean
}

const subsDetailsListItem: SubscriptionDetailsTotalItem[] = [
  {
    id: 0,
    title: 'Subscription',
    subtitle: 'Details and overview of the Subscription.',
    isSelected: isSubsSectionNameAvailable('Subscription'),
  },
  {
    id: 1,
    title: 'Licenses',
    subtitle: 'Details and overview of the Licenses.',
    isSelected: isSubsSectionNameAvailable('Licenses'),
  },
  {
    id: 2,
    title: 'Subscriotion Charts',
    subtitle: 'Graphical represenations of usages of user.',
    isSelected: isSubsSectionNameAvailable('Subscriotion Charts'),
  },
  {
    id: 3,
    title: 'Payments',
    subtitle: 'Details and overview of the Payments.',
    isSelected: isSubsSectionNameAvailable('Payments'),
  },
  {
    id: 4,
    title: 'Comments',
    subtitle: 'Details and overview of the Comments.',
    isSelected: isSubsSectionNameAvailable('Comments'),
  },
  {
    id: 5,
    title: 'Documents',
    subtitle: 'Details and overview of the Documents.',
    isSelected: isSubsSectionNameAvailable('Documents'),
  },
  {
    id: 6,
    title: 'Vendor',
    subtitle: 'Details and overview of the Vendor.',
    isSelected: isSubsSectionNameAvailable('Vendor'),
  },
  {
    id: 7,
    title: 'Account Agreement',
    subtitle: 'Details of Account Agreements.',
    isSelected: isSubsSectionNameAvailable('Account Agreement'),
  },
  {
    id: 8,
    title: 'Pricing',
    subtitle: 'Details and overview of the Pricing.',
    isSelected: isSubsSectionNameAvailable('Pricing'),
  },
  {
    id: 9,
    title: 'Cancelation',
    subtitle: 'Details and procedures of the Cancelation.',
    isSelected: isSubsSectionNameAvailable('Cancelation'),
  },
  {
    id: 10,
    title: 'Tags',
    subtitle: 'Details and procedures of the Tags.',
    isSelected: isSubsSectionNameAvailable('Tags'),
  },
]

type Props = {
  companyDetailsDialogEnable?: boolean | any
  companyDetailsUpdateDialogState?: boolean | any
}
export default function CompanyDetailsDialog({
  companyDetailsDialogEnable,
  companyDetailsUpdateDialogState,
}: Props) {
  const [dialogState, setDialogState] = React.useState(false)
  const [isChecked, setIsChecked] = React.useState<boolean[]>(() =>
    subsDetailsListItem.map((item) => {
      return item.isSelected
    }),
  )
  const isCheckboxChecked = (index: number, checked: boolean, changedOptTitle: string) => {
    subsDetailsListItem.forEach((item, idx) => {
      if (item.title === changedOptTitle) {
        subsDetailsListItem[idx].isSelected = checked // Update the isSelected property in the original array
      }
    })
    setIsChecked((isChecked: boolean[]) => {
      return isChecked.map((c, i) => {
        if (i === index) return checked
        return c
      })
    })
  }

  const handleClose = () => {
    setDialogState(false)
    companyDetailsUpdateDialogState(false)
  }

  const handleSubmit = () => {
    let savedList = subsDetailsListItem.map((item) => {
      if (item.isSelected) {
        return item.title
      } else return false
    })
    savedList = savedList.filter((ele) => ele != null)
    const sectionNames = savedList.toString()
    localStorage.setItem('subsSectionName', sectionNames) // Store the updated sectionName in local storage
    companyDetailsUpdateDialogState(false)
  }

  React.useEffect(() => {
    setDialogState(companyDetailsDialogEnable)
  }, [companyDetailsDialogEnable])

  const isSectionChecked = React.useCallback(
    (secName: string) => {
      return isSubsSectionNameAvailable(secName)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSubsSectionNameAvailable],
  )
  // Set default checkbox values when the component mounts
  React.useEffect(() => {
    const defaultCheckedValues = subsDetailsListItem.map(
      (item) => isSectionChecked(item.title) ?? false,
    )
    setIsChecked(defaultCheckedValues)
    // Load the section names from local storage and update the subsDetailsListItem array
    const sectionNamesFromLocalStorage = localStorage.getItem('subsSectionName')
    if (sectionNamesFromLocalStorage) {
      const sectionNamesArray = sectionNamesFromLocalStorage.split(',')
      subsDetailsListItem.forEach((item) => {
        item.isSelected = sectionNamesArray.includes(item.title)
      })
    }
  }, [isSectionChecked])

  return (
    <Dialog
      open={dialogState}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1080px', // Set your width here
          },
        },
      }}
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        <Box pb={3}>
          <Typography variant="subtitle1">
            Choose the cards that you would like displayed on the details screen
          </Typography>
        </Box>
        <Grid container direction="row" alignItems="center" spacing={1}>
          {subsDetailsListItem.map((checkbox, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={3}
                md={3}
                sx={{ border: 1, borderColor: '#134872' }}
                key={index}
              >
                <FormControlLabel
                  key={checkbox.title} // Use a unique key here
                  className="twocolelement"
                  control={
                    <Checkbox
                      name={checkbox.title}
                      value={checkbox.title}
                      id={checkbox.title}
                      checked={isChecked[index]}
                      color="primary"
                      onChange={(e) => isCheckboxChecked(index, e.target.checked, checkbox.title)}
                    />
                  }
                  label={checkbox.title}
                />
                <Typography style={{ fontSize: '11px', color: '#134872' }} variant="h6">
                  <b>{checkbox.subtitle}</b>
                </Typography>
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose()} style={{ backgroundColor: '#dcdcdc', color: '#000' }}>
          Close
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </Dialog>
  )
}
