import {
  Alert,
  AlertColor,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { ToggleActiveByUserIdReq } from 'reduxStore/users/toggleActive/model'
import {
  getToggleActivateByUserID,
  toggleActiveByUserIdClearRes,
} from 'reduxStore/users/toggleActive/toggleActiveSlice'

type Props = {
  itemId?: string | any
  toggleInactiveUserByIDDialogEnable?: boolean | any
  toggleInactiveUserByIDDialogUpdateState?: boolean | any
}

export default function ActiveUserDialog({
  itemId,
  toggleInactiveUserByIDDialogEnable,
  toggleInactiveUserByIDDialogUpdateState,
}: Props) {
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')

  // Call store
  const toggleActiveValue = useAppSelector((state) => state.getToggleActivateByUserId)
  const [netErrMsq, setNetErrMsq] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState('')
  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    toggleInactiveUserByIDDialogUpdateState(false)
    dispatch(toggleActiveByUserIdClearRes())
  }

  let ToggleActiveUserByIDBody = {
    userId: selectedItem,
    token: Token!,
  } as ToggleActiveByUserIdReq

  const reActivateUsers = async () => {
    if (navigator.onLine) {
      setNetErrMsq('')
      setTimeout(() => {
        handleClose()
      }, 1000)
      await dispatch(getToggleActivateByUserID(ToggleActiveUserByIDBody))
    } else {
      setNetErrMsq('You are currently offline')
    }
  }
  const [showAPISuccessMsgView, setShowAPISuccessMsgView] = React.useState(false)
  const [apiResMsg, setAPIresMsg] = useState('')
  const [msgType, setMsgType] = useState<AlertColor>('success')
  React.useEffect(() => {
    if (toggleActiveValue?.error !== '') {
      setShowAPISuccessMsgView(true)
      setMsgType('error')
      setAPIresMsg(toggleActiveValue?.error)
    }
  }, [toggleActiveValue])
  React.useEffect(() => {
    setSelectedItem(itemId)
    setDialogState(toggleInactiveUserByIDDialogEnable)
  }, [itemId, toggleInactiveUserByIDDialogEnable])
  return (
    <Dialog open={dialogState} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <DialogContentText>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Are you sure you want to Active this User?
          </Typography>
        </DialogContentText>
        {netErrMsq !== '' ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <Typography sx={{ color: '#c62828' }}>{netErrMsq}</Typography>
            </Grid>
          </Grid>
        ) : null}

        <Collapse in={showAPISuccessMsgView}>
          <Alert
            icon={false}
            severity={msgType}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAPISuccessMsgView(false)
                  dispatch(toggleActiveByUserIdClearRes())
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {apiResMsg !== '' ? apiResMsg : null}
          </Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => reActivateUsers()}
          disabled={toggleActiveValue.loading}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography>Yes</Typography>
            </Grid>
            {toggleActiveValue.loading ? (
              <Grid item>
                <CircularProgress color="inherit" size={18} />
              </Grid>
            ) : null}
          </Grid>
        </Button>
        <Button variant="outlined" onClick={handleClose} disabled={toggleActiveValue.loading}>
          <Typography sx={{ color: '#3f51b5' }}>No</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
