import * as React from 'react'
import { useAppSelector, useAppDispatch } from '../../reduxStore/hooks'
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
  AlertColor,
} from '@mui/material'

import { Results } from 'reduxStore/alert/Active/Model'
import { HideAlertReq } from 'reduxStore/alert/Hide/Model'
import { hideAlerts, updateHideAlertClearRes } from 'reduxStore/alert/Hide/hideAlertSlice'
import Accordians from '../Accordians'
import ApplogieSnackbar from '../ApplogieSnackbar'

type Props = {
  alertSuccessMessage?: (message: string) => void
}

export const SubscriptionAlertDetails = ({ alertSuccessMessage }: Props) => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const subsAlertListValue = useAppSelector((state) => state.optimizationsDetailsbyAccountID)

  const [alertListErrorMsg, setAlertListErrorMsg] = React.useState('')
  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setAlertListErrorMsg('')
  }
  // for alert list api call
  React.useEffect(() => {
    if (subsAlertListValue.error !== undefined) {
      setHideAlertMsgType('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert])

  const [hideItem, setHideItem] = React.useState(0)

  // get data from child component
  const selectedItem = (passedval: any) => {
    handleAlertViewModalOpen(passedval)
  }
  // Modal
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [alertViewModalOpen, setAlertViewModalOpen] = React.useState(false)
  // Open Modal Action
  const handleAlertViewModalOpen = (id: number) => {
    id !== undefined ? setAlertViewModalOpen(true) : setAlertViewModalOpen(false)
    // setSelectedItem(id)
    setHideItem(id)
  }

  const hideAlert = async () => {
    let hideModalParam = {
      id: String(hideItem),
      token: Token!,
    } as HideAlertReq
    await dispatch(hideAlerts(hideModalParam))
  }
  const hideAlertRes = useAppSelector((state) => state.hideAlert)
  const [hideAlertMsg, setHideAlertMsg] = React.useState('')
  const [hideAlertMsgType, setHideAlertMsgType] = React.useState<AlertColor>('success')
  const closeAddUserSnackbar = () => {
    dispatch(updateHideAlertClearRes())
    setHideAlertMsg('')
  }
  // for alert hide api call
  React.useEffect(() => {
    if (hideAlertRes?.successMsg !== '') {
      setHideAlertMsgType('success')
      setHideAlertMsg(hideAlertRes?.successMsg)
      alertSuccessMessage && alertSuccessMessage(hideAlertRes?.successMsg)
      setAlertViewModalOpen(false)
    }
    if (hideAlertRes?.error !== '') {
      setHideAlertMsgType('error')
      setHideAlertMsg(hideAlertRes?.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideAlertRes])

  // Close Modal Action
  const handleAlertViewModalClose = () => {
    setAlertViewModalOpen(false)
  }

  return (
    <Box>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {subsAlertListValue.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Grid sx={{ maxHeight: '340px', overflow: 'auto' }}>
              {subsAlertListValue.optimizations.results.length > 0 ? (
                subsAlertListValue.optimizations.results.map((item: Results) => (
                  <Box p={1} key={item.id}>
                    <Accordians item={item} selectedItem={selectedItem} />
                  </Box>
                ))
              ) : (
                <Typography> No content available</Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Dialog
        fullScreen={fullScreen}
        open={alertViewModalOpen}
        onClose={handleAlertViewModalClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            <strong>Are you sure you want to hide this alert for all users?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => hideAlert()}
            disabled={hideAlertRes?.loading}
            style={{
              backgroundColor: '#30a289',
            }}
          >
            {hideAlertRes?.loading ? 'Loading...' : 'Hide Alert'}
          </Button>
          <Button
            variant="outlined"
            disabled={hideAlertRes?.loading}
            onClick={handleAlertViewModalClose}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ApplogieSnackbar msg={alertListErrorMsg} type={hideAlertMsgType} alertClose={handleClose} />
      <ApplogieSnackbar
        msg={hideAlertMsg}
        type={hideAlertMsgType}
        alertClose={closeAddUserSnackbar}
      />
    </Box>
  )
}
