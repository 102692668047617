import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import {
  OptimizationsDetailsByAccIdRes,
  OptimizationsDetailsByAccIdBody,
} from './optimizationModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  optimizations: OptimizationsDetailsByAccIdRes
  error: string
}
const initialState: InitialState = {
  loading: false,
  optimizations: {} as OptimizationsDetailsByAccIdRes,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchOptimizationsDetailsByAccID = createAsyncThunk<
  OptimizationsDetailsByAccIdRes,
  OptimizationsDetailsByAccIdBody
>(
  'optimizationsDetailsByAccID/fetchOptimizationsDetailsByAccID',
  async (reqParam: OptimizationsDetailsByAccIdBody, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return await setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/alert?cost_based=false&account=${reqParam.id}`, { headers })
      .then((response) => response.data)
      .catch(
        (error) =>
          rejectWithValue(error.response.data[0].detail) ||
          error.response.data.message ||
          'Something went wrong',
      )
  },
)

const optimizationsDetailsByAccIDSlice = createSlice({
  name: 'optimizationsDetailsByAccID',
  initialState,
  reducers: {
    optimizationDetailsByAccIdClearMsg: (state) => {
      state.error = ''
    },
    optimizationDetailsByAccIdClearRes: (state) => {
      state.error = ''
      state.optimizations = {} as OptimizationsDetailsByAccIdRes
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchOptimizationsDetailsByAccID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchOptimizationsDetailsByAccID.fulfilled,
      (state, action: PayloadAction<OptimizationsDetailsByAccIdRes>) => {
        state.loading = false
        state.optimizations = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchOptimizationsDetailsByAccID.rejected, (state, action) => {
      state.loading = false
      state.optimizations = {} as OptimizationsDetailsByAccIdRes
      state.error = String(action.payload!)
    })
  },
})
export const { optimizationDetailsByAccIdClearMsg, optimizationDetailsByAccIdClearRes } =
  optimizationsDetailsByAccIDSlice.actions
export default optimizationsDetailsByAccIDSlice.reducer
