import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-premium'
import React from 'react'
import dayjs from 'dayjs'
import { useLocation } from 'react-router-dom'
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle'

export function BreachesTableColumn() {
  const location = useLocation()
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Vendor Name',
      sortable: true,
      hideable: false,
      minWidth: 260,
      flex: 1,
      align: 'left',
    },
    {
      field: 'date',
      headerName: 'Breach Date',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'right',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>
          {params.row.renewalDate !== '---'
            ? dayjs(params.row.renewalDate!).format('MMM DD, YYYY')
            : null}
        </React.Fragment>
      ),
    },

    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'center',
      minWidth: 80,
      renderCell: (params) =>
        location.pathname === '/dashboard' ? null : (
          <CustomDetailPanelToggle id={params.id} value={params.value} />
        ),
      renderHeader: (_params: GridColumnHeaderParams) => <span>{'Action'}</span>,
    },
  ]
  return columns
}
