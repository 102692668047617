import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { GetUsageListByLicenseIdResponse, GetUsageListByLicenseIdBody } from './Model'

type InitialState = {
  loading: boolean
  response: GetUsageListByLicenseIdResponse
  successMessage: string
  errorMessage: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as GetUsageListByLicenseIdResponse,
  successMessage: '',
  errorMessage: '',
}

export const getUsageListByLicenseID = createAsyncThunk<
  GetUsageListByLicenseIdResponse,
  GetUsageListByLicenseIdBody
>(
  'getUsageListByLicenseID/getUsageListByLicenseID',
  async (payload: GetUsageListByLicenseIdBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + `/api/accountlicenseusage/get_usage_by_license_id`, payload.mainBody, {
        headers,
      })
      .then((response) => response.data)
      .catch((errorMessage) =>
        rejectWithValue(
          errorMessage?.response?.data !== undefined
            ? errorMessage?.response?.data[0]?.description !== undefined
              ? errorMessage?.response?.data[0]?.description
              : errorMessage.response.data.message !== undefined
              ? errorMessage.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const getUsageListByLicenseIDSlice = createSlice({
  name: 'getUsageListByLicenseID',
  initialState,
  reducers: {
    updateLicenseClearMsg: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
    },
    updateLicenseClearRes: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
      state.response = {} as GetUsageListByLicenseIdResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsageListByLicenseID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      getUsageListByLicenseID.fulfilled,
      (state, action: PayloadAction<GetUsageListByLicenseIdResponse>) => {
        state.loading = false
        state.response = action.payload
        state.successMessage = 'Usage list by license list has been fetched successfully'
        state.errorMessage = ''
      },
    )
    builder.addCase(getUsageListByLicenseID.rejected, (state, action) => {
      state.loading = false
      state.response = {} as GetUsageListByLicenseIdResponse
      state.successMessage = ''
      state.errorMessage = String(action.payload!)
    })
  },
})
export const { updateLicenseClearMsg, updateLicenseClearRes } =
getUsageListByLicenseIDSlice.actions

export default getUsageListByLicenseIDSlice.reducer
