import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { AddLicenseResponse, AddLicenseBody } from './addLicenseModel'

type InitialState = {
  loading: boolean
  response: AddLicenseResponse
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as AddLicenseResponse,
  succssMessage: '',
  error: '',
}

export const addLicense = createAsyncThunk<AddLicenseResponse, AddLicenseBody>(
  'addLicense/addLicense',
  async (payload: AddLicenseBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + `/api/accountlicense/create_license_and_count`, payload.updateBody, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data[0].detail))
  },
)

const addLicenseSlice = createSlice({
  name: 'addLicense',
  initialState,
  reducers: {
    addLicenseClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    addLicenseClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.response = {} as AddLicenseResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addLicense.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addLicense.fulfilled, (state, action: PayloadAction<AddLicenseResponse>) => {
      state.loading = false
      state.response = action.payload
      state.succssMessage =
        action?.payload?.succssMessage !== undefined
          ? action?.payload?.succssMessage !== ''
            ? action?.payload?.succssMessage
            : 'License added successful'
          : 'License added successful'
      state.error = ''
    })
    builder.addCase(addLicense.rejected, (state, action) => {
      state.loading = false
      state.response = {} as AddLicenseResponse
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})

export const { addLicenseClearMsg, addLicenseClearRes } = addLicenseSlice.actions
export default addLicenseSlice.reducer
