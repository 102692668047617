import { Card, CardContent } from '@mui/material'
import * as React from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import TabContainer from './TabContainer'

export default function CompanyDetailsChart() {
  const subsValue = useAppSelector((state) => state.accountDetails)
  const [showTab, setShowTab] = React.useState(false)
  const [showAmazonTab, setShowAmazonTab] = React.useState(false)
  const [showGcpTab, setShowGcpTab] = React.useState(false)
  const [showSalesTab, setShowSalesTab] = React.useState(false)

  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      subsValue.accountDetails?.vendor?.name !== undefined
        ? subsValue.accountDetails?.vendor?.name === 'Microsoft'
          ? setShowTab(true)
          : setShowTab(false)
        : setShowTab(false)
    }
    // eslint-disable-next-line no-lone-blocks
    {
      subsValue.accountDetails?.vendor?.name !== undefined
        ? subsValue.accountDetails?.vendor?.name === 'Amazon - AWS'
          ? setShowAmazonTab(true)
          : setShowAmazonTab(false)
        : setShowAmazonTab(false)
    }
    // eslint-disable-next-line no-lone-blocks
    {
      subsValue.accountDetails?.vendor?.name !== undefined
        ? subsValue.accountDetails?.vendor?.name.includes('Google')
          ? setShowGcpTab(true)
          : setShowGcpTab(false)
        : setShowGcpTab(false)
    }
    // eslint-disable-next-line no-lone-blocks
    {
      subsValue.accountDetails?.vendor?.name !== undefined
        ? subsValue.accountDetails?.vendor?.name === 'Amazon - AWS' ||
          subsValue.accountDetails?.vendor?.name === 'Microsoft' ||
          subsValue.accountDetails?.vendor?.name === 'Salesforce'
          ? setShowSalesTab(true)
          : setShowSalesTab(false)
        : setShowSalesTab(false)
    }
    // eslint-disable-next-line no-lone-blocks
  }, [subsValue])

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <TabContainer
          microsoft={showTab}
          amazon={showAmazonTab}
          gcp={showGcpTab}
          users={showSalesTab}
        />
      </CardContent>
    </Card>
  )
}
