import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { fetchBenchMarkChart } from 'reduxStore/account/benchMark/benchMarkChartSlice'
import { BenchMarkChartListReq } from 'reduxStore/account/benchMark/Model'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)
export default function BenchmarkChart() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const benchMarkChartValue = useAppSelector((state) => state.benchMarkChart)

  // Get the userId param from the URL.
  let { id } = useParams()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const [data, setData] = useState([] as am5.Chart[])
  let modifiedArray: any[] = []

  const addOrUpdateChart = (
    license_count: any,
    value: any,
    period: any,
    account_id: any,
    subscriptionName: any,
  ) => {
    var adjusted_value: number = parseFloat(value)
    var suffix: string = ''
    let valueName: string = ''

    // Let's make your own different
    if (parseInt(id!) === parseInt(account_id)) {
      suffix = '3'
    }
    if (period === 1) {
      //Annual
      if (suffix !== '3') {
        suffix = '2'
      }
      if (license_count) {
        adjusted_value = value / license_count / 12
      }
      valueName = 'annual'
    } else {
      if (license_count) {
        adjusted_value = value / license_count
      }
      valueName = 'monthly'
    }

    modifiedArray.push({
      ['x' + suffix]: Number(license_count),
      ['y' + suffix]: Number(adjusted_value.toFixed(2)),
      [valueName]: Number(adjusted_value.toFixed(2)),
      period: period === 0 ? 'Unknown' : period === 1 ? 'Annual' : 'Monthly',
      name: subscriptionName,
    })
    setData(modifiedArray)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return true
  }

  useEffect(() => {
    let getBenchMarkChartbody = {
      id: String(id),
      token: Token!,
    } as BenchMarkChartListReq
    dispatch(fetchBenchMarkChart(getBenchMarkChartbody))
  }, [dispatch, Token, id])

  useEffect(() => {
    if (benchMarkChartValue?.benchmarkChart?.results !== undefined) {
      if (benchMarkChartValue.benchmarkChart.results.length > 0) {
        for (var i = 0; i < benchMarkChartValue.benchmarkChart.results.length; i++) {
          addOrUpdateChart(
            benchMarkChartValue.benchmarkChart.results[i].license_count,
            benchMarkChartValue.benchmarkChart.results[i].value,
            benchMarkChartValue.benchmarkChart.results[i].period,
            benchMarkChartValue.benchmarkChart.results[i].account.id,
            benchMarkChartValue.benchmarkChart.results[i].sku_part_number,
          )
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [benchMarkChartValue])

  useEffect(() => {
    let root = am5.Root.new('subscriptionbenchMarkChart')
    var modal = am5.Modal.new(root, {
      content: 'benchmark chart has no data',
    })
    root.setThemes([am5themes_Animated.new(root)])

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelY: 'zoomXY',
        pinchZoomX: true,
        pinchZoomY: true,
      }),
    )

    let xAxis = chart.xAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 50 }),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    )

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: 0,
        // baseValue: 0.1,
        // maxPrecision: 0,
        // strictMinMax: true,
        // extraMax: 10,
        renderer: am5xy.AxisRendererY.new(root, {}),
        numberFormat: "'$'#,###",
        tooltip: am5.Tooltip.new(root, {}),
      }),
    )

    let bulletXSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Monthly Annual Rate',
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'y',
        valueXField: 'x',
        valueField: 'x',
        fill: am5.Color.fromString('#01cba3'),
        tooltip: am5.Tooltip.new(root, {
          labelText: '$ {y}',
        }),
      }),
    )

    let circleTemplate = am5.Template.new({})
    bulletXSeries.bullets.push(function () {
      let graphics = am5.Circle.new(root, {
        fill: bulletXSeries.get('fill'),
      })
      return am5.Bullet.new(root, {
        sprite: graphics,
      })
    })

    bulletXSeries.set('heatRules', [
      {
        target: circleTemplate,
        min: 3,
        max: 35,
        dataField: 'value',
        key: 'radius',
      },
    ])

    let starTemplate = am5.Template.new({})
    let bulletX1Series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Month-to-Month Rate',
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'y2',
        valueXField: 'x2',
        valueField: 'x2',
        fill: am5.Color.fromString('#134872'),
        tooltip: am5.Tooltip.new(root, {
          labelText: '$ {y2}',
        }),
      }),
    )

    bulletX1Series.bullets.push(function () {
      let graphics = am5.Circle.new(root, {
        fill: bulletX1Series.get('fill'),
      })
      return am5.Bullet.new(root, {
        sprite: graphics,
      })
    })

    bulletX1Series.set('heatRules', [
      {
        target: starTemplate,
        min: 3,
        max: 50,
        dataField: 'value',
        key: 'radius',
      },
    ])

    let triangleSeries = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: 'Your Rate',
        calculateAggregates: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'y3',
        valueXField: 'x3',
        valueField: 'x3',
        fill: am5.Color.fromString('#FF4872'),
        tooltip: am5.Tooltip.new(root, {
          labelText: 'Name: {name} \nPeriod: {period} \n$ {y3}',
        }),
      }),
    )

    triangleSeries.bullets.push(function () {
      let graphics = am5.Star.new(root, {
        fill: triangleSeries.get('fill'),
        spikes: 4,
        innerRadius: am5.percent(70),
      })
      return am5.Bullet.new(root, {
        sprite: graphics,
      })
    })

    triangleSeries.set('heatRules', [
      {
        target: starTemplate,
        min: 3,
        max: 50,
        dataField: 'value',
        key: 'radius',
      },
    ])

    bulletXSeries.strokes.template.set('strokeOpacity', 0)
    bulletX1Series.strokes.template.set('strokeOpacity', 0)
    triangleSeries.strokes.template.set('strokeOpacity', 0)

    chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        behavior: 'zoomXY',
        snapToSeries: [bulletXSeries, bulletX1Series, triangleSeries],
      }),
    )

    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
      }),
    )

    chart.set(
      'scrollbarY',
      am5.Scrollbar.new(root, {
        orientation: 'vertical',
      }),
    )

    let legend = chart.children.push(
      am5.Legend.new(root, {
        y: am5.percent(100),
        x: am5.percent(23),
        layout: root.horizontalLayout,
      }),
    )
    legend.data.setAll(chart.series.values)

    if (data.length === 0) {
      modal.open()
    } else {
      modal.close()
    }

    let exporting = am5plugins_exporting.Exporting.new(root, {})
    exporting.setAll({
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: 'BenchmarkChart',
      dataSource: data,
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
    })

    bulletXSeries.data.setAll(data)
    bulletX1Series.data.setAll(data)
    triangleSeries.data.setAll(data)

    bulletXSeries.appear(1000)
    bulletX1Series.appear(1000)
    triangleSeries.appear(1000)

    chart.appear(1000, 100)
    return () => root.dispose()
  }, [data])

  return (
    <Box>
      <div id="subscriptionbenchMarkChart" style={{ width: '100%', height: '620px' }}></div>
    </Box>
  )
}
