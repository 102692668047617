import { Alert, Box, CircularProgress, Collapse, Grid, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { Uploader } from 'rsuite'
import FileUploadIcon from '@mui/icons-material/FileUpload'
import url from 'APIConfig'
import { useAppDispatch } from 'reduxStore/hooks'
import { fetchSubscription } from 'reduxStore/subcription/Active/subcriptionSlice'

type Props = {
  selectedPaymentVendorShName?: string
  uploadbody: {}
  dropdownchangedValue: boolean | any
  updatedSuccessMsgState: boolean | any
  fileValue: boolean | any
  updatedFileUploadState: boolean | any
}

export default function UploadFile({
  selectedPaymentVendorShName,
  uploadbody,
  dropdownchangedValue,
  updatedSuccessMsgState,
  fileValue,
  updatedFileUploadState,
}: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const [successMsg, setSuccessMsg] = React.useState(false)
  const [openAlert, setAlert] = React.useState(false)
  const closeAlert = () => {
    setAlert(false)
  }
  const [loader, setLoader] = React.useState(false)

  function Loader() {
    if (loader === false) {
      setLoader(true)
    } else {
      setLoader(false)
    }
  }

  let headers = {
    Authorization: `Token ${Token!}`,
  }

  let uploadUrl = url.backEndURL + '/api/paymentCSV'

  React.useEffect(() => {}, [selectedPaymentVendorShName, dropdownchangedValue])

  React.useEffect(() => {
    if (dropdownchangedValue === true) {
      setAlert(false)
    }
  }, [selectedPaymentVendorShName, dropdownchangedValue, uploadbody])

  const page =
    selectedPaymentVendorShName === 'PD' ||
    selectedPaymentVendorShName === 'MX' ||
    selectedPaymentVendorShName === 'SO' ? null : (
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Collapse in={openAlert} timeout="auto" unmountOnExit>
          <Box m={3}>
            <Alert severity="success" onClose={closeAlert}>
              {successMsg}
            </Alert>
          </Box>
        </Collapse>
        <Box
          style={{
            textAlign: 'center',
            border: '2px #c4c2c2 dotted',
            borderRadius: '2px',
            width: '375px',
          }}
          p={2}
        >
          <Stack>
            {loader === true ? (
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item>
                  <CircularProgress color="inherit" size={30} />
                </Grid>
              </Grid>
            ) : null}
            <Uploader
              action={uploadUrl}
              data={uploadbody}
              name="payment_document"
              accept=".xlsx,.csv"
              method="POST"
              headers={headers}
              onSuccess={(response: any) => {
                setSuccessMsg(response[0])
                setAlert(true)
                setLoader(false)
                updatedSuccessMsgState(false)
                updatedFileUploadState(true)
                dispatch(fetchSubscription(Token!))
              }}
              onUpload={() => {
                Loader()
              }}
              onError={() => {
                setLoader(false)
              }}
              draggable
              fileListVisible={fileValue}
            >
              <button type="button">
                <FileUploadIcon />
              </button>
            </Uploader>
          </Stack>
          <Typography style={{ color: 'gray' }}>
            Accepts only .csv or .xlsx file for upload.
          </Typography>
        </Box>
      </Grid>
    )

  return <div>{page}</div>
}
