import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  EditCompanyUserBody,
  MainBody,
} from 'reduxStore/customer/companyUser/editCompanyUser/editCompanyUserModel'
import { editCompanyUserApi } from 'reduxStore/customer/companyUser/editCompanyUser/editCompanyUserSlice'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
interface Props {
  item: any
}
export default function EditCompanyUser({ item }: Props) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [width, setWidth] = useState('')
  useEffect(() => {
    setWidth(fullScreen ? '100%' : '30vw')
  }, [fullScreen])
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const editCompanyUserValue = useAppSelector((state) => state.editCompanyUserRes)
  const [userName, setUserName] = useState('')
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  useEffect(() => {
    if (item !== undefined) {
      setUserName(item.userName)
      setEmail(item.email)
      setFirstName(item.firstName)
      setLastName(item.lastName)
      setPhoneNumber(item.phoneNumber)
    } else {
      setUserName('')
      setEmail('')
      setFirstName('')
      setFirstName('')
      setLastName('')
      setPhoneNumber('')
    }
  }, [item])
  const updateUserDetails = () => {
    let editApiCallBody = {} as EditCompanyUserBody
    editApiCallBody = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      password: password,
      phone_number: phoneNumber,
      username: userName,
    } as EditCompanyUserBody
    let mainBody = {
      id: item.id,
      token: Token!,
      body: editApiCallBody,
    } as MainBody
    dispatch(editCompanyUserApi(mainBody))
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ py: 2, height: '100%', boxSizing: 'border-box', backgroundColor: '#f2f2f2' }}
    >
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ minWidth: width, maxWidth: width }}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Typography variant="h6" gutterBottom component="div">
              Edit User
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{ minWidth: width, maxWidth: width }}
          >
            <Divider style={{ color: '#000000', width: '100%' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ minWidth: width, maxWidth: width }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          columnSpacing={{ xs: 2, sm: 3, md: 4 }}
          rowSpacing={2}
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionName-id"
              label="Username"
              variant="standard"
              value={userName}
              onChange={(event) => setUserName(event.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionAliasName-id"
              label="Email"
              variant="standard"
              fullWidth
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionAliasName-id"
              label="First Name"
              variant="standard"
              fullWidth
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionAliasName-id"
              label="Last Name"
              variant="standard"
              fullWidth
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionAliasName-id"
              label="Phone Number"
              variant="standard"
              fullWidth
              value={phoneNumber}
              onChange={(e) => {
                setPhoneNumber(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField
              id="subscriptionAliasName-id"
              label="Phone Number"
              variant="standard"
              fullWidth
              value={password}
              onChange={(e) => {
                setPassword(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
              <Grid item>
                <Button
                  onClick={() => updateUserDetails()}
                  variant="contained"
                  fullWidth={fullScreen ? true : false}
                  disabled={editCompanyUserValue.loading}
                >
                  {editCompanyUserValue.loading === true ? (
                    <Stack direction="row" spacing={1}>
                      <Typography>Save</Typography>
                      <Box pl={1}>
                        <CircularProgress color="inherit" size={18} />
                      </Box>
                    </Stack>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
