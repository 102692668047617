import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { SearchTagSourceReq, Results } from './Model'

type InitialState = {
    loading: boolean
    searchTagSource: Results
    error: string
}
const initialState: InitialState = {
    loading: false,
    searchTagSource: {} as Results,
    error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchTagSearchlist = createAsyncThunk<Results, SearchTagSourceReq>('searchTagSource/fetchTagSearchlist', (reqParam: SearchTagSourceReq) => {
    let headers = {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
        .get(url.backEndURL + `/api/account-tags-search/${reqParam.name}/False/`, { headers })
        .then((response) => response.data)
})

const searchTagListSlice = createSlice({
    name: 'searchTagSource',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTagSearchlist.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchTagSearchlist.fulfilled, (state, action: PayloadAction<Results>) => {
            state.loading = false
            state.searchTagSource = action.payload
            state.error = ''
        })
        builder.addCase(fetchTagSearchlist.rejected, (state, action) => {
            state.loading = false
            state.searchTagSource = {} as Results
            state.error = action.error.message || 'Something went wrong'
        })
    },
})

export default searchTagListSlice.reducer
