import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { UpdateBudgetBody, UpdateBudgetRes } from './Model'

type InitialState = {
  loading: boolean
  response: UpdateBudgetRes
  successMessage: string
  errorMessage: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as UpdateBudgetRes,
  successMessage: '',
  errorMessage: '',
}

export const updateBudgetDetails = createAsyncThunk<
UpdateBudgetRes,
UpdateBudgetBody
>(
  'updateBudgetDetails/updateBudgetDetails',
  async (payload: UpdateBudgetBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
    .post(Url.backEndURL + '/api/budget', payload, { headers })
      .then((response) => response.data)
      .catch((errorMessage) =>
        rejectWithValue(
          errorMessage?.response?.data !== undefined
            ? errorMessage?.response?.data[0]?.description !== undefined
              ? errorMessage?.response?.data[0]?.description
              : errorMessage.response.data.message !== undefined
              ? errorMessage.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const updateBudgetDetailsSlice = createSlice({
  name: 'updateBudgetDetails',
  initialState,
  reducers: {
    updateBudgetClearMsg: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
    },
    updateBudgetClearRes: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
      state.response = {} as UpdateBudgetRes
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBudgetDetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      updateBudgetDetails.fulfilled,
      (state, action: PayloadAction<UpdateBudgetRes>) => {
        state.loading = false
        state.response = action.payload
        state.successMessage = 'Budget details has been updated successfully'
        state.errorMessage = ''
      },
    )
    builder.addCase(updateBudgetDetails.rejected, (state, action) => {
      state.loading = false
      state.response = {} as UpdateBudgetRes
      state.successMessage = ''
      state.errorMessage = String(action.payload!)
    })
  },
})
export const { updateBudgetClearMsg, updateBudgetClearRes } =
updateBudgetDetailsSlice.actions

export default updateBudgetDetailsSlice.reducer

