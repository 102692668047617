import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { UpdatePaymentReq, UpdatePaymentRes } from './AccountUpdatePaymentModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  updatePaymentList: UpdatePaymentRes
  succssMessage: string
  error: string
  enableDialog: boolean
  selectedItem: any
}
const initialState: InitialState = {
  loading: false,
  updatePaymentList: {} as UpdatePaymentRes,
  succssMessage: '',
  error: '',
  enableDialog: false,
  selectedItem: {} as any,
}

// Generates pending, fulfilled and rejected action types
export const updatePayment = createAsyncThunk<UpdatePaymentRes, UpdatePaymentReq>(
  'updatePayment/updatePayment',
  (reqParam: UpdatePaymentReq, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .post(url.backEndURL + `/api/payment/add_edit_account_payment`, reqParam?.updateBody, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data[0]?.description !== undefined
              ? error?.response?.data[0]?.description
              : error.response.data.message !== undefined
              ? error.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const updatePaymentSlice = createSlice({
  name: 'updatePaymentDetails',
  initialState,
  reducers: {
    updatePaymentClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    updatePaymentClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.updatePaymentList = {} as UpdatePaymentRes
    },
    updateSubscriptionPaymentEditDialogState: (state, action) => {
      state.enableDialog = action.payload
    },
    selectedSubscriptionPaymentDetails: (state, action) => {
      state.selectedItem = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePayment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(updatePayment.fulfilled, (state, action: PayloadAction<UpdatePaymentRes>) => {
      state.loading = false
      state.updatePaymentList = action.payload
      state.succssMessage =
        action?.payload?.message !== undefined
          ? action?.payload?.message !== ''
            ? action?.payload?.message
            : 'Payment Edited successfully'
          : 'Payment Edited successfully'
      state.error = ''
    })
    builder.addCase(updatePayment.rejected, (state, action) => {
      state.loading = false
      state.updatePaymentList = {} as UpdatePaymentRes
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})
// Action creators are generated for each case reducer function
export const {
  updatePaymentClearMsg,
  updatePaymentClearRes,
  updateSubscriptionPaymentEditDialogState,
  selectedSubscriptionPaymentDetails,
} = updatePaymentSlice.actions
export default updatePaymentSlice.reducer
