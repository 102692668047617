import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { TagDetailsModelReq, TagListByIdModel } from './TagListByIdModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  addTagRes: TagListByIdModel
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  addTagRes: {} as TagListByIdModel,
  succssMessage: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const addTag = createAsyncThunk<TagListByIdModel, TagDetailsModelReq>(
  'addTag/addTag',
  (reqParam: TagDetailsModelReq, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/account-tag-add/${reqParam.accountId}/${reqParam.tagVal}`, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error !== undefined
            ? error.response !== undefined
              ? error.response.data !== undefined
              : 'Something went wrong'
            : 'Something went wrong',
        ),
      )
  },
)

const addTagSlice = createSlice({
  name: 'addTag',
  initialState,
  reducers: {
    addTagClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    addTagClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.addTagRes = {} as TagListByIdModel
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addTag.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addTag.fulfilled, (state, action: PayloadAction<TagListByIdModel>) => {
      state.loading = false
      state.addTagRes = action.payload
      state.succssMessage =
        action?.payload?.succssMessage !== undefined
          ? action?.payload?.succssMessage !== ''
            ? action?.payload?.succssMessage
            : 'Tag added successful'
          : 'tag added successful'
      state.error = ''
    })
    builder.addCase(addTag.rejected, (state, action) => {
      state.loading = false
      state.addTagRes = {} as TagListByIdModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { addTagClearMsg, addTagClearRes } = addTagSlice.actions
export default addTagSlice.reducer
