import * as React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import firebase from 'firebase/compat/app'
import 'firebase/compat/messaging'
import { Alert, AlertTitle, Box, Grid, Snackbar, Typography } from '@mui/material'
import { UserDeviceBody, UserDeviceRawBody } from 'reduxStore/userdeviceFcmToken/Model'
import { userDevice } from 'reduxStore/userdeviceFcmToken/userdeviceSlice'
import ResponsiveLogo from '../../images/logo_full-color.svg'
export default function FirebasePushNotification() {
  const dispatch = useAppDispatch()
  // Firebase cloud messaging
  // Your web app's Firebase configuration
  var firebaseConfig = {
    apiKey: 'AIzaSyBL7xkfXc1kcD4qFE4lj0lgdbaWwtaV_EQ',
    authDomain: 'applogie-26fc5.firebaseapp.com',
    projectId: 'applogie-26fc5',
    storageBucket: 'applogie-26fc5.appspot.com',
    messagingSenderId: '6967090870',
    appId: '1:6967090870:web:54c15e715500b527251017',
    measurementId: 'G-GNPQX6DGVV',
  }
  firebase.initializeApp(firebaseConfig)
  const messaging = firebase.messaging()
  const [PushNotificationTitle, setPushNotificationTitle] = React.useState('')
  const [PushNotificationBody, setPushNotificationBody] = React.useState('')
  const [fcm, setFcm] = React.useState('')
  const [openToast, setOpenToast] = React.useState(false)

  const [userToken, setUserToken] = React.useState('')

  const handlePushMessageBannerDismiss = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }

    setPushNotificationTitle('')
    setPushNotificationBody('')
    setOpenToast(false)
  }
  const userLoginToken = localStorage.getItem('token')

  const adminLoginDetails = useAppSelector((state) => state.login)

  React.useEffect(() => {
    adminLoginDetails?.response?.token !== undefined
      ? setUserToken(adminLoginDetails?.response?.token)
      : userLoginToken !== null
      ? setUserToken(userLoginToken)
      : setUserToken('')
  }, [adminLoginDetails, userLoginToken])

  React.useEffect(() => {
    if (userLoginToken !== null && userToken !== null) {
      dispatch(userDevice(mainBody))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoginToken])

  const pushNotificationDiv = PushNotificationTitle ? (
    <Snackbar
      open={openToast}
      autoHideDuration={15000}
      onClose={handlePushMessageBannerDismiss}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert
        onClose={handlePushMessageBannerDismiss}
        severity="info"
        icon={false}
        sx={{ width: '100%' }}
      >
        <Grid container spacing={2}>
          <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
            <img src={ResponsiveLogo} width="50" height="70" alt="" />
          </Grid>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <AlertTitle sx={{ fontWeight: 'bold' }}>{PushNotificationTitle}</AlertTitle>
                <Typography variant="subtitle1">{PushNotificationBody}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Alert>
    </Snackbar>
  ) : (
    ''
  )

  let addLicenseBody = {
    token: fcm,
  } as UserDeviceRawBody
  let mainBody = {
    updateBody: addLicenseBody,
    token: userLoginToken!,
  } as UserDeviceBody

  
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      messaging
        .getToken()
        .then((currentToken: any) => {
          if (currentToken) {
            setFcm(currentToken)
          } else {
            console.log(' No Instance ID token available. Request permission to generate one.')
          }
        })
        .catch((err: any) => {
          console.log('An Error occured while retreving token. ', err)
        })
    }
  })

  messaging.onMessage(function (payload) {
    setPushNotificationTitle(payload.notification.title)
    setPushNotificationBody(payload.notification.body)
    // window.alert(payload.notification.body)
    setOpenToast(true)
  })
  return <Box>{pushNotificationDiv}</Box>
}
