import React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AppBar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  ThemeProvider,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { createTheme } from '@mui/material/styles'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import MenuIcon from '@mui/icons-material/Menu'
import FeatureImage from '../../images/applogie-logo.svg'
import ResponsiveLogo from '../../images/mstile-70x70.png'
import { User } from 'reduxStore/login/Model'
import { useAppSelector } from 'reduxStore/hooks'
import BasicPopover from './QuickActionButton'

const appBarButton = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
            color: '#ffffff',
          },
        },
      },
    },
  },
})

interface Props {
  pageName: string
  tabLayout: any
  updateDrawerState: () => void
  updateAlertDialogState: () => void
  appLogout: () => void
}
export function ApplogieAppBar({
  pageName,
  tabLayout,
  updateDrawerState,
  updateAlertDialogState,
  appLogout,
}: Props) {
  const token = localStorage.getItem('token')
  let navigate = useNavigate()
  let userDetails = JSON.parse(localStorage.getItem('user')!) as User
  const alert = useAppSelector((state) => state.alert)

  const theme = useTheme()
  const showMenuButton = useMediaQuery(theme.breakpoints.down('sm'))

  const selectAction = (selectedItem: number) => {
    if (selectedItem === 0) {
      updateAlertDialogState()
    } else if (selectedItem === 1) {
      navigate('/company')
    } else if (selectedItem === 2) {
      window.open('http://support.applogie.com/')
    } else {
      appLogout()
    }
  }
  return (
    <AppBar component="nav" style={{ backgroundColor: '#091231' }}>
      <Toolbar>
        <Grid container justifyContent="space-between" alignItems="baseline" spacing={2}>
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={2}
            >
              <Grid item sx={{ display: { xs: 'none', sm: 'block' } }}>
                <img src={FeatureImage} width="160" height="45" alt="" />
              </Grid>
              <Grid item sx={{ display: { sm: 'none' } }}>
                <img src={ResponsiveLogo} height="40" alt="" />
              </Grid>
              {token ? (
                <Grid item sx={{ display: { sm: 'none' } }}>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => updateDrawerState()}
                    sx={{ mr: 2, display: { sm: 'none' } }}
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              ) : null}
              {showMenuButton ? (
                <Grid item >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                      color: '#ffffff',
                      paddingTop: '8px',
                    }}
                  >
                    {pageName}
                  </Typography>
                </Grid>
              ) : null}

              {token ? (
                <Grid item>
                  <Grid item>
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>{tabLayout}</Box>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          {token ? (
            <Grid item>
              {showMenuButton ? (
                <BasicPopover quickActionItem={selectAction} />
              ) : (
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="baseline"
                  spacing={1}
                >
                  <Grid item>
                    <IconButton onClick={() => updateAlertDialogState()}>
                      <Badge
                        badgeContent={alert.alerts.length}
                        color="primary"
                        sx={{
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'flex',
                            '& .MuiBadge-badge': {
                              color: 'black',
                              backgroundColor: '#01cba3',
                            },
                          },
                        }}
                      >
                        <AccessAlarmIcon
                          sx={{
                            display: {
                              color: '#ffffff',
                            },
                          }}
                        />
                      </Badge>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <ThemeProvider theme={appBarButton}>
                      <Button
                        variant="text"
                        onClick={() => navigate('/company')}
                        sx={{
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'block',
                            color: '#ffffff',
                          },
                        }}
                      >
                        Hi {userDetails?.first_name!}!
                      </Button>
                    </ThemeProvider>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'block',
                          color: '#ffffff',
                          fontSize: '14px',
                        },
                      }}
                    >
                      |
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ThemeProvider theme={appBarButton}>
                      <Button
                        variant="text"
                        onClick={() => window.open('http://support.applogie.com/')}
                        sx={{
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'block',
                            color: '#ffffff',
                          },
                        }}
                      >
                        Support
                      </Button>
                    </ThemeProvider>
                  </Grid>
                  <Grid item>
                    <Typography
                      sx={{
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'block',
                          color: '#ffffff',
                          fontSize: '14px',
                        },
                      }}
                    >
                      |
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ThemeProvider theme={appBarButton}>
                      <Button
                        variant="text"
                        onClick={() => appLogout()}
                        sx={{
                          display: {
                            xs: 'none',
                            sm: 'none',
                            md: 'block',
                            color: '#ffffff',
                          },
                        }}
                      >
                        Logout
                      </Button>
                    </ThemeProvider>
                  </Grid>
                </Grid>
              )}
            </Grid>
          ) : (
            <Grid item>
              <ThemeProvider theme={appBarButton}>
                <Button
                  variant="text"
                  onClick={() => navigate('/login')}
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'none',
                      md: 'block',
                      color: '#ffffff',
                    },
                  }}
                >
                 Log In
                </Button>
              </ThemeProvider>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
