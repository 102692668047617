import * as React from 'react'
import { Stack, TextField, Button, Typography, Box, CircularProgress } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { fetchSearchUser } from 'reduxStore/users/searchUser/searchUserSlice'
import { SearchUserExtendedBody, SearchUserBody } from 'reduxStore/users/searchUser/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'

type Props = {
  tabIndex?: number | any
}

export const SearchActiveUser = ({ tabIndex }: Props) => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const searchUserListValue = useAppSelector((state) => state.userSearch)
  // console.log("searchUserListValue===>",searchUserListValue);
  const [userStatus, setUserStatus] = React.useState(0)
  React.useEffect(() => {
    if (tabIndex !== undefined) {
      setUserStatus(tabIndex)
    }
  }, [tabIndex])
  const [searchUser, setSearchUser] = React.useState('')
  const handleSearchUserNameChange = (val: string) => {
    setSearchUser(val)
  }

  const searchActiveUsersItem = () => {
    if (userStatus === 0) {
      let updatedbody = {
        isActive: 'True',
        searchStr: searchUser,
      } as SearchUserExtendedBody
      let mainbody = {
        updateBody: updatedbody,
        token: Token!,
      } as SearchUserBody
      dispatch(fetchSearchUser(mainbody))
    } else {
      let updatedbody = {
        isActive: 'False',
        searchStr: searchUser,
      } as SearchUserExtendedBody
      let mainbody = {
        updateBody: updatedbody,
        token: Token!,
      } as SearchUserBody
      dispatch(fetchSearchUser(mainbody))
    }
  }
  return (
    <Stack direction={'row'} spacing={1}>
      <TextField
        id="standard-basic"
        label=""
        placeholder="Enter Search value"
        variant="standard"
        value={searchUser}
        onChange={(e) => {
          handleSearchUserNameChange(e.target.value)
        }}
        onKeyPress={(event) => {
          if (event.key === 'Enter') searchActiveUsersItem()
        }}
        sx={{
          width: { sm: 200, md: 300 },
          "& .MuiInputBase-root": {
              height: 30
          }
      }}
      />
      <Button
        variant="contained"
        size="small"
        startIcon={<SearchIcon />}
        onClick={() => searchActiveUsersItem()}
      >
        {searchUserListValue.loading === true ? (
          <Stack direction="row" spacing={1}>
            <Typography>Search</Typography>
            <Box pl={1}>
              <CircularProgress color="inherit" size={18} />
            </Box>
          </Stack>
        ) : (
          'Search'
        )}
      </Button>
    </Stack>
  )
}
