import * as React from 'react'
import { useEffect } from 'react'
import { useAppDispatch } from '../../reduxStore/hooks'
import { Box, Tab, Tabs } from '@mui/material'
import { fetchOptimization } from 'reduxStore/optimizations/optimizationsList/optimizationSlice'
import { ActiveUser } from 'pages/users/Active/ActiveUser'
import { InactiveUser } from 'pages/users/Inactive/InactiveUser'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
import { fetchUserTagList } from 'reduxStore/users/userTagList/getUserTagListSlice'
import PageTitle from 'components/PageTitle'

export const UsersLayout = () => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const [value, setValue] = React.useState(0)
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  useEffect(() => {
    dispatch(fetchAlerts(Token!))
    dispatch(fetchOptimization(Token!))
    dispatch(fetchUserTagList(Token!))
  }, [dispatch, Token])

  return (
    <Box>
      <PageTitle title={'Users'} />
      <Box sx={{ bgcolor: '#fff' }} pt={1}>
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: '#134872',
            },
          }}
          sx={{
            '& button:hover': { background: '' },
            '& button.Mui-selected': {
              background: '#fffff',
              color: '#01cba3',
            },
          }}
        >
          <Tab label="ACTIVE USERS" />
          <Tab label="INACTIVE USERS" />
        </Tabs>
      </Box>
      <Box sx={{ padding: 2 }}>
        {value === 0 && (
          <Box>
            <ActiveUser tabIndex={value} />
          </Box>
        )}
        {value === 1 && (
          <Box>
            <InactiveUser tabIndex={value} />
          </Box>
        )}
      </Box>
    </Box>
  )
}
