import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { CustomerPaymentList } from 'reduxStore/customer/payment/customerPaymentList/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  customerPaymentList: CustomerPaymentList
  error: string
}
const initialState: InitialState = {
  loading: false,
  customerPaymentList: {} as CustomerPaymentList,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchCustomerPaymentList = createAsyncThunk<CustomerPaymentList, string>(
  'customerPaymentList/fetchCustomerPaymentList',
  (adminToken, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${adminToken}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + '/api/paymentsource', { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data.message || 'Something went wrong'))
  },
)

const customerPaymentListSlice = createSlice({
  name: 'customerPaymentList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerPaymentList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchCustomerPaymentList.fulfilled,
      (state, action: PayloadAction<CustomerPaymentList>) => {
        state.loading = false
        state.customerPaymentList = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchCustomerPaymentList.rejected, (state, action) => {
      state.loading = false
      state.customerPaymentList = {} as CustomerPaymentList
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default customerPaymentListSlice.reducer
