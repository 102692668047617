import React from 'react'
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material'
import dayjs from 'dayjs'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import { useNavigate } from 'react-router-dom'
import { Result } from 'reduxStore/users/Active/Model'

type Props = {
  item?: Result | any
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F6F8FA',
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export default function ShowAccountDetails({ item }: Props) {
  let navigate = useNavigate()
  const showVendorId = (id: number) => {
    navigate(`/detail/${id}`)
  }

  function DetailRow(props: { item: any; index: number }) {
    const { item } = props
    return (
      <React.Fragment>
        <TableRow hover key={item.id} sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            {item.account.map((acc: any) => (
              <Typography
                onClick={() => showVendorId(acc.account.id)}
                sx={{ color: '#30a289', '&:hover': { color: '#134872', cursor: 'pointer' } }}
              >
                {acc.account.vendor.name !== undefined ? acc.account.vendor.name : 'Not Set'}
              </Typography>
            ))}
          </TableCell>
          <TableCell align="left">
            {item.account.map((acc: any) => (
              <Typography>
                {acc.account.account_number !== undefined ? acc.account.account_number : 'Not Set'}
              </Typography>
            ))}
          </TableCell>
          <TableCell align="left">
            {item.account.map((acc: any) => (
              <p>{acc.account.active !== true ? 'True' : 'False'} </p>
            ))}
          </TableCell>
          <TableCell align="left">
            {item.account.map((acc: any) => (
              <Typography>
                {acc.last_login !== null ? dayjs(acc.last_login).format('ddd, DD MMM YYYY') : '---'}
              </Typography>
            ))}
          </TableCell>
          <TableCell align="left">
            {item.account.map((acc: any) => (
              <Typography>
                {acc.last_updated !== null
                  ? dayjs(acc.last_updated).format('ddd, DD MMM YYYY')
                  : '---'}
              </Typography>
            ))}
          </TableCell>
        </TableRow>
      </React.Fragment>
    )
  }
  return (
    <Stack sx={{ py: 2, height: '100%', boxSizing: 'border-box' }} direction="column">
      <Grid container direction="row" justifyContent="center" alignItems="center">
        <Box py={2}>
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
              <AccountBalanceWalletIcon />
              <Typography variant="h6" gutterBottom>
                &nbsp; &nbsp; Account
              </Typography>
            </Grid>
            <Divider />
            <Card sx={{ minHeight: '215px', margin: 1 }}>
              <CardContent sx={{ maxHeight: '245px', overflow: 'auto' }}>
                <TableContainer>
                  <Table stickyHeader aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Account</StyledTableCell>
                        <StyledTableCell align="left">Number</StyledTableCell>
                        <StyledTableCell align="left">Active</StyledTableCell>
                        <StyledTableCell align="left">Last Login</StyledTableCell>
                        <StyledTableCell align="left">Last Updated</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <DetailRow key={item.id} item={item} index={item} />
                      <StyledTableRow />
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Box>
      </Grid>
    </Stack>
  )
}
