import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Results } from './spendModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  monthlyBudgetForecasts: Results[]
  error: string
}
const initialState: InitialState = {
  loading: false,
  monthlyBudgetForecasts: [],
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchMonthlyBudgetForecasts = createAsyncThunk<Results[], string>(
  'monthlyVendorSpend/fetchMonthlyBudgetForecasts',
  (adminToken) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${adminToken}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + '/api/customers/budget/', { headers })
      .then((response) => response.data.results)
  },
)

const monthlyVendorSpendSlice = createSlice({
  name: 'monthlyVendorSpend',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMonthlyBudgetForecasts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchMonthlyBudgetForecasts.fulfilled,
      (state, action: PayloadAction<Results[]>) => {
        state.loading = false
        state.monthlyBudgetForecasts = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchMonthlyBudgetForecasts.rejected, (state, action) => {
      state.loading = false
      state.monthlyBudgetForecasts = []
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default monthlyVendorSpendSlice.reducer
