import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { HideAlertReq, HideAlerts } from 'reduxStore/alert/Hide/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  hideAlerts: HideAlerts
  successMsg: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  hideAlerts: {} as HideAlerts,
  successMsg: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const hideAlerts = createAsyncThunk<HideAlerts, HideAlertReq>(
  'alert/fetchAlerts',
  (reqParam: HideAlertReq, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .post(url.backEndURL + `/api/alert/${reqParam.id}/hide_alert`, null, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : 'Something went wrong',
        ),
      )
  },
)

const hideAlertSlice = createSlice({
  name: 'hideAlert',
  initialState,
  reducers: {
    updateHideAlertClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    updateHideAlertClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.hideAlerts = {} as HideAlerts
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hideAlerts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(hideAlerts.fulfilled, (state, action: PayloadAction<HideAlerts>) => {
      state.loading = false
      state.hideAlerts = action.payload
      state.successMsg =
        action?.payload?.status !== undefined
          ? action?.payload?.status !== ''
            ? 'Alert has been hide successfully'
            : 'Alert has been hide successfully'
          : ''
      state.error = ''
    })
    builder.addCase(hideAlerts.rejected, (state, action) => {
      state.loading = false
      state.hideAlerts = {} as HideAlerts
      state.successMsg = ''
      state.error = String(action.payload!)
    })
  },
})

export const { updateHideAlertClearMsg, updateHideAlertClearRes } = hideAlertSlice.actions
export default hideAlertSlice.reducer
