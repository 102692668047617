import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import {
  LisenceChartListReq,
  LicenseChartListRes,
} from 'reduxStore/account/license/licenseChart/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  licensesChart: LicenseChartListRes
  error: string
}
const initialState: InitialState = {
  loading: false,
  licensesChart: {} as LicenseChartListRes,
  error: '',
}

export const fetchLicenseChart = createAsyncThunk<LicenseChartListRes, LisenceChartListReq>(
  'fetchLicenseChart/fetchLicenseChart',
  async (payload: LisenceChartListReq, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/account/license/usage/?account=${payload.id}`, { headers })
      .then((response) => response.data)
      .catch(
        (error) =>
          rejectWithValue(error.response.data[0].detail) ||
          error.response.data.message ||
          'Something went wrong',
      )
  },
)

const licenseChartSlice = createSlice({
  name: 'fetchLicenseChart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLicenseChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchLicenseChart.fulfilled, (state, action: PayloadAction<LicenseChartListRes>) => {
      state.loading = false
      state.licensesChart = action.payload
      state.error = ''
    })
    builder.addCase(fetchLicenseChart.rejected, (state, action) => {
      state.loading = false
      state.licensesChart = {} as LicenseChartListRes
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default licenseChartSlice.reducer
