import React, { useEffect, useState } from 'react'
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import RefreshIcon from '@mui/icons-material/Refresh'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { UserAccountList } from '../../userDetailsModel'
import dayjs from 'dayjs'
import { DataGridPremium, GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { checkReturnValue } from 'components/Utility'
import { useParams } from 'react-router-dom'
import { UserDetailsModelReq } from 'reduxStore/users/userDetails/Model'
import { fetchUserDetailsById } from 'reduxStore/users/userDetails/userDetailsSlice'

export default function Accounts() {
  // Get the userId param from the URL.
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  let userDetailsByIdBody = {
    userId: String(id),
    token: Token,
  } as UserDetailsModelReq
  const userDetailsById = useAppSelector((state) => state.userDetailsById)
  const [userAccountList, setUserAccountList] = useState([] as UserAccountList[])

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Account',
      sortable: true,
      hideable: false,
      minWidth: 120,
      align: 'left',
      flex: 1,
    },
    {
      field: 'account_number',
      headerName: 'Account Number',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
    },
    {
      field: 'active',
      headerName: 'Active',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>{params.row.active ? 'True' : 'False'}</React.Fragment>
      ),
    },
    {
      field: 'last_login',
      headerName: 'Last Login',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>
          {params.row.renewalDate !== null
            ? dayjs(params.row.renewalDate!).format('ddd, DD MMM YYYY h:mm:ss')
            : null}
        </React.Fragment>
      ),
    },
    {
      field: 'last_updated',
      headerName: 'Last Updated',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>
          {params.row.renewalDate !== null
            ? dayjs(params.row.renewalDate!).format('ddd, DD MMM YYYY h:mm:ss')
            : null}
        </React.Fragment>
      ),
    },
  ]

  useEffect(() => {
    setUserAccountList(
      checkReturnValue(userDetailsById?.userDetails?.account_set) !== 'Not Set'
        ? userDetailsById?.userDetails?.account_set.map((item) => ({
            id: item.id,
            name: item.account?.vendor?.name! !== null ? item.account?.vendor?.name! : ' Not Set',
            account_number:
              item.account?.account_number! !== null ? item.account?.account_number! : 'Not Set',
            active: item.active,
            last_login: item.last_login!,
            last_updated: item.last_updated!,
          }))
        : ([] as UserAccountList[]),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetailsById])

  return (
    <Card>
      <CardHeader
        avatar={<AccountBalanceWalletIcon sx={{ fontWeight: 'bold' }} />}
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Accounts
              </Typography>
            </Grid>
            <Grid item>
              <Chip label={userAccountList.length} />
            </Grid>
            <Grid item>
              <IconButton onClick={() => dispatch(fetchUserDetailsById(userDetailsByIdBody))}>
                <RefreshIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {userDetailsById.loading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress color="inherit" size={30} />
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ height: '35vh' }}>
            <DataGridPremium
              columns={columns}
              rows={userAccountList}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              hideFooter
              disableRowGrouping
              disableAggregation
              disableRowSelectionOnClick
              loading={userDetailsById.loading}
              localeText={{ noRowsLabel: 'No Accounts Details available' }}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
