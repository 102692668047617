import {
  Alert,
  AlertColor,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  inactiveCustomerPaymentByID,
  updateInActivePaymentByIDClearRes,
} from 'reduxStore/customer/payment/inactivePayment/inactiveCustomerPaymentByIDSlice'
import { InactiveCustomerPaymentByIDBody } from 'reduxStore/customer/payment/inactivePayment/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { fetchCustomerPaymentList } from 'reduxStore/customer/payment/customerPaymentList/customerPaymentListSlice'
import ApplogieSnackbar from 'components/ApplogieSnackbar'

type Props = {
  itemId?: string | any
  inActivePaymentDialogEnable?: boolean | any
  inActivePaymentDialogUpdateState?: boolean | any
  onSuccessUpadtePasswordMessage: (message: string) => void
}

export default function InactivePaymentDialog({
  itemId,
  inActivePaymentDialogEnable,
  inActivePaymentDialogUpdateState,
  onSuccessUpadtePasswordMessage,
}: Props) {
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')

  // Call store
  const inActiveCustomerpaymentByIDVal = useAppSelector(
    (state) => state.inActiveCustomerpaymentByID,
  )

  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')
  const [showInactivePaymentMsg, setInactivePaymentMsg] = React.useState('')
  const closeInactivePaymentSnackbar = () => {
    dispatch(updateInActivePaymentByIDClearRes())
    setInactivePaymentMsg('')
  }

  React.useEffect(() => {
    if (inActiveCustomerpaymentByIDVal?.successMsg !== '') {
      setAPIMsgType('success')
      setInactivePaymentMsg(inActiveCustomerpaymentByIDVal?.inActivePaymentByIDRes?.status)
      setDialogState(false)
      onSuccessUpadtePasswordMessage(inActiveCustomerpaymentByIDVal?.inActivePaymentByIDRes?.status)
    }
    if (inActiveCustomerpaymentByIDVal?.error !== '') {
      if (inActiveCustomerpaymentByIDVal?.error) {
        setAPIMsgType('error')
        setInactivePaymentMsg(inActiveCustomerpaymentByIDVal?.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inActiveCustomerpaymentByIDVal])
  const [netErrMsq, setNetErrMsq] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState('')

  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    inActivePaymentDialogUpdateState(false)
  }
  let inActivePaymentByIDBody = {
    id: selectedItem,
    token: Token!,
  } as InactiveCustomerPaymentByIDBody

  const inActivePayment = async () => {
    if (navigator.onLine) {
      setNetErrMsq('')
      await dispatch(inactiveCustomerPaymentByID(inActivePaymentByIDBody))
      await dispatch(fetchCustomerPaymentList(Token!))
      await handleClose()
    } else {
      setNetErrMsq('You are currently offline')
    }
  }

  const [showErrorMsgView, setShowErrorMsgView] = React.useState(false)
  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      'inActivePaymentByIDRes' in inActiveCustomerpaymentByIDVal
        ? inActiveCustomerpaymentByIDVal.inActivePaymentByIDRes !== undefined ||
          inActiveCustomerpaymentByIDVal.inActivePaymentByIDRes !== null
          ? 'count' in inActiveCustomerpaymentByIDVal.inActivePaymentByIDRes
            ? setShowErrorMsgView(true)
            : setShowErrorMsgView(false)
          : setShowErrorMsgView(false)
        : setShowErrorMsgView(false)
    }
  }, [inActiveCustomerpaymentByIDVal])

  React.useEffect(() => {
    setSelectedItem(itemId)
    setDialogState(inActivePaymentDialogEnable)
  }, [itemId, inActivePaymentDialogEnable])

  return (
    <Box>
      <Dialog open={dialogState} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <DialogContentText>
            <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
              Are you sure; you want to Inactive this payment?
            </Typography>
          </DialogContentText>
          {netErrMsq !== '' ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <Typography sx={{ color: '#c62828' }}>{netErrMsq}</Typography>
              </Grid>
            </Grid>
          ) : null}
          <Collapse in={showErrorMsgView}>
            <Alert
              icon={false}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setShowErrorMsgView(false)
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {'error' in inActiveCustomerpaymentByIDVal
                ? inActiveCustomerpaymentByIDVal.error
                : ''}
            </Alert>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => inActivePayment()}
            disabled={inActiveCustomerpaymentByIDVal?.loading}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            onClick={handleClose}
            disabled={inActiveCustomerpaymentByIDVal?.loading}
            autoFocus
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
      <ApplogieSnackbar
        msg={showInactivePaymentMsg}
        type={apiMsgType}
        alertClose={closeInactivePaymentSnackbar}
      />
    </Box>
  )
}
