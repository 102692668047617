import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { UserDetailsModel, UserDetailsModelReq } from './Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  userDetails: UserDetailsModel
  error: string
}
const initialState: InitialState = {
  loading: false,
  userDetails: {} as UserDetailsModel,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchUserDetailsById = createAsyncThunk<
UserDetailsModel,
UserDetailsModelReq
>('userDetailsById/fetchUserDetailsById', (reqParam: UserDetailsModelReq) => {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Token ${reqParam.token}`,
  }
  return setupInterceptorsTo(axios)
    .get(url.backEndURL + `/api/organizationuser/${reqParam.userId}`, { headers })
    .then((response) => response.data)
})

const userDetailsByIdSlice = createSlice({
  name: 'userDetailsById',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetailsById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
        fetchUserDetailsById.fulfilled,
      (state, action: PayloadAction<UserDetailsModel>) => {
        state.loading = false
        state.userDetails = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchUserDetailsById.rejected, (state, action) => {
      state.loading = false
      state.userDetails = {} as UserDetailsModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default userDetailsByIdSlice.reducer
