import * as React from 'react'
import { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../reduxStore/hooks'
import { Card, CardContent, Box, CircularProgress, Grid, CardHeader } from '@mui/material'
import { fetchOptimization } from 'reduxStore/optimizations/optimizationsList/optimizationSlice'
import InfoIcon from '@mui/icons-material/Info'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
import OptimizationTable from './OptimizationTable'
import OptimizationCardHeader from './OptimizationCardHeader'
import PageTitle from 'components/PageTitle'

export const Optimizations = () => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const optimizationVal = useAppSelector((state) => state.optimization)

  function reloadOptimization() {
    dispatch(fetchOptimization(Token!))
  }

  useEffect(() => {
    dispatch(fetchOptimization(Token!))
    // eslint-disable-next-line no-lone-blocks
    dispatch(fetchAlerts(Token!))
  }, [dispatch, Token])

  return (
    <Box p={2}>
      <PageTitle title={'Optimizations'} />
      <Card>
        <CardHeader
          avatar={<InfoIcon sx={{ fontWeight: 'bold' }} />}
          title={<OptimizationCardHeader reloadOptimization={reloadOptimization} />}
        />
        <CardContent>
          {optimizationVal.loading === true ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" size={20} thickness={4} />
            </Grid>
          ) : (
            <Box sx={{ height: 'calc(100ch - 185px)' }}>
              <OptimizationTable />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
