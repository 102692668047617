import React from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { UserListTableColumn } from './UserListTableColumn'
import MUIDDCustomToolbar from 'components/MUIDGCustomToolbar'
import { AllSubscriptionDetailsUserList } from './AllSubscriptionDetailsUserList'

export default function UserLists() {
  const customerPaymentList = useAppSelector((state) => state.customerPaymentList)
  const accountUserDetailsById = useAppSelector((state) => state.accountUserDetailsById)

  return (
    <DataGridPremium
      columns={UserListTableColumn()}
      rows={AllSubscriptionDetailsUserList()}
      initialState={{
        sorting: {
          sortModel: [{ field: 'firstName', sort: 'asc' }],
        },
      }}
      slots={{
        toolbar: MUIDDCustomToolbar,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      hideFooter
      disableRowGrouping
      disableAggregation
      disableRowSelectionOnClick
      loading={accountUserDetailsById.loading || customerPaymentList?.loading}
      rowHeight={35}
    />
  )
}
