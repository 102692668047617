import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { InActiveUser, InactiveUserBody } from 'reduxStore/account/inactiveUser/Model'

type InitialState = {
  loading: boolean
  inActiveUserByIDRes: InActiveUser
  successMsg: string
  error: string
  toggleUserStatusId: string
}
const initialState: InitialState = {
  loading: false,
  inActiveUserByIDRes: {} as InActiveUser,
  successMsg: '',
  error: '',
  toggleUserStatusId: '',
}

// Generates pending, fulfilled and rejected action types
export const inactiveUserByID = createAsyncThunk<InActiveUser, InactiveUserBody>(
  'inactiveUserByID/inactiveUserByID',
  (payload, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/accountuser/${payload.id}/toggle_active_accountuser`, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : 'Something went wrong',
        ),
      )
  },
)

const inactiveUserSlice = createSlice({
  name: 'inactiveUserByID',
  initialState,
  reducers: {
    inactiveUserClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    inactiveUserClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.inActiveUserByIDRes = {} as InActiveUser
    },
    updateUserStatus: (state, action) => {
      state.toggleUserStatusId = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(inactiveUserByID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(inactiveUserByID.fulfilled, (state, action: PayloadAction<InActiveUser>) => {
      state.loading = false
      state.inActiveUserByIDRes = action.payload
      state.successMsg =
        action?.payload?.status !== undefined
          ? action?.payload?.status !== ''
            ? action?.payload?.status
            : 'Users Switch successfully'
          : ''
      state.error = ''
    })
    builder.addCase(inactiveUserByID.rejected, (state, action) => {
      state.loading = false
      state.inActiveUserByIDRes = {} as InActiveUser
      state.successMsg = ''
      state.error = String(action.payload!)
    })
  },
})

export const { inactiveUserClearMsg, inactiveUserClearRes, updateUserStatus } =
  inactiveUserSlice.actions
export default inactiveUserSlice.reducer
