import { checkReturnValue } from 'components/Utility'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import { Result } from 'reduxStore/account/accountUserDetailsById/AccountUserDetailsByIdModel'
import { UserList } from '../Model'

export function AllSubscriptionDetailsUserList() {
  const userType = localStorage.getItem('subDetailUserTab')
  const accountUserDetailsById = useAppSelector((state) => state.accountUserDetailsById)
  const [userList, setUserList] = useState([] as UserList[])
  const [filteredUserList, setFilteredUserList] = useState([] as UserList[])

  // Modify dataset for subscription details user table
  useEffect(() => {
    setUserList(
      checkReturnValue(accountUserDetailsById.accountUserDetails.results) !== 'Not Set'
        ? accountUserDetailsById.accountUserDetails.results.map((item: Result) => ({
            id: item.id,
            firstName:
              checkReturnValue(item?.user?.first_name) !== 'Not Set'
                ? item?.user?.first_name
                : null,
            lastName:
              checkReturnValue(item?.user?.last_name) !== 'Not Set' ? item?.user?.last_name : null,
            email: checkReturnValue(item?.user?.email) !== 'Not Set' ? item?.user?.email : null,
            isActive: item.active,
            lastLogin: checkReturnValue(item?.last_login) !== 'Not Set' ? item?.last_login : null,
          }))
        : ([] as UserList[]),
    )
  }, [accountUserDetailsById])
  useEffect(() => {
    if (userList.length > 0 && userType !== null) {
      if (userType === '0') {
        setFilteredUserList(userList.filter((activeUsr) => activeUsr.isActive === true))
      } else setFilteredUserList(userList.filter((inactiveUsr) => inactiveUsr.isActive === false))
    }
  }, [userList, userType])

  return filteredUserList
}
