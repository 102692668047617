import { Grid, IconButton, Tooltip } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { useAppDispatch } from 'reduxStore/hooks'
import {
  selectedLicenseDetails,
  updateAddEditDialogState,
} from 'reduxStore/account/license/licenseListByAccountID/licenseSlice'
import {
  enableDeleteLicenseDialog,
  selectItemForDeleteLicense,
} from 'reduxStore/account/license/deleteLicensebyID/deleteLicenseByIDSlice'

export function LicenseListTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      hideable: true,
      minWidth: 100,
      align: 'left',
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Value',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
    },
    {
      field: 'count',
      headerName: 'Count',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
    },
    {
      field: 'type',
      headerName: 'Type',
      sortable: true,
      hideable: true,
      width: 80,
      align: 'left',
    },
    {
      field: 'period',
      headerName: 'Period',
      sortable: true,
      hideable: true,
      minWidth: 85,
      align: 'left',
    },
    {
      field: 'modifiedDescription',
      headerName: 'Description',
      sortable: true,
      hideable: true,
      minWidth: 90,
      align: 'left',
      flex: 1,
      renderCell: (params: GridCellParams) => (
        <Tooltip title={params.row.description}>{params.row.modifiedDescription}</Tooltip>
      ),
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      sortable: true,
      hideable: true,
      minWidth: 140,
      align: 'left',
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      sortable: true,
      hideable: true,
      minWidth: 125,
      align: 'left',
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: true,
      hideable: false,
      minWidth: 110,
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        return (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={0.5}
          >
            <Grid item>
              <IconButton
                aria-label="edit"
                onClick={() => {
                  dispatch(updateAddEditDialogState(true))
                  dispatch(selectedLicenseDetails(params.row))
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                aria-label="delete"
                onClick={() => {
                  dispatch(enableDeleteLicenseDialog(true))
                  dispatch(selectItemForDeleteLicense(String(params.row.id)))
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        )
      },
    },
  ]
  return columns
}
