import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Tab,
  Tabs,
  Toolbar,
  Typography,
} from '@mui/material'
import FeatureImageDrawer from '../images/applogie-logo-black.svg'
import { LogOutResponseBody } from 'reduxStore/logOut/Model'
import AlertDialog from 'components/AlertDialog'
import { logOut } from 'reduxStore/logOut/logOutSlice'
import { useAppDispatch } from 'reduxStore/hooks'
import { DrawerList } from './AppFrame/DrawerLisr'
import { ApplogieAppBar } from './AppFrame/ApplogieAppBar'
interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  windows?: () => Window
}

const drawerWidth = 240

export default function AppFrame(props: Props) {
  const dispatch = useAppDispatch()
  let navigate = useNavigate()
  const token = localStorage.getItem('token')
  const { windows } = props
  const [mobileOpen, setMobileOpen] = React.useState(false)

  const [pageName, setPageName] = React.useState('')

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const [showAlertDialog, setShowAlertDialog] = React.useState(false)
  const updateAlertDialogState = () => {
    setShowAlertDialog(true)
  }

  const updateAlertDialogValFromChild = (passedVal: boolean) => {
    setShowAlertDialog(passedVal)
  }

  const logoutBody = {
    token: token,
  } as LogOutResponseBody

  async function handleLogout() {
    await dispatch(logOut(logoutBody))
    await setPageName('')
    await navigate('/login')
    await window.location.reload()
  }
  // ==== List Item====
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
  ) => {
    setSelectedIndex(index)
  }

  const location = useLocation()
  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      // let path = window.location.pathname
      if (location.pathname === '/dashboard' || location.pathname === '/') {
        setValue(0)
        setSelectedIndex(0)
        setPageName('Overview')
      } else if (location.pathname === '/subscriptions' || location.pathname.includes('/detail')) {
        setValue(1)
        setSelectedIndex(1)
        setPageName('Subscriptions')
      } else if (location.pathname === '/users' || location.pathname.includes('/userdetail')) {
        setValue(2)
        setSelectedIndex(2)
        setPageName('Users')
      } else if (location.pathname === '/optimizations') {
        setValue(3)
        setSelectedIndex(3)
        setPageName('optimizations')
      } else if (location.pathname === '/breaches' || location.pathname.includes('/breachdetail')) {
        setValue(4)
        setSelectedIndex(4)
        setPageName('Breaches')
      } else {
        setValue(0)
        setSelectedIndex(0)
        setPageName('')
      }
    }
  }, [location])

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <img src={FeatureImageDrawer} width="160" height="40" alt="" />
      <Divider />
      <List>
        {token ? (
          <div>
            <List component="nav" aria-label="main mailbox folders">
              {DrawerList.slice(0, 5).map((item, index) => {
                return (
                  <ListItem component={Link} to={item.path} key={item.name} disablePadding>
                    <ListItemButton
                      selected={selectedIndex === index}
                      onClick={(event) => handleListItemClick(event, 0)}
                    >
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                )
              })}
              <ListItemButton selected={selectedIndex === 5} onClick={() => handleLogout()}>
                <ListItemText>
                  <Typography variant="body1" fontSize={14} style={{ color: '#006fbb' }}>
                    Log Out
                  </Typography>
                </ListItemText>
              </ListItemButton>
            </List>
          </div>
        ) : (
          <List component="nav" aria-label="main mailbox folders">
            {DrawerList.slice(5).map((item, index) => {
              return (
                <ListItem component={Link} to={item.path} key={item.name} disablePadding>
                  <ListItemButton
                    selected={selectedIndex === index}
                    onClick={(event) => handleListItemClick(event, 0)}
                  >
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </ListItem>
              )
            })}
          </List>
        )}
      </List>
    </Box>
  )

  const container = windows !== undefined ? () => windows().document.body : undefined

  // ====== Tabs ======

  const [value, setValue] = React.useState(0)
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const tabLayout = (
    <Box sx={{ width: '100%' }}>
      <Tabs
        onChange={handleChange}
        value={value}
        aria-label="Tabs where selection follows focus"
        selectionFollowsFocus
        textColor="secondary"
        TabIndicatorProps={{
          style: {
            backgroundColor: '#01cba3',
          },
        }}
      >
        {DrawerList.slice(0, 5).map((item) => {
          return (
            <Tab
              label={item.name}
              component={Link}
              to={item.path}
              style={{ textDecoration: 'none', color: '#ffffff' }}
            />
          )
        })}
      </Tabs>
    </Box>
  )

  const layout = (
    <Box sx={{ display: 'flex' }}>
      <ApplogieAppBar
        pageName={pageName}
        tabLayout={tabLayout}
        updateDrawerState={handleDrawerToggle}
        updateAlertDialogState={updateAlertDialogState}
        appLogout={handleLogout}
      />
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main">
        <Toolbar />
      </Box>
    </Box>
  )

  return (
    <Box>
      {layout}
      {showAlertDialog ? (
        <AlertDialog
          alertDialogEnable={showAlertDialog}
          alertDialogUpdateState={updateAlertDialogValFromChild}
        />
      ) : null}
    </Box>
  )
}
