import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import * as React from 'react'
import { fetchTestSource } from 'reduxStore/subcription/TestSource/testSourceSlice'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'

type Props = {
  selectedPaymentVendorShName?: string
}

export default function TestSource(selectedPaymentVendorShName: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const addTestSource = useAppSelector((state) => state.testSource)

  const [paymentMode, setPaymentMode] = React.useState<string | undefined>('')

  function testSourceApiCAll(paymentMode?: string) {
    let reqBody = {
      paymentMode: paymentMode!,
      token: Token!,
    }
    dispatch(fetchTestSource(reqBody))
  }

  const page =
    selectedPaymentVendorShName.selectedPaymentVendorShName === 'PD' ||
    selectedPaymentVendorShName.selectedPaymentVendorShName === 'SO' ? null : (
      <Button
        variant="contained"
        fullWidth
        style={{ backgroundColor: '#0000ff' }}
        onClick={() => testSourceApiCAll(selectedPaymentVendorShName.selectedPaymentVendorShName)}
      >
        {addTestSource.loading === true ? (
          <Stack direction="row" spacing={1}>
            <Typography>TEST SOURCE</Typography>
            <Box pl={1}>
              <CircularProgress color="inherit" size={18} />
            </Box>
          </Stack>
        ) : (
          'TEST SOURCE'
        )}
      </Button>
    )

  React.useEffect(() => {
    setPaymentMode(selectedPaymentVendorShName!.selectedPaymentVendorShName!)
  }, [selectedPaymentVendorShName, paymentMode])

  return <div>{page}</div>
}
