import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { Result, MainBody } from 'reduxStore/subcription/Amex/Model'

type InitialState = {
    loading: boolean
    amex: Result
    error: string
}
const initialState: InitialState = {
    loading: false,
    amex: {} as Result,
    error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchAmexSource = createAsyncThunk<Result, MainBody>('amexsource/fetchAmexSource', (reqParam: MainBody) => {
    let headers = {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
        .post(url.backEndURL + `/api/api-amex-auth-token`, reqParam.objBody, { headers })
        .then((response) => response.data)
})

const amexSourceSlice = createSlice({
    name: 'amex',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchAmexSource.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchAmexSource.fulfilled, (state, action: PayloadAction<Result>) => {
            state.loading = false
            state.amex = action.payload
            state.error = ''
        })
        builder.addCase(fetchAmexSource.rejected, (state, action) => {
            state.loading = false
            state.amex = {} as Result
            state.error = action.error.message || 'Something went wrong'
        })
    },
})

export default amexSourceSlice.reducer
