import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@mui/material'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import * as React from 'react'
import { SubscriptionAlertDetails } from './SubscriptionAlertDetails'
import { optimizationDetailsByAccIdClearRes } from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationSlice'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
type Props = {
  alertDialogEnable?: boolean | any
  alertDialogUpdateState: (value: boolean) => void
}

export default function SubscriptionAlert({ alertDialogEnable, alertDialogUpdateState }: Props) {
  const [dialogState, setDialogState] = React.useState(false)
  const dispatch = useAppDispatch()

  const handleClose = () => {
    setDialogState(false)
    alertDialogUpdateState(false)
    dispatch(optimizationDetailsByAccIdClearRes())
  }
  React.useEffect(() => {
    setDialogState(alertDialogEnable)
  }, [alertDialogEnable])
  const subsAlertListValue = useAppSelector((state) => state.optimizationsDetailsbyAccountID)
  return (
    <Dialog open={dialogState} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
          <Grid item>
            <AccessAlarmIcon sx={{ fontWeight: 'bold', mt: 1 }} />
          </Grid>
          <Grid item>
            {  subsAlertListValue?.optimizations?.results[0]?.account?.vendor_alias !== "" ?(
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              { subsAlertListValue.optimizations.results[0].account.vendor_alias}&nbsp;Alerts
            </Typography> 
            ):(
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              { subsAlertListValue.optimizations.results[0].account.vendor.name}&nbsp;Alerts
            </Typography> 
            )
            }
          </Grid>
          <Grid item>
            <Chip
              label={
                'results' in subsAlertListValue.optimizations
                  ? subsAlertListValue.optimizations.results !== undefined
                    ? subsAlertListValue.optimizations.results.length
                    : 0
                  : 0
              }
              sx={{ fontWeight: 'bold' }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <SubscriptionAlertDetails />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          autoFocus
          variant="contained"
          style={{ backgroundColor: '#DCDCDC' }}
        >
          <Typography sx={{ color: '#6253B5', fontSize: '15px', fontWeight: 'bold' }}>
            Close
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
