import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Results, InactiveUsersDetailsReq } from '../Inactive/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  inactiveuser: Results
  successMsg: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  inactiveuser: {} as Results,
  successMsg: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchInactiveuser = createAsyncThunk<Results, InactiveUsersDetailsReq>(
  'inactiveuser/fetchInactiveuser',
  (reqParam: InactiveUsersDetailsReq, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(
        url.backEndURL +
          `/api/organizationuser/False/get_active_pagination?page=${reqParam.pageCount}&size=${reqParam.length}`,
        { headers },
      )
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : 'Something went wrong',
        ),
      )
  },
)

const inactiveuserSlice = createSlice({
  name: 'inactiveuser',
  initialState,
  reducers: {
    inactiveUserListClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    inactiveUserListClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.inactiveuser = {} as Results
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInactiveuser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchInactiveuser.fulfilled, (state, action: PayloadAction<Results>) => {
      state.loading = false
      state.inactiveuser = action.payload
      state.successMsg = 'User list fetch successfully'
      state.error = ''
    })
    builder.addCase(fetchInactiveuser.rejected, (state, action) => {
      state.loading = false
      state.inactiveuser = {} as Results
      state.successMsg = ''
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { inactiveUserListClearMsg, inactiveUserListClearRes } = inactiveuserSlice.actions
export default inactiveuserSlice.reducer
