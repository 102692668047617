import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import dayjs from 'dayjs'
import { Autocomplete, Box, TextField, Stack } from '@mui/material'
import { SpendChartTable } from './SpendChartTable'
import { FilteredSpendList, monthNames, TotalSpendBudget, GridData, YearList } from './Model'
// import SpendChartData from './SpendChartData'
import IndividualMonthlyVendorChart from '.'
import { updateAccountWiseSpendListClearRes } from 'reduxStore/account/accountCustomerSpend/accountCustomerSpendByIdSlice'
import { updateAccountWiseBudgetListClearRes } from 'reduxStore/account/accountCustomerBudgetById/accountCustomerBudgetByIdSlice'

export default function SpendChart() {
  const dispatch = useAppDispatch()
  const customerSpendById = useAppSelector((state) => state.customerSpendByAccountId)
  const customerBudgetById = useAppSelector((state) => state.customerBudgetByAccountId)

  const [newData, setNewData] = useState([] as am5.Chart[])

  const [tempData, setTempData] = useState([] as FilteredSpendList[] | [] as any[])
  const [chartTempData, setChartTempData] = useState([] as FilteredSpendList[] | [] as any[])

  const [yearList, setYearList] = useState([] as YearList[])
  const [selectedYear, setSelectedYear] = React.useState({} as YearList | null)
  const handleYearChange = (newValue: any) => {
    setSelectedYear(newValue)
    let tempData = newData.filter((item: any) => item.year === Number(newValue.label))
    setTempData(tempData)
    setChartTempData(tempData)
  }
  // Clear Spend API Response & Budget API Response on page render to clear old data from store
  useEffect(() => {
    dispatch(updateAccountWiseSpendListClearRes())
    dispatch(updateAccountWiseBudgetListClearRes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const modifiedData = (startDate: any, endDate: any, array: any) => {
    // Loop over each month between the start and end dates
    for (let dt = startDate; dt <= endDate; dt.setMonth(dt.getMonth() + 1)) {
      // Check if this month/year is in the data
      let monthYear = dt.toLocaleString('default', { month: 'long' }) + ' ' + dt.getFullYear()
      if (!array.some((row: any) => row.modifiedYear === monthYear)) {
        // If not, add a new row with this month/year and zero values
        array.push({
          date: dt.getTime(),
          month: dt.toLocaleString('default', { month: 'long' }),
          year: dt.getFullYear(),
          budget: 0,
          spend: 0,
          modifiedYear: monthYear,
        })
      }
    }

    // Sort the data again after adding rows
    array.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())
    return array
  }
  useEffect(() => {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let spendArr = [] as any[]
    if (customerSpendById.spendList.length > 0 && customerBudgetById.budgetList.length >= 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      spendArr = customerSpendById.spendList.map((item) => {
        return {
          date: new Date(item.year + '/' + item.month + '/01').getTime(),
          month: monthNames[dayjs(new Date(item.year + '/' + item.month + '/1')).month()],
          year: item.year,
          budget: 'projected_cost' in item ? 0 : 0,
          spend: Number(item.cost!),
          modifiedYear:
            monthNames[dayjs(new Date(item.year + '/' + item.month + '/01')).month()] +
            ' ' +
            dayjs(new Date(item.year + '/' + item.month + '/01')).year(),
        }
      })
    }
    // Sort the data by date
    spendArr.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())
    spendArr.length > 0 &&
      modifiedData(
        new Date(spendArr[0].date),
        new Date(spendArr[spendArr.length - 1].date),
        spendArr,
      )

    let budgetArr = [] as any[]
    if (customerSpendById.spendList.length >= 0 && customerBudgetById.budgetList.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      budgetArr = customerBudgetById.budgetList.map((item) => {
        return {
          date: new Date(item.year + '/' + item.month + '/01').getTime(),
          month: monthNames[dayjs(new Date(item.year + '/' + item.month + '/01')).month()],
          year: item.year,
          budget: Number(item.projected_cost!),
          spend: 'projected_cost' in item ? 0 : 0,
          modifiedYear:
            monthNames[dayjs(new Date(item.year + '/' + item.month + '/01')).month()] +
            ' ' +
            dayjs(new Date(item.year + '/' + item.month + '/01')).year(),
        }
      })
    }
    // Sort the data by date
    budgetArr.length > 0 &&
      budgetArr.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime())

    // Initialize an empty array to store the merged data
    let mergedArrays = [] as any[]

    // Iterate over the spendArr
    spendArr.forEach((spendItem) => {
      // Find the corresponding budget item by matching the "modifiedYear" key
      let budgetItem = budgetArr.find((item) => item.modifiedYear === spendItem.modifiedYear)

      // If a corresponding budget item is found, merge the data
      if (budgetItem) {
        // Create a new object with the merged data
        let mergedObject = {
          modifiedYear: spendItem.modifiedYear,
          budget: budgetItem.budget,
          spend: spendItem.spend,
          date: spendItem.date,
          month: spendItem.month,
          year: spendItem.year,
        }

        // Push the merged object to the mergedArray
        mergedArrays.push(mergedObject)
      }
    })

    // Create a new Map to hold the merged data
    let map = new Map<string, any>()

    // Add all objects from arr1 to the map
    for (let obj of spendArr) {
      map.set(obj.modifiedYear, { ...obj })
    }

    // Merge all objects from arr2 into the map
    for (let obj of budgetArr) {
      if (map.has(obj.modifiedYear)) {
        // If the object already exists in the map, merge the two objects
        let existingObj = map.get(obj.modifiedYear)
        map.set(obj.modifiedYear, { ...existingObj, ...obj })
      } else {
        // If the object doesn't exist in the map, add it
        map.set(obj.modifiedYear, { ...obj })
      }
    }

    // Convert the Map back into an array
    let mergedArray: any[] = Array.from(map.values())

    // Function to replace array2 values with array1 objects where modified year matched
    function replaceValues(mergedArrays: any, mergedArray: any) {
      return mergedArray.map((obj2: any) => {
        const matchingObj = mergedArrays.find(
          (obj1: any) => obj1.modifiedYear === obj2.modifiedYear,
        )
        if (matchingObj) {
          return { ...matchingObj, id: obj2.id } // Replace id from array2
        } else {
          return obj2 // If no match found, keep array2 object as is
        }
      })
    }
    const newArray = replaceValues(mergedArrays, mergedArray)
    let filteredData = newArray.filter((item: any) => !(item.budget === 0 && item.spend === 0))
    filteredData.sort((a: any, b: any) => a.date - b.date)
    setNewData(filteredData)

    newArray.reverse()
    let yr = [] as YearList[]
    newArray.sort((a: any, b: any) => b.label - a.label)
    let newA = newArray.map((item: any) => {
      return item.year
    })
    let uniq = [...new Set(newA)]
    let objYear = uniq.map((year, index) => ({ label: String(year), value: index }))

    yr = objYear
    yr.sort((a: YearList, b: YearList) => Number(b.label) - Number(a.label))
    setYearList(yr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerSpendById, customerBudgetById])

  useEffect(() => {
    if (yearList.length > 0) {
      if (
        yearList.find((element: YearList) => Number(element.label) === new Date().getFullYear()) !==
        undefined
      ) {
        handleYearChange(
          selectedYear?.label === undefined
            ? yearList.find(
                (element: YearList) => Number(element.label) === new Date().getFullYear(),
              )
            : selectedYear,
        )
      } else {
        handleYearChange(
          selectedYear?.label === undefined
            ? yearList.find((element: YearList) => element.label === yearList[0].label)
            : selectedYear,
        )
      }
    } else setSelectedYear({} as any)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearList])

  let yearWiseSpendBudget = tempData.reduce(function (r, a) {
    r[a.year] = r[a.year] || []
    r[a.year].push(a)
    return r
  }, Object.create(null))
  // Assigning default spend and budget to 0 for missing months
  const back_fill_months = (data: TotalSpendBudget[], year: string) => {
    if (data !== undefined) {
      return Array.from(
        monthNames,
        (month) =>
          data.find((sale) => sale.month === month) || {
            date: dayjs(new Date(year + '/' + month + '/01')).format('ddd, DD MMM YYYY'),
            month: month,
            spend: 0,
            budget: 0,
            year: Number(year),
            modifiedYear: month + ' ' + year,
          },
      )
    }
  }

  for (const [key] of Object.entries(yearWiseSpendBudget)) {
    yearWiseSpendBudget[key] = back_fill_months(yearWiseSpendBudget[key], key)
  }

  const prepareDataForTable = (data: TotalSpendBudget[]) => {
    if (data !== undefined) {
      let obj = data.map((item: any) => {
        return { [item.month]: item.spend }
      })
      let budgetobj = data.map((item: any) => {
        return { [item.month]: item.budget }
      })
      let sortedObj = obj.sort(
        (a: any, b: any) =>
          a.month - b.month || monthNames.indexOf(a.month) - monthNames.indexOf(b.month),
      )
      let budgetSortedObj = budgetobj.sort(
        (a: any, b: any) =>
          a.month - b.month || monthNames.indexOf(a.month) - monthNames.indexOf(b.month),
      )
      const spendObj = Object.assign({}, ...sortedObj)
      const budgetObj = Object.assign({}, ...budgetSortedObj)
      spendObj.id = 'Spend'
      budgetObj.id = 'Forecast'
      let gridArray = [] as GridData[]
      gridArray.push(spendObj)
      gridArray.push(budgetObj)
      return gridArray
    }
  }
  const prepareDataForChart = (data: TotalSpendBudget[]) => {
    if (data !== undefined) {
      return data
    } else [] as any[]
  }

  const [tableData, setTableData] = useState([] as GridData[])
  useEffect(() => {
    if (selectedYear !== undefined && tempData !== undefined && chartTempData !== undefined) {
      let arr = prepareDataForChart(chartTempData)!
      if (arr !== undefined) {
        // setArrayData(arr!)
        setTableData(prepareDataForTable(tempData)!)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, tempData])

  return (
    <Box>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        {/* <SpendChartData data={arrData} year={selectedYear} /> */}
        <IndividualMonthlyVendorChart data={newData} year={selectedYear} />
        <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
          <Autocomplete
            value={selectedYear}
            onChange={(_event: any, newValue: any) => {
              handleYearChange(newValue)
            }}
            id="controllable-states-demo"
            options={yearList}
            sx={{ width: 170 }}
            renderInput={(params) => (
              <TextField {...params} label="Year" variant="standard" placeholder="Select year" />
            )}
            disableClearable={true as any}
            getOptionLabel={(option: any) => option?.label! || ''}
            isOptionEqualToValue={(option: any, value) => option.value === value.value}
          />
          <SpendChartTable filteredItem={tableData} selectedYear={selectedYear} />
        </Stack>
      </Stack>
    </Box>
  )
}
