import { Button, Dialog, DialogActions, DialogContent, Grid, Typography } from '@mui/material'
import * as React from 'react'
import { AlertView } from 'components/AlertView'
type Props = {
  alertDialogEnable?: boolean | any
  alertDialogUpdateState?: boolean | any
}

export default function AlertDialog({
  alertDialogEnable,
  alertDialogUpdateState,
}: Props) {
  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    alertDialogUpdateState(false)
  }
  React.useEffect(() => {
    setDialogState(alertDialogEnable)
  }, [alertDialogEnable])
  return (
    <Dialog open={dialogState} onClose={handleClose}>
      <DialogContent dividers>
        <Grid item xs={6} md={4}>
          <AlertView />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          autoFocus
          variant="contained"
          style={{ backgroundColor: '#DCDCDC' }}
        >
          <Typography sx={{ color: '#6253B5', fontWeight: 'bold' }}>Close</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
