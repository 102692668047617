import { Box, Button, Card, Divider, Stack, TextField, Collapse, Alert } from '@mui/material'
import * as React from 'react'
import { MainBody, RequestBody } from 'reduxStore/subcription/Amex/Model'
import Url from 'APIConfig'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { fetchAmexSource } from 'reduxStore/subcription/Amex/amexSlice'

type Props = {
  selectedPaymentVendorShName?: string
}

export default function PaymentMode(selectedPaymentVendorShName: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const amexValue = useAppSelector((state) => state.fetchAmexSource)
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')

  const [successMsg, setSuccessMsg] = React.useState('')
  const [openAlert, setAlert] = React.useState(false)
  const closeAlert = () => {
    setAlert(false)
  }

  function connectToSdk() {
    var client_id = ''
    var url_params = ''
    if (selectedPaymentVendorShName.selectedPaymentVendorShName === 'QB') {
      // var client_id = 'Q0jJGcDrB04o6n7je2DkHkBoHbreRXc6HUbDvxcCY38wFaBlfC' //Sandbox
      // if (this.serverURL != 'http://localhost:8000') {
      client_id = 'Q0m2N0V59egld74t4clLQuzglOjxh467uGGGvotBXK4v2MzztV' //Production
      // }
      url_params =
        'scope=com.intuit.quickbooks.accounting&redirect_uri=' +
        Url.backEndURL +
        '/api-qb-auth-token&response_type=code&state=' +
        13 +
        '&client_id=' +
        client_id
      PopupCenter(
        'https://appcenter.intuit.com/connect/oauth2?' + url_params,
        'Connect Applogie to Quickbooks',
        600,
        600,
      )
      return false
    } else {
      if (selectedPaymentVendorShName.selectedPaymentVendorShName === 'SO') {
        // var state = this.storedUser.profile.customer.id
        client_id = 'e42457db5619d5664954'
        url_params =
          'redirect_uri=' +
          Url.backEndURL +
          '/api-sageone-token-auth&state=' +
          13 +
          '&client_id=' +
          client_id
        PopupCenter(
          'https://www.sageone.com/oauth2/auth/central?response_type=code&scopes=read_only&' +
            url_params,
          'Connect Applogie to Sage',
          600,
          600,
        )
        return false
      } else {
        if (selectedPaymentVendorShName.selectedPaymentVendorShName === 'XO') {
          var xeroAppUrl =
            'https://api.xero.com/oauth/Authorize?oauth_token=FEJM7BY3DEHPR4HAAXMBOXOYUKG44F'
          var xeroResponseType = 'code'
          client_id = '3BXB3L5WN5P7YQCAVUBMIGPQHH1QKC' //ApplogieConnector Xero App
          var xeroRoute = '/api-xero-auth-generate'
          var state = 15
          url_params =
            'response_type=' +
            xeroResponseType +
            '&client_id=' +
            client_id +
            '&redirect_uri=' +
            Url.backEndURL +
            xeroRoute +
            '&state=' +
            state
          var sfUrl = xeroAppUrl + url_params
          PopupCenter(sfUrl, 'Connect Applogie to Xero', 600, 600)
          return false
        }
      }
    }
  }

  function PopupCenter(url: string, title: string, w: number, h: number) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenLeft //screen.left
    var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenTop //screen.top

    var width = window.innerWidth
    var height = window.innerHeight

    var left = width / 2 - w / 2 + dualScreenLeft
    var top = height / 2 - h / 2 + dualScreenTop
    var newWindow = window.open(
      url,
      title,
      'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left,
    )

    // Puts focus on the newWindow
    // if (window.scrollTo(0, 0)) {
    //   if (newWindow) {
    newWindow!.scrollTo(0, 0)
    //   } else {
    //     alert('Please enable pop-up windows')
    //   }
    // }
  }

  function connectToAmex() {
    let reqBody = {
      user: username,
      password: password,
    } as RequestBody
    let mainBody = {
      objBody: reqBody,
      token: Token!,
    } as MainBody
    dispatch(fetchAmexSource(mainBody))
  }

  React.useEffect(() => {}, [selectedPaymentVendorShName])

  React.useEffect(() => {
    var apisuccessMsg =
      'id' in amexValue.amex ? 'Congratulations! Your Payment Source is properly authorized.' : ''
    if (apisuccessMsg !== '') {
      setSuccessMsg(apisuccessMsg)
      setAlert(true)
    }
  }, [amexValue, successMsg])

  const page =
    selectedPaymentVendorShName.selectedPaymentVendorShName === 'PD' ? (
      <Card>
        <Button
          variant="outlined"
          fullWidth
          sx={{
            textTransform: 'none',
            color: '#2a5a74',
            backgroundColor: '#fff',
            borderColor: '#2a5a74',
            '&:hover': { backgroundColor: '#2a5a74', color: '#fff', cursor: 'pointer' },
          }}
          onClick={() => connectToSdk()}
        >
          Linked account with Plaid
        </Button>
      </Card>
    ) : selectedPaymentVendorShName.selectedPaymentVendorShName === 'SO' ? (
      <Button
        variant="outlined"
        fullWidth
        style={{ textTransform: 'none', color: '#000', borderColor: '#B2BEB5' }}
        onClick={() => connectToSdk()}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <img alt="so" src="sageone.png" style={{ width: '90px' }} />
            <img alt="so" src="favicon.ico" />
          </Stack>
        </Box>
      </Button>
    ) : selectedPaymentVendorShName.selectedPaymentVendorShName === 'XO' ? (
      <Button
        variant="outlined"
        fullWidth
        style={{ textTransform: 'none', color: '#000', borderColor: '#B2BEB5' }}
        onClick={() => connectToSdk()}
      >
        <Box>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <img alt="so" src="xero400.png" style={{ width: '50px', height: '50px' }} />
            <p>
              Connect to <b>Xero</b>
            </p>
          </Stack>
        </Box>
      </Button>
    ) : selectedPaymentVendorShName.selectedPaymentVendorShName === 'MX' ? (
      <Card>
        <Box m={1}>
          <Collapse in={openAlert} timeout="auto" unmountOnExit>
            <Box>
              <Alert severity="success" onClose={closeAlert}>
                {successMsg}
              </Alert>
            </Box>
          </Collapse>
          <TextField
            id="username-basic"
            label="User Name"
            variant="standard"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value)
            }}
            fullWidth
          />
          <TextField
            id="password-basic"
            label="Password"
            variant="standard"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
            }}
            fullWidth
            style={{ marginTop: '10px' }}
            type="password"
          />
          <Button
            variant="outlined"
            style={{
              color: '#fff',
              backgroundColor: '#00daaf',
              borderColor: '#00daaf',
              marginTop: '10px',
              marginBottom: '10px',
              float: 'right',
            }}
            onClick={connectToAmex}
          >
            Connect to Amex
          </Button>
        </Box>
      </Card>
    ) : (
      <Button
        variant="outlined"
        fullWidth
        style={{ textTransform: 'none', color: '#4ba849', borderColor: '#B2BEB5' }}
        onClick={() => connectToSdk()}
      >
        <Box>
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <img alt="so" src="qb.png" />
            <p>Connect to QuickBooks</p>
          </Stack>
        </Box>
      </Button>
    )

  return <div>{page}</div>
}
