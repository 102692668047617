import React from 'react'
import { Helmet } from 'react-helmet-async'
interface Props {
  title: string
}
export default function PageTitle({ title }: Props) {
  return (
    <Helmet>
      <title>{title} - Applogie</title>
    </Helmet>
  )
}
