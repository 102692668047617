import React, { useEffect } from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import { Result } from 'reduxStore/breaches/breachesDetailsByID/Model'
import { BreachesDetailsList } from './Model'

export function TableListData() {
  const breachesDetailsByID = useAppSelector((state) => state.breachesDetailsByID)
  const [breachesList, setBreachesList] = React.useState([] as BreachesDetailsList[])
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    if (breachesDetailsByID?.breachesDetailsByID?.results !== undefined) {
      let breachdetailsItem = breachesDetailsByID.breachesDetailsByID.results.map(
        (element: Result) => {
          return {
            id: element?.id,
            userId: element?.user_id !== null ? element?.user_id : 0,
            fName:
              element?.first_name !== null
                ? element?.first_name !== ''
                  ? element?.first_name
                  : 'Not Set'
                : 'Not Set',
            lName:
              element?.last_name !== null
                ? element?.last_name !== ''
                  ? element?.last_name
                  : 'Not Set'
                : 'Not Set',
            email:
              element?.email !== null
                ? element?.email !== ''
                  ? element?.email
                  : 'Not Set'
                : 'Not Set',
            desc:
              element?.description! !== null
                ? element?.description !== ''
                  ? element?.description
                  : ''
                : '',
          }
        },
      )
      setBreachesList(breachdetailsItem)
    } else setBreachesList([] as BreachesDetailsList[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [breachesDetailsByID])
  return breachesList
}
