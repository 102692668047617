export type FilteredSpendList = FilteredSpendListItem[]

export interface FilteredSpendListItem {
  date: number
  month: string
  year: number
  budget: number
  spend: number
}

export interface Chart {
  year: string
  spend: number
  budget: number
  modifiedYear: string
}

export interface YearList {
  label: string
  value: number
}

export interface TotalSpendBudget {
  spend: string
  budget: string
  month: string
  year: number
  modifiedYear: string
}

export interface YearOfForecast {
  field: string
  value: string
  id?: string
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export interface TotalSpendBudget {
  spend: string
  budget: string
  month: string
  year: number
  modifiedYear: string
}
export interface GridData {
  id: string
  January: string
  February: string
  March: string
  April: string
  May: string
  June: string
  July: string
  August: string
  September: string
  October: string
  November: string
  December: string
}
