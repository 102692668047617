import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import { LoginBody, LoginResponse } from './Model'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: LoginResponse
  successMsg: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as LoginResponse,
  successMsg: '',
  error: '',
}
const headers = {
  Accept: 'application/json',
  'Content-type': 'application/json',
}

export const login = createAsyncThunk<LoginResponse, LoginBody>(
  'login/login',
  async (payload: LoginBody, { rejectWithValue }) => {
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + '/expiry-api-auth-token', payload, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error.response.data.non_field_errors[0] ||
            error.response.message ||
            'Something went wrong',
        ),
      )
  },
)

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    updateLoginClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    updateLoginClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.response = {} as LoginResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true
    })
    builder.addCase(login.fulfilled, (state, action: PayloadAction<LoginResponse>) => {
      state.loading = false
      state.response = action.payload
      state.successMsg =
        action?.payload?.message !== undefined
          ? action?.payload?.message !== ''
            ? action?.payload?.message
            : 'Login Successful'
          : 'Login Successful'
      state.error = ''
      localStorage.setItem('user', JSON.stringify(action.payload?.user!)!)
      localStorage.setItem(
        'demoUser',
        JSON.stringify(
          action.payload?.profile?.customer?.is_demo_customer !== undefined
            ? action.payload?.profile?.customer?.is_demo_customer!
            : false,
        )!,
      )
      localStorage.setItem('token', action.payload.token)
    })
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false
      state.response = {} as LoginResponse
      state.successMsg = ''
      state.error = String(action.payload!)
    })
  },
})
export const { updateLoginClearMsg, updateLoginClearRes } = loginSlice.actions
export default loginSlice.reducer
