import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { apiBody, Results } from 'reduxStore/comments/Model'
import url from 'APIConfig'

type InitialState = {
    loading: boolean
    comments: Results
    error: string
}
const initialState: InitialState = {
    loading: false,
    comments: {} as Results,
    error: '',
}

export const fetchComments = createAsyncThunk<
    Results,
    apiBody
>(
    'fetchComments/fetchComments',
    async (payload: apiBody, { rejectWithValue }) => {
        const headers = {
            Accept: 'application/json',
            'Content-type': 'application/json',
            Authorization: `Token ${payload.token}`,
        }
        return await setupInterceptorsTo(axios) 
            .get(url.backEndURL + `/api/account/comments/?account=${payload.id}`, { headers })
            .then((response) => response.data)
            .catch((error) => rejectWithValue(error.response.data[0].detail))
    },
)

const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchComments.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchComments.fulfilled, (state, action: PayloadAction<Results>) => {
            state.loading = false
            state.comments = action.payload
            state.error = ''
        })
        builder.addCase(fetchComments.rejected, (state, action) => {
            state.loading = false
            state.comments = {} as Results
            state.error = action.error.message || 'Something went wrong'
        })
    },
})

export default commentSlice.reducer
