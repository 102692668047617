import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { useParams } from 'react-router-dom'
import { AccountDetailsModelReq } from 'reduxStore/account/suspiciousActivityList/SuspiciousActivityModel'
import { fetchSuspiciousActivityListById } from 'reduxStore/account/suspiciousActivityList/SuspiciousActivityListSlice'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SuspiciousActivityList from './SuspiciousActivityList'

type Props = {
  suspiciousActivityDialogEnable?: boolean
  suspiciousActivityDialogUpdateState: (value: boolean) => void
}

export default function SuspiciousActivityDialog({
  suspiciousActivityDialogEnable,
  suspiciousActivityDialogUpdateState,
}: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const suspiciousActivityList = useAppSelector((state) => state.suspiciousActivityList)
  // Get the userId param from the URL.
  let { id } = useParams()

  let accountDetailsByIdBody = {
    accountId: String(id),
    token: Token,
  } as AccountDetailsModelReq

  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    suspiciousActivityDialogUpdateState(false)
  }

  React.useEffect(() => {
    setDialogState(suspiciousActivityDialogEnable!)
  }, [suspiciousActivityDialogEnable])

  React.useEffect(() => {
    dispatch(fetchSuspiciousActivityListById(accountDetailsByIdBody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  return (
    <Dialog
      open={dialogState}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="responsive-dialog-title">
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }} style={{ marginLeft: 15 }}>
                  Suapicious Activity:
                </Typography>
              </Grid>
              <Grid item>
                <Chip
                  label={
                    'results' in suspiciousActivityList.accountDetails
                      ? suspiciousActivityList.accountDetails.results.length
                      : 0
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <HighlightOffIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Box sx={{ height: '545px' }}>
          <SuspiciousActivityList />
        </Box>
      </DialogContent>
    </Dialog>
  )
}
