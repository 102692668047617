import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../../../APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { MainBody, EditCompanyUserErrorResponse, EditCompanyUserResponse } from './editCompanyUserModel'

type InitialState = {
  loading: boolean
  editresponse: EditCompanyUserResponse
  error: EditCompanyUserErrorResponse
  succssMessage: string
}
const initialState: InitialState = {
  loading: false,
  editresponse: {} as EditCompanyUserResponse,
  error: { message: '' } as EditCompanyUserErrorResponse,
  succssMessage: '',
}

export const editCompanyUserApi = createAsyncThunk<
  EditCompanyUserResponse,
  MainBody
>(
  'editCompanyUserApi/editCompanyUserApi',
  async (payload: MainBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .put(Url.backEndURL + `/api/users/${payload.id}`, payload.body, { headers })
      .then((editresponse) => editresponse.data)
      .catch((error) => rejectWithValue(error.editresponse.data[0].detail))
  },
)

const editCompanyUserSlice = createSlice({
  name: 'editCompanyUserApi',
  initialState,
  reducers: {
    editCompanyUserClearMsg: (state) => {
      state.succssMessage = ''
      state.error = { message: '' } as EditCompanyUserErrorResponse
    },
    editCompanyUserClearRes: (state) => {
      state.succssMessage = ''
      state.error = { message: '' } as EditCompanyUserErrorResponse
      state.editresponse = {} as EditCompanyUserResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editCompanyUserApi.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      editCompanyUserApi.fulfilled,
      (state, action: PayloadAction<EditCompanyUserResponse>) => {
        state.loading = false
        state.editresponse = action.payload
        state.succssMessage =
        action?.payload?.succssMessage !== undefined
          ? action?.payload?.succssMessage !== ''
            ? action?.payload?.succssMessage
            : 'Edit Company User successful'
          : 'Edit Company User successful'
        state.error = {} as any
      },
    )
    builder.addCase(editCompanyUserApi.rejected, (state, action) => {
      state.loading = false
      state.editresponse = {} as EditCompanyUserResponse
      state.error.message = action.payload! || 'Something went wrong'
    })
  },
})
export const { editCompanyUserClearMsg, editCompanyUserClearRes } = editCompanyUserSlice.actions
export default editCompanyUserSlice.reducer
