import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import {
  LisenceListReq,
  LicenseListRes,
} from 'reduxStore/account/license/licenseListByAccountID/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  licenses: LicenseListRes
  error: string
  enableDialog: boolean
  selectedItem: any
}
const initialState: InitialState = {
  loading: false,
  licenses: {} as LicenseListRes,
  error: '',
  enableDialog: false,
  selectedItem: {} as any,
}

export const fetchLicense = createAsyncThunk<LicenseListRes, LisenceListReq>(
  'fetchLicense/fetchLicense',
  async (payload: LisenceListReq, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/account/license/?account=${payload.id}`, { headers })
      .then((response) => response.data)
      .catch(
        (error) =>
          rejectWithValue(error.response.data[0].detail) ||
          error.response.data.message ||
          'Something went wrong',
      )
  },
)

const licenseSlice = createSlice({
  name: 'license',
  initialState,
  reducers: {
    updateAddEditDialogState: (state, action) => {
      state.enableDialog = action.payload
    },
    selectedLicenseDetails: (state, action) => {
      state.selectedItem = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLicense.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchLicense.fulfilled, (state, action: PayloadAction<LicenseListRes>) => {
      state.loading = false
      state.licenses = action.payload
      state.error = ''
    })
    builder.addCase(fetchLicense.rejected, (state, action) => {
      state.loading = false
      state.licenses = {} as LicenseListRes
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export const { updateAddEditDialogState, selectedLicenseDetails } = licenseSlice.actions
export default licenseSlice.reducer
