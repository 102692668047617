import React, { useState } from 'react'
import {
  Chip,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { User } from 'reduxStore/login/Model'
import { useAppSelector } from 'reduxStore/hooks'

interface Props {
  quickActionItem: (selectedItem: number) => void
}
export default function BasicPopover({ quickActionItem }: Props) {
  const alert = useAppSelector((state) => state.alert)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  let userDetails = JSON.parse(localStorage.getItem('user')!) as User

  const quickActionButtonList = ['Alert', 'UserName', 'Support', 'Logout']

  return (
    <div>
      <IconButton onClick={handleClick} style={{ color: '#ffffff' }}>
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List aria-label="main mailbox folders" dense>
          {quickActionButtonList.map((item: any, index: number) => {
            return (
              <ListItem disablePadding>
                <ListItemButton
                  key={index}
                  onClick={() => {
                    quickActionItem(index)
                  }}
                >
                  {index === 0 ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>{item}</Grid>
                      <Grid item>
                        <Chip label={` ${alert?.alerts.length}`} />
                      </Grid>
                    </Grid>
                  ) : (
                    <ListItemText primary={index === 1 ? 'Hi ' + userDetails?.first_name : item} />
                  )}
                </ListItemButton>
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </div>
  )
}
