import {
  Alert,
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { useParams } from 'react-router-dom'
import {
  accountAgreementClearRes,
  addAccAgreement,
} from 'reduxStore/AddAccountAgrementDetails/addAccountAgreementDetailsSlice'
import { AddAccAgrementBody, MainBody } from 'reduxStore/AddAccountAgrementDetails/Model'
import ApplogieSnackbar from 'components/ApplogieSnackbar'

type Props = {
  toggleAddAccountAgreementDialogEnable?: boolean | any
  toggleAddAccountAgreementDialogUpdateState?: boolean | any
  updateAccAgreementSuccessMessage?: (message: string) => void
}

export default function UpdateAccountAgreementDetailsDialog({
  toggleAddAccountAgreementDialogEnable,
  toggleAddAccountAgreementDialogUpdateState,
  updateAccAgreementSuccessMessage,
}: Props) {
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')

  // Get the userId param from the URL.
  let { id } = useParams()

  // Call store
  const getAccountAgreementDetailsById = useAppSelector(
    (state) => state.getAccountAgreementDetailsById,
  )
  const addAccAgreementRes = useAppSelector((state) => state.addAccountAgreementDetailsRes)
  const [contactName, setContactName] = React.useState('')
  const [contactPhone, setContactPhone] = React.useState('')
  const [contactEmail, setContactEmail] = React.useState('')
  const [remidiation, setRemidiation] = React.useState('')
  const [branchDetails, setBranchDetails] = React.useState('')
  const [risk, setRisk] = React.useState('')
  const [openAlert, setAlert] = React.useState(false)
  const closeAlert = () => {
    setAlert(false)
  }
  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')
  const [showAddLicenseMsg, setShowAddLicenseMsg] = React.useState('')
  const closeUpdateagreementSnackbar = () => {
    dispatch(accountAgreementClearRes())
    setShowAddLicenseMsg('')
  }
  React.useEffect(() => {
    if (addAccAgreementRes?.succssMessage !== '') {
      setAPIMsgType('success')
      setShowAddLicenseMsg(addAccAgreementRes?.succssMessage)
      updateAccAgreementSuccessMessage &&
        updateAccAgreementSuccessMessage(addAccAgreementRes?.succssMessage)
      setTimeout(() => {
        handleClose()
      }, 100)
    }
    if (addAccAgreementRes?.error !== '') {
      if (addAccAgreementRes?.error) {
        setAPIMsgType('error')
        setShowAddLicenseMsg(addAccAgreementRes?.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addAccAgreementRes])

  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
        ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== undefined
          ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
            ? 'results' in getAccountAgreementDetailsById.getAccountAgreementDetailsRes
              ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== undefined
                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== null
                  ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results.length > 0
                    ? 'contact_name' in
                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .contact_name !== undefined ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .contact_name !== null ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .contact_name !== ''
                        ? setContactName(
                            getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                              .contact_name,
                          )
                        : setContactName('')
                      : setContactName('')
                    : setContactName('')
                  : setContactName('')
                : setContactName('')
              : setContactName('')
            : setContactName('')
          : setContactName('')
        : setContactName('')
    }
    // eslint-disable-next-line no-lone-blocks
    {
      'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
        ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== undefined
          ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
            ? 'results' in getAccountAgreementDetailsById.getAccountAgreementDetailsRes
              ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== undefined
                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== null
                  ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results.length > 0
                    ? 'contact_phone_number' in
                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .contact_phone_number !== undefined ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .contact_phone_number !== null ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .contact_phone_number !== ''
                        ? setContactPhone(
                            getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                              .contact_phone_number,
                          )
                        : setContactPhone('')
                      : setContactPhone('')
                    : setContactPhone('')
                  : setContactPhone('')
                : setContactPhone('')
              : setContactPhone('')
            : setContactPhone('')
          : setContactPhone('')
        : setContactPhone('')
    }
    // eslint-disable-next-line no-lone-blocks
    {
      'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
        ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== undefined
          ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
            ? 'results' in getAccountAgreementDetailsById.getAccountAgreementDetailsRes
              ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== undefined
                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== null
                  ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results.length > 0
                    ? 'contact_email' in
                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .contact_email !== undefined ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .contact_email !== null ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .contact_email !== ''
                        ? setContactEmail(
                            getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                              .contact_email,
                          )
                        : setContactEmail('')
                      : setContactEmail('')
                    : setContactEmail('')
                  : setContactEmail('')
                : setContactEmail('')
              : setContactEmail('')
            : setContactEmail('')
          : setContactEmail('')
        : setContactEmail('')
    }
    // eslint-disable-next-line no-lone-blocks
    {
      'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
        ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== undefined
          ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
            ? 'results' in getAccountAgreementDetailsById.getAccountAgreementDetailsRes
              ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== undefined
                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== null
                  ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results.length > 0
                    ? 'risk_calculation' in
                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .risk_calculation !== undefined ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .risk_calculation !== null ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .risk_calculation !== ''
                        ? setRisk(
                            getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                              .risk_calculation,
                          )
                        : setRisk('')
                      : setRisk('')
                    : setRisk('')
                  : setRisk('')
                : setRisk('')
              : setRisk('')
            : setRisk('')
          : setRisk('')
        : setRisk('')
    }
    // eslint-disable-next-line no-lone-blocks
    {
      'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
        ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== undefined
          ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
            ? 'results' in getAccountAgreementDetailsById.getAccountAgreementDetailsRes
              ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== undefined
                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== null
                  ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results.length > 0
                    ? 'remediation' in
                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .remediation !== undefined ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .remediation !== null ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .remediation !== ''
                        ? setRemidiation(
                            getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                              .remediation,
                          )
                        : setRemidiation('')
                      : setRemidiation('')
                    : setRemidiation('')
                  : setRemidiation('')
                : setRemidiation('')
              : setRemidiation('')
            : setRemidiation('')
          : setRemidiation('')
        : setRemidiation('')
    }
    // eslint-disable-next-line no-lone-blocks
    {
      'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
        ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== undefined
          ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
            ? 'results' in getAccountAgreementDetailsById.getAccountAgreementDetailsRes
              ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== undefined
                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results !== null
                  ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results.length > 0
                    ? 'breach_sla_details' in
                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .breach_sla_details !== undefined ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .breach_sla_details !== null ||
                        getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                          .breach_sla_details !== ''
                        ? setBranchDetails(
                            getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results[0]
                              .breach_sla_details,
                          )
                        : setBranchDetails('')
                      : setBranchDetails('')
                    : setBranchDetails('')
                  : setBranchDetails('')
                : setBranchDetails('')
              : setBranchDetails('')
            : setBranchDetails('')
          : setBranchDetails('')
        : setBranchDetails('')
    }
  }, [getAccountAgreementDetailsById])

  const [netErrMsq, setNetErrMsq] = React.useState('')
  const [dialogState, setDialogState] = React.useState(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleClose = () => {
    setContactName('')
    setContactPhone('')
    setContactEmail('')
    setRemidiation('')
    setBranchDetails('')
    setRisk('')
    setNetErrMsq('')
    setDialogState(false)
    setShowAddLicenseMsg('')
    dispatch(accountAgreementClearRes())
    toggleAddAccountAgreementDialogUpdateState(false)
  }

  let aggrementBody = {
    account_id: id,
    breach_sla_details: branchDetails,
    contact_name: contactName,
    contact_phone_number: contactPhone,
    contact_email: contactEmail,
    risk_calculation: risk,
    remediation: remidiation,
  } as MainBody
  let mainBody = {
    id: id,
    body: aggrementBody,
    token: Token!,
  } as AddAccAgrementBody

  const addAgrementDetails = async () => {
    if (
      contactName !== '' &&
      contactPhone !== '' &&
      contactEmail !== '' &&
      remidiation !== '' &&
      branchDetails !== '' &&
      risk !== ''
    ) {
      if (navigator.onLine) {
        setNetErrMsq('')
        await dispatch(addAccAgreement(mainBody))
      } else {
        setNetErrMsq('You are currently offline')
        setAlert(true)
      }
    }
  }

  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      'error' in addAccAgreementRes
        ? addAccAgreementRes.error !== ''
          ? handleClose()
          : null
        : null
    }
  }, [addAccAgreementRes, handleClose])
  React.useEffect(() => {
    setDialogState(toggleAddAccountAgreementDialogEnable)
  }, [toggleAddAccountAgreementDialogEnable])
  return (
    <Box>
      <Dialog open={dialogState} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction={'row'}>
              <Typography variant="h6">
                <b>Update Account Agreement:</b>
              </Typography>
            </Stack>
          </Stack>
        </DialogTitle>
        <Collapse in={openAlert} timeout="auto" unmountOnExit>
          <Box m={3}>
            <Alert severity="error" onClose={closeAlert}>
              <b>Error:</b> All fields are mendatory, Please provide all the Values.
            </Alert>
          </Box>
        </Collapse>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            rowSpacing={50}
            spacing={4}
          >
            <Grid item>
              <Grid container direction="column" justifyContent="center" alignItems="center" mt={2}>
                <Box>
                  <Grid item>
                    <TextField
                      id="standard-basic"
                      label="Contact Name"
                      variant="standard"
                      placeholder="Please Enter username"
                      value={contactName}
                      onChange={(e) => setContactName(e.target.value)}
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <TextField
                      id="standard-basic"
                      label="Contact Email"
                      placeholder="Contact Email"
                      variant="standard"
                      name="email"
                      type="email"
                      value={contactEmail}
                      onChange={(e) => setContactEmail(e.target.value)}
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <TextField
                      id="standard-basic"
                      label="Remediation"
                      placeholder="Remediation"
                      variant="standard"
                      value={remidiation}
                      onChange={(e) => setRemidiation(e.target.value)}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction="column" justifyContent="center" alignItems="center" mt={2}>
                <Box>
                  <Grid item>
                    <TextField
                      id="standard-basic"
                      label="Contact Phone Number"
                      variant="standard"
                      placeholder="123-456-7890"
                      value={contactPhone}
                      onChange={(e) => setContactPhone(e.target.value)}
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <TextField
                      id="standard-basic"
                      label="Risk Calculation"
                      placeholder="Risk Calculation"
                      variant="standard"
                      value={risk}
                      onChange={(e) => setRisk(e.target.value)}
                    />
                  </Grid>
                  <Grid item mt={2}>
                    <TextField
                      id="standard-basic"
                      label="Breach SLA Details"
                      placeholder="Breach SLA Details"
                      variant="standard"
                      value={branchDetails}
                      onChange={(e) => setBranchDetails(e.target.value)}
                    />
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {netErrMsq !== '' ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <Typography sx={{ color: '#c62828' }}>{netErrMsq}</Typography>
              </Grid>
            </Grid>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} autoFocus>
            <Typography sx={{ color: '#292929' }}>Cancel</Typography>
          </Button>
          <Button variant="contained" onClick={() => addAgrementDetails()}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography sx={{ color: '#000000' }}>Save</Typography>
              </Grid>
              {addAccAgreementRes.loading ? (
                <Grid item>
                  <CircularProgress color="inherit" size={18} />
                </Grid>
              ) : null}
            </Grid>
          </Button>
        </DialogActions>
      </Dialog>
      <ApplogieSnackbar
        msg={showAddLicenseMsg}
        type={apiMsgType}
        alertClose={closeUpdateagreementSnackbar}
      />
    </Box>
  )
}
