import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { DeletePaymentResponse, DeletePaymentBody } from './Model'

type InitialState = {
  loading: boolean
  response: DeletePaymentResponse
  succssMessage: string
  error: string
  enableDeletePaymentDialog: boolean
  selectedItem: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as DeletePaymentResponse,
  error: '',
  succssMessage: '',
  enableDeletePaymentDialog: false,
  selectedItem: '',
}

export const deletePaymentByID = createAsyncThunk<DeletePaymentResponse, DeletePaymentBody>(
  'deletePaymentByID/deletePaymentByID',
  async (payload: DeletePaymentBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(
        Url.backEndURL + `/api/payment/delete_payment`,
        { payment_id: payload.payment_id },
        { headers },
      )
      .then((response) => response.data)
      .catch(
        (error) => rejectWithValue(error.response.data[0].detail) || error.response.data.message,
      )
  },
)

const deletePaymentByIDSlice = createSlice({
  name: 'deletePaymentByID',
  initialState,
  reducers: {
    updateDeletePaymentClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    updateDeletePaymentClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.response = {} as DeletePaymentResponse
    },
    enableDeleteSubscriptionPaymentDialog: (state, action) => {
      state.enableDeletePaymentDialog = action.payload
    },
    selectItemForDeleteSubscriptionPayment: (state, action) => {
      state.selectedItem = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deletePaymentByID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      deletePaymentByID.fulfilled,
      (state, action: PayloadAction<DeletePaymentResponse>) => {
        state.loading = false
        state.response = action.payload
        state.succssMessage =
        action?.payload?.message !== undefined
          ? action?.payload?.message !== ''
            ? action?.payload?.message
            : 'Payment Deleted successfully'
          : 'Payment Deleted successfully'
        state.error = ''
      },
    )
    builder.addCase(deletePaymentByID.rejected, (state, action) => {
      state.loading = false
      state.response = {} as DeletePaymentResponse
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})
export const {
  updateDeletePaymentClearMsg,
  updateDeletePaymentClearRes,
  enableDeleteSubscriptionPaymentDialog,
  selectItemForDeleteSubscriptionPayment,
} = deletePaymentByIDSlice.actions
export default deletePaymentByIDSlice.reducer
