import * as React from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import HandshakeIcon from '@mui/icons-material/Handshake'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
} from '@mui/material'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import UpdateAccountAgreementDetailsDialog from './UpdateAccountAgreementDetailsDialog'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { accountAgreementClearRes } from 'reduxStore/AddAccountAgrementDetails/addAccountAgreementDetailsSlice'

export default function AccountAgreementDetails() {
  const dispatch = useAppDispatch()
  // Call store
  const getAccountAgreementDetailsById = useAppSelector(
    (state) => state.getAccountAgreementDetailsById,
  )
  const [successMessage, setSuccessMessage] = React.useState('')
  const [showAddAgreementDialog, setShowAddAgreementDialog] = React.useState(false)
  const updateAddAgreementDialog = () => {
    setShowAddAgreementDialog(true)
  }
  // get data from child component
  const updateDialogValFromChild = (passedval: boolean) => {
    // the callback. Use a better name
    setShowAddAgreementDialog(passedval)
  }

  const handleSuccessMessage = (message: string) => {
    // Handle success message in the parent component
    setSuccessMessage(message)
  }

  const closeAddagreementSnackbar = () => {
    dispatch(accountAgreementClearRes())
    setSuccessMessage('')
  }

  return (
    <Box>
      <Card sx={{ minHeight: '250px' }}>
        <CardHeader
          avatar={<HandshakeIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack direction={'row'}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Account Agreement Details
                </Typography>
              </Stack>
              <Stack direction={'row'}>
                <IconButton aria-label="delete" onClick={updateAddAgreementDialog}>
                  <BorderColorIcon />
                </IconButton>
              </Stack>
            </Stack>
          }
        ></CardHeader>
        <CardContent sx={{ maxHeight: '170px', overflow: 'auto' }}>
          {getAccountAgreementDetailsById.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Box>
              {'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById ? (
                'results' in getAccountAgreementDetailsById.getAccountAgreementDetailsRes ? (
                  getAccountAgreementDetailsById.getAccountAgreementDetailsRes.results.length >
                  0 ? (
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                      spacing={2}
                      pl={1}
                    >
                      <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                          {'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
                            ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !==
                                undefined ||
                              getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
                              ? 'results' in
                                getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== undefined ||
                                  getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== null
                                  ? 'contact_name' in
                                    getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                      .results[0]
                                    ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].contact_name !== undefined ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].contact_name !== null ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].contact_name !== ''
                                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                          .results[0].contact_name
                                      : null
                                    : null
                                  : null
                                : null
                              : null
                            : null}
                        </Typography>
                        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                          Contact Name
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                          {'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
                            ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !==
                                undefined ||
                              getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
                              ? 'results' in
                                getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== undefined ||
                                  getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== null
                                  ? 'contact_phone_number' in
                                    getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                      .results[0]
                                    ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].contact_phone_number !== undefined ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].contact_phone_number !== null ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].contact_phone_number !== ''
                                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                          .results[0].contact_phone_number
                                      : null
                                    : null
                                  : null
                                : null
                              : null
                            : null}
                        </Typography>
                        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                          Contact Phone Number
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                          {'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
                            ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !==
                                undefined ||
                              getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
                              ? 'results' in
                                getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== undefined ||
                                  getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== null
                                  ? 'contact_email' in
                                    getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                      .results[0]
                                    ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].contact_email !== undefined ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].contact_email !== null ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].contact_email !== ''
                                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                          .results[0].contact_email
                                      : null
                                    : null
                                  : null
                                : null
                              : null
                            : null}
                        </Typography>
                        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                          Contact Email
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                          {'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
                            ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !==
                                undefined ||
                              getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
                              ? 'results' in
                                getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== undefined ||
                                  getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== null
                                  ? 'risk_calculation' in
                                    getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                      .results[0]
                                    ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].risk_calculation !== undefined ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].risk_calculation !== null ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].risk_calculation !== ''
                                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                          .results[0].risk_calculation
                                      : null
                                    : null
                                  : null
                                : null
                              : null
                            : null}
                        </Typography>
                        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                          Risk Calculation
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                          {'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
                            ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !==
                                undefined ||
                              getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
                              ? 'results' in
                                getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== undefined ||
                                  getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== null
                                  ? 'remediation' in
                                    getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                      .results[0]
                                    ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].remediation !== undefined ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].remediation !== null ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].remediation !== ''
                                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                          .results[0].remediation
                                      : null
                                    : null
                                  : null
                                : null
                              : null
                            : null}
                        </Typography>
                        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                          Remidiation
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                          {'getAccountAgreementDetailsRes' in getAccountAgreementDetailsById
                            ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes !==
                                undefined ||
                              getAccountAgreementDetailsById.getAccountAgreementDetailsRes !== null
                              ? 'results' in
                                getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== undefined ||
                                  getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                    .results !== null
                                  ? 'breach_sla_details' in
                                    getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                      .results[0]
                                    ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].breach_sla_details !== undefined ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].breach_sla_details !== null ||
                                      getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                        .results[0].breach_sla_details !== ''
                                      ? getAccountAgreementDetailsById.getAccountAgreementDetailsRes
                                          .results[0].breach_sla_details
                                      : null
                                    : null
                                  : null
                                : null
                              : null
                            : null}
                        </Typography>
                        <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
                          Breach SLA Details
                        </Typography>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      pt={1}
                    >
                      <Grid item>
                        <Typography variant="subtitle1">No content available</Typography>
                      </Grid>
                    </Grid>
                  )
                ) : null
              ) : null}
            </Box>
          )}
        </CardContent>
      </Card>
      <ApplogieSnackbar
        msg={successMessage}
        type="success"
        alertClose={closeAddagreementSnackbar}
      />
      {showAddAgreementDialog ? (
        <UpdateAccountAgreementDetailsDialog
          toggleAddAccountAgreementDialogEnable={showAddAgreementDialog}
          toggleAddAccountAgreementDialogUpdateState={updateDialogValFromChild}
          updateAccAgreementSuccessMessage={handleSuccessMessage}
        />
      ) : null}
    </Box>
  )
}
