import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Result } from './vendorDeptModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  vendorSpends: Result[]
  error: string
}
const initialState: InitialState = {
  loading: false,
  vendorSpends: [],
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchVendorDepts = createAsyncThunk<Result[], string>(
  'fetchVendorCategory/fetchVendorDepts',
  (adminToken) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${adminToken}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + '/api/customers/usage/department/', { headers })
      .then((response) => response.data.results)
  },
)

const fetchVendorDeptSlice = createSlice({
  name: 'fetchVendorCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVendorDepts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchVendorDepts.fulfilled, (state, action: PayloadAction<Result[]>) => {
      state.loading = false
      state.vendorSpends = action.payload
      state.error = ''
    })
    builder.addCase(fetchVendorDepts.rejected, (state, action) => {
      state.loading = false
      state.vendorSpends = []
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default fetchVendorDeptSlice.reducer
