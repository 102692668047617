import {
  Alert,
  AlertColor,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { ToggleCompnayUserByIDBody } from 'reduxStore/customer/companyUser/toggleCompanyUserByID/Model'
import {
  toggleCompnayUserByID,
  toggleCompnayUserClearRes,
} from 'reduxStore/customer/companyUser/toggleCompanyUserByID/toggleCompanyUserByIDSlice'

type Props = {
  itemId?: string | any
  toggleCompanyUserByIDDialogEnable?: boolean | any
  toggleCompanyUserByIDDialogUpdateState?: boolean | any
}

export default function ToggleCompanyUserByIDDialog({
  itemId,
  toggleCompanyUserByIDDialogEnable,
  toggleCompanyUserByIDDialogUpdateState,
}: Props) {
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')

  // Call store
  const toggleCompanyUserByID = useAppSelector((state) => state.toggleCompanyUserByID)

  const [netErrMsq, setNetErrMsq] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState('')
  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    toggleCompanyUserByIDDialogUpdateState(false)
    dispatch(toggleCompnayUserClearRes())
  }
  let toggleCompanyUserByIDBody = {
    id: String(selectedItem),
    token: Token!,
  } as ToggleCompnayUserByIDBody

  const deActivateUsers = async () => {
    if (navigator.onLine) {
      setNetErrMsq('')
      setTimeout(() => {
        handleClose()
      }, 1500)
      await dispatch(toggleCompnayUserByID(toggleCompanyUserByIDBody))
    } else {
      setNetErrMsq('You are currently offline')
    }
  }
  const [showAPISuccessMsgView, setShowAPISuccessMsgView] = React.useState(false)
  const [apiResMsg, setAPIresMsg] = useState('')
  const [msgType, setMsgType] = useState<AlertColor>('success')
  React.useEffect(() => {
    if (toggleCompanyUserByID?.error !== '') {
      setShowAPISuccessMsgView(true)
      setMsgType('error')
      setAPIresMsg(toggleCompanyUserByID?.error)
    }
  }, [toggleCompanyUserByID])
  React.useEffect(() => {
    setSelectedItem(itemId)
    setDialogState(toggleCompanyUserByIDDialogEnable)
  }, [itemId, toggleCompanyUserByIDDialogEnable])
  return (
    <Dialog open={dialogState} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <DialogContentText>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Are you sure you want to Inactive this User?
          </Typography>
        </DialogContentText>
        {netErrMsq !== '' ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <Typography sx={{ color: '#c62828' }}>{netErrMsq}</Typography>
            </Grid>
          </Grid>
        ) : null}

        <Collapse in={showAPISuccessMsgView}>
          <Alert
            icon={false}
            severity={msgType}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAPISuccessMsgView(false)
                  dispatch(toggleCompnayUserClearRes())
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {apiResMsg !== '' ? apiResMsg : null}
          </Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => deActivateUsers()}
          disabled={toggleCompanyUserByID.loading}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Typography>Yes</Typography>
            </Grid>
            {toggleCompanyUserByID.loading ? (
              <Grid item>
                <CircularProgress color="inherit" size={18} />
              </Grid>
            ) : null}
          </Grid>
        </Button>
        <Button variant="outlined" onClick={handleClose} disabled={toggleCompanyUserByID.loading}>
          <Typography sx={{ color: '#3f51b5' }}>No</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  )
}
