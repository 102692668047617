import { Grid, IconButton, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React from 'react'
import { useAppDispatch } from 'reduxStore/hooks'
import { numberWithDecimalCommas } from 'utils/numberWithCommas'
import {
  selectedSubscriptionPaymentDetails,
  updateSubscriptionPaymentEditDialogState,
} from 'reduxStore/account/accountUpdatePayment/accountUpdatePaymentSlice'
import {
  enableDeleteSubscriptionPaymentDialog,
  selectItemForDeleteSubscriptionPayment,
} from 'reduxStore/account/accountPaymentDeleteByID/deletePaymentByIDSlice'

export function SubscriptionPaymentListTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      sortable: true,
      hideable: true,
      width: 110,
      align: 'left',
    },
    {
      field: 'amount',
      headerName: 'Payment',
      sortable: true,
      hideable: false,
      minWidth: 110,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return (
          <Typography sx={{ pt: 2, fontSize: '14px' }}>
            {params.row.amount !== null ? numberWithDecimalCommas(params.row.amount) : null}
          </Typography>
        )
      },
    },
    {
      field: 'memo',
      headerName: 'Memo',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      flex: 1,
    },
    {
      field: 'id',
      headerName: 'Action',
      sortable: true,
      hideable: false,
      minWidth: 110,
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        let getIndividualUsageUpdate = localStorage.getItem('allowIndividualSpendUpdate')
        return (
          <React.Fragment>
            {getIndividualUsageUpdate !== null ? (
              getIndividualUsageUpdate === 'true' ? (
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={0.5}
                >
                  <Grid item>
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        dispatch(updateSubscriptionPaymentEditDialogState(true))
                        dispatch(selectedSubscriptionPaymentDetails(params.row))
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        dispatch(enableDeleteSubscriptionPaymentDialog(true))
                        dispatch(selectItemForDeleteSubscriptionPayment(String(params.row.id)))
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              ) : null
            ) : null}
          </React.Fragment>
        )
      },
    },
  ]
  return columns
}
