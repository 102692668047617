import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import { UpdateSpendBody, UpdateSpendRes } from './Model'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: UpdateSpendRes
  successMessage: string
  errorMessage: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as UpdateSpendRes,
  successMessage: '',
  errorMessage: '',
}

export const updateSpendDetails = createAsyncThunk<UpdateSpendRes, UpdateSpendBody>(
  'updateSpendDetails/updateSpendDetails',
  async (payload: UpdateSpendBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + '/api/usage', payload, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data[0]?.description !== undefined
              ? error?.response?.data[0]?.description
              : error.response.data.message !== undefined
              ? error.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const updateSpendDetailsSlice = createSlice({
  name: 'updateSpendDetails',
  initialState,
  reducers: {
    updateSpendClearMsg: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
    },
    updateSpendClearRes: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
      state.response = {} as UpdateSpendRes
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSpendDetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      updateSpendDetails.fulfilled,
      (state, action: PayloadAction<UpdateSpendRes>) => {
        state.loading = false
        state.response = action.payload
        state.successMessage = 'Spend details has been updated successfully'
        state.errorMessage = ''
      },
    )
    builder.addCase(updateSpendDetails.rejected, (state, action) => {
      state.loading = false
      state.response = {} as UpdateSpendRes
      state.successMessage = ''
      state.errorMessage = String(action.payload!)
    })
  },
})
export const { updateSpendClearMsg, updateSpendClearRes } =
updateSpendDetailsSlice.actions

export default updateSpendDetailsSlice.reducer
