export interface Item {
  label: string
  value: number
}
export const Items = [
  {
    label: 'Owner',
    value: 1,
  },
  {
    label: 'Department',
    value: 2,
  },
  {
    label: 'Renewal Date',
    value: 3,
  },
  {
    label: 'Status',
    value: 4,
  },
] as Item[]
