import { Typography } from '@mui/material'
import * as React from 'react'

type Props = {
  selectedPaymentVendorShName?: string
}

export default function TextComponent(selectedPaymentVendorShName: Props) {
  const page =
    selectedPaymentVendorShName.selectedPaymentVendorShName === 'MX' ||
    selectedPaymentVendorShName.selectedPaymentVendorShName === 'SO' ||
    selectedPaymentVendorShName.selectedPaymentVendorShName === 'PD' ||
    selectedPaymentVendorShName.selectedPaymentVendorShName === 'SO' ? null : (
      <Typography align="center">OR</Typography>
    )

  React.useEffect(() => {}, [selectedPaymentVendorShName])

  return <div>{page}</div>
}
