import React from 'react'
import { styled } from '@mui/material/styles'
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material'
export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgba(21, 171, 209, 0.87)',
    color: 'rgba(255, 255, 255, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))
