import React, { useEffect } from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import { Result } from 'reduxStore/breaches/breachesList/breachesModel'
import dayjs from 'dayjs'
import { BreachesList } from './Model'

export function TableListData() {
  const breachVal = useAppSelector((state) => state.breaches)
  const [breachesList, setBreachesList] = React.useState([] as BreachesList[])
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (breachVal?.spend?.results!) {
        let breachItem = breachVal?.spend?.results!.map((element: Result) => {
          return {
            id: element.id,
            vendorName: element?.vendor_name! !== null ? element.vendor_name : 'Not Set',
            vendorID: element?.breach_vendor_id ? element.breach_vendor_id : 0,
            userCount: element?.user_count,
            description: element?.description! !== null ? element?.description : '',
            breachDate:
              element?.breach_date! !== null
                ? dayjs(element?.breach_date).format('ddd, DD MM YYYY')
                : 'Not Set',
            password: element?.passwords!,
            creditCard: element?.credit_cards!,
            authToken: element?.auth_tokens!,
            passwordHint: element?.password_hints!,
            securityQA: element?.security_q_and_a!,
          } as BreachesList
        })
        setBreachesList(breachItem)
      } else setBreachesList([] as BreachesList[])
    }
  }, [breachVal])
  return breachesList
}
