import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Item, Items } from './Items'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { updateSubscriptionListItem } from 'reduxStore/selectSubscriptionForSetDept/selectSubscriptionForSetDeptSlice'
export default function ActionItems() {
  const dispatch = useAppDispatch()
  const selectSubscriptionForSetDeptRes = useAppSelector(
    (state) => state.selectSubscriptionForSetDeptRes,
  )
  const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)
  const [actionItems, setActionItems] = useState([] as Item[])
  const [selectedActionItem, setSelectedActionItem] = useState<Item | null>(null)

  const actionItemChange = (item: Item | null) => {
    setSelectedActionItem(item)
    if (item !== null) {
      let itemVal = item.value
      dispatch(updateSubscriptionListItem(itemVal))
    }
  }
  useEffect(() => {
    setActionItems(Items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Items])

  return (
    <Autocomplete
      options={actionItems}
      value={selectedActionItem}
      onChange={(_event, value: any) => actionItemChange(value)}
      disableClearable={true as any}
      disabled={editSubscriptionValue?.loading}
      getOptionLabel={(option) => `${option.label}`}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{pb:1}}
          label="Select Actions"
          variant="standard"
          size="small"
          fullWidth
          disabled={String(selectSubscriptionForSetDeptRes?.updateType) === ''}
        />
      )}
    />
  )
}
