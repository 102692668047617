import { Switch } from '@mui/material'
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-premium'
import React from 'react'
import { CustomDetailPanelToggle } from '../../../components/Subscripiton/CustomDetailPanelToggle'
import { useAppDispatch } from 'reduxStore/hooks'
import { updateDeactivateUser } from 'reduxStore/customer/companyUser/toggleCompanyUserByID/toggleCompanyUserByIDSlice'

export function EditCompanyUserTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'userName',
      headerName: 'UserName',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      hideable: true,
      minWidth: 180,
      flex: 1,
      align: 'left',
    },
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      hideable: true,
      minWidth: 120,
      flex: 1,
      align: 'left',
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      hideable: true,
      minWidth: 120,
      flex: 1,
      align: 'left',
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone No.',
      sortable: true,
      hideable: true,
      minWidth: 130,
      align: 'center',
    },
    {
      field: 'role',
      headerName: 'Role',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'center',
    },
    {
      field: 'active',
      headerName: 'Active',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'center',
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        return (
          <Switch
            checked={params.row.userStatus}
            onClick={() => {
              dispatch(updateDeactivateUser(params.row.id))
            }}
          />
        )
      },
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'left',
      minWidth: 80,
      headerName: 'Action',
      sortable: false,
      hideable: false,
      renderCell: (params) => <CustomDetailPanelToggle id={params.id} value={params.value} />,
      renderHeader: (_params: GridColumnHeaderParams) => <span>{'Action'}</span>,
    },
  ]
  return columns
}
