import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Results } from 'reduxStore/alert/Active/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  alerts: Results[]
  successMsg: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  alerts: [] as Results[],
  successMsg: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchAlerts = createAsyncThunk<Results[], string>(
  'alert/fetchAlerts',
  (adminToken, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${adminToken}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + '/api/alert', { headers })
      .then((response) => response.data.results)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : 'Something went wrong',
        ),
      )
  },
)

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    updateAlertClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    updateAlertClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.alerts = [] as Results[]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAlerts.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchAlerts.fulfilled, (state, action: PayloadAction<Results[]>) => {
      state.loading = false
      state.alerts = action.payload
      state.successMsg = 'Alert list has been fetched successfully'
      state.error = ''
    })
    builder.addCase(fetchAlerts.rejected, (state, action) => {
      state.loading = false
      state.alerts = [] as Results[]
      state.successMsg = ''
      state.error = String(action.payload!)
    })
  },
})

export const { updateAlertClearMsg, updateAlertClearRes } = alertSlice.actions
export default alertSlice.reducer
