import React from 'react'
import { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../../reduxStore/hooks'
import { Card, CardContent, Box, CircularProgress, Grid, CardHeader } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
import { BreachesListByIDBody } from 'reduxStore/breaches/breachesDetailsByID/Model'
import { fetchBreachesDetailsByID } from 'reduxStore/breaches/breachesDetailsByID/breachSlice'
import BreachesDetailsTable from './BreachesDetailsTable'
import BreachDetailsHeader from './BreachDetailsHeader'
import { useParams } from 'react-router-dom'
import PageTitle from 'components/PageTitle'
import { checkReturnValue } from 'components/Utility'

export const BreachesDetails = () => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const breachesDetailsByID = useAppSelector((state) => state.breachesDetailsByID)
  // Get the breachId param from the URL.
  let { id } = useParams()

  let breachDetailsByID = {
    id: id,
    token: Token!,
  } as BreachesListByIDBody

  function reloadBreaches() {
    dispatch(fetchBreachesDetailsByID(breachDetailsByID))
  }

  useEffect(() => {
    dispatch(fetchBreachesDetailsByID(breachDetailsByID))
    dispatch(fetchAlerts(Token!))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  return (
    <Box p={2} sx={{ flexGrow: 1 }}>
      <PageTitle
        title={
          checkReturnValue(breachesDetailsByID?.breachesDetailsByID?.results) !== 'Not Set'
            ? checkReturnValue(breachesDetailsByID?.breachesDetailsByID?.results[0]?.vendor_name!)
            : 'Breaches Details'
        }
      />
      <Card>
        <CardHeader
          avatar={<InfoIcon sx={{ fontWeight: 'bold' }} />}
          title={<BreachDetailsHeader reloadBreach={reloadBreaches} />}
        />

        <CardContent>
          {breachesDetailsByID.loading === true ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" size={20} thickness={4} />
            </Grid>
          ) : (
            <Box style={{ height: '78vh' }}>
              <BreachesDetailsTable />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
