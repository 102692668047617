import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'

interface TabData {
  label: string
}

interface TabComponentProps {
  tabs: Record<string, TabData> | any
  order: string[] | any
  value: string | any
  handleChange: (event: React.SyntheticEvent, newValue: string) => void
}

const TabComponent: React.FC<TabComponentProps> = ({ tabs, order, value, handleChange }) => {
  return (
    <>
      {order !== undefined && order !== undefined && value !== undefined ? (
        <Tabs
          value={value}
          onChange={handleChange}
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: '#01cba3',
              top: '0px',
            },
          }}
          sx={{
            '& button:hover': { background: '' },
            '& button.Mui-selected': {
              background: '#fffff',
              color: '#01cba3',
            },
          }}
        >
          {order !== undefined && tabs !== undefined
            ? order.map((tabKey: any) => (
                <Tab key={tabKey} label={tabs[tabKey]?.label} value={tabKey} />
              ))
            : null}
        </Tabs>
      ) : null}
    </>
  )
}

export default TabComponent
