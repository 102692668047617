import * as React from 'react'
import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { useNavigate, useSearchParams } from 'react-router-dom'
import ConnectData from 'pages/Subscription/Active/ConnectData/ConnectData'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import CloudQueueIcon from '@mui/icons-material/CloudQueue'
import { fetchSubscription } from 'reduxStore/subcription/Active/subcriptionSlice'
import RefreshIcon from '@mui/icons-material/Refresh'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import { fetchStatus } from 'reduxStore/subcription/Status/StatusSlice'
import { fetchDepartment } from 'reduxStore/subcription/Department/DepartmentSlice'
import { fetchPaymentVendorList } from 'reduxStore/subcription/PaymentVendorList/paymentVendorListSlice'
import { fetchCompanyUserList } from 'reduxStore/customer/companyUser/companyUserList/companyUserListSlice'
import { Tags } from './Model'
import { TagSourceReq } from 'reduxStore/subcription/TagList/Model'
import { fetchTaglist } from 'reduxStore/subcription/TagList/tagSlice'
import { fetchTagSearchlist } from 'reduxStore/subcription/searchTagList/searchTagSlice'
import { SearchTagSourceReq } from 'reduxStore/subcription/searchTagList/Model'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import {
  DataGridPremium,
  GridRowParams,
  DataGridPremiumProps,
  GridFilterModel,
  GridFilterItem,
  GridRowSelectionModel,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid-premium'
import EditSubscription from '../../../components/Subscripiton/EditSubscription'
import { AllActiveSubscriptionList } from '../../../components/Subscripiton/AllActiveSubscriptionList'
import { SubscriptionListByTag } from '../../../components/Subscripiton/SubscriptionListByTag'
import { SubscriptionTableColumn } from '../../../components/Subscripiton/SubscriptionTableColumn'
import { updateSubscriptionList } from 'reduxStore/selectSubscriptionForSetDept/selectSubscriptionForSetDeptSlice'
import { editSubscriptionClearRes } from 'reduxStore/subcription/editSubscription/editSubscriptionSlice'
import SubscriptionAlert from 'components/Subscripiton/SubscriptionAlert'
import { optimizationDetailsByAccIdClearRes } from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationSlice'
import { ApplogieDGToolbar } from 'components/Subscripiton/DataGridTableHeader'

export default function ActiveSubscription() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  let navigate = useNavigate()

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const subsValue = useAppSelector((state) => state.subscription)
  const paymentVendorValue = useAppSelector((state) => state.paymentVendorList)
  const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)
  const tagListValue = useAppSelector((state) => state.tagList)
  const searchTagListValue = useAppSelector((state) => state.searchTagList)
  const individualSubscriptionAlertRes = useAppSelector(
    (state) => state.optimizationsDetailsbyAccountID,
  )

  // Individual subscriptions Alert
  const [showAlertDialog, setShowAlertDialog] = React.useState(false)
  const updateAlertDialogValFromChild = (passedVal: boolean) => {
    setShowAlertDialog(passedVal)
  }
  useEffect(() => {
    setShowAlertDialog(
      individualSubscriptionAlertRes.optimizations?.results !== undefined ? true : false,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [individualSubscriptionAlertRes])

  // Initiate the following functions only when the page renders
  useEffect(() => {
    dispatch(fetchSubscription(Token!))
    dispatch(fetchStatus(Token!))
    dispatch(fetchDepartment(Token!))

    let reqBody = {
      resultCount: '5',
      token: Token!,
    }
    dispatch(fetchPaymentVendorList(reqBody))
    dispatch(fetchCompanyUserList(Token!))
    dispatch(fetchCompanyUserList(Token!))
    let tagBody = {
      token: Token!,
    } as TagSourceReq
    dispatch(fetchTaglist(tagBody))
    dispatch(optimizationDetailsByAccIdClearRes())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  const [searchKey, setSearchKey] = React.useState([] as any[])

  const [showDialog, setShowDialog] = React.useState(false)
  const updateDialogState = () => {
    setShowDialog(true)
  }
  const updateSuccessMsgChild = (passedVal: boolean) => {
    setShowDialog(passedVal)
  }

  const [selectedTag, setSelectedTag] = React.useState<Tags | null>(null)
  const filterSearch = (owner: any, _e: any) => {
    setSelectedTag(owner)
    if (owner === null) {
      searchParams.delete('searchbytag')
      setSearchParams(searchParams)
    }
  }
  // Initiate Subscription List by Tag API
  React.useEffect(() => {
    if (selectedTag !== null) {
      searchParams.set('searchbytag', selectedTag?.name)
      setSearchParams(searchParams)
      let body = {
        name: selectedTag.name,
        token: Token!,
      } as SearchTagSourceReq
      dispatch(fetchTagSearchlist(body))
    } else {
      dispatch(fetchSubscription(Token!))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag])

  let [searchParams, setSearchParams] = useSearchParams()
  const [subscriptionList, setSubscriptionList] = React.useState([] as any[])
  useEffect(() => {
    if (searchKey.length > 0) {
      if (searchParams.get('searchbytag')! !== null) {
        if (searchParams.get('searchbytag')! !== '') {
          let obj = searchKey.find((o) => o.name === searchParams.get('searchbytag')!)
          setSelectedTag(obj)
        } else {
          setSelectedTag(null)
        }
      } else setSelectedTag(null)
    }
    // initiate actions in data grid for the following query params value
    if (
      searchParams.get('searchbyname') !== null ||
      searchParams.get('searchbyowner') !== null ||
      searchParams.get('searchbydepartment') !== null ||
      searchParams.get('quick-filter') !== null
    ) {
      let filterItem = [] as GridFilterItem[]
      let quickFilterItem = [] as string[]
      // get name value from query param & set it to filter
      if (searchParams.get('searchbyname') !== null) {
        if (searchParams.get('searchbyname') !== '') {
          const nameObj = {
            field: 'name',
            operator: 'contains',
            value: searchParams.get('searchbyname'),
          } as any
          filterItem = [...filterItem, nameObj]
          setFilterModel({ items: filterItem, quickFilterValues: quickFilterItem })
        }
      }
      // get owner value from query param & set it to filter
      if (searchParams.get('searchbyowner') !== null) {
        if (searchParams.get('searchbyowner') !== '') {
          const departmentObj = {
            field: 'userEmail',
            operator: 'contains',
            value: searchParams.get('searchbyowner'),
          } as any
          filterItem = [...filterItem, departmentObj]
          setFilterModel({ items: filterItem, quickFilterValues: quickFilterItem })
        }
      }
      // get name department from query param & set it to filter
      if (searchParams.get('searchbydepartment') !== null) {
        if (searchParams.get('searchbydepartment') !== '') {
          const departmentObj = {
            field: 'department',
            operator: 'contains',
            value: searchParams.get('searchbydepartment'),
          } as any
          filterItem = [...filterItem, departmentObj]
          setFilterModel({ items: filterItem, quickFilterValues: quickFilterItem })
        }
      }
      // get quick filter value from query param & set it to quickFilter
      if (searchParams.get('quick-filter') !== null) {
        if (searchParams.get('quick-filter') !== '') {
          const quickFilterValue = [searchParams.get('quick-filter')] as any[]
          setFilterModel({ items: filterItem, quickFilterValues: quickFilterValue })
        }
      } else {
        setFilterModel({ items: filterItem })
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams, subscriptionList, searchKey])

  const handleUpdateSetSearchName = (name: string) => {
    if (name !== undefined) {
      if (name !== '') {
        searchParams.set('searchbyname', name)
        setSearchParams(searchParams)
      }
    }
  }
  const handleUpdateSearchOwner = (owner: string) => {
    if (owner !== undefined) {
      if (owner !== '') {
        searchParams.set('searchbyowner', owner)
        setSearchParams(searchParams)
      }
    }
  }
  const handleUpdateSearchDept = (department: string) => {
    if (department !== undefined) {
      if (department !== '') {
        searchParams.set('searchbydepartment', department)
        setSearchParams(searchParams)
      }
    }
  }

  // Modify dataset for subscription table
  let allSubscriptionList = AllActiveSubscriptionList()
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    allSubscriptionList.length > 0
      ? setSubscriptionList(allSubscriptionList)
      : setSubscriptionList([] as any[])
  }, [allSubscriptionList])

  useEffect(() => {
    if (tagListValue !== undefined) {
      let tagname = ''
      let searchKey: any[] = []
      if (tagListValue.tagSource !== undefined) {
        if (tagListValue.tagSource.tags !== undefined) {
          for (var k = 0; k < tagListValue.tagSource.tags.length; k++) {
            tagname = tagListValue.tagSource.tags[k]
            searchKey.push({ id: k + 1, name: tagname })
          }
          setSearchKey(searchKey) //as any
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagListValue])

  // reset table filter value after successfully edit subscription
  useEffect(() => {
    if (editSubscriptionValue?.succssMessage !== '') {
      if (filterModel?.quickFilterValues !== undefined) {
        if (filterModel?.quickFilterValues!.length > 0) {
          setFilterModel({
            items: [],
            quickFilterValues: [],
          })
          searchParams.delete('quick-filter')
          setSearchParams(searchParams)
          dispatch(editSubscriptionClearRes())
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubscriptionValue])

  // Set subscription table data from Tag list search
  let subscripitonListByTag = SubscriptionListByTag()
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    subscripitonListByTag.length > 0
      ? setSubscriptionList(subscripitonListByTag)
      : setSubscriptionList([] as any[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscripitonListByTag])

  function DetailPanelContent({ row }: { row: any }) {
    return <EditSubscription item={row} />
  }

  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, [])

  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
    quickFilterValues: [],
  })

  useEffect(() => {
    if (filterModel.items.length > 0) {
      // Set Name, Owner, Department & QuickFilter value in query param when user use column filter & quick filter actions
      if (filterModel.items.find((e) => e.field === 'name')) {
        if (
          filterModel.items.find((e) => e.field === 'name')?.value !== undefined ||
          filterModel.items.find((e) => e.field === 'name')?.value !== ''
        ) {
          handleUpdateSetSearchName(filterModel.items.find((e) => e.field === 'name')?.value)
        } else {
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      }
      if (filterModel.items.find((e) => e.field === 'userEmail')) {
        if (
          filterModel.items.find((e) => e.field === 'userEmail')?.value !== undefined ||
          filterModel.items.find((e) => e.field === 'userEmail')?.value !== ''
        ) {
          handleUpdateSearchOwner(filterModel.items.find((e) => e.field === 'userEmail')?.value!)
        } else {
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      }
      if (filterModel.items.find((e) => e.field === 'department')) {
        if (
          filterModel.items.find((e) => e.field === 'department')?.value !== undefined ||
          filterModel.items.find((e) => e.field === 'department')?.value !== ''
        ) {
          handleUpdateSearchDept(filterModel.items.find((e) => e.field === 'department')?.value!)
        } else {
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      }

      if (
        searchParams.get('searchbyname')! !== null &&
        filterModel.items.find((e) => e.field === 'name') === undefined
      ) {
        searchParams.delete('searchbyname')
        setSearchParams(searchParams)
      } else if (
        searchParams.get('searchbyowner')! !== null &&
        (filterModel.items.find((e) => e.field === 'userEmail') === undefined ||
          filterModel.items.find((e) => e.field === 'userEmail')?.value === '')
      ) {
        searchParams.delete('searchbyowner')
        setSearchParams(searchParams)
      } else if (
        searchParams.get('searchbydepartment')! !== null &&
        (filterModel.items.find((e) => e.field === 'department') === undefined ||
          filterModel.items.find((e) => e.field === 'department')?.value === '')
      ) {
        searchParams.delete('searchbydepartment')
        setSearchParams(searchParams)
      }
      if (filterModel?.quickFilterValues !== undefined) {
        if (filterModel?.quickFilterValues!.length > 0) {
          searchParams.set('quick-filter', filterModel?.quickFilterValues![0])
          setSearchParams(searchParams)
        } else {
          searchParams.delete('quick-filter')
          setSearchParams(searchParams)
        }
      } else {
        searchParams.delete('quick-filter')
        setSearchParams(searchParams)
      }
    } else {
      searchParams.delete('searchbyname')
      searchParams.delete('searchbyowner')
      searchParams.delete('searchbydepartment')
      setSearchParams(searchParams)
      // Set QuickFilter value in query param when user does not use column filter
      if (filterModel?.quickFilterValues !== undefined) {
        if (filterModel?.quickFilterValues.length > 0) {
          searchParams.set('quick-filter', filterModel?.quickFilterValues[0])
          setSearchParams(searchParams)
        } else {
          searchParams.delete('quick-filter')
          setSearchParams(searchParams)
          navigate('.', { replace: true, state: { key: Date.now() } })
        }
      } else {
        searchParams.delete('quick-filter')
        setSearchParams(searchParams)
        navigate('.', { replace: true, state: { key: Date.now() } })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterModel])

  // table checkbox selection
  const [selectionModel, setSelectionModel] = React.useState<GridRowSelectionModel>([])
  // find selected subscription details on table selection & save that data in store for add department into that selected items
  useEffect(() => {
    if (selectionModel !== undefined) {
      let selectedSubscriptions = [] as any[]
      selectionModel.forEach((item: any) => {
        if (!selectedSubscriptions.includes(item)) {
          selectedSubscriptions.push(subscriptionList.find((o) => o.id === item))
        }
      })
      dispatch(updateSubscriptionList(selectedSubscriptions))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionModel, subscriptionList])

  // clear subscription table selected value when subscription table value gets changed
  useEffect(() => {
    if (subsValue?.subcriptions?.results !== undefined) {
      setSelectionModel([])
    }
  }, [subsValue])

  // Table Column visibility
  const [columnVisibilityModel, setColumnVisibilityModel] =
    React.useState<GridColumnVisibilityModel>({
      alert: true,
      name: true,
      account: false,
      renewalDate: true,
      userEmail: true,
      department: true,
      yearlyBudget: true,
      quarterlyBudget: true,
      monthlyBudget: true,
      status: true,
    })

  return (
    <Box>
      <Card>
        <CardHeader
          avatar={<CloudQueueIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid item>
                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                          Subscriptions
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Chip
                          label={subscriptionList.length ? String(subscriptionList.length) : '0'}
                        />
                      </Grid>
                      <Grid item>
                        <IconButton onClick={() => dispatch(fetchSubscription(Token!))}>
                          <RefreshIcon />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                        <Autocomplete
                          id="dept-states"
                          options={searchKey}
                          value={selectedTag}
                          onChange={(event: any, newValue: any) => {
                            filterSearch(newValue, event)
                          }}
                          getOptionLabel={(option: any) => option.name}
                          isOptionEqualToValue={(option: any, value) => option.id === value.id}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label=""
                              placeholder="Select Tag Name"
                              variant="standard"
                              InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LocalOfferIcon />
                                  </InputAdornment>
                                ),
                              }}
                              sx={{ minWidth: fullScreen ? '128px' : '200px' }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                    <Box id="filter-panel" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<InsertLinkIcon />}
                  onClick={() => updateDialogState()}
                  fullWidth
                >
                  Connect Data
                </Button>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {subsValue.loading || searchTagListValue.loading === true ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" size={20} thickness={4} />
            </Grid>
          ) : (
            <Box sx={{ height: 'calc(100vh - 265px)' }}>
              <DataGridPremium
                columns={SubscriptionTableColumn()}
                rows={subscriptionList}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={
                  selectionModel.length <= 0 ? getDetailPanelContent : undefined
                }
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'name', sort: 'asc' }],
                  },
                }}
                filterModel={filterModel}
                onFilterModelChange={(model) => setFilterModel(model)}
                slots={{
                  toolbar: ApplogieDGToolbar,
                }}
                slotProps={{
                  toolbar: {
                    showQuickFilter: true,
                    quickFilterProps: { debounceMs: 500 },
                  },
                }}
                hideFooter
                checkboxSelection
                onRowSelectionModelChange={(newSelectionModel) => {
                  setSelectionModel(newSelectionModel)
                }}
                rowSelectionModel={selectionModel}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                disableRowGrouping
                disableAggregation
                disableRowSelectionOnClick
                loading={individualSubscriptionAlertRes.loading || subsValue.loading}
                localeText={{ noRowsLabel: 'No Subscriptions are available' }}
              />
            </Box>
          )}
        </CardContent>
      </Card>
      {showDialog ? (
        <ConnectData
          dialogEnable={showDialog}
          updatedSuccessMsgState={updateSuccessMsgChild}
          paymentVendorValue={paymentVendorValue}
        />
      ) : null}

      {showAlertDialog ? (
        <SubscriptionAlert
          alertDialogEnable={showAlertDialog}
          alertDialogUpdateState={updateAlertDialogValFromChild}
        />
      ) : null}
    </Box>
  )
}
