import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import { ForgotPasswordResponse } from './Model'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: ForgotPasswordResponse
  successMsg: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as ForgotPasswordResponse,
  successMsg: '',
  error: '',
}
const headers = {
  Accept: 'application/json',
  'Content-type': 'application/json',
}

export const forgotPassword = createAsyncThunk<ForgotPasswordResponse, string>(
  'login/forgotPassword',
  async (param: string) => {
    return await setupInterceptorsTo(axios)
      .get(Url.backEndURL + `/api/user/password/reset/${param}/`, { headers })
      .then((response) => response.data)
  },
)

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    updateForgotPasswordClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    updateForgotPasswordClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.response = {} as ForgotPasswordResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      forgotPassword.fulfilled,
      (state, action: PayloadAction<ForgotPasswordResponse>) => {
        state.loading = false
        state.response = action.payload
        state.successMsg =
          action?.payload?.message !== undefined
            ? action?.payload?.message !== ''
              ? action?.payload?.message
              : 'Password Reset Request Sent!'
            : 'Password Reset Request Sent!'
        state.error = ''
      },
    )
    builder.addCase(forgotPassword.rejected, (state, action) => {
      state.loading = false
      state.response = {} as ForgotPasswordResponse
      state.successMsg = ''
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { updateForgotPasswordClearMsg, updateForgotPasswordClearRes } = forgotPasswordSlice.actions
export default forgotPasswordSlice.reducer
