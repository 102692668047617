import { Chip, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useAppSelector } from 'reduxStore/hooks'
interface Props {
  reloadBreach: () => void
}
export default function BreachesHeader({ reloadBreach }: Props) {
  const breachVal = useAppSelector((state) => state.breaches)
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Breaches
        </Typography>
      </Grid>
      <Grid item>
        <Chip label={'count' in breachVal.spend ? String(breachVal.spend.results.length) : null} />
      </Grid>
      <Grid item>
        <IconButton onClick={reloadBreach}>
          <RefreshIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
