import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  UpdatePasswordBody,
  UpdatePasswordReqBody,
} from 'reduxStore/updatePassword/UpdatePasswordModel'
import {
  updatePassword,
  updatePasswordClearRes,
} from 'reduxStore/updatePassword/updatePasswordSlice'

import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

type Props = {
  dialogEnable?: boolean
  updatedDialogState: (value: boolean) => void
}

export default function UpdatePassword({
  dialogEnable,
  updatedDialogState,
}: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const updatePassVal = useAppSelector((state) => state.updatePassword)

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  const [currentPassMsg, setCurrentPassMsg] = useState('')
  const [oldPassMsg, setOldPassMsg] = useState('')
  const [apiErrMsq, setApiErrMsq] = useState('')

  const [dialogState, setDialogState] = useState(false)
  const handleClose = () => {
    setDialogState(false)
    updatedDialogState(false)
    dispatch(updatePasswordClearRes())
  }
  let updatePasswordBody = {
    old_password: currentPassword,
    new_password: newPassword,
  } as UpdatePasswordBody
  let mainReqBody = {
    payload: updatePasswordBody,
    token: Token!,
  } as UpdatePasswordReqBody

  const updateUserPassword = () => {
    if (currentPassword === '' && newPassword === '') {
      setCurrentPassMsg('Current Password can not be empty')
      setOldPassMsg('Old Password can not be empty')
    } else if (currentPassword === '' && newPassword !== '') {
      setCurrentPassMsg('Current Password can not be empty')
      setOldPassMsg('')
    } else if (currentPassword !== '' && newPassword === '') {
      setCurrentPassMsg('')
      setOldPassMsg('Old Password can not be empty')
    } else {
      setCurrentPassMsg('')
      setOldPassMsg('')
      setApiErrMsq('')
      navigator.onLine
        ? dispatch(updatePassword(mainReqBody))
        : setApiErrMsq('You are currently offline')
    }
  }
  useEffect(() => {
    setDialogState(dialogEnable!)
    dispatch(updatePasswordClearRes())
    setCurrentPassword('')
    setNewPassword('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogEnable])

  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const handleClickShowCurrentPassword = () => setShowCurrentPassword((show) => !show);
  const handleMouseDownCurrentPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleMouseDownNewPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <Dialog onClose={handleClose} open={dialogState} fullWidth maxWidth="xs">
      <DialogTitle>Update Password</DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
        >
          <Grid item>
            <TextField
              id="currentPassword"
              label="Current Password"
              variant="standard"
              fullWidth
              value={currentPassword}
              onChange={(e) => {
                setCurrentPassword(e.target.value)
              }}
              type={showCurrentPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowCurrentPassword}
                      onMouseDown={handleMouseDownCurrentPassword}
                      edge="end"
                    >
                      {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            {currentPassMsg !== '' ? (
              <Typography sx={{ color: '#c62828' }}>{currentPassMsg}</Typography>
            ) : null}
          </Grid>
          <Grid item>
            <TextField
              id="newPassword"
              label="New Password"
              variant="standard"
              fullWidth
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value)
              }}
              type={showNewPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownNewPassword}
                      edge="end"
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
              }}
            />
            {oldPassMsg !== '' ? (
              <Typography sx={{ color: '#c62828' }}>{oldPassMsg}</Typography>
            ) : null}
          </Grid>
          {apiErrMsq !== '' ? (
            <Grid item>
              <Typography sx={{ color: '#c62828' }}>{apiErrMsq}</Typography>
            </Grid>
          ) : null}

        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="contained"
              onClick={() => updateUserPassword()}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>Update Password</Grid>
                {updatePassVal.loading ? (<Grid item>
                  <CircularProgress color="inherit" size={20} thickness={4} /></Grid>
                ) : null}
              </Grid>
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => handleClose()} style={{ backgroundColor: '#DCDCDC' }}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
