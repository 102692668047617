import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Result, VendorDeptDetailsReq } from './vendorDeptDetailsModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  vendorSpends: Result[]
  successMsg: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  vendorSpends: [] as Result[],
  successMsg: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchVendorDeptDetails = createAsyncThunk<Result[], VendorDeptDetailsReq>(
  'vendorDeptDetails/fetchVendorDeptDetails',
  (reqParam: VendorDeptDetailsReq) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(
        url.backEndURL +
          `/api/customers/usage/department/account/?department=${reqParam.deptId}&limit=${reqParam.length}`,
        { headers },
      )
      .then((response) => response.data.results)
  },
)

const vendorDeptDetailsSlice = createSlice({
  name: 'vendorDeptDetails',
  initialState,
  reducers: {
    updateVendorDeptDetailsClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    updateVendorDeptDetailsClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.vendorSpends= [] as Result[]
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVendorDeptDetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchVendorDeptDetails.fulfilled,
      (state, action: PayloadAction<Result[]>) => {
        state.loading = false
        state.vendorSpends = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchVendorDeptDetails.rejected, (state, action) => {
      state.loading = false
      state.vendorSpends = [] as Result[]
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { updateVendorDeptDetailsClearMsg, updateVendorDeptDetailsClearRes } = vendorDeptDetailsSlice.actions
export default vendorDeptDetailsSlice.reducer
