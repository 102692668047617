import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import {
  addCompanyUser,
  addUserClearMsg,
} from 'reduxStore/customer/companyUser/addCompanyUser/addCompanyUserSlice'
import { AddUserBody, MainBody } from 'reduxStore/customer/companyUser/addCompanyUser/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import CancelIcon from '@mui/icons-material/Cancel'
type Props = {
  dialogEnable?: boolean | any
  updatedDialogState?: boolean | any
}

export default function AddCustomer({ dialogEnable, updatedDialogState }: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  const addedCompanyUserApiResponse = useAppSelector((state) => state.addCompanyUser)
  const [addUserErrorMsg, setAddUserErrorMsg] = React.useState('')

  const [userName, setUserName] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [firstName, setFirstname] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [phone, setPhone] = React.useState('')

  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    updatedDialogState(false)
    dispatch(addUserClearMsg())
    setUserName('')
    setPassword('')
    setFirstname('')
    setLastName('')
    setPhone('')
  }
  React.useEffect(() => {
    if (addedCompanyUserApiResponse?.addCompanyUserRes?.id !== undefined) {
      setDialogState(false)
      updatedDialogState(false)
    }
    addedCompanyUserApiResponse?.error.message !== ''
      ? setAddUserErrorMsg(addedCompanyUserApiResponse?.error.message)
      : setAddUserErrorMsg('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedCompanyUserApiResponse])

  const addUser = () => {
    let body = {
      username: userName,
      password: password,
      email: email,
      first_name: firstName,
      last_name: lastName,
      phone_number: phone,
    } as MainBody
    let mainBody = {
      body: body,
      token: Token!,
    } as AddUserBody
    if (
      body.username !== '' &&
      body.first_name !== '' &&
      body.last_name !== '' &&
      body.email !== '' &&
      body.phone_number !== '' &&
      body.password !== ''
    ) {
      dispatch(addCompanyUser(mainBody))
    }
  }

  React.useEffect(() => {
    setDialogState(dialogEnable)
  }, [dialogEnable])
  return (
    <Box>
      <Dialog onClose={handleClose} open={dialogState}>
        <DialogTitle id="customer-add-dialog-title">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction={'row'}>
              <Typography variant="h6">
                <b>Add a User:</b>
              </Typography>
            </Stack>
            <Stack direction={'row'}>
              <IconButton aria-label="delete" onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="stretch"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                id="userName"
                label="Username*"
                variant="standard"
                fullWidth
                sx={{ mt: '10' }}
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="userPassword"
                type="password"
                label="Password*"
                variant="standard"
                fullWidth
                sx={{ mt: '10' }}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="userEmail"
                label="Email"
                variant="standard"
                fullWidth
                sx={{ mt: '10' }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="userFirstName"
                label="First Name"
                variant="standard"
                fullWidth
                sx={{ mt: '10' }}
                value={firstName}
                onChange={(e) => {
                  setFirstname(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="userLastName"
                label="Last Name"
                variant="standard"
                fullWidth
                sx={{ mt: '10' }}
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="userPhoneNumber"
                label="Phone Number*"
                variant="standard"
                fullWidth
                sx={{ mt: '10' }}
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value)
                }}
              />
            </Grid>
          </Grid>
          <Box py={2}>
            {addUserErrorMsg !== '' ? (
              <Alert
                icon={false}
                severity="error"
                sx={{ mb: 2 }}
                onClose={() => {
                  dispatch(addUserClearMsg())
                }}
              >
                {addUserErrorMsg}
              </Alert>
            ) : null}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => addUser()}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography>Add User</Typography>
              </Grid>
              {addedCompanyUserApiResponse.loading ? (
                <Grid item>
                  <CircularProgress color="inherit" size={18} />
                </Grid>
              ) : null}
            </Grid>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
