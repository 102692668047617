import React, { useEffect, useState } from 'react'
import {
  MenuItem,
  Select,
  Typography,
  Grid,
  Paper,
  Alert,
  Box,
  FormControl,
  InputLabel,
} from '@mui/material'
import { GridColDef, DataGridPremium, GridRowModel } from '@mui/x-data-grid-premium'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import dayjs from 'dayjs'
import { Result } from 'reduxStore/account/license/licenseListByAccountID/Model'
import {
  GetUsageListByLicenseIdBody,
  GetUsageListByLicenseIdMainBody,
} from 'reduxStore/account/license/usage/getUsageListByLicenseID/Model'
import { useParams } from 'react-router-dom'
import { getUsageListByLicenseID } from 'reduxStore/account/license/usage/getUsageListByLicenseID/getUsageListByLicenseID'
import { UpdateUsageByIdBody } from 'reduxStore/account/license/usage/updateUsageByID/Model'
import { updateUsageById } from 'reduxStore/account/license/usage/updateUsageByID/updateUsageById'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { LisenceChartListReq } from 'reduxStore/account/license/licenseChart/Model'
import { fetchLicenseChart } from 'reduxStore/account/license/licenseChart/licenseChartSlice'

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Item',
    sortable: false,
    disableColumnMenu: true,
  },
  {
    field: 'Jan',
    headerName: 'January',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Feb',
    headerName: 'February',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Mar',
    headerName: 'March',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Apr',
    headerName: 'April',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'May',
    headerName: 'May',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Jun',
    headerName: 'June',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Jul',
    headerName: 'July',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Aug',
    headerName: 'August',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Sep',
    headerName: 'September',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Oct',
    headerName: 'October',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Nov',
    headerName: 'November',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
  {
    field: 'Dec',
    headerName: 'December',
    type: 'number',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    flex: 1,
  },
]

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel, id: string): any {
  if (newRow.Jan !== oldRow.Jan) {
    return { field: 'Jan', value: newRow.Jan, id: id }
  }
  if (newRow.Feb !== oldRow.Feb) {
    return { field: 'Feb', value: newRow.Feb, id: id }
  }
  if (newRow.Mar !== oldRow.Mar) {
    return { field: 'Mar', value: newRow.Mar, id: id }
  }
  if (newRow.Apr !== oldRow.Apr) {
    return { field: 'Apr', value: newRow.Apr, id: id }
  }
  if (newRow.May !== oldRow.May) {
    return { field: 'May', value: newRow.May, id: id }
  }
  if (newRow.Jun !== oldRow.Jun) {
    return { field: 'Jun', value: newRow.Jun, id: id }
  }
  if (newRow.Jul !== oldRow.Jul) {
    return { field: 'Jul', value: newRow.Jul, id: id }
  }
  if (newRow.Aug !== oldRow.Aug) {
    return { field: 'Aug', value: newRow.Aug, id: id }
  }
  if (newRow.Sep !== oldRow.Sep) {
    return { field: 'Sep', value: newRow.Sep, id: id }
  }
  if (newRow.Oct !== oldRow.Oct) {
    return { field: 'Oct', value: newRow.Oct, id: id }
  }
  if (newRow.Nov !== oldRow.Nov) {
    return { field: 'Nov', value: newRow.Nov, id: id }
  }
  if (newRow.Dec !== oldRow.Dec) {
    return { field: 'Dec', value: newRow.Dec, id: id }
  }
  return { field: '', value: '', id: id }
}
interface Props {
  item?: Result | any
}
export default function UpdateLicenseUsage({ item }: Props) {
  const Token = localStorage.getItem('token')
  const { id } = useParams()
  const dispatch = useAppDispatch()
  const getUsageListByLicenseId = useAppSelector((state) => state.getUsageListByLicenseIDRes)
  const updateUsageByIdRes = useAppSelector((state) => state.updateUsageByIdRes)

  let getIndividualUsageUpdate = localStorage.getItem('allowIndividualSpendUpdate')

  const [yearList, setYearList] = useState([] as number[])

  const customParseFormat = require('dayjs/plugin/customParseFormat')
  dayjs.extend(customParseFormat)

  useEffect(() => {
    if (item?.startDate !== 'Not Set' && item?.endDate !== 'Not Set') {
      let start = dayjs(dayjs(item.startDate, 'ddd, DD MMM YYYY').toDate()).format('YYYY')
      let end = dayjs(dayjs(item.endDate, 'ddd, DD MMM YYYY').toDate()).format('YYYY')

      let yearLists = [] as number[]
      for (let year = Number(start); year <= Number(end); year++) {
        yearLists.push(year)
      }
      // Sort array items from highest to lowest
      yearLists.sort(function (a, b) {
        return b - a
      })
      setYearList(yearLists)
    }
  }, [item])

  useEffect(() => {
    if (yearList.length > 0) {
      let currentYear = new Date().getFullYear()
      let mostRecentYear = Math.max(...yearList.filter((year) => year <= currentYear))
      selectedYearChange(String(mostRecentYear))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearList])

  function transformData(data: any) {
    const months = [
      'jan',
      'feb',
      'mar',
      'apr',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
    ]
    const result = [{ id: 'Count' }, { id: 'Usage' }] as any

    months.forEach((month: any) => {
      result[0][month.charAt(0).toUpperCase() + month.slice(1)] = parseFloat(data[`${month}_count`])
      result[1][month.charAt(0).toUpperCase() + month.slice(1)] = parseFloat(data[`${month}_usage`])
    })

    return result
  }

  const [tableData, setTableData] = useState([] as any[])
  const [manualErrMsg, setManualErrMsg] = useState('')

  const UpdatedValuesMutation = () => {
    return React.useCallback(
      (updatedValue: Partial<any>) =>
        new Promise<Partial<any>>(async () => {
          if (
            item?.startDate !== null ||
            item?.startDate !== '' ||
            item?.endDate !== null ||
            item?.endDate !== ''
          ) {
            setManualErrMsg('')
            if (updatedValue?.id === 'Usage') {
              if (tableData.length > 0) {
                if (tableData[0]![updatedValue.field] > 0) {
                  setManualErrMsg('')
                  // Check if Count is greater than 0 for the same month
                  if (tableData[0]![updatedValue.field] >= updatedValue.value) {
                    // eslint-disable-next-line array-callback-return
                    tableData.map((obj: any) => {
                      if (obj.id === 'Usage') {
                        obj[updatedValue.field] = updatedValue.value
                      }
                    })

                    let result: { [key: string]: any } = {} as any
                    let itemType = tableData[1].id.toLowerCase()
                    delete tableData[1].id
                    for (let month in tableData[1]) {
                      let key = `${month.slice(0, 3).toLowerCase()}_${itemType}`
                      result[key] = tableData[1][month].toFixed(2)
                    }
                    result['account_license_usage_id'] = Number(
                      getUsageListByLicenseId?.response?.id!,
                    )
                    result['account_license_id'] = Number(item.id)
                    result['year'] = Number(selectedYear)
                    result['account_id'] = String(id)

                    let updateUsageMainBody = {
                      mainBody: result,
                      token: Token,
                    } as UpdateUsageByIdBody

                    await dispatch(updateUsageById(updateUsageMainBody))
                  } else {
                    setManualErrMsg('You have exceed your total License count limit')
                  }
                } else {
                  setManualErrMsg('You have exceed your total License count limit')
                }
              }
            }
          } else {
            setManualErrMsg('Check your license Start Date or End Date')
          }
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [tableData],
    )
  }

  const updatedValues = UpdatedValuesMutation()
  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>(async (resolve) => {
        const mutation = computeMutation(newRow, oldRow, newRow.id)
        if (mutation) {
          // Save the arguments to resolve or reject the promise later
          updatedValues(mutation)
          resolve(newRow)
        } else {
          resolve(oldRow) // Nothing was changed
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedValues],
  )

  useEffect(() => {
    if (getUsageListByLicenseId?.response?.id !== undefined) {
      setTableData(transformData(getUsageListByLicenseId?.response))
    } else setTableData([] as any[])
  }, [getUsageListByLicenseId])

  const [selectedYear, setSelectedYear] = React.useState('')
  const selectedYearChange = (event: string) => {
    setSelectedYear(event)
    const mainBody = {
      account_license_id: item.id,
      account_id: id,
      year: Number(event),
    } as GetUsageListByLicenseIdMainBody
    let fullBody = {
      mainBody: mainBody,
      token: Token,
    } as GetUsageListByLicenseIdBody
    dispatch(getUsageListByLicenseID(fullBody))
  }

  useEffect(() => {
    if (updateUsageByIdRes?.successMessage !== '') {
      const mainBody = {
        account_license_id: item.id,
        account_id: id,
        year: Number(selectedYear),
      } as GetUsageListByLicenseIdMainBody
      let fullBody = {
        mainBody: mainBody,
        token: Token,
      } as GetUsageListByLicenseIdBody
      dispatch(getUsageListByLicenseID(fullBody))
      // initiate license usage chart API
      dispatch(
        fetchLicenseChart({
          id: String(id),
          token: Token!,
        } as LisenceChartListReq),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateUsageByIdRes])

  return (
    <Paper
      sx={{
        px: 2,
        bgcolor:
          item?.startDate !== 'Not Set' || item?.endDate !== 'Not Set' ? '#f5f5f5' : 'transparent',
      }}
      variant="outlined"
    >
      <Typography variant="h6">
        <b>Edit Usage</b>
      </Typography>
      {item?.startDate !== 'Not Set' || item?.endDate !== 'Not Set' ? (
        <Box sx={{ width: '100%' }}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Grid item xs={12} sm={12} md={2} lg={2} xl={2}>
              <FormControl fullWidth>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  License Year
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  label="Select a year"
                  value={selectedYear}
                  onChange={(e) => selectedYearChange(e?.target.value)}
                  fullWidth
                  variant="standard"
                  sx={{ minWidth: '128px' }}
                >
                  {yearList.length > 0
                    ? yearList.map(function (option: any) {
                        return (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        )
                      })
                    : null}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ width: '100%' }}>
              <DataGridPremium
                rows={tableData}
                columns={columns}
                hideFooter
                hideFooterPagination
                isCellEditable={(params) =>
                  params.id === 'Usage' && getIndividualUsageUpdate === 'true'
                }
                processRowUpdate={processRowUpdate}
                autoHeight
                density="compact"
                loading={getUsageListByLicenseId.loading || updateUsageByIdRes?.loading}
                sx={{ width: '100%' }}
                disableRowGrouping
                getRowHeight={() => 'auto'}
              />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box my={2}>
          <Alert severity="warning">
            To check this license usage details; at first you have to add <b>License Start Date</b>{' '}
            & <b>License End Date</b>.
          </Alert>
        </Box>
      )}

      <ApplogieSnackbar msg={manualErrMsg} type={'error'} alertClose={() => setManualErrMsg('')} />
    </Paper>
  )
}
