import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { apiReqParam, Results } from 'reduxStore/comments/commentVisibility/Model'
import url from 'APIConfig'

type InitialState = {
    loading: boolean
    commentsVisibility: Results
    error: string
}
const initialState: InitialState = {
    loading: false,
    commentsVisibility: {} as Results,
    error: '',
}
export const fetchCommentsVisibility = createAsyncThunk<Results, apiReqParam>('fetchCommentsVisibility/fetchCommentsVisibility', (reqParam: apiReqParam,{ rejectWithValue }) => {
    let headers = {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
        .get(url.backEndURL + `/api/account/comments/?visibility=${reqParam.count}`, { headers })
        .then((response) => response.data)
        .catch((error) => rejectWithValue(error.response.data[0].detail))
})

const commentVisibilitySlice = createSlice({
    name: 'commentvisibility',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchCommentsVisibility.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchCommentsVisibility.fulfilled, (state, action: PayloadAction<Results>) => {
            state.loading = false
            state.commentsVisibility = action.payload
            state.error = ''
        })
        builder.addCase(fetchCommentsVisibility.rejected, (state, action) => {
            state.loading = false
            state.commentsVisibility = {} as Results
            state.error = action.error.message || 'Something went wrong'
        })
    },
})

export default commentVisibilitySlice.reducer
