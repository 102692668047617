import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { MicrosoftUserModel, AccountDetailsModelReq } from './MicrosoftUserModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  accountDetails: MicrosoftUserModel
  error: string
}
const initialState: InitialState = {
  loading: false,
  accountDetails: {} as MicrosoftUserModel,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchMicrosoftAccountDetailsById = createAsyncThunk<MicrosoftUserModel, AccountDetailsModelReq>(
  'microsoftUserDetails/fetchMicrosoftAccountDetailsById',
  (reqParam: AccountDetailsModelReq) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/msaccountusersprodlic?account/${reqParam.accountId}`, { headers })
      .then((response) => response.data)
  },
)

const MicrosoftUserListSlice = createSlice({
  name: 'microsoftUserDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMicrosoftAccountDetailsById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
        fetchMicrosoftAccountDetailsById.fulfilled,
      (state, action: PayloadAction<MicrosoftUserModel>) => {
        state.loading = false
        state.accountDetails = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchMicrosoftAccountDetailsById.rejected, (state, action) => {
      state.loading = false
      state.accountDetails = {} as MicrosoftUserModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default MicrosoftUserListSlice.reducer
