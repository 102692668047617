import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../../APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { UpdateUserDetailsModel, updateUserDetailsBody } from './updateUserDetailsModel'

type InitialState = {
  loading: boolean
  response: UpdateUserDetailsModel
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as UpdateUserDetailsModel,
  succssMessage: '',
  error: '',
}

export const updateUserDetailsById = createAsyncThunk<
  UpdateUserDetailsModel,
  updateUserDetailsBody
>(
  'updateUserDetails/updateUserDetailsById',
  async (payload: updateUserDetailsBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(
        Url.backEndURL + `/api/organizationuser/${payload.id}/set_org_user_department`,
        payload.updateBody,
        { headers },
      )
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data[0]?.description !== undefined
              ? error?.response?.data[0]?.description
              : error.response.data.message !== undefined
              ? error.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const updateUserDetailsSlice = createSlice({
  name: 'updateUserDetails',
  initialState,
  reducers: {
    updateUserDetailsClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    updateUserDetailsClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.response = {} as UpdateUserDetailsModel
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUserDetailsById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      updateUserDetailsById.fulfilled,
      (state, action: PayloadAction<UpdateUserDetailsModel>) => {
        state.loading = false
        state.response = action.payload
        state.succssMessage =
          action?.payload?.status !== undefined
            ? action?.payload?.status !== ''
              ? action?.payload?.status
              : 'Organization User Department changed succesfully'
            : 'Organization User Department changed succesfully'
        state.error = ''
      },
    )
    builder.addCase(updateUserDetailsById.rejected, (state, action) => {
      state.loading = false
      state.response = {} as UpdateUserDetailsModel
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})

export const { updateUserDetailsClearMsg, updateUserDetailsClearRes } = updateUserDetailsSlice.actions
export default updateUserDetailsSlice.reducer
