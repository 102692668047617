import * as React from 'react'
import { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from 'reduxStore/hooks'
import { AlertColor, Box, CircularProgress, Grid } from '@mui/material'
import {
  DataGridPremium,
  GridCellSelectionModel,
  GridColDef,
  GridRowModel,
  GridRowModelUpdate,
  useGridApiRef,
} from '@mui/x-data-grid-premium'
import { useParams } from 'react-router-dom'
import { UpdateBudgetBody } from 'reduxStore/updateBudgetDetails/Model'
import { CompanyAccountDetailsModelReq } from 'reduxStore/account/companyAccountDetailsById/companyAccountDetailsModel'
import { fetchAccountCustomerBudgetById } from 'reduxStore/account/accountCustomerBudgetById/accountCustomerBudgetByIdSlice'
import { fetchAccountCustomerSpendById } from 'reduxStore/account/accountCustomerSpend/accountCustomerSpendByIdSlice'
import {
  updateBudgetClearRes,
  updateBudgetDetails,
} from 'reduxStore/updateBudgetDetails/updateBudgetDetailsSlice'
import { GridData, YearList, YearOfForecast, monthNames } from './Model'
import { UpdateSpendBody } from 'reduxStore/updateSpendDetails/Model'
import {
  updateSpendClearRes,
  updateSpendDetails,
} from 'reduxStore/updateSpendDetails/updateSpendDetailsSlice'
import { numberWithDecimalCommas } from 'utils/numberWithCommas'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'Item',
    sortable: false,
    disableColumnMenu: true,
    minWidth: 76,
    flex: 1,
  },
  {
    field: 'January',
    headerName: 'January',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    renderCell: (params: any) => {
      if (params.row.January === null || params.row.January === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(params.row.January)} `
    },
    flex: 1,
  },
  {
    field: 'February',
    headerName: 'February',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'March',
    headerName: 'March',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'April',
    headerName: 'April',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'May',
    headerName: 'May',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'June',
    headerName: 'June',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'July',
    headerName: 'July',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'August',
    headerName: 'August',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'September',
    headerName: 'September',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'October',
    headerName: 'October',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'November',
    headerName: 'November',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
  {
    field: 'December',
    headerName: 'December',
    type: 'number',
    minWidth: 130,
    headerAlign: 'left',
    align: 'left',
    editable: true,
    sortable: false,
    disableColumnMenu: true,
    valueFormatter: (value: number) => {
      if (value === null || value === undefined) {
        return `$ 0.00`
      }
      return `${numberWithDecimalCommas(value)} `
    },
    flex: 1,
  },
]

function computeMutation(newRow: GridRowModel, oldRow: GridRowModel, id: string): YearOfForecast {
  if (newRow.January !== oldRow.January) {
    return { field: 'January', value: newRow.January, id: id }
  }
  if (newRow.February !== oldRow.February) {
    return { field: 'February', value: newRow.February, id: id }
  }
  if (newRow.March !== oldRow.March) {
    return { field: 'March', value: newRow.March, id: id }
  }
  if (newRow.April !== oldRow.April) {
    return { field: 'April', value: newRow.April, id: id }
  }
  if (newRow.May !== oldRow.May) {
    return { field: 'May', value: newRow.May, id: id }
  }
  if (newRow.June !== oldRow.June) {
    return { field: 'June', value: newRow.June, id: id }
  }
  if (newRow.July !== oldRow.July) {
    return { field: 'July', value: newRow.July, id: id }
  }
  if (newRow.August !== oldRow.August) {
    return { field: 'August', value: newRow.August, id: id }
  }
  if (newRow.September !== oldRow.September) {
    return { field: 'September', value: newRow.September, id: id }
  }
  if (newRow.October !== oldRow.October) {
    return { field: 'October', value: newRow.October, id: id }
  }
  if (newRow.November !== oldRow.November) {
    return { field: 'November', value: newRow.November, id: id }
  }
  if (newRow.December !== oldRow.December) {
    return { field: 'December', value: newRow.December, id: id }
  }
  return { field: '', value: '', id: id }
}

type Props = {
  filteredItem: GridData[] | any[]
  selectedYear: YearList | any
}
export const SpendChartTable = ({ filteredItem, selectedYear }: Props) => {
  let checkUserCategory = JSON.parse(localStorage.getItem('demoUser')!)
  let allowIndividualUsageUpdate = localStorage.getItem('allowIndividualSpendUpdate')
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const [updateSpendDetailsMsg, setUpdateSpendDetailsMsg] = React.useState('')
  const [updateSpendDetailsMsgType, setUpdateSpendDetailsMsgType] =
    React.useState<AlertColor>('success')
  const [updateBudgetDetailsMsg, setUpdateBudgetDetailsMsg] = React.useState('')
  const [updateBudgetDetailsMsgType, setUpdateBudgetDetailsMsgType] =
    React.useState<AlertColor>('success')
  const [year, setYear] = React.useState('')

  // Get the userId param from the URL.
  const { id } = useParams()

  const companyAccountDetailsByIdBody = {
    accountId: String(id),
    token: Token,
  } as CompanyAccountDetailsModelReq

  const closeUpdateBudgetDetails = () => {
    dispatch(updateBudgetClearRes())
    setUpdateBudgetDetailsMsg('')
  }

  useEffect(() => {
    dispatch(fetchAccountCustomerBudgetById(companyAccountDetailsByIdBody))
    dispatch(fetchAccountCustomerSpendById(companyAccountDetailsByIdBody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const customerSpendById = useAppSelector((state) => state.customerSpendByAccountId)
  const customerBudgetById = useAppSelector((state) => state.customerBudgetByAccountId)
  const updateBudgetById = useAppSelector((state) => state.updateBudgetDetailsByAccountId)
  const updateSpendDetailsByAccountId = useAppSelector(
    (state) => state.updateSpendDetailsByAccountId,
  )

  React.useEffect(() => {
    if (updateSpendDetailsByAccountId?.successMessage !== '') {
      setUpdateSpendDetailsMsgType('success')
      setUpdateSpendDetailsMsg(updateSpendDetailsByAccountId?.successMessage)
    }
    if (updateSpendDetailsByAccountId?.errorMessage !== '') {
      setUpdateSpendDetailsMsgType('error')
      setUpdateSpendDetailsMsg(updateSpendDetailsByAccountId?.errorMessage)
    }
  }, [updateSpendDetailsByAccountId])

  const closeUpdateSpendDetails = () => {
    dispatch(updateSpendClearRes())
    setUpdateSpendDetailsMsg('')
  }

  React.useEffect(() => {
    if (updateBudgetById?.successMessage !== '') {
      setUpdateBudgetDetailsMsgType('success')
      setUpdateBudgetDetailsMsg(updateBudgetById?.successMessage)
    }
    if (updateBudgetById?.errorMessage !== '') {
      setUpdateBudgetDetailsMsgType('error')
      setUpdateBudgetDetailsMsg(updateBudgetById?.errorMessage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateBudgetById])

  const [finedArray, setFinedArray] = React.useState([] as any[])
  const selectedYearRef = React.useRef(selectedYear)
  useEffect(() => {
    selectedYearRef.current = year
  }, [year])

  const UpdatedValuesMutation = () => {
    return React.useCallback(
      (updatedValue: Partial<YearOfForecast>) =>
        new Promise<Partial<YearOfForecast>>(async () => {
          if (updatedValue?.id === 'Spend') {
            let updateBudgetItem = {
              account: Number(id),
              cost: Number(updatedValue.value),
              month: Number(monthNames.findIndex((items) => items === updatedValue.field)) + 1,
              year: Number(selectedYearRef.current),
              token: Token,
            } as UpdateSpendBody
            await dispatch(updateSpendDetails(updateBudgetItem))
            await dispatch(fetchAccountCustomerBudgetById(companyAccountDetailsByIdBody))
            await dispatch(fetchAccountCustomerSpendById(companyAccountDetailsByIdBody))
          } else {
            if (selectedYearRef.current && updatedValue.field !== '') {
              let updateBudgetItem = {
                account: Number(id),
                projected_cost: Number(updatedValue.value),
                month: Number(monthNames.findIndex((items) => items === updatedValue.field)) + 1,
                year: Number(selectedYearRef.current),
                token: Token,
              } as UpdateBudgetBody
              console.log('Single Update Payload', updatedValue, updateBudgetItem)
              await dispatch(updateBudgetDetails(updateBudgetItem))
              await dispatch(fetchAccountCustomerBudgetById(companyAccountDetailsByIdBody))
              await dispatch(fetchAccountCustomerSpendById(companyAccountDetailsByIdBody))
            }
          }
        }),
      [],
    )
  }

  const updatedValues = UpdatedValuesMutation()

  const processRowUpdate = React.useCallback(
    (newRow: GridRowModel, oldRow: GridRowModel) =>
      new Promise<GridRowModel>(async (resolve) => {
        const mutation = computeMutation(newRow, oldRow, newRow.id)
        if (mutation) {
          if (getUpdatedKeys(oldRow, newRow).length > 1) {
            multipleRowUpdate(getUpdatedKeys(oldRow, newRow))
          } else {
            updatedValues(mutation)
          }
          // Save the arguments to resolve or reject the promise later
          // updatedValues(mutation)
          resolve(newRow)
        } else {
          resolve(oldRow) // Nothing was changed
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [updatedValues],
  )

  function multipleRowUpdate(passedVal: any) {
    for (let i = 0; i < passedVal.length; i++) {
      if (i !== passedVal.length - 1) {
        if (passedVal[i]?.id === 'Spend') {
          let updateBudgetItem = {
            account: Number(id),
            cost: Number(passedVal[i].value),
            month: Number(monthNames.findIndex((items) => items === passedVal[i].field)) + 1,
            year: Number(selectedYearRef.current),
            token: Token,
          } as UpdateSpendBody
          dispatch(updateSpendDetails(updateBudgetItem))
        } else {
          if (selectedYearRef.current && passedVal[i].field !== 0) {
            console.log('value', passedVal)
            let updateBudgetItem = {
              account: Number(id),
              projected_cost: Number(passedVal[i].value),
              month: Number(monthNames.findIndex((items) => items === passedVal[i].field)) + 1,
              year: Number(selectedYearRef.current),
              token: Token,
            } as UpdateBudgetBody
            dispatch(updateBudgetDetails(updateBudgetItem))
          }
        }
      } else {
        if (passedVal[i]?.id === 'Spend') {
          let updateBudgetItem = {
            account: Number(id),
            cost: Number(passedVal[i].value),
            month: Number(monthNames.findIndex((items) => items === passedVal[i].field)) + 1,
            year: Number(selectedYearRef.current),
            token: Token,
          } as UpdateSpendBody
          dispatch(updateSpendDetails(updateBudgetItem))
          dispatch(fetchAccountCustomerBudgetById(companyAccountDetailsByIdBody))
          dispatch(fetchAccountCustomerSpendById(companyAccountDetailsByIdBody))
        } else {
          if (selectedYearRef.current && passedVal[i].field !== 0) {
            let updateBudgetItem = {
              account: Number(id),
              projected_cost: Number(passedVal[i].value),
              month: Number(monthNames.findIndex((items) => items === passedVal[i].field)) + 1,
              year: Number(selectedYearRef.current),
              token: Token,
            } as UpdateBudgetBody
            dispatch(updateBudgetDetails(updateBudgetItem))
            dispatch(fetchAccountCustomerBudgetById(companyAccountDetailsByIdBody))
            dispatch(fetchAccountCustomerSpendById(companyAccountDetailsByIdBody))
          }
        }
      }
    }
  }

  useEffect(() => {
    if (filteredItem !== undefined) {
      setFinedArray(filteredItem)
    } else setFinedArray(([] as GridData[]) || ([] as any[]))
    if (selectedYear !== undefined) {
      setYear(selectedYear.label)
    } else setYear('')
  }, [filteredItem, selectedYear])

  const apiRef = useGridApiRef()
  const [value, setValue] = React.useState('')
  const [cellSelectionModel, setCellSelectionModel] = React.useState<GridCellSelectionModel>({})

  const handleCellSelectionModelChange = React.useCallback((newModel: GridCellSelectionModel) => {
    setCellSelectionModel(newModel)
  }, [])

  const updateSelectedCells = React.useCallback(() => {
    const updates: GridRowModelUpdate[] = []

    Object.entries(cellSelectionModel).forEach(([id, fields]) => {
      const updatedRow = { ...apiRef.current.getRow(id) }

      Object.entries(fields).forEach(([field, isSelected]) => {
        if (isSelected) {
          updatedRow[field] = value
        }
      })

      updates.push(updatedRow)
    })
    apiRef.current.updateRows(updates)
  }, [apiRef, cellSelectionModel, value])

  function getUpdatedKeys(oldObj: any, newObj: any) {
    let result = []
    for (let key in oldObj) {
      if (oldObj[key] !== newObj[key]) {
        result.push({
          id: newObj.id,
          field: key,
          value: newObj[key],
        })
      }
    }
    return result
  }

  useEffect(() => {
    document.addEventListener('keydown', function (zEvent) {
      if (Token && (zEvent.ctrlKey || zEvent.metaKey) && zEvent.keyCode === 86) {
        updateSelectedCells()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Token])

  return (
    <Box sx={{ bgcolor: '#f2f2f2' }}>
      {customerSpendById.loading === true && customerBudgetById.loading === true ? (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <CircularProgress color="inherit" size={20} thickness={4} />
        </Grid>
      ) : (
        <DataGridPremium
          rows={finedArray}
          columns={columns}
          hideFooter
          hideFooterPagination
          disableRowSelectionOnClick
          isCellEditable={
            checkUserCategory && allowIndividualUsageUpdate === 'true'
              ? undefined
              : (params) => params.id === 'Forecast'
          }
          processRowUpdate={processRowUpdate}
          density="compact"
          rowHeight={50}
          loading={
            updateBudgetById.loading || customerSpendById.loading || customerBudgetById.loading
          }
          disableRowGrouping
          cellSelection
          ignoreValueFormatterDuringExport
          onClipboardCopy={(copiedString) => setValue(copiedString)}
          apiRef={apiRef}
          rowSelection={false}
          cellSelectionModel={cellSelectionModel}
          onCellSelectionModelChange={handleCellSelectionModelChange}
        />
      )}
      {updateBudgetDetailsMsg !== '' && (
        <ApplogieSnackbar
          msg={updateBudgetDetailsMsg}
          type={updateBudgetDetailsMsgType}
          alertClose={closeUpdateBudgetDetails}
        />
      )}

      {updateSpendDetailsMsg !== '' && (
        <ApplogieSnackbar
          msg={updateSpendDetailsMsg}
          type={updateSpendDetailsMsgType}
          alertClose={closeUpdateSpendDetails}
        />
      )}
    </Box>
  )
}
