import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material'
import * as React from 'react'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { useAppSelector } from 'reduxStore/hooks'

export default function VendorDetails() {
  const accountDetailsByid = useAppSelector((state) => state.accountDetails)
  return (
    <Card sx={{ minHeight: '250px' }}>
      <CardHeader
        avatar={<AssignmentIcon sx={{ fontWeight: 'bold' }} />}
        title={
          <Stack direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2}>
            <Stack direction={'row'}>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Vendor Details
              </Typography>
            </Stack>
          </Stack>
        }
      ></CardHeader>
      <CardContent sx={{ maxHeight: '170px', overflow: 'auto' }}>
        {accountDetailsByid.loading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress color="inherit" size={30} />
            </Grid>
          </Grid>
        ) : 'accountDetails' in accountDetailsByid ? (
          accountDetailsByid.accountDetails !== undefined ? (
            accountDetailsByid.accountDetails !== null ? (
              'vendor' in accountDetailsByid.accountDetails ? (
                accountDetailsByid.accountDetails.vendor !== undefined ? (
                  accountDetailsByid.accountDetails.vendor !== null ? (
                    'description' in accountDetailsByid.accountDetails.vendor ? (
                      accountDetailsByid.accountDetails.vendor.description !== undefined ? (
                        accountDetailsByid.accountDetails.vendor.description !== null ? (
                          <Box
                            mt={1}
                            dangerouslySetInnerHTML={{
                              __html: accountDetailsByid.accountDetails.vendor.description,
                            }}
                          />
                        ) : (
                          <Typography variant="subtitle1">No content available</Typography>
                        )
                      ) : (
                        <Typography variant="subtitle1">No content available</Typography>
                      )
                    ) : (
                      <Typography variant="subtitle1">No content available</Typography>
                    )
                  ) : (
                    <Typography variant="subtitle1">No content available</Typography>
                  )
                ) : (
                  <Typography variant="subtitle1">No content available</Typography>
                )
              ) : (
                <Typography variant="subtitle1">No content available</Typography>
              )
            ) : (
              <Typography variant="subtitle1">No content available</Typography>
            )
          ) : (
            <Typography variant="subtitle1">No content available</Typography>
          )
        ) : (
          <Typography variant="subtitle1">No content available</Typography>
        )}
      </CardContent>
    </Card>
  )
}
