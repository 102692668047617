import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useAppSelector, useAppDispatch } from 'reduxStore/hooks'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import dayjs from 'dayjs'
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { VendorSpendDetailsReq } from 'reduxStore/vendorSpendLicesnseRenewal/vendorSpendDetails/vendorSpendDetailsModel'
import { fetchVendorSpendDetails, updateVendorSpendDetailsClearRes } from 'reduxStore/vendorSpendLicesnseRenewal/vendorSpendDetails/vendorSpendDetailsSlice'
import VendorSpendCatRenewalDetails from 'pages/Dashboard/VendorSpend/VendorSpendCatRenewalDetails'
import _ from 'lodash'
import { Results as SpendRes } from 'reduxStore/vendorSpendUsage/usageModel'
import { Results } from 'reduxStore/vendorSpendLicesnseRenewal/spendModel'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'

interface VendorSpendChart {
  date: string
  spend: number
  budget: number
  year?: string
  modifiedYear: string
}

interface deptLimitData {
  limit: string
}

const textfieldStyle = {
  '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
    color: 'inherit',
    visibility: 'visible',
  },
}

export interface VendorSpendCatRenewalDialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function VendorSpendCatRenewalDialogTitle(props: VendorSpendCatRenewalDialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function Chart() {
  am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const monthlyVendorSpend = useAppSelector((state) => state.monthlyVendorSpend)
  const monthlyVendorSpendUsage = useAppSelector((state) => state.monthlyVendorSpendUsage)

  const [data, setData] = useState([] as VendorSpendChart[])
  const [tempData, setTempData] = useState([] as VendorSpendChart[])

  const [yearList, setYearList] = useState([] as any[])
  const [selectedItem, setSelectedItem] = useState({} as any)
  const [xAxisZoom, setXAxisZoom] = useState(undefined as any)
  const [xAxisYearZoom, setXAxisYearZoom] = useState(undefined as any)

  const [selectedDate, setSelectedDate] = useState('')

  const [modalOpen, setModalOpen] = React.useState(false)

  function selectChartItem(item: any) {
    setSelectedItem(item)
    setSelectedDate(
      `${String(dayjs(item.date).format()).substring(5, 7)}/${String(
        dayjs(item.date).format(),
      ).substring(0, 4)}`,
    )
    let deptDetailsParam = {
      length: String(10),
      month: String(dayjs(item.date!).format()).substring(5, 7),
      year: String(dayjs(item.date!).format()).substring(0, 4),
      token: Token!,
    } as VendorSpendDetailsReq
    dispatch(fetchVendorSpendDetails(deptDetailsParam))
  }

  // Close Modal Action
  const handleModalClose = () => {
    setModalOpen(false)
    dispatch (updateVendorSpendDetailsClearRes())
  }

  const myTrails = [
    {
      limit: '10',
    },
    {
      limit: '20',
    },
    {
      limit: '30',
    },
  ] as deptLimitData[]
  function handleLimitChange(limit: deptLimitData) {
    let deptDetailsParam = {
      length: limit.limit,
      month: String(dayjs(selectedItem.date).format()).substring(5, 7),
      year: String(dayjs(selectedItem.date).format()).substring(0, 4),
      token: Token!,
    } as VendorSpendDetailsReq
    dispatch(fetchVendorSpendDetails(deptDetailsParam))
    dispatch (updateVendorSpendDetailsClearRes())
  }
  const [selectedYear, setSelectedYear] = React.useState({} as any | null)

  // show whole chart, but on selection, zoom to that year
  const handleChange = (newValue: any) => {
    setSelectedYear(newValue)
    let tempData = data
    setTempData(tempData)
    xAxisZoom !== undefined
      ? xAxisZoom.zoomToDates(
          new Date(Number(newValue.label), 0),
          new Date(Number(newValue.label), 12),
        )
      : console.log('No x axis yet')

    xAxisYearZoom !== undefined
      ? xAxisYearZoom.zoomToDates(
          new Date(Number(newValue.label), 0),
          new Date(Number(newValue.label), 12),
        )
      : console.log('No x axis yet')
  }

  useEffect(() => {
    if (yearList.length > 0) {
      handleChange(yearList.find((element) => element.label === String(new Date().getFullYear())))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearList])
  useEffect(() => {
    let data = [] as VendorSpendChart[]
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    let spendArr = [] as VendorSpendChart[]
    if (
      monthlyVendorSpendUsage.monthlyUsageSpends.length > 0 &&
      monthlyVendorSpend.monthlyBudgetForecasts.length > 0
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      spendArr = monthlyVendorSpendUsage.monthlyUsageSpends.map((item: SpendRes) => {
        return {
          date: dayjs(new Date(item.year + '/' + item.month + '/01')).format('ddd DD MMM YYYY'),
          budget: 'projected_cost' in item ? 0 : 0,
          spend: Math.round(Number(item.cost!)),
          modifiedYear:
            monthNames[dayjs(new Date(item.year + '/' + item.month + '/01')).month()] +
            ' ' +
            dayjs(new Date(item.year + '/' + item.month + '/01')).year(),
          year: String(item.year),
        }
      })
    }

    let budgetArr = [] as VendorSpendChart[]
    if (
      monthlyVendorSpendUsage.monthlyUsageSpends.length > 0 &&
      monthlyVendorSpend.monthlyBudgetForecasts.length > 0
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      budgetArr = monthlyVendorSpend.monthlyBudgetForecasts.map((item: Results) => {
        return {
          date: dayjs(new Date(item.year + '/' + item.month + '/01')).format('ddd DD MMM YYYY'),
          budget: Math.round(Number(item.projected_cost!)),
          spend: 'cost' in item ? 0 : 0,
          modifiedYear:
            monthNames[dayjs(new Date(item.year + '/' + item.month + '/01')).month()] +
            ' ' +
            dayjs(new Date(item.year + '/' + item.month + '/01')).year(),
          year: String(item.year),
        }
      })
    }
    // Pick uniq items from from spend array which are not present in budget array by checking with modifiedYear key
    let uniqSpendArrayItems = spendArr.filter(
      (spendArrayItem: VendorSpendChart) =>
        !budgetArr.some(
          (budgetArrayItem: VendorSpendChart) =>
            spendArrayItem.modifiedYear === budgetArrayItem.modifiedYear,
        ),
    )
    // Pick uniq items from from budget array which are not present in spend array by checking with modifiedYear key
    let uniqueBudgetArrayItems = budgetArr.filter(
      (budgetArrayItem: VendorSpendChart) =>
        !spendArr.some(
          (spendArrayItem: VendorSpendChart) =>
            budgetArrayItem.modifiedYear === spendArrayItem.modifiedYear,
        ),
    )
    // merge unmatched spend & budget array by checking modifiedYear key value to prevent duplicate
    let unmatchedSpendBudgetItemArray = _.unionBy(
      uniqSpendArrayItems,
      uniqueBudgetArrayItems,
      'modifiedYear',
    )

    let matchedSpendBudgetItemArray = [] as VendorSpendChart[]
    // Pick matched items from spend & budget array by checking modifiedYear key
    if (
      'monthlyBudgetForecasts' in monthlyVendorSpend &&
      'monthlyUsageSpends' in monthlyVendorSpendUsage
    ) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      matchedSpendBudgetItemArray = spendArr.map((spendArrayItem: VendorSpendChart) => {
        const budgetItem = budgetArr.find(
          (budgetArrayItem: VendorSpendChart) =>
            budgetArrayItem.modifiedYear === spendArrayItem.modifiedYear,
        )
        return {
          date: spendArrayItem.date!,
          spend: spendArrayItem.spend!,
          budget: budgetItem?.budget === undefined ? 0 : budgetItem?.budget,
          modifiedYear: spendArrayItem.modifiedYear!,
          year: String(spendArrayItem.year),
        }
      })
    }
    // merge matched & unmatched spendBudget array by modifiedYear key without duplicate entry
    let finalArrayMerge = _.unionBy(
      unmatchedSpendBudgetItemArray,
      matchedSpendBudgetItemArray,
      'modifiedYear',
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
    data = finalArrayMerge
    // sort array items by month & date
    data.sort(
      (a: any, b: any) =>
        a.modifiedYear.slice(-4) - b.modifiedYear.slice(-4) ||
        monthNames.indexOf(a.modifiedYear.slice(0, -5)) -
          monthNames.indexOf(b.modifiedYear.slice(0, -5)),
    )
    // adjust so the axis line up - start with the beginning of a quarter and end with the end of a quarter
    if (data.length > 0) {
      if (
        !data[data.length - 1].date.includes('Mar') &&
        !data[data.length - 1].date.includes('Jun') &&
        !data[data.length - 1].date.includes('Sep') &&
        !data[data.length - 1].date.includes('Dec')
      ) {
        data.push({
          date: String(
            dayjs(new Date(Number(data[data.length - 1].date.substring(11, 15)), 11)).format(
              'ddd DD MMM YYYY',
            ),
          ),
          spend: 0,
          budget: 0,
          year: data[data.length - 1].date.substring(11, 15),
          modifiedYear: data[data.length - 1].date.substring(11, 15),
        } as VendorSpendChart)
      }
      if (
        !data[0].date.includes('Jan') &&
        !data[0].date.includes('Apr') &&
        !data[0].date.includes('Jul') &&
        !data[0].date.includes('Oct')
      ) {
        data.push({
          date: String(
            dayjs(new Date(Number(data[data.length - 1].date.substring(11, 15)), 0)).format(
              'ddd DD MMM YYYY',
            ),
          ),
          spend: 0,
          budget: 0,
          year: data[data.length - 1].date.substring(11, 15),
          modifiedYear: data[data.length - 1].date.substring(11, 15),
        } as VendorSpendChart)
      }
    }
    setData(data)
    let newA = data.map((item) => {
      return item.year
    })
    let uniq = [...new Set(newA)]
    let objYear = uniq.map((item, index) => {
      return { label: item!.toString(), value: index }
    })
    setYearList(objYear)
  }, [monthlyVendorSpend, monthlyVendorSpendUsage])
  useEffect(() => {
    let root = am5.Root.new('chartdiv')
    var modal = am5.Modal.new(root, {
      content: 'The Monthly Vendor Spend and License Renewal chart has no data',
    })
    root.setThemes([am5themes_Animated.new(root)])

    root.dateFormatter.setAll({
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['valueX'],
    })

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
      }),
    )

    chart.children.unshift(
      am5.Label.new(root, {
        text: 'Monthly Vendor Spend and License Renewal',
        fontSize: 20,
        fontWeight: '600',
        textAlign: 'center',
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 10,
      }),
    )

    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        //behavior: 'zoomX',
      }),
    )
    cursor.lineY.set('visible', false)

    let xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 1 },
        markUnitChange: false,
        gridIntervals: [
          { timeUnit: 'month', count: 1 },
          //{ timeUnit: 'month', count: 3 },
        ],
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
        tooltipDateFormat: 'MMMM yyyy',
      }),
    )
    // make the axis accessible
    setXAxisZoom(xAxis)

    // second date access for the quarters
    let xAxisYear = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 3 },
        markUnitChange: false,
        gridIntervals: [{ timeUnit: 'month', count: 3 }],
        dateFormats: { month: '                Qq yyyy' },
        renderer: am5xy.AxisRendererX.new(root, {}),
        //x: am5.percent(10),
      }),
    )
    // make the axis accessible
    setXAxisYearZoom(xAxisYear)

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "'$'#0a",
        renderer: am5xy.AxisRendererY.new(root, {
          opposite: false,
          minGridDistance: 40,
        }),
      }),
    )

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: 'Monthly Spend',
        fontSize: 12,
        fontWeight: 'bold',
        y: am5.p50,
        centerX: am5.p50,
      }),
    )

    let spendbar = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Spend',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'spend',
        valueXField: 'date',
        fill: am5.Color.fromString('#134872'),
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          // eslint-disable-next-line no-template-curly-in-string
          labelText: '{name}: ${valueY}',
        }),
        // eslint-disable-next-line no-template-curly-in-string
        legendValueText: '${valueY}',
      }),
    )

    spendbar.columns.template.setAll({
      width: am5.percent(30),
      fillOpacity: 1,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    })

    spendbar.data.processor = am5.DataProcessor.new(root, {
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
    })

    let forecastbar = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Forecast',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'budget',
        valueXField: 'date',
        fill: am5.Color.fromString('#01cba3'),
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: 'horizontal',
          // eslint-disable-next-line no-template-curly-in-string
          labelText: '{name}: ${valueY}',
        }),
        // eslint-disable-next-line no-template-curly-in-string
        legendValueText: '${valueY}',
      }),
    )

    forecastbar.columns.template.setAll({
      width: am5.percent(65),
      fillOpacity: 0.3,
      strokeOpacity: 0,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    })

    let series0 = chart.series.push(
      am5xy.SmoothedXLineSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'spend',
        valueXField: 'date',
        stroke: am5.Color.fromString('#20acd4'),
      }),
    )
    series0.strokes.template.setAll({
      strokeWidth: 2,
    })

    series0.bullets.push(function () {
      return am5.Bullet.new(root, {
        sprite: am5.Circle.new(root, {
          stroke: series0.get('fill'),
          strokeWidth: 2,
          fill: root.interfaceColors.get('background'),
          radius: 2,
        }),
      })
    })

    // new series (with the same data) to be linked with the second date axis
    var seriesY = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        xAxis: xAxisYear,
        yAxis: yAxis,
        valueYField: 'spend',
        valueXField: 'date',
        forceHidden: true,
      }),
    )

    let scrollbar = chart.set(
      'scrollbarX',
      am5xy.XYChartScrollbar.new(root, {
        orientation: 'horizontal',
        height: 50,
        background: am5.Rectangle.new(root, {
          fill: am5.Color.fromString('#DEECF3'),
          fillOpacity: 0.7,
        }),
      }),
    )

    let sbDateAxis = scrollbar.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: {
          timeUnit: 'month',
          count: 1,
        },
        visible: false,
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    )

    // second date access for the year
    let sbSecondDateAxis = scrollbar.chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        baseInterval: { timeUnit: 'month', count: 3 },
        markUnitChange: true,
        visible: false,
        gridIntervals: [{ timeUnit: 'month', count: 3 }],
        dateFormats: { month: 'Qq' },
        renderer: am5xy.AxisRendererX.new(root, {}),
      }),
    )

    let sbValueAxis0 = scrollbar.chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    )
    /*
      let sbValueAxis1 = scrollbar.chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererY.new(root, {}),
        }),
      )
      */
    let sbSeriesForecast = scrollbar.chart.series.push(
      am5xy.ColumnSeries.new(root, {
        valueYField: 'budget',
        valueXField: 'date',
        fill: am5.Color.fromString('#01cba3'),
        clustered: false,
        xAxis: sbDateAxis,
        yAxis: sbValueAxis0,
      }),
    )

    // make the scrollbar columns more similar to the chart
    sbSeriesForecast.columns.template.setAll({
      width: am5.percent(70),
      fillOpacity: 0.2,
      strokeOpacity: 0,
      cornerRadiusTL: 2,
      cornerRadiusTR: 2,
    })

    sbSeriesForecast.columns.template.setAll({ fillOpacity: 0.5, strokeOpacity: 1 })

    let sbSeriesSpend = scrollbar.chart.series.push(
      am5xy.ColumnSeries.new(root, {
        valueYField: 'spend',
        valueXField: 'date',
        fill: am5.Color.fromString('#134872'),
        clustered: false,
        xAxis: sbDateAxis,
        yAxis: sbValueAxis0,
      }),
    )

    // make the scrollbar columns more similar to the chart
    sbSeriesSpend.columns.template.setAll({
      width: am5.percent(30),
      fillOpacity: 1,
      strokeOpacity: 0,
      cornerRadiusTL: 2,
      cornerRadiusTR: 2,
    })

    sbSeriesSpend.columns.template.setAll({ fillOpacity: 0.5, strokeOpacity: 1 })

    let sbSeriesSpendLine = scrollbar.chart.series.push(
      am5xy.LineSeries.new(root, {
        valueYField: 'spend',
        valueXField: 'date',
        xAxis: sbDateAxis,
        yAxis: sbValueAxis0,
      }),
    )

    let sbSeriesY = scrollbar.chart.series.push(
      am5xy.LineSeries.new(root, {
        valueYField: 'spend',
        valueXField: 'date',
        visible: false,
        xAxis: sbSecondDateAxis,
        yAxis: sbValueAxis0,
      }),
    )

    // add start and end labels on the scrollbar grips
    let startLabel = scrollbar.startGrip.children.push(
      am5.Label.new(root, {
        isMeasured: false,
        width: 100,
        fill: am5.color(0x000000),
        centerX: 50,
        centerY: 35,
        x: am5.p50,
        y: 0,
        textAlign: 'center',
        populateText: true,
      }),
    )

    if (tempData.length !== 0) {
      scrollbar.on('start', function (position) {
        setTimeout(function () {
          startLabel.set(
            'text',
            root.dateFormatter.format(xAxis.positionToDate(Number(position)), 'Qq yyyy'),
          )
        }, 50)
      })
    }

    let endLabel = scrollbar.endGrip.children.push(
      am5.Label.new(root, {
        isMeasured: false,
        width: 100,
        fill: am5.color(0x000000),
        centerX: 50,
        centerY: 35,
        x: am5.p50,
        y: 0,
        textAlign: 'center',
        populateText: true,
      }),
    )

    if (tempData.length !== 0) {
      scrollbar.on('end', function (position) {
        setTimeout(function () {
          endLabel.set(
            'text',
            root.dateFormatter.format(xAxis.positionToDate(Number(position)), 'Qq yyyy'),
          )
        }, 50)
      })
    }

    let legend = chart.children.push(
      am5.Legend.new(root, {
        x: am5.p50,
        centerX: am5.p50,
        marginTop: 10,
      }),
    )
    legend.valueLabels.template.setAll({
      width: 90,
      textAlign: 'right',
    })
    legend.data.setAll([forecastbar, spendbar])

    if (tempData.length === 0) {
      modal.open()
    } else {
      modal.close()
    }

    spendbar.data.setAll(tempData)
    forecastbar.data.setAll(tempData)
    // set the data and settings for the year axis
    seriesY.data.setAll(tempData)

    xAxis.get('renderer').grid.template.setAll({
      location: 0.5,
    })

    xAxisYear.get('renderer').grid.template.setAll({
      //location: 0.5,
    })

    xAxisYear.get('renderer').labels.template.setAll({
      fontWeight: 'bold',
      //location: 0.5,
      //dx: (xAxis.width() / 12) * 1.5,
    })

    xAxisYear.get('renderer').grid.template.setAll({
      strokeOpacity: 2,
    })

    xAxisYear.get('renderer').ticks.template.setAll({
      //visible: false,
      strokeOpacity: 1,
      length: 50,
      dy: -25,
      location: 0.5,
    })
    // Add event listeners on click event
    spendbar.columns.template.events.on('click', function (ev) {
      selectChartItem(ev.target.dataItem?.dataContext)
      setModalOpen(true)
    })

    forecastbar.columns.template.events.on('click', function (ev) {
      selectChartItem(ev.target.dataItem?.dataContext)
      setModalOpen(true)
    })

    let exporting = am5plugins_exporting.Exporting.new(root, {})
    exporting.setAll({
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: 'MonthlyVendorSpend',
      dataSource: tempData,
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
    })

    series0.data.setAll(tempData)
    sbSeriesForecast.data.setAll(tempData)
    sbSeriesSpend.data.setAll(tempData)
    sbSeriesSpendLine.data.setAll(tempData)
    sbSeriesY.data.setAll(tempData)

    series0.appear(1000)
    chart.appear(1000, 100)

    return () => root.dispose()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempData])

  // when the graph is loaded, the x axes will zoom to the current calendar year
  xAxisZoom !== undefined
    ? xAxisZoom.zoomToDates(
        new Date(Number(selectedYear.label), 0),
        new Date(Number(selectedYear.label), 12),
      )
    : console.log('No x axis yet later')

  xAxisYearZoom !== undefined
    ? xAxisYearZoom.zoomToDates(
        new Date(Number(selectedYear.label), 0),
        new Date(Number(selectedYear.label), 12),
      )
    : console.log('No x axis yet later')

  return (
    <Box>
      <Autocomplete
        value={selectedYear.label === undefined ? { label: ' ', value: -1 } : selectedYear}
        onChange={(_event: any, newValue: any) => {
          handleChange(newValue)
        }}
        id="controllable-states-demo"
        options={yearList}
        sx={{ ml: '85%', width: '15%' }}
        renderInput={(params) => (
          <TextField {...params} label="Select Year" variant="standard" placeholder="Select year" />
        )}
        disableClearable={true as any}
        getOptionLabel={(option: any) => option?.label!}
        isOptionEqualToValue={(option: any, value) => option.label === value.label}
      />
      <Box id="chartdiv" style={{ width: '100%', height: '500px' }} />
      <Dialog
        fullScreen
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="responsive-dialog-title"
      >
        <VendorSpendCatRenewalDialogTitle id="customized-dialog-title" onClose={handleModalClose} />
        <DialogContent>
          <DialogContentText>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              pt={1}
            >
              <Typography variant="h6">Vendor Spend Department - {selectedDate}</Typography>
              <Autocomplete
                onChange={(_event, value: any) => handleLimitChange(value!)}
                options={myTrails}
                id="autocomplete-1"
                getOptionLabel={(option) => `${option.limit}`}
                defaultValue={myTrails[0]}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Limit Graph to Top"
                      sx={textfieldStyle}
                    />
                  )
                }}
                sx={{ mb: 2, minWidth: 400 }}
              />
            </Stack>
            <VendorSpendCatRenewalDetails />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  )
}
