import {
  Alert,
  AlertColor,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import * as React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import {
  inactiveUserByID,
  inactiveUserClearMsg,
  updateUserStatus,
} from 'reduxStore/account/inactiveUser/inactiveUserSlice'
import { InactiveUserBody } from 'reduxStore/account/inactiveUser/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { fetchAccountUserDetailsById } from 'reduxStore/account/accountUserDetailsById/accountUserDetailsByIdSlice'
import { CompanyAccountDetailsModelReq } from 'reduxStore/account/companyAccountDetailsById/companyAccountDetailsModel'
import { useParams } from 'react-router-dom'

type Props = {
  itemId?: string | any
  text?: string | any
  inActiveUserDialogEnable?: boolean | any
  inActiveUserDialogUpdateState: (value: boolean) => void
}

export default function ToggleUserStatusDialog({
  itemId,
  text,
  inActiveUserDialogEnable,
  inActiveUserDialogUpdateState,
}: Props) {
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')

  // Call store
  const inActiveUserByIDVal = useAppSelector((state) => state.userInactive)

  const [netErrMsq, setNetErrMsq] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState('')
  const [dialogToggleText, setDialogToggleText] = React.useState('')

  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    inActiveUserDialogUpdateState(false)
    dispatch(updateUserStatus(''))
  }
  let inActiveUserByIDBody = {
    id: selectedItem,
    token: Token!,
  } as InactiveUserBody
  // Get the userId param from the URL.
  let { id } = useParams()
  let companyAccountDetailsByIdBody = {
    accountId: String(id),
    token: Token,
  } as CompanyAccountDetailsModelReq

  const inActiveUser = async () => {
    if (navigator.onLine) {
      setNetErrMsq('')
      await dispatch(inactiveUserByID(inActiveUserByIDBody))
      await dispatch(fetchAccountUserDetailsById(companyAccountDetailsByIdBody))
      await handleClose()
    } else {
      setNetErrMsq('You are currently offline')
    }
  }

  const [showErrorMsgView, setShowErrorMsgView] = React.useState(false)

  React.useEffect(() => {
    setSelectedItem(itemId)
    setDialogState(inActiveUserDialogEnable)
    setDialogToggleText(text)
  }, [itemId, inActiveUserDialogEnable, text])

  const [toggleUserStatusMsgType, setToggleUserStatusType] = React.useState<AlertColor>('success')
  const [toggleUserStatusMsg, setToggleUserStatusMsg] = React.useState('')

  React.useEffect(() => {
    if (inActiveUserByIDVal?.successMsg !== '') {
      handleClose()
    }
    if (inActiveUserByIDVal?.error !== '') {
      setShowErrorMsgView(true)
      setToggleUserStatusType('error')
      setToggleUserStatusMsg(inActiveUserByIDVal?.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inActiveUserByIDVal])

  return (
    <Dialog open={dialogState} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogContent>
        <DialogContentText>
          <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
            Are you sure you want to {dialogToggleText} this user?
          </Typography>
        </DialogContentText>
        {netErrMsq !== '' ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <Typography sx={{ color: '#c62828' }}>{netErrMsq}</Typography>
            </Grid>
          </Grid>
        ) : null}
        <Collapse in={showErrorMsgView}>
          <Alert
            icon={false}
            severity={toggleUserStatusMsgType}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  dispatch(inactiveUserClearMsg())
                  setShowErrorMsgView(false)
                  setToggleUserStatusMsg('')
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {toggleUserStatusMsg}
          </Alert>
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => inActiveUser()}
          disabled={inActiveUserByIDVal?.loading}
        >
          {inActiveUserByIDVal?.loading ? 'Loading...' : 'Yes'}
        </Button>
        <Button variant="outlined" onClick={handleClose} autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}
