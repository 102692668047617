import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { UserDeviceResponse, UserDeviceErrorResponse, UserDeviceBody } from './Model'

type InitialState = {
  loading: boolean
  response: UserDeviceResponse
  error: UserDeviceErrorResponse
}
const initialState: InitialState = {
  loading: false,
  response: {} as UserDeviceResponse,
  error: { message: '' } as UserDeviceErrorResponse,
}

export const userDevice = createAsyncThunk<UserDeviceResponse, UserDeviceBody>(
  'userDevice/userDevice',
  async (payload: UserDeviceBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + `/api/userdevice`, payload.updateBody, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data[0].detail))
  },
)

const userDeviceSlice = createSlice({
  name: 'userDevice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(userDevice.pending, (state) => {
      state.loading = true
    })
    builder.addCase(userDevice.fulfilled, (state, action: PayloadAction<UserDeviceResponse>) => {
      state.loading = false
      state.response = action.payload
      state.error = {} as any
    })
    builder.addCase(userDevice.rejected, (state, action) => {
      state.loading = false
      state.response = {} as UserDeviceResponse
      state.error.message = action.error.message || 'Something went wrong'
    })
  },
})

export default userDeviceSlice.reducer
