import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  AlertColor,
} from '@mui/material'
import * as React from 'react'
import PeopleIcon from '@mui/icons-material/People'
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { Result } from 'reduxStore/customer/companyUser/companyUserList/Model'
import AddCustomer from 'pages/Account/CompanyUsers/AddCustomer'
import ToggleCompanyUserByIDDialog from './ToggleCompanyUserByIDDialog'
import {
  addUserClearMsg,
  addUserClearRes,
} from 'reduxStore/customer/companyUser/addCompanyUser/addCompanyUserSlice'
import { fetchCompanyUserList } from 'reduxStore/customer/companyUser/companyUserList/companyUserListSlice'
import {
  editCompanyUserClearMsg,
  editCompanyUserClearRes,
} from 'reduxStore/customer/companyUser/editCompanyUser/editCompanyUserSlice'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { DataGridPremium, DataGridPremiumProps, GridRowParams } from '@mui/x-data-grid-premium'
import { EditCompanyUserTableColumn } from './EditCompanyUserTableColumn'
import MUIDDCustomToolbar from 'components/MUIDGCustomToolbar'
import EditCompanyUser from './EditCompanyUser'
import { checkReturnValue } from 'components/Utility'
import { toggleCompnayUserClearRes } from 'reduxStore/customer/companyUser/toggleCompanyUserByID/toggleCompanyUserByIDSlice'

export default function CompanyUsers() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  // Call store
  const companyUserList = useAppSelector((state) => state.companyUserList)
  const editCompanyUserValue = useAppSelector((state) => state.editCompanyUserRes)
  const toggleCompanyUserByID = useAppSelector((state) => state.toggleCompanyUserByID)

  const [userListArray, setUserListArray] = React.useState([] as any[])

  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (companyUserList?.companyUserList?.results! !== undefined) {
        let userObj = companyUserList?.companyUserList?.results!.map((element: Result) => {
          return {
            id: element?.id,
            userName: element?.user?.username! !== undefined ? element?.user?.username! : 'Not Set',
            email: checkReturnValue(element?.user?.email),
            firstName: checkReturnValue(element?.user?.first_name),
            lastName: checkReturnValue(element?.user?.last_name),
            role: 'Admin',
            userStatus:
              checkReturnValue(element?.active_company_user) !== 'Not Set'
                ? element?.active_company_user
                : false,
            phoneNumber: checkReturnValue(element?.phone_number),
          }
        })
        setUserListArray(userObj)
      }
    }
  }, [companyUserList])
  const [showDialog, setShowDialog] = React.useState(false)
  const updateDialogState = () => {
    setShowDialog(true)
  }
  // get data from child component
  const updateDialogValFromChild = (passedval: boolean) => {
    setShowDialog(passedval)
  }

  const [selectedItem, setSelectedItem] = React.useState('')
  const updateToggleCompanyUserByIDDialogState = (id: string) => {
    setSelectedItem(String(id))
    setShowToggleCompanyUserByIDDialog(true)
  }

  const [showToggleCompanyUserByIDDialog, setShowToggleCompanyUserByIDDialog] =
    React.useState(false)

  // get data from ToggleCompanyUserByID Dialog (child) component
  const updateToggleCompanyUserByIDDialogValFromChild = (passedVal: boolean) => {
    setShowToggleCompanyUserByIDDialog(passedVal)
    dispatch(editCompanyUserClearMsg())
  }

  React.useEffect(() => {
    if (editCompanyUserValue?.succssMessage !== '') {
      setHideAlertMsgType('success')
      setEditUserSuccessMsg(editCompanyUserValue.succssMessage)
      dispatch(fetchCompanyUserList(Token!))
    }
    if (editCompanyUserValue?.error?.message !== '') {
      setHideAlertMsgType('error')
      setEditUserSuccessMsg(editCompanyUserValue?.error?.message)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editCompanyUserValue])

  const [apiResMsg, setAPIresMsg] = React.useState('')
  const [msgType, setMsgType] = React.useState<AlertColor>('success')
  React.useEffect(() => {
    if (toggleCompanyUserByID?.succssMessage !== '') {
      setMsgType('success')
      setAPIresMsg(toggleCompanyUserByID?.succssMessage)
      dispatch(fetchCompanyUserList(Token!))
    }
    if (toggleCompanyUserByID?.error !== '') {
      setMsgType('error')
      setAPIresMsg(toggleCompanyUserByID?.error)
    }
    if (toggleCompanyUserByID?.deActivateUser !== '') {
      updateToggleCompanyUserByIDDialogState(toggleCompanyUserByID?.deActivateUser)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleCompanyUserByID])

  const closeUserStatusChangeSnackbar = () => {
    dispatch(toggleCompnayUserClearRes())
    setAPIresMsg('')
    setMsgType('success')
  }

  const addedCompanyUserApiResponse = useAppSelector((state) => state.addCompanyUser)
  const [addUserSuccessMsg, setAddUserSuccessMsg] = React.useState('')
  const [editUserSuccessMsg, setEditUserSuccessMsg] = React.useState('')
  const [hideAlertMsgType, setHideAlertMsgType] = React.useState<AlertColor>('success')
  React.useEffect(() => {
    if (addedCompanyUserApiResponse?.addCompanyUserRes?.id !== undefined) {
      setHideAlertMsgType('success')
      setAddUserSuccessMsg('User added successfully')
      dispatch(fetchCompanyUserList(Token!))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addedCompanyUserApiResponse])

  const closeAddUserSnackbar = () => {
    dispatch(addUserClearMsg())
    dispatch(addUserClearRes())
    dispatch(editCompanyUserClearMsg())
    dispatch(editCompanyUserClearRes())
    setAddUserSuccessMsg('')
    setEditUserSuccessMsg('')
  }

  function DetailPanelContent({ row }: { row: any }) {
    return <EditCompanyUser item={row} />
  }

  const getDetailPanelContent = React.useCallback(
    ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getDetailPanelHeight = React.useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, [])

  return (
    <Box>
      {showDialog ? (
        <AddCustomer dialogEnable={showDialog} updatedDialogState={updateDialogValFromChild} />
      ) : null}
      {showToggleCompanyUserByIDDialog ? (
        <ToggleCompanyUserByIDDialog
          itemId={selectedItem}
          toggleCompanyUserByIDDialogEnable={showToggleCompanyUserByIDDialog}
          toggleCompanyUserByIDDialogUpdateState={updateToggleCompanyUserByIDDialogValFromChild}
        />
      ) : null}
      <Card>
        <CardHeader
          avatar={<PeopleIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start">
              <Stack direction={'row'}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                  pt={1}
                >
                  <Stack direction={'row'}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Company Users:
                    </Typography>
                  </Stack>
                  <Stack direction={'row'}>
                    <Chip label={userListArray.length}></Chip>
                  </Stack>
                  <Stack direction={'row'}></Stack>
                </Stack>
              </Stack>
              <Stack direction={'row'}>
                <Button
                  variant="contained"
                  startIcon={<PersonAddAltIcon />}
                  onClick={() => updateDialogState()}
                >
                  Add Users
                </Button>
              </Stack>
            </Stack>
          }
        />
        <CardContent>
          {companyUserList.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ height: '435px' }}>
              <DataGridPremium
                columns={EditCompanyUserTableColumn()}
                rows={userListArray}
                getDetailPanelHeight={getDetailPanelHeight}
                getDetailPanelContent={getDetailPanelContent}
                initialState={{
                  sorting: {
                    sortModel: [{ field: 'username', sort: 'asc' }],
                  },
                }}
                slots={{
                  toolbar: MUIDDCustomToolbar,
                }}
                hideFooter
                disableRowGrouping
              />
            </Box>
          )}
        </CardContent>
      </Card>
      <ApplogieSnackbar
        msg={addUserSuccessMsg}
        type={hideAlertMsgType}
        alertClose={closeAddUserSnackbar}
      />
      <ApplogieSnackbar
        msg={editUserSuccessMsg}
        type={hideAlertMsgType}
        alertClose={closeAddUserSnackbar}
      />
      <ApplogieSnackbar msg={apiResMsg} type={msgType} alertClose={closeUserStatusChangeSnackbar} />
    </Box>
  )
}
