import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Results, ActiveUsersDetailsReq } from '../Active/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  activeusers: Results
  error: string
}
const initialState: InitialState = {
  loading: false,
  activeusers: {} as Results,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchActiveuser = createAsyncThunk<Results, ActiveUsersDetailsReq>(
  'activeusers/fetchActiveuser',
  (reqParam: ActiveUsersDetailsReq) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(
        url.backEndURL +
          `/api/organizationuser/True/get_active_pagination?page=${reqParam.pageCount}&size=${reqParam.length}`,
        { headers },
      )
      .then((response) => response.data)
  },
)

const activeusersSlice = createSlice({
  name: 'activeusers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchActiveuser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchActiveuser.fulfilled, (state, action: PayloadAction<Results>) => {
      state.loading = false
      state.activeusers = action.payload
      state.error = ''
    })
    builder.addCase(fetchActiveuser.rejected, (state, action) => {
      state.loading = false
      state.activeusers = {} as Results
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default activeusersSlice.reducer
