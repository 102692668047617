export const numberWithCommas = (number: number) => {
    return number.toLocaleString('en-US', { 
		style: 'currency', 
		currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
	});
}

export const numberWithDecimalCommas = (number: number) => {
  return number.toLocaleString('en-US', { 
  style: 'currency', 
  currency: 'USD',
});
}
