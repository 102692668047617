import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { AccountDetailsModel, AccountDetailsModelReq } from './AccountUserDetailsByIdModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  accountUserDetails: AccountDetailsModel
  error: string
}
const initialState: InitialState = {
  loading: false,
  accountUserDetails: {} as AccountDetailsModel,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchAccountUserDetailsById = createAsyncThunk<
  AccountDetailsModel,
  AccountDetailsModelReq
>('accountUserDetailsById/fetchAccountUserDetailsById', (reqParam: AccountDetailsModelReq) => {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Token ${reqParam.token}`,
  }
  return setupInterceptorsTo(axios)
    .get(url.backEndURL + `/api/accountuser?account=${reqParam.accountId}`, { headers })
    .then((response) => response.data)
})

const accountUserDetailsByIdSlice = createSlice({
  name: 'accountUserDetailsById',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountUserDetailsById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchAccountUserDetailsById.fulfilled,
      (state, action: PayloadAction<AccountDetailsModel>) => {
        state.loading = false
        state.accountUserDetails = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchAccountUserDetailsById.rejected, (state, action) => {
      state.loading = false
      state.accountUserDetails = {} as AccountDetailsModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default accountUserDetailsByIdSlice.reducer
