import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Breaches } from './breachesModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  spend: Breaches
  error: string
}
const initialState: InitialState = {
  loading: false,
  spend: {} as Breaches,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchBreaches = createAsyncThunk<Breaches, string>('braches/Breaches', (adminToken) => {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Token ${adminToken}`,
  }
  return setupInterceptorsTo(axios)
    .get(url.backEndURL + '/api/customerbreachvendors', { headers })
    .then((response) => response.data)
})

const fetchBreachesSlice = createSlice({
  name: 'monthlyVendorSpend',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBreaches.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchBreaches.fulfilled, (state, action: PayloadAction<Breaches>) => {
      state.loading = false
      state.spend = action.payload
      state.error = ''
    })
    builder.addCase(fetchBreaches.rejected, (state, action) => {
      state.loading = false
      state.spend = {} as Breaches
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default fetchBreachesSlice.reducer
