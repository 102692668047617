import { createTheme, ThemeOptions } from '@mui/material'

import { applogiePalette } from './palette/applogiePalette'
import { typography } from './typography'

const commonThemeProperties: ThemeOptions = {
  zIndex: {
    appBar: 101100,
    modal: 101300,
    mobileStepper: 101000,
    speedDial: 101050,
    drawer: 101200,
    snackbar: 101400,
    tooltip: 101500,
    fab: 101050
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    ...typography,
  },
}

const applogieTheme = createTheme({
  ...commonThemeProperties,
  palette: applogiePalette,
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.025), rgba(255, 255, 255, 0.025))',
        },
      },
    },
    MuiAppBar: {
      defaultProps: {
        color: 'transparent',
      },
    },
    MuiTypography: {
      styleOverrides: {
        h5: {
          fontWeight: 800,
          lineHeight: '2rem',
        },
      },
    },
  },
})

export { applogieTheme }
