import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  deletePaymentByID,
  enableDeleteSubscriptionPaymentDialog,
  selectItemForDeleteSubscriptionPayment,
  updateDeletePaymentClearMsg,
  updateDeletePaymentClearRes,
} from 'reduxStore/account/accountPaymentDeleteByID/deletePaymentByIDSlice'
import CloseIcon from '@mui/icons-material/Close'
import { DeletePaymentBody } from 'reduxStore/account/accountPaymentDeleteByID/Model'
import { fetchAccountPaymentListById } from 'reduxStore/account/accountPaymentList/accountPaymentListByIdSlice'
import { AccountDetailsModelReq } from 'reduxStore/account/accountPaymentList/AccountPaymentListByIdModel'
import { fetchAccountCustomerSpendById } from 'reduxStore/account/accountCustomerSpend/accountCustomerSpendByIdSlice'
import { CustomerBudgetByAccountIdModelReq } from 'reduxStore/account/accountCustomerSpend/accountCustomerSpendModel'
import { fetchAccountCustomerBudgetById } from 'reduxStore/account/accountCustomerBudgetById/accountCustomerBudgetByIdSlice'
import { useParams } from 'react-router-dom'
type Props = {
  itemId?: string | any
  deletePaymentDialogEnable?: boolean | any
  deletePaymentDialogUpdateState: (value: boolean) => void
}

export default function DeletePayment({
  itemId,
  deletePaymentDialogEnable,
  deletePaymentDialogUpdateState,
}: Props) {
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')

  const deletePaymenteByIDRes = useAppSelector((state) => state.deletePaymentByID)

  // Get the userId param from the URL.
  const { id } = useParams()
  const [netErrMsq, setNetErrMsq] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState('')
  const [dialogState, setDialogState] = React.useState(false)
  const [showErrorMsg, setShowErrorMsg] = React.useState('')
  const [showErrorMsgView, setShowErrorMsgView] = React.useState(false)

  const closeDeletepaymentDialog = () => {
    setDialogState(false)
    deletePaymentDialogUpdateState(false)
    dispatch(updateDeletePaymentClearMsg())
    dispatch(updateDeletePaymentClearRes())
    dispatch(enableDeleteSubscriptionPaymentDialog(false))
    dispatch(selectItemForDeleteSubscriptionPayment(''))
  }
  
  const deleteActivePayment = () => {
    if (navigator.onLine) {
      setNetErrMsq('')
      let deletePaymentByIDBody = {
        payment_id: selectedItem,
        token: Token!,
      } as DeletePaymentBody
       dispatch(deletePaymentByID(deletePaymentByIDBody))
    } else {
      setNetErrMsq('You are currently offline')
    }
  }

  const closeAPIResMsg = () => {
    setShowErrorMsgView(false)
    setShowErrorMsg('')
  }

  React.useEffect(() => {
    setSelectedItem(itemId)
    setDialogState(deletePaymentDialogEnable!)
  }, [itemId, deletePaymentDialogEnable])
  
  React.useEffect(() => {
    if (deletePaymenteByIDRes?.succssMessage !== '') {
        dispatch(
          fetchAccountPaymentListById({
            accountId: String(id),
            token: Token,
          } as AccountDetailsModelReq),
        )
        dispatch(
          fetchAccountCustomerSpendById({
            accountId: String(id),
            token: Token,
          } as CustomerBudgetByAccountIdModelReq),
        )
        dispatch(
          fetchAccountCustomerBudgetById({
            accountId: String(id),
            token: Token,
          } as CustomerBudgetByAccountIdModelReq),
        )
        setTimeout(() => {
          closeDeletepaymentDialog()
        }, 100)
      }
    if (deletePaymenteByIDRes?.error !== '') {
      // setUpdatePaymentMsgType('error')
      setShowErrorMsg(deletePaymenteByIDRes?.error)
      setDialogState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deletePaymenteByIDRes])
  return (
    <Box>
      <Dialog open={dialogState} onClose={closeDeletepaymentDialog} aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Are you sure you want to delete this Payment?
            </Typography>
          </DialogContentText>
          {netErrMsq !== '' ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <Typography sx={{ color: '#c62828' }}>{netErrMsq}</Typography>
              </Grid>
            </Grid>
          ) : null}
          <Collapse in={showErrorMsgView}>
            <Alert
              icon={false}
              severity="error"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    closeAPIResMsg()
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
            >
              {showErrorMsg}
            </Alert>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={deleteActivePayment}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography>Yes</Typography>
              </Grid>
              {deletePaymenteByIDRes.loading ? (
                <Grid item>
                  <CircularProgress color="inherit" size={18} />
                </Grid>
              ) : null}
            </Grid>
          </Button>
          <Button variant="outlined" onClick={closeDeletepaymentDialog} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
