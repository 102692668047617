import { Box, CardHeader, Chip, CircularProgress, Grid, Typography } from '@mui/material'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { useParams } from 'react-router-dom'
import { fetchMicrosoftAccountDetailsById } from 'reduxStore/account/microsoft/MicrosoftUserListSlice'
import { AccountDetailsModelReq } from 'reduxStore/account/microsoft/MicrosoftUserModel'
import { UserList } from './Model'
import { checkReturnValue } from 'components/Utility'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { MicrosoftTableColumn } from './MicrosoftTableColumn'

export default function MicrosoftUser() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const microsoftUserList = useAppSelector((state) => state.microsoftUserList)
  const [userList, setUserList] = React.useState([] as UserList[])

  // Get the userId param from the URL.
  let { id } = useParams()

  let accountDetailsByIdBody = {
    accountId: String(id),
    token: Token,
  } as AccountDetailsModelReq

  React.useEffect(() => {
    dispatch(fetchMicrosoftAccountDetailsById(accountDetailsByIdBody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  React.useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      checkReturnValue(microsoftUserList?.accountDetails?.results) !== 'Not Set'
        ? setUserList(
            microsoftUserList?.accountDetails?.results.map((item) => {
              return {
                id: item.id!,
                sku_name: checkReturnValue(item?.sku_name),
                purchased_licenses: item?.purchased_licenses!,
                consumed_licenses: item?.consumed_licenses!,
              }
            }) as UserList[],
          )
        : setUserList([] as UserList[])
    }
  }, [microsoftUserList])

  return (
    <Box sx={{ maxHeight: '545px' }}>
      <CardHeader
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="h6" fontWeight="bold">
                Total User
              </Typography>
            </Grid>
            <Grid item>
              <Chip label={userList.length}></Chip>
            </Grid>
          </Grid>
        }
      />
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        {microsoftUserList.loading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress color="inherit" size={30} />
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ height: '400px', width: '100%' }}>
            <DataGridPremium
              columns={MicrosoftTableColumn()}
              rows={userList}
              disableColumnSelector
              initialState={{
                sorting: {
                  sortModel: [{ field: 'sku_name', sort: 'asc' }],
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              hideFooter
              disableRowGrouping
              getRowHeight={() => 'auto'}
            />
          </Box>
        )}
      </Grid>
    </Box>
  )
}
