import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import MicrosoftImage from '../../images/Microsoft-logo.png'
import { Alert, Grid, IconButton, Typography, Stack } from '@mui/material'
import Url from '../../APIConfig'
import { useParams } from 'react-router-dom'
import CancelIcon from '@mui/icons-material/Cancel'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { MicrosoftUserIdModelReq } from 'reduxStore/account/microsoftIntegration/MicrosoftIntegrationModel'
import { fetchMicrosoftIntegrationById } from 'reduxStore/account/microsoftIntegration/microsofIntegrationSlice'

type Props = {
  microsoftIntDialogEnable?: boolean | any
  microsoftIntDialogUpdateState?: boolean | any
}

export default function MicrosoftIntDialog({
  microsoftIntDialogEnable,
  microsoftIntDialogUpdateState,
}: Props) {
  // Get the userId param from the URL.
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const msClientId = useAppSelector((state) => state.msClientId)
  let microsoftUserClientByIdBody = {
    accountId: String(id),
    token: Token,
  } as MicrosoftUserIdModelReq

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    microsoftIntDialogUpdateState(false)
  }

  React.useEffect(() => {
    dispatch(fetchMicrosoftIntegrationById(microsoftUserClientByIdBody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  function openMSAuth() {
    var state = id
    var client_id = msClientId.accountDetails.applicationId
    var url_params =
      'redirect_uri=' +
      Url.backEndURL +
      '/api-msft-graph-auth-token&state=' +
      state +
      '&client_id=' +
      client_id

    PopupCenter(
      'https://login.microsoftonline.com/organizations/v2.0/adminconsent?nonce=graph_explorer&prompt=select_account&scope=Application.Read.All&User.Read&Directory.AccessAsUser.All&Directory.Read.All&Directory.ReadWrite.All&Mail.Read&profile&User.Read.All&User.ReadBasic.All&User.ReadWrite&User.ReadWrite.All&response_type=token&' +
        url_params,
      'Connect Applogie to Microsoft Graph',
      600,
      600,
    )
    return false
  }

  function PopupCenter(url: string, title: string, w: number, h: number) {
    // Fixes dual-screen position                         Most browsers      Firefox
    var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenLeft //screen.left
    var dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenTop //screen.top

    var width = window.innerWidth
    var height = window.innerHeight

    var left = width / 2 - w / 2 + dualScreenLeft
    var top = height / 2 - h / 2 + dualScreenTop
    var newWindow = window.open(
      url,
      title,
      'scrollbars=yes, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left,
    )

    // Puts focus on the newWindow
    newWindow!.scrollTo(0, 0)
  }

  React.useEffect(() => {
    setDialogState(microsoftIntDialogEnable)
  }, [microsoftIntDialogEnable])

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={dialogState}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction={'row'}>
              <Typography variant="h6">{'Update Integration:'}</Typography>
            </Stack>
            <Stack direction={'row'}>
              <IconButton aria-label="delete" onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <DialogContentText pb={1}>
            <Alert icon={false} color="success">
              Click below and follow the Oauth Steps to grant us read-only access to user
              information.
            </Alert>
          </DialogContentText>
          <Button variant="outlined" onClick={() => openMSAuth()}>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
              <Grid item>
                <img src={MicrosoftImage} width="160" height="60" alt="" />
              </Grid>
              <Grid item>|</Grid>
              <Grid item>
                <Typography>
                  Sign in with <b>Microsoft</b>
                </Typography>
              </Grid>
            </Grid>
          </Button>
        </DialogContent>
      </Dialog>
    </div>
  )
}
