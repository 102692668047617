
import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { hideDocumentResponse, hideDocumentErrorResponse, hideDocumentReq } from './Model'

type InitialState = {
  loading: boolean
  response: hideDocumentResponse
  succssMessage: string
  error: hideDocumentErrorResponse
}
const initialState: InitialState = {
  loading: false,
  response: {} as hideDocumentResponse,
  succssMessage: '',
  error: { message: '' } as hideDocumentErrorResponse,
}

export const hideDocument = createAsyncThunk<
hideDocumentResponse,
hideDocumentReq
>(
  'hideDocument/hideDocument',
  (payload, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return setupInterceptorsTo(axios)
      .post(url.backEndURL + `/api/accountdocument/${payload.id}/hide_document`, payload.body, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) =>
      rejectWithValue(
        error !== undefined
          ? error.response !== undefined
            ? error.response.data !== undefined
            : 'Something went wrong'
          : 'Something went wrong',
      ),
      )
  },
)

const hideDocumentSlice = createSlice({
  name: 'hideDocument',
  initialState,
  reducers: {
    hideDocumentClearMsg: (state) => {
      state.succssMessage = ''
      state.error = {} as hideDocumentErrorResponse
    },
    hideDocumentClearRes: (state) => {
      state.succssMessage = ''
      state.error = {} as hideDocumentErrorResponse
      state.response = {} as hideDocumentResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(hideDocument.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
        hideDocument.fulfilled,
      (state, action: PayloadAction<hideDocumentResponse>) => {
        state.loading = false
        state.response = action.payload
        state.succssMessage =
        action?.payload?.succssMessage !== undefined
          ? action?.payload?.succssMessage !== ''
            ? action?.payload?.succssMessage
            : 'Document Hide Successfully !'
          : 'Document Hide Successfully !'
        state.error = {} as any
      },
    )
    builder.addCase(hideDocument.rejected, (state, action) => {
      state.loading = false
      state.response = {} as hideDocumentResponse
      state.error.message = action.error.message || 'Something went wrong'
    })
  },
})
export const { hideDocumentClearMsg, hideDocumentClearRes } =
hideDocumentSlice.actions
export default hideDocumentSlice.reducer
