import { GridColDef } from '@mui/x-data-grid-premium'

export function MicrosoftTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'sku_name',
      headerName: 'Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
    },
    {
      field: 'purchased_licenses',
      headerName: 'Purchased Licenses',
      sortable: true,
      hideable: false,
      minWidth: 150,
      align: 'center',
    },
    {
      field: 'consumed_licenses',
      headerName: 'Assigned',
      sortable: true,
      hideable: false,
      minWidth: 80,
      align: 'center',
    },
  ]
  return columns
}
