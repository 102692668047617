import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { TagSourceReq, Results } from './Model'

type InitialState = {
    loading: boolean
    tagSource: Results
    error: string
}
const initialState: InitialState = {
    loading: false,
    tagSource: {} as Results,
    error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchTaglist = createAsyncThunk<Results, TagSourceReq>('tagSource/fetchTaglist', (reqParam: TagSourceReq) => {
    let headers = {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
        .get(url.backEndURL + `/api/account-tags-list/`, { headers })
        .then((response) => response.data)
})

const testTagListSlice = createSlice({
    name: 'tagSource',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTaglist.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchTaglist.fulfilled, (state, action: PayloadAction<Results>) => {
            state.loading = false
            state.tagSource = action.payload
            state.error = ''
        })
        builder.addCase(fetchTaglist.rejected, (state, action) => {
            state.loading = false
            state.tagSource = {} as Results
            state.error = action.error.message || 'Something went wrong'
        })
    },
})

export default testTagListSlice.reducer
