import {
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import AddIcon from '@mui/icons-material/Add'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import CancelIcon from '@mui/icons-material/Cancel'
import { useParams } from 'react-router-dom'
import { addTag, addTagClearRes } from 'reduxStore/addTag/tagListByIdSlice'
import { TagDetailsModelReq } from 'reduxStore/addTag/TagListByIdModel'
import { fetchTagListById } from 'reduxStore/tagList/tagListByIdSlice'
import { TagDetailsModelReq as GetTagListByIDReq } from 'reduxStore/addTag/TagListByIdModel'
import ApplogieSnackbar from 'components/ApplogieSnackbar'

export default function TagsList() {
  // Get the userId param from the URL.
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  // Call taglist Store
  const tagListById = useAppSelector((state) => state.tagListById)
  // Call Add Tag Store
  const addTagResponse = useAppSelector((state) => state.addTagRes)

  const [tag, setTag] = React.useState('')

  const [dialogOpen, setDialogOpen] = React.useState(false)
  // open Dialog
  const openDialog = () => {
    setTag('')
    setDialogOpen(false)
    setDialogOpen(true)
  }

  //close Dialog
  const closeDialog = () => {
    setTag('')
    setDialogOpen(false)
    dispatch(addTagClearRes())
    setTagMsg('')
  }

  const showTags = () => {
    return 'tagList' in tagListById
      ? tagListById.tagList !== undefined || tagListById.tagList !== null
        ? 'tags' in tagListById.tagList
          ? tagListById.tagList.tags !== undefined ||
            tagListById.tagList.tags !== null ||
            String(tagListById.tagList.tags).length > 0
            ? tagListById.tagList.tags.map((item: any) => {
                return <Chip label={item} color="success" sx={{ margin: '2px' }} key={item} />
              })
            : null
          : null
        : null
      : null
  }
  // addTag Body
  let body = {
    token: Token!,
    accountId: id,
    tagVal: tag,
  } as TagDetailsModelReq
  //
  let getTagListByIDBody = {
    token: Token!,
    accountId: id,
  } as GetTagListByIDReq
  const addTags = () => {
    if (tag !== '') {
      dispatch(addTag(body))
    }
  }

  React.useEffect(() => {
    //   // eslint-disable-next-line no-lone-blocks
    // handle addTag API success task
    if ('addTagRes' in addTagResponse) {
      if (addTagResponse.addTagRes !== undefined) {
        if (addTagResponse.addTagRes !== null) {
          if ('tags' in addTagResponse.addTagRes) {
            if (addTagResponse.addTagRes.tags !== undefined) {
              if (addTagResponse.addTagRes.tags !== null) {
                if (addTagResponse.addTagRes.tags.length > 0) {
                }
              }
            }
          }
        }
      }
    }
    // handle task add Tag API error block
    if ('addTagRes' in addTagResponse) {
      if (addTagResponse.error !== undefined) {
        if (addTagResponse.error !== '') {
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTagResponse])

  const [tagMsg, setTagMsg] = React.useState('')
  const [tagMsgType, setTagMsgType] = React.useState<AlertColor>('success')
  const closeAddTagSnackbar = () => {
    dispatch(addTagClearRes())
    setTagMsg('')
  }
  React.useEffect(() => {
    if (addTagResponse?.succssMessage !== '') {
      setTagMsgType('success')
      setDialogOpen(false)
      setTagMsg(addTagResponse?.succssMessage)
      dispatch(fetchTagListById(getTagListByIDBody))
    }
    if (addTagResponse?.error !== '') {
      setTagMsg(addTagResponse?.error)
      setTagMsgType('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTagResponse])

  return (
    <Box>
      <Card sx={{ minHeight: '200px' }}>
        <CardHeader
          avatar={<LocalOfferIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction={'row'}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Stack direction={'row'}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Tags
                    </Typography>
                  </Stack>
                  <Stack direction={'row'}>
                    <Chip
                      label={
                        'tagList' in tagListById
                          ? 'tags' in tagListById.tagList
                            ? tagListById.tagList.tags.length
                            : 0
                          : 0
                      }
                    ></Chip>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={'row'}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={openDialog}
                >
                  Add Tag
                </Button>
              </Stack>
            </Stack>
          }
        ></CardHeader>
        <CardContent>
          <Box>{showTags()}</Box>
          <Dialog open={dialogOpen} onClose={closeDialog}>
            <DialogTitle id="alert-dialog-title">
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction={'row'}>
                  <Typography variant="h6">
                    <b>Add Tag:</b>
                  </Typography>
                </Stack>
                <Stack direction={'row'}>
                  <IconButton aria-label="delete" onClick={closeDialog}>
                    <CancelIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Tag Name"
                type="tag"
                fullWidth
                variant="standard"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={addTags} autoFocus variant="contained">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography>Yes</Typography>
                  </Grid>
                  {addTagResponse.loading ? (
                    <Grid item>
                      <CircularProgress color="inherit" size={18} />
                    </Grid>
                  ) : null}
                </Grid>
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
      <ApplogieSnackbar msg={tagMsg} type={tagMsgType} alertClose={closeAddTagSnackbar} />
    </Box>
  )
}
