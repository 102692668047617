import React, { useEffect } from 'react'
import { AlertColor, Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import RefreshIcon from '@mui/icons-material/Refresh'
import ToggleUserStatusDialog from './ToggleUserStatusDialog'
import SuspiciousActivityDialog from './SuspicuousActivity'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import UserLists from './UserList'
import { inactiveUserClearRes } from 'reduxStore/account/inactiveUser/inactiveUserSlice'
import { AllSubscriptionDetailsUserList } from './UserList/AllSubscriptionDetailsUserList'

const theme = createTheme({
  typography: {
    // Tell MUI what the font-size on the html element is.
    htmlFontSize: 20,
  },
})

export default function User() {
  const dispatch = useAppDispatch()
  const accountUserDetailsById = useAppSelector((state) => state.accountUserDetailsById)
  const [value, setValue] = React.useState(0)
  const handleChange = (newValue: number) => {
    setValue(newValue)
    localStorage.setItem('subDetailUserTab', String(newValue))
  }
  const userType = localStorage.getItem('subDetailUserTab')
  useEffect(() => {
    userType !== null ? handleChange(Number(userType)) : handleChange(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [selectedItem, setSelectedItem] = React.useState('')
  const [toggleUserStatusDialog, setToggleUserStatusDialog] = React.useState(false)
  const [showSuspiciousActivityDialog, setSuspiciousActivityDialog] = React.useState(false)
  const updateSuspiciousActivityDialogState = (value: boolean) => {
    setSuspiciousActivityDialog(value)
  }
  const [dialogToggleText, setDialogToggleText] = React.useState('')

  const updateCompanyDetailsDialogValFromChild = (passedVal: boolean) => {
    setToggleUserStatusDialog(passedVal)
  }

  const inActiveUserByIDVal = useAppSelector((state) => state.userInactive)

  const [toggleUserStatusMsgType, setToggleUserStatusType] = React.useState<AlertColor>('success')
  const [toggleUserStatusMsg, setToggleUserStatusMsg] = React.useState('')
  const closeToggleUserStatusSnackbar = () => {
    dispatch(inactiveUserClearRes())
    setToggleUserStatusMsg('')
  }
  useEffect(() => {
    if (inActiveUserByIDVal?.toggleUserStatusId !== '') {
      setSelectedItem(inActiveUserByIDVal?.toggleUserStatusId)
      setToggleUserStatusDialog(true)
      setDialogToggleText(value === 0 ? 'Inactive' : 'Active')
    }
    if (inActiveUserByIDVal?.successMsg !== '') {
      setToggleUserStatusType('success')
      setToggleUserStatusMsg(inActiveUserByIDVal?.successMsg)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inActiveUserByIDVal])
  return (
    <Box sx={{ maxHeight: '590px', bgcolor: '#f2f2f2' }}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
        <Grid item sx={{ bgcolor: '#dadada' }}>
          <Tabs
            value={value}
            onChange={(_event: React.SyntheticEvent, newValue: number) => handleChange(newValue)}
            centered
            TabIndicatorProps={{
              style: {
                backgroundColor: '#01cba3',
              },
            }}
            sx={{
              '& button:hover': { background: '' },
              '& button.Mui-selected': {
                background: '#fffff',
                color: '#01cba3',
              },
            }}
          >
            <Tab label="ACTIVE USERS"></Tab>
            <Tab label="INACTIVE USERS" />
          </Tabs>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Grid item>
              <Button
                variant="contained"
                style={{
                  backgroundColor: '#f53f07',
                  margin: 5,
                }}
                onClick={() => setSuspiciousActivityDialog(true)}
              >
                Suspicious Activity
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}
            p={2}
          >
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {AllSubscriptionDetailsUserList().length}
              </Typography>
              <ThemeProvider theme={theme}>
                <Typography sx={{ fontStyle: 'italic' }}>
                  {value === 0 ? 'Active Users' : 'Inactive Users'}&nbsp;<RefreshIcon></RefreshIcon>
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <b>{<AttachMoneyIcon />}</b>
              <ThemeProvider theme={theme}>
                <Typography>
                  <i>Cost per user (annual)</i>
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <b>{<AttachMoneyIcon />}</b>
              <ThemeProvider theme={theme}>
                <Typography>
                  <i>Cost per user (monthly)</i>
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {accountUserDetailsById?.accountUserDetails?.results?.length}
              </Typography>
              <ThemeProvider theme={theme}>
                <Typography>
                  <i>Total Users</i>
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <b>{<AttachMoneyIcon />}</b>
              <ThemeProvider theme={theme}>
                <Typography>
                  <i>Protential Savings (annual)</i>
                </Typography>
              </ThemeProvider>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <b>{<AttachMoneyIcon />}</b>
              <ThemeProvider theme={theme}>
                <Typography>
                  <i>Protential Savings (monthly)</i>
                </Typography>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} style={{ marginLeft: 15 }}>
            {value === 0 ? 'Active User List' : 'Inactive User List'}
          </Typography>
        </Grid>
        <Grid item></Grid>
      </Grid>
      <Box px={1} sx={{ height: '245px'}}>
        <UserLists />
      </Box>
      {toggleUserStatusDialog ? (
        <ToggleUserStatusDialog
          itemId={selectedItem}
          text={dialogToggleText}
          inActiveUserDialogEnable={toggleUserStatusDialog}
          inActiveUserDialogUpdateState={updateCompanyDetailsDialogValFromChild}
        />
      ) : null}
      {showSuspiciousActivityDialog ? (
        <SuspiciousActivityDialog
          suspiciousActivityDialogEnable={showSuspiciousActivityDialog}
          suspiciousActivityDialogUpdateState={updateSuspiciousActivityDialogState}
        />
      ) : null}
      {toggleUserStatusMsg !== '' && (
        <ApplogieSnackbar
          msg={toggleUserStatusMsg}
          type={toggleUserStatusMsgType}
          alertClose={closeToggleUserStatusSnackbar}
        />
      )}
    </Box>
  )
}
