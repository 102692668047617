import React, { useEffect } from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import { numberWithDecimalCommas } from 'utils/numberWithCommas'
export function TableListData() {
  const optimizationVal = useAppSelector((state) => state.optimization)
  const [optimizationsList, setOptimizationsList] = React.useState([] as any[])
  useEffect(() => {
    // eslint-disable-next-line no-lone-blocks
    {
      if (optimizationVal?.optimizations?.results! !== undefined) {
        let optimizationsObj = optimizationVal?.optimizations?.results!.map((element) => {
          return {
            id: element.id,
            vendorName:
              element.account?.vendor?.name! !== undefined
                ? element.account?.vendor?.name!
                : 'Not Set',
            vendorID: element.account?.id! !== undefined ? element.account?.id! : 0,
            message: element?.message! !== undefined ? element?.message : 'Not Set',
            createDate: element?.from_date! !== undefined ? element?.from_date! : 'Not Set',
            // overage: element?.overage !== undefined ? element?.overage : 'Not Set',
            overage: element?.overage !== undefined ?  numberWithDecimalCommas(Number(element?.overage)) : '$0.00',
            potentialSavings:
              element?.potential_savings !== undefined ? numberWithDecimalCommas(Number(element?.potential_savings)) : '$0.00',
          }
        })
        setOptimizationsList(optimizationsObj)
      }
    }
  }, [optimizationVal])
  return optimizationsList
}
