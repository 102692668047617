import React from 'react'
import { Typography } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import { useNavigate } from 'react-router-dom'
import { TableListData } from '../TableListData'

export function BreachesDetailsTableColumn() {
  const data = TableListData() as  any
  const columns: GridColDef<(typeof data)[number]>[] = [
    {
      field: 'fName',
      headerName: 'First Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Typography
            onClick={() => {
              navigate(`/userdetail/${params.row.userId}`)
            }}
            sx={{ color: '#30a289', '&:hover': { color: '#134872', cursor: 'pointer' } }}
          >
            <b>{params.row.fName}</b>
          </Typography>
        )
      },
    },
    {
      field: 'lName',
      headerName: 'Last Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Typography
            onClick={() => {
              navigate(`/userdetail/${params.row.userId}`)
            }}
            sx={{ color: '#30a289', '&:hover': { color: '#134872', cursor: 'pointer' } }}
          >
            <b>{params.row.lName}</b>
          </Typography>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Typography
            onClick={() => {
              navigate(`/userdetail/${params.row.userId}`)
            }}
            sx={{ color: '#30a289', '&:hover': { color: '#134872', cursor: 'pointer' } }}
          >
            <b>{params.row.email}</b>
          </Typography>
        )
      },
    },
  ]
  return columns
}
