import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { Typography } from '@mui/material'

export function SuspiciousActivityListTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      align: 'left',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Typography
            onClick={() => {
              navigate(`/userdetail/${params.row.id}`)
            }}
            fontWeight={'bold'}
            sx={{ color: '#30a289', '&:hover': { color: '#134872', cursor: 'pointer' } }}
          >
            {params.row.firstName}
          </Typography>
        )
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Typography
            onClick={() => {
              navigate(`/userdetail/${params.row.id}`)
            }}
            fontWeight={'bold'}
            sx={{ color: '#30a289', '&:hover': { color: '#134872', cursor: 'pointer' } }}
          >
            {params.row.lastName}
          </Typography>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      flex: 1,
    },
    {
      field: 'message',
      headerName: 'Message',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      flex: 1,
    },
    {
      field: 'created',
      headerName: 'Created',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>
          {params.row.renewalDate !== null
            ? dayjs(params.row.renewalDate!).format('MMM DD, YYYY')
            : null}
        </React.Fragment>
      ),
    },
    {
      field: 'hide',
      headerName: 'Hidden',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        return <Typography>{params.row.hide ? 'True' : 'False'}</Typography>
      },
    },
  ]
  return columns
}
