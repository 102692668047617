import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Result } from './CustomerModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  customerDetails: Result[]
  error: string
}
const initialState: InitialState = {
  loading: false,
  customerDetails: [] as Result[],
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchCustomerDetails = createAsyncThunk<Result[], string>(
  'customerDetails/fetchCustomerDetails',
  (adminToken) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${adminToken}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + '/api/customers', { headers })
      .then((response) => response.data.results)
  },
)

const customerDetailsSlice = createSlice({
  name: 'customerDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCustomerDetails.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchCustomerDetails.fulfilled, (state, action: PayloadAction<Result[]>) => {
      state.loading = false
      state.customerDetails = action.payload
      state.error = ''
    })
    builder.addCase(fetchCustomerDetails.rejected, (state, action) => {
      state.loading = false
      state.customerDetails = [] as Result[]
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default customerDetailsSlice.reducer
