import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { UpdateUsageByIdResponse, UpdateUsageByIdBody } from './Model'

type InitialState = {
  loading: boolean
  response: any
  successMessage: string
  errorMessage: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as any,
  successMessage: '',
  errorMessage: '',
}

export const updateUsageById = createAsyncThunk<any, UpdateUsageByIdBody>(
  'updateUsageById/updateUsageById',
  async (payload: UpdateUsageByIdBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + `/api/accountlicenseusage/update_usage`, payload.mainBody, {
        headers,
      })
      .then((response) => response.data)
      .catch((errorMessage) =>
        rejectWithValue(
          errorMessage?.response?.data !== undefined
            ? errorMessage?.response?.data[0]?.description !== undefined
              ? errorMessage?.response?.data[0]?.description
              : errorMessage.response.data.message !== undefined
              ? errorMessage.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const updateUsageByIdSlice = createSlice({
  name: 'updateUsageById',
  initialState,
  reducers: {
    updateUsageByIdClearMsg: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
    },
    updateUsageByIdClearRes: (state) => {
      state.successMessage = ''
      state.errorMessage = ''
      state.response = {} as UpdateUsageByIdResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUsageById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      updateUsageById.fulfilled,
      (state, action: PayloadAction<UpdateUsageByIdResponse>) => {
        state.loading = false
        state.response = action.payload
        state.successMessage = 'Usage has been updated successfully'
        state.errorMessage = ''
      },
    )
    builder.addCase(updateUsageById.rejected, (state, action) => {
      state.loading = false
      state.response = {} as UpdateUsageByIdResponse
      state.successMessage = ''
      state.errorMessage = String(action.payload!)
    })
  },
})
export const { updateUsageByIdClearMsg, updateUsageByIdClearRes } = updateUsageByIdSlice.actions

export default updateUsageByIdSlice.reducer
