import React from 'react'
import { IconButton } from '@mui/material'
import {
  GridRenderCellParams,
  gridDetailPanelExpandedRowsContentCacheSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-premium'
import EditIcon from '@mui/icons-material/Edit'
import EditOffIcon from '@mui/icons-material/EditOff'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { useLocation } from 'react-router-dom'

export function CustomDetailPanelToggle(props: Pick<GridRenderCellParams, 'id' | 'value'>) {
  const location = useLocation()
  const { id, value: isExpanded } = props
  const apiRef = useGridApiContext()

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(apiRef, gridDetailPanelExpandedRowsContentCacheSelector)

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id])

  return (
    <IconButton  size="small" tabIndex={-1} disabled={!hasDetail}>
      {isExpanded ? (
        location.pathname === '/breaches' ? (
          <VisibilityOffIcon fontSize="small"/>
        ) : (
          <EditOffIcon  fontSize="small"/>
        )
      ) : location.pathname === '/breaches' ? (
        <VisibilityIcon fontSize="small"/>
      ) : (
        <EditIcon fontSize="small"/>
      )}
    </IconButton>
  )
}
