import { applogieTheme as defaultApplogieTheme } from './index'
import { createTheme, responsiveFontSizes } from '@mui/material/styles'
import type {} from '@mui/x-data-grid-premium/themeAugmentation'

const applogieTheme = createTheme({
  ...defaultApplogieTheme,
  components: {
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          '&.Mui-selected': {
            backgroundColor: '#6feec6', // Change this to your desired color
          },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#d9d9d9',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        outlined: {
          color: '#000000',
          borderColor: '#30a289',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: defaultApplogieTheme.spacing(1),
        },
        indicator: {
          height: 2,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: defaultApplogieTheme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [defaultApplogieTheme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: defaultApplogieTheme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#e0e0e0',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#1D476F',
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: defaultApplogieTheme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          minWidth: 'auto',
          marginRight: defaultApplogieTheme.spacing(2),
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    }
  },
})

const applogieCustomTheme = responsiveFontSizes(applogieTheme)
export default applogieCustomTheme
