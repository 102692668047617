import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { isSubsSectionNameAvailable } from 'components/Utility'

interface SubscriptionDetailsTotalItem {
  title: string
  id: number
  subtitle: string
  isSelected: boolean
}

const subsDetailsListItem: SubscriptionDetailsTotalItem[] = [
  {
    id: 0,
    title: 'Subscription',
    subtitle: 'Details and overview of the Subscription.',
    isSelected: isSubsSectionNameAvailable('Subscription'),
  },
  {
    id: 1,
    title: 'Licenses',
    subtitle: 'Details and overview of the Licenses.',
    isSelected: isSubsSectionNameAvailable('Licenses'),
  },
  {
    id: 2,
    title: 'Subscriotion Charts',
    subtitle: 'Graphical represenations of usages of user.',
    isSelected: isSubsSectionNameAvailable('Subscriotion Charts'),
  },
  {
    id: 3,
    title: 'Payments',
    subtitle: 'Details and overview of the Payments.',
    isSelected: isSubsSectionNameAvailable('Payments'),
  },
  {
    id: 4,
    title: 'Comments',
    subtitle: 'Details and overview of the Comments.',
    isSelected: isSubsSectionNameAvailable('Comments'),
  },
  {
    id: 5,
    title: 'Documents',
    subtitle: 'Details and overview of the Documents.',
    isSelected: isSubsSectionNameAvailable('Documents'),
  },
  {
    id: 6,
    title: 'Vendor',
    subtitle: 'Details and overview of the Vendor.',
    isSelected: isSubsSectionNameAvailable('Vendor'),
  },
  {
    id: 7,
    title: 'Account Agreement',
    subtitle: 'Details of Account Agreements.',
    isSelected: isSubsSectionNameAvailable('Account Agreement'),
  },
  {
    id: 8,
    title: 'Pricing',
    subtitle: 'Details and overview of the Pricing.',
    isSelected: isSubsSectionNameAvailable('Pricing'),
  },
  {
    id: 9,
    title: 'Cancelation',
    subtitle: 'Details and procedures of the Cancelation.',
    isSelected: isSubsSectionNameAvailable('Cancelation'),
  },
  {
    id: 10,
    title: 'Tags',
    subtitle: 'Details and procedures of the Tags.',
    isSelected: isSubsSectionNameAvailable('Tags'),
  },
]

export default function SubscriptionPreference() {
  const [isChecked, setIsChecked] = React.useState<boolean[]>(() =>
    subsDetailsListItem.map((item) => {
      return item.isSelected
    }),
  )
  const isCheckboxChecked = (index: number, checked: boolean, changedOptTitle: string) => {
    subsDetailsListItem.forEach((item, idx) => {
      if (item.title === changedOptTitle) {
        subsDetailsListItem[idx].isSelected = checked // Update the isSelected property in the original array
      }
    })
    setIsChecked((isChecked: boolean[]) => {
      return isChecked.map((c, i) => {
        if (i === index) return checked
        return c
      })
    })
  }

  const handleSubmit = () => {
    let savedList = subsDetailsListItem.map((item) => {
      if (item.isSelected) {
        return item.title
      } else return false
    })
    savedList = savedList.filter((ele) => ele != null)
    const sectionNames = savedList.toString()
    localStorage.setItem('subsSectionName', sectionNames) // Store the updated sectionName in local storage
  }

  const isSectionChecked = React.useCallback(
    (secName: string) => {
      return isSubsSectionNameAvailable(secName)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSubsSectionNameAvailable],
  )
  // Set default checkbox values when the component mounts
  React.useEffect(() => {
    const defaultCheckedValues = subsDetailsListItem.map(
      (item) => isSectionChecked(item.title) ?? false,
    )
    setIsChecked(defaultCheckedValues)
    // Load the section names from local storage and update the subsDetailsListItem array
    const sectionNamesFromLocalStorage = localStorage.getItem('subsSectionName')
    if (sectionNamesFromLocalStorage) {
      const sectionNamesArray = sectionNamesFromLocalStorage.split(',')
      subsDetailsListItem.forEach((item) => {
        item.isSelected = sectionNamesArray.includes(item.title)
      })
    }
  }, [isSectionChecked])
  return (
    <Box px={2}>
      <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Typography variant="h6">Subscription Details Preference</Typography>
        <Box sx={{ bgcolor: '#F2F2F2' }}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            pl={2}
          >
            {subsDetailsListItem.map((checkbox, index) => {
              return (
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} px={3} key={index}>
                  <FormControlLabel
                    key={checkbox.title} // Use a unique key here
                    className="twocolelement"
                    control={
                      <Checkbox
                        name={checkbox.title}
                        value={checkbox.title}
                        id={checkbox.title}
                        checked={isChecked[index]}
                        color="primary"
                        onChange={(e) => isCheckboxChecked(index, e.target.checked, checkbox.title)}
                      />
                    }
                    label={checkbox.title}
                  />
                  <Typography style={{ fontSize: '11px', color: '#134872' }} variant="h6">
                    <b>{checkbox.subtitle}</b>
                  </Typography>
                </Grid>
              )
            })}
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            px={1}
            py={1}
            spacing={2}
          >
            <Grid item>
              <Button
                onClick={() => handleSubmit()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  )
}
