// TabContainer.tsx
import React, { useEffect, useState } from 'react'
import TabComponent from './TabComponent'

import SpendChart from './SpendChart/SpendChart'
import LicenseChart from './LicenseChart/LicenseChart'
import BenchmarkChart from './BenchmarkChart/BenchmarkChart'
import AmazonChart from './Amazon/AmazonChart'
import MicrosoftUser from 'pages/MicrosoftUser/MicrosoftUser'
import GcpChart from './Google/GcpChart'
import User from '../User/User'

interface Props {
  microsoft?: boolean | any
  amazon?: boolean | any
  gcp?: boolean | any
  users?: boolean | any
}

interface TabData {
  label: string
  content?: any
}

export default function TabContainer({ microsoft, amazon, gcp, users }: Props) {
  const [tabs, setTabs] = useState<Record<string, TabData>>({
    tab1: { label: 'Spend', content: <SpendChart /> },
    tab2: { label: 'License', content: <LicenseChart /> },
    tab3: { label: 'Benchmark', content: <BenchmarkChart /> },
    tab4: { label: 'Amazon', content: <AmazonChart /> },
    tab5: { label: 'Microsoft', content: <MicrosoftUser /> },
    tab6: { label: 'GCP', content: <GcpChart /> },
    tab7: { label: 'Users', content: <User /> },
  })

  const [order, setOrder] = useState<string[]>([
    'tab1',
    'tab2',
    'tab3',
    'tab4',
    'tab5',
    'tab6',
    'tab7',
  ])
  useEffect(() => {
    // For Amazon subscription
    if (amazon && !microsoft && !gcp && users) {
      setTabs({
        tab1: { label: 'Spend', content: <SpendChart /> },
        tab2: { label: 'License', content: <LicenseChart /> },
        tab3: { label: 'Benchmark', content: <BenchmarkChart /> },
        tab4: { label: 'Amazon', content: <AmazonChart /> },
        tab7: { label: 'Users', content: <User /> },
      })
      setOrder(['tab1', 'tab2', 'tab4', 'tab3', 'tab7'])
    }
    // For Microsoft subscription
    if (!amazon && microsoft && !gcp && users) {
      setTabs({
        tab1: { label: 'Spend', content: <SpendChart /> },
        tab2: { label: 'License', content: <LicenseChart /> },
        tab3: { label: 'Benchmark', content: <BenchmarkChart /> },
        tab5: { label: 'Microsoft', content: <MicrosoftUser /> },
        tab7: { label: 'Users', content: <User /> },
      })
      setOrder(['tab1', 'tab2', 'tab3', 'tab7', 'tab5'])
    }
    // For Google subscription
    if (!amazon && !microsoft && gcp && !users) {
      setTabs({
        tab1: { label: 'Spend', content: <SpendChart /> },
        tab2: { label: 'License', content: <LicenseChart /> },
        tab6: { label: 'GCP', content: <GcpChart /> },
        tab3: { label: 'Benchmark', content: <BenchmarkChart /> },
      })
      setOrder(['tab1', 'tab2', 'tab6', 'tab3'])
    }
    // For User Tabs
    if (users) {
      // For Salesforce Subscription
      if (!amazon && !microsoft && !gcp && users) {
        setTabs({
          tab1: { label: 'Spend', content: <SpendChart /> },
          tab2: { label: 'License', content: <LicenseChart /> },
          tab3: { label: 'Benchmark', content: <BenchmarkChart /> },
          tab7: { label: 'Users', content: <User /> },
        })
        setOrder(['tab1', 'tab2', 'tab3', 'tab7'])
      }
    }
    if (!amazon && !microsoft && !gcp && !users) {
      setTabs({
        tab1: { label: 'Spend', content: <SpendChart /> },
        tab2: { label: 'License', content: <LicenseChart /> },
        tab3: { label: 'Benchmark', content: <BenchmarkChart /> },
      })
      setOrder(['tab1', 'tab2', 'tab3'])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amazon,microsoft,gcp,users])

  const [value, setValue] = useState<string>('tab1')

  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <>
      {tabs !== undefined ? (
        <TabComponent tabs={tabs} order={order} value={value} handleChange={handleChange} />
      ) : null}

      {/* Render tab content based on the selected tab */}
      {tabs[value].content}
    </>
  )
}
