import React from 'react'
import AppFrame from './components/AppFrame'
import Router from './Routes'
import { LicenseInfo } from '@mui/x-license-pro'
import FirebasePushNotification from './pages/Firebase'
import { HelmetProvider } from 'react-helmet-async'

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_LICENSE_KEY!)
function App() {
  return (
    <div className="App">
      <HelmetProvider>
        <AppFrame />
        <Router />
        <FirebasePushNotification />
      </HelmetProvider>
    </div>
  )
}

export default App
