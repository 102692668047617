import * as React from 'react'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider, Theme } from '@mui/material/styles'
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography,
  Link,
  Box,
  FormControl,
  CircularProgress,
  Input,
  Stack,
  AlertColor,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { LoginBody } from 'reduxStore/login/Model'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'reduxStore/store'
import { login, updateLoginClearRes } from 'reduxStore/login/loginSlice'
import { useAppSelector } from 'reduxStore/hooks'
import ApplogieSnackbar from 'components/ApplogieSnackbar'

declare module '@mui/material' {
  interface DefaultTheme extends Theme { }
}

export default function SignIn() {
  const dispatch = useDispatch<AppDispatch>()
  let navigate = useNavigate()
  const token = localStorage.getItem('token')
  const theme = createTheme({
    palette: {
      primary: {
        main: '#006FBB',
      },
      secondary: {
        main: '#330101',
      },
      text: {
        primary: '#FFFFF',
        secondary: '#006FBB',
      },
    },
  })

  interface EmailState {
    email: string
  }

  const [email, setEmail] = React.useState<EmailState>({
    email: '',
  })

  const handleEmailChange =
    (prop: keyof EmailState) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setEmail({ ...email, [prop]: event.target.value })
    }
  const [emailFieldError, setEmailFieldError] = React.useState(false)
  const emailFieldNullMsg = 'Please complete this required field'
  const emailFieldChange = (e: any) => {
    e.target.value.length > 0 ? setEmailFieldError(false) : setEmailFieldError(true)
  }

  interface State {
    password: string
    showPassword: boolean
  }

  const [password, setPassword] = React.useState<State>({
    password: '',
    showPassword: false,
  })

  const [passwordError, setPasswordError] = React.useState(false)
  const passwordFieldNullMsg = 'Please complete this required field'
  const passwordFieldChange = (e: any) => {
    e.target.value.length > 0 ? setPasswordError(false) : setPasswordError(true)
  }

  const handlePasswordChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword({ ...password, [prop]: event.target.value })
    }

  const handleClickShowPassword = () => {
    setPassword({ ...password, showPassword: !password.showPassword })
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  const handleLoginForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
  }
  const [loginMsgType, setLoginMsgType] = React.useState<AlertColor>('success')
  const closeLoginSnackbar = () => {
    dispatch(updateLoginClearRes())
    setLoginMsg('')
  }

  const loginBody = {
    email_or_username: email.email,
    password: password.password,
  } as LoginBody

  //handle button click of login form
  const handleLogin = async () => {
    if (loginBody.email_or_username === '' && loginBody.password === '') {
      setEmailFieldError(true)
      setPasswordError(true)
    } else if (loginBody.email_or_username !== '' && loginBody.password === '') {
      setEmailFieldError(false)
      setPasswordError(true)
    } else if (loginBody.email_or_username === '' && loginBody.password !== '') {
      setEmailFieldError(true)
      setPasswordError(false)
    } else {
      setEmailFieldError(false)
      setPasswordError(false)
      dispatch(login(loginBody))
    }
  }

  const [loginMsg, setLoginMsg] = React.useState('')

  // navigate to dashboard page on login success
  if (token) {
    setTimeout(() => {
      navigate('/dashboard')
      dispatch(updateLoginClearRes())
      setLoginMsg('')
    }, 100)
  }

  // Call Login store
  const loginDetails = useAppSelector((state) => state.login)
  React.useEffect(() => {
    if (loginDetails?.successMsg !== '') {
      setLoginMsgType('success')
      setLoginMsg(loginDetails?.successMsg)
    }
    if (loginDetails?.error !== '') {
      setLoginMsg(loginDetails?.error)
      setLoginMsgType('error')
    }
  }, [loginDetails])

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justifyContent="center"
        columns={{ xs: 4, sm: 8, md: 12 }}
        p={1}
      >
        <Grid item xs={4} sm={12} md={4}>
          <Card>
            <CardContent>
              <CssBaseline />
              <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                Log In
              </Typography>
              <Box component="form" onSubmit={handleLoginForm} noValidate sx={{ mt: 1 }}>
                <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                  <InputLabel htmlFor="standard-emailFieldID">Username</InputLabel>
                  <Input
                    id="emailFieldID"
                    required
                    value={email.email}
                    error={emailFieldError}
                    onChange={handleEmailChange('email')}
                    onBlur={(e) => emailFieldChange(e)}
                    fullWidth
                  />
                  {emailFieldError === true ? (
                    <Typography style={{ color: '#d32f2f' }} sx={{ fontSize: 13 }}>
                      {emailFieldNullMsg}
                    </Typography>
                  ) : null}
                </FormControl>
                <FormControl variant="standard" fullWidth sx={{ mb: 2 }}>
                  <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
                  <Input
                    id="standard-adornment-password"
                    fullWidth
                    required
                    type={password.showPassword ? 'text' : 'password'}
                    value={password.password}
                    error={passwordError}
                    onChange={handlePasswordChange('password')}
                    onBlur={(e) => passwordFieldChange(e)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {password.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {passwordError === true ? (
                    <Typography style={{ color: '#d32f2f' }} sx={{ fontSize: 13 }}>
                      {passwordFieldNullMsg}
                    </Typography>
                  ) : null}
                </FormControl>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="medium"
                      sx={{ mt: 1, mb: 1 }}
                      onClick={() => {
                        handleLogin()
                      }}
                    >
                      {loginDetails.loading === true ? (
                        <Stack direction="row" spacing={1}>
                          <Typography> log In</Typography>
                          <Box pl={1}>
                            <CircularProgress color="inherit" size={18} />
                          </Box>
                        </Stack>
                      ) : (
                        'Log In'
                      )}
                    </Button>
                  </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
                  <Grid item>
                    <Link underline="always" onClick={() => navigate('/forgotten-password')} style={{ cursor: 'pointer' }}>
                      {'Forgot Password'}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <ApplogieSnackbar msg={loginMsg} type={loginMsgType} alertClose={closeLoginSnackbar} />
    </ThemeProvider>
  )
}
