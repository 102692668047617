import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { AccAgreementRes, AddAccAgrementBody } from 'reduxStore/AddAccountAgrementDetails/Model'

type InitialState = {
  loading: boolean
  addAccAgreementRes: AccAgreementRes
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  addAccAgreementRes: {} as AccAgreementRes,
  succssMessage: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const addAccAgreement = createAsyncThunk<AccAgreementRes, AddAccAgrementBody>(
  'addAccAgreement/addAccAgreement',
  (payload, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return setupInterceptorsTo(axios)
      .post(url.backEndURL + `/api/accountagreementdetails?account=${payload.id}`, payload.body, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data[0]?.description !== undefined
              ? error?.response?.data[0]?.description
              : error.response.data.message !== undefined
              ? error.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const addAccountAgreementDetailsSlice = createSlice({
  name: 'addAccAgreement',
  initialState,
  reducers: {
    accountAgreementClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    accountAgreementClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.addAccAgreementRes = {} as AccAgreementRes
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addAccAgreement.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addAccAgreement.fulfilled, (state, action: PayloadAction<AccAgreementRes>) => {
      state.loading = false
      state.addAccAgreementRes = action.payload
      state.succssMessage =
        action?.payload?.succssMessage !== undefined
          ? action?.payload?.succssMessage !== ''
            ? action?.payload?.succssMessage
            : 'Account Agreement Details Edited successfully'
          : 'Account Agreement Details Edited successfully'
      state.error = ''
    })
    builder.addCase(addAccAgreement.rejected, (state, action) => {
      state.loading = false
      state.addAccAgreementRes = {} as AccAgreementRes
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})

export const { accountAgreementClearMsg, accountAgreementClearRes } =
  addAccountAgreementDetailsSlice.actions
export default addAccountAgreementDetailsSlice.reducer
