import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Autocomplete,
  Box,
  IconButton,
  Typography,
} from '@mui/material'
import CancelIcon from '@mui/icons-material/Cancel'
import * as React from 'react'
import { Result } from 'reduxStore/subcription/PaymentVendorList/Model'
import TestSource from 'pages/Subscription/Active/TestSource/TestSource'
import PaymentMode from 'pages/Subscription/Active/PaymentMode/PaymentMode'
import UploadFile from 'pages/Subscription/Active/UploadFile/UploadFile'
import TextComponent from 'pages/Subscription/Active/TestComponent/TextComponent'

type Props = {
  dialogEnable?: boolean | any
  updatedSuccessMsgState?: boolean | any
  paymentVendorValue?: {} | any
}

export default function ConnectData({
  dialogEnable,
  updatedSuccessMsgState,
  paymentVendorValue,
}: Props) {
  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    updatedSuccessMsgState(false)
  }
  const [totalPaymentVendorValue, setPaymentVendorItems] = React.useState([] as Result[])

  const [selectedPaymentVendorId, setSelectedPaymentendorId] = React.useState(0)
  const [selectedPaymentVendorShName, setSelectedPaymentendorShName] = React.useState('')
  const [dropdownchangedValue, setDropDownChangedValue] = React.useState(false)
  const [fileValue, setFileValue] = React.useState(true)
  const filterVendor = (paymentvendorname: any, _e: any) => {
    setSelectedPaymentendorId(paymentvendorname.id)
    setSelectedPaymentendorShName(paymentvendorname.short_name)
    setDropDownChangedValue(true)
    setFileValue(false)
  }

  const updateSuccessMsgFromChild = (passedval: boolean) => {
    // the callback. Use a better name
    setDropDownChangedValue(passedval)
  }

  const updateFileUploadFromChild = (passedval: boolean) => {
    // the callback. Use a better name
    setFileValue(passedval)
  }

  let uploadbody = {
    payment_vendor: selectedPaymentVendorId,
    payment_description: selectedPaymentVendorShName,
  }

  React.useEffect(() => {
    setDialogState(dialogEnable)
    setSelectedPaymentendorId(3)
    setSelectedPaymentendorShName('NS')
  }, [dialogEnable, paymentVendorValue])

  React.useEffect(() => {
    if ('paymentVendorList' in paymentVendorValue) {
      if ('results' in paymentVendorValue.paymentVendorList) {
        setPaymentVendorItems(paymentVendorValue.paymentVendorList.results)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalPaymentVendorValue, updateSuccessMsgFromChild, dropdownchangedValue])

  return (
    <Dialog onClose={handleClose} open={dialogState} fullWidth maxWidth="md">
      <DialogTitle>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" fontWeight="bold">
              Add Subscription
            </Typography>
          </Grid>
          <Grid item>
            <IconButton onClick={() => handleClose()}>
              <CancelIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <Grid
          container
          direction="column"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={2}
        >
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Autocomplete
              value={
                totalPaymentVendorValue !== undefined
                  ? totalPaymentVendorValue.find(
                      (element: any) => element.id === selectedPaymentVendorId,
                    ) || null
                  : null
              }
              id="payment-states"
              options={totalPaymentVendorValue}
              onChange={(event: any, newValue: any) => {
                filterVendor(newValue, event)
              }}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Payment Vendor" variant="standard" />
              )}
              getOptionLabel={(option: any) => option.display_name}
              isOptionEqualToValue={(option: any, value) => option.id === value.id}
            />
          </Grid>
          {selectedPaymentVendorShName === 'SP' ||
          selectedPaymentVendorShName === 'IN' ||
          selectedPaymentVendorShName === 'NS' ? null : (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                  <Box mt={5}>
                    <Grid item>
                      <PaymentMode selectedPaymentVendorShName={selectedPaymentVendorShName} />
                    </Grid>
                  </Box>
                  <Box>
                    <Box mt={5}>
                      <Grid item>
                        <TestSource selectedPaymentVendorShName={selectedPaymentVendorShName} />
                      </Grid>
                    </Box>
                    <Box mt={5}>
                      <Grid item>
                        <TextComponent selectedPaymentVendorShName={selectedPaymentVendorShName} />
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <UploadFile
              selectedPaymentVendorShName={selectedPaymentVendorShName}
              uploadbody={uploadbody}
              dropdownchangedValue={dropdownchangedValue}
              updatedSuccessMsgState={updateSuccessMsgFromChild}
              fileValue={fileValue}
              updatedFileUploadState={updateFileUploadFromChild}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
