import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Typography,
} from '@mui/material'
import TuneIcon from '@mui/icons-material/Tune'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  generateDemoPreference,
  generateDemoPreferenceClearMsg,
  generateDemoPreferenceClearRes,
} from 'reduxStore/customer/DemoPreference/GenerateDemoPreference/generateDemoPreferenceSlice'
import {
  GenerateDemoPreferenceBody,
  MainBody,
} from 'reduxStore/customer/DemoPreference/GenerateDemoPreference/Model'
import dayjs from 'dayjs'
export function DemoPreference() {
  const monthList = [
    {
      label: 'January',
      value: 1,
    },
    {
      label: 'February',
      value: 2,
    },
    {
      label: 'March',
      value: 3,
    },
    {
      label: 'April',
      value: 4,
    },
    {
      label: 'May',
      value: 5,
    },
    {
      label: 'June',
      value: 6,
    },
    {
      label: 'July',
      value: 7,
    },
    {
      label: 'August',
      value: 8,
    },
    {
      label: 'September',
      value: 9,
    },
    {
      label: 'October',
      value: 10,
    },
    {
      label: 'November',
      value: 11,
    },
    {
      label: 'December',
      value: 12,
    },
  ] as any[]

  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  let getIndividualUsageUpdate = localStorage.getItem('allowIndividualSpendUpdate')
  const generateDemoPreferenceRes = useAppSelector((state) => state.generateDemoPreferenceRes)
  let checkUserCategory = JSON.parse(localStorage.getItem('demoUser')!)
  const [generateDemoPreferenceMsg, setGenerateDemoPreferenceMsg] = useState('')
  const [generateDemoPreferenceMsgType, setGenerateDemoPreferenceMsgType] =
    useState<AlertColor>('success')

  useEffect(() => {
    if (generateDemoPreferenceRes?.generateDemoPreferenceRes?.message !== undefined) {
      setGenerateDemoPreferenceMsgType('success')
      setGenerateDemoPreferenceMsg(generateDemoPreferenceRes?.generateDemoPreferenceRes?.message)
    }
    if (generateDemoPreferenceRes?.error !== '') {
      setGenerateDemoPreferenceMsgType('error')
      setGenerateDemoPreferenceMsg(generateDemoPreferenceRes?.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateDemoPreferenceRes])

  const generateDataPayload = {
    month: monthList.find((e) => e.value === dayjs(new Date()).month() + 1).label, //selectedMonth !== null ? "November" : null,
    year: String(dayjs(new Date()).year()), //selectedYear !== null ? "2023" : null,
  } as MainBody
  const generateData = () => {
    dispatch(
      generateDemoPreference({
        id: '1',
        body: generateDataPayload,
        token: Token!,
      } as GenerateDemoPreferenceBody),
    )
    // }
  }

  const [allowIndividualUsageUpdate, setAllowIndividualUsageUpdate] = useState(true)
  const handleAllowIndividualUsageUpdateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAllowIndividualUsageUpdate(event.target.checked)
    localStorage.setItem('allowIndividualSpendUpdate', String(event.target.checked))
  }
  useEffect(() => {
    getIndividualUsageUpdate !== null
      ? getIndividualUsageUpdate === 'true'
        ? setAllowIndividualUsageUpdate(true)
        : setAllowIndividualUsageUpdate(false)
      : setAllowIndividualUsageUpdate(false)
  }, [getIndividualUsageUpdate])
  const [enableCheckDemoUser, setEnableCheckDemoUser] = useState(false)
  useEffect(() => {
    checkUserCategory !== null
      ? checkUserCategory
        ? setEnableCheckDemoUser(checkUserCategory)
        : setEnableCheckDemoUser(false)
      : setEnableCheckDemoUser(false)
  }, [checkUserCategory])
  return (
    <Card>
      <CardHeader
        avatar={<TuneIcon sx={{ fontWeight: 'bold' }} />}
        title={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Demo Preference
                  </Typography>
                </Grid>
                <Grid item>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={allowIndividualUsageUpdate}
                          disabled={enableCheckDemoUser ? false : true}
                          onChange={handleAllowIndividualUsageUpdateChange}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label="Allow account wise usage update"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => generateData()}
              >
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>Generate Monthly Demo Data</Grid>
                  {generateDemoPreferenceRes?.loading ? (
                    <Grid item>
                      <CircularProgress size={15} style={{ color: '#ffffff' }} />
                    </Grid>
                  ) : null}
                </Grid>
              </Button>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid container direction="column" justifyContent="flex-start" alignItems="stretch">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            {generateDemoPreferenceMsg !== '' ? (
              <Box my={2}>
                <Alert
                  severity={generateDemoPreferenceMsgType}
                  onClose={() => {
                    dispatch(generateDemoPreferenceClearMsg())
                    dispatch(generateDemoPreferenceClearRes())
                    setGenerateDemoPreferenceMsg('')
                  }}
                >
                  {generateDemoPreferenceMsg}
                </Alert>
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}