import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  FormControlLabel,
  Alert,
} from '@mui/material'
import { isSectionNameAvailable } from 'components/Utility'
import * as React from 'react'
interface TotalItem {
  title: string
  id: number
  subtitle: string
  isSelected: boolean
}

const totalItem: TotalItem[] = [
  {
    id: 1,
    title: 'Alerts',
    subtitle: 'Details and overview of the alerts.',
    isSelected: isSectionNameAvailable('Alerts'),
  },
  {
    id: 2,
    title: 'Summary',
    subtitle: 'Details and overview of the summary.',
    isSelected: isSectionNameAvailable('Summary'),
  },
  {
    id: 3,
    title: 'Charts',
    subtitle: 'Graphical representations of usages of user.',
    isSelected: isSectionNameAvailable('Charts'),
  },
]

type Props = {
  dialogEnable?: boolean | any
  updatedDialogState?: boolean | any
}

export default function DashboardDialog({ dialogEnable, updatedDialogState }: Props) {
  const [dialogState, setDialogState] = React.useState(false)
  const [showErrorMsg, setShowErrorMsg] = React.useState('')
  const [isChecked, setIsChecked] = React.useState<boolean[]>(() =>
    totalItem.map((item) => {
      return item.isSelected
    }),
  )
  const isCheckboxChecked = (index: number, checked: boolean, changedOptTitle: string) => {
    totalItem.forEach((item, idx) => {
      if (item.title === changedOptTitle) {
        totalItem[idx].isSelected = checked // Update the isSelected property in the original array
      }
    })
    setIsChecked((isChecked: boolean[]) => {
      return isChecked.map((c, i) => {
        if (i === index) return checked
        return c
      })
    })
  }

  const handleClose = () => {
    setDialogState(false)
    updatedDialogState(false)
  }

  const handleSubmit = () => {
    let savedList = totalItem.map((item) => {
      if (item.isSelected) {
        return item.title
      } else return false
    })
    savedList = savedList.filter((ele) => ele != null)
    const sectionNames = savedList.toString()

    if (savedList.every((val) => val === false)) {
      setShowErrorMsg("You can't hide all cards")
    } else {
      setShowErrorMsg('')
      localStorage.setItem('sectionName', sectionNames) // Store the updated sectionName in local storage
      updatedDialogState(false)
    }
  }

  React.useEffect(() => {
    setDialogState(dialogEnable)
  }, [dialogEnable])

  const isSectionChecked = React.useCallback(
    (secName: string) => {
      return isSectionNameAvailable(secName)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSectionNameAvailable],
  )
  // Set default checkbox values when the component mounts
  React.useEffect(() => {
    const defaultCheckedValues = totalItem.map((item) => isSectionChecked(item.title) ?? false)
    setIsChecked(defaultCheckedValues)
    // Load the section names from local storage and update the totalItem array
    const sectionNamesFromLocalStorage = localStorage.getItem('sectionName')
    if (sectionNamesFromLocalStorage) {
      const sectionNamesArray = sectionNamesFromLocalStorage.split(',')
      totalItem.forEach((item) => {
        item.isSelected = sectionNamesArray.includes(item.title)
      })
    }
  }, [isSectionChecked])

  return (
    <Dialog
      open={dialogState}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '1080px', // Set your width here
          },
        },
      }}
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent dividers>
        <Box pb={3}>
          <Typography variant="subtitle1">
            Choose the cards that you would like displayed on the details screen:
          </Typography>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          pl={2}
          mb={1}
        >
          {totalItem.map((checkbox, index) => {
            return (
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={4}
                sx={{ border: 1, borderColor: '#134872' }}
                key={index}
              >
                <FormControlLabel
                  key={checkbox.title} // Use a unique key here
                  className="twocolelement"
                  control={
                    <Checkbox
                      name={checkbox.title}
                      value={checkbox.title}
                      id={checkbox.title}
                      checked={isChecked[index]}
                      color="primary"
                      onChange={(e) => isCheckboxChecked(index, e.target.checked, checkbox.title)}
                    />
                  }
                  label={
                    <Grid
                      container
                      direction="column"
                      justifyContent="flex-start"
                      alignItems="stretch"
                    >
                      <Grid item>{checkbox.title}</Grid>
                      <Grid item>
                        <Typography style={{ fontSize: '11px', color: '#134872' }} variant="h6">
                          <b>{checkbox.subtitle}</b>
                        </Typography>{' '}
                      </Grid>
                    </Grid>
                  }
                />
              </Grid>
            )
          })}
        </Grid>
        {showErrorMsg !== '' && <Alert severity="error">{showErrorMsg}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="contained" onClick={() => handleSubmit()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
