import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { UserListByTagSearchReq, UserListbyTagSearchRes, UserListByTagSearchErrRes } from './Model'

type InitialState = {
  loading: boolean
  userListByOrgTag: UserListbyTagSearchRes[]
  error: UserListByTagSearchErrRes
}
const initialState: InitialState = {
  loading: false,
  userListByOrgTag: [] as UserListbyTagSearchRes[],
  error: { message: '' } as UserListByTagSearchErrRes,
}

// Generates pending, fulfilled and rejected action types
export const fetchUserListByOrgTag = createAsyncThunk<
  UserListbyTagSearchRes[],
  UserListByTagSearchReq
>(
  'fetchUserListByOrgTag/fetchUserListByOrgTag',
  (reqParam: UserListByTagSearchReq, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/orguser-tags-search/${reqParam.name}/${reqParam.status}/`, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data))
  },
)

const userListByOrgTagSlice = createSlice({
  name: 'fetchUserListByOrgTag',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserListByOrgTag.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchUserListByOrgTag.fulfilled,
      (state, action: PayloadAction<UserListbyTagSearchRes[]>) => {
        state.loading = false
        state.userListByOrgTag = action.payload
        state.error = {} as any
      },
    )
    builder.addCase(fetchUserListByOrgTag.rejected, (state, action) => {
      state.loading = false
      state.userListByOrgTag = [] as UserListbyTagSearchRes[]
      state.error.message = action.error.message! || 'Something went wrong'
    })
  },
})

export default userListByOrgTagSlice.reducer
