import React, { useState, useEffect } from 'react'
import {
  AlertColor,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import ApartmentIcon from '@mui/icons-material/Apartment'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  updateCustomerDetailsClearRes,
  updateCustomerDetailsId,
} from 'reduxStore/updateCustomerDetailsById/updateCustomerDetailsByIdSlice'
import {
  Name,
  UpdateCustomerDetailsIdBody,
} from 'reduxStore/updateCustomerDetailsById/updateCustomerDetailsByIdModel'
import { fetchCustomerDetails } from 'reduxStore/customer/customer/customerSlice'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { checkReturnValue } from 'components/Utility'

export default function CompanyDetails() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const accountDetailsById = useAppSelector((state) => state.customerDetails)
  const updateAccountDetailsById = useAppSelector((state) => state.updateCustomerDetailsById)

  const [customerName, setCustomerName] = useState('')
  const [customerId, setCustomerId] = useState(0)

  const [apiMsgType, setAPIMsgType] = useState<AlertColor>('success')
  const [showCompanydetailsMsg, setShowCompanydetailsMsg] = useState('')
  const closeCompanyDetailsSnackbar = () => {
    dispatch(updateCustomerDetailsClearRes())
    setShowCompanydetailsMsg('')
  }

  useEffect(() => {
    if (updateAccountDetailsById?.succssMessage !== '') {
      setAPIMsgType('success')
      setShowCompanydetailsMsg(updateAccountDetailsById?.succssMessage)
    }
    if (updateAccountDetailsById?.error !== '') {
      if (updateAccountDetailsById?.error) {
        setAPIMsgType('error')
        setShowCompanydetailsMsg(updateAccountDetailsById?.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAccountDetailsById])

  useEffect(() => {
    setCustomerName(
      checkReturnValue(accountDetailsById?.customerDetails[0]) !== 'Not Set'
        ? accountDetailsById.customerDetails[0].name
        : '',
    )
    setCustomerId(
      checkReturnValue(accountDetailsById?.customerDetails[0]) !== 'Not Set'
        ? accountDetailsById.customerDetails[0].id
        : 0,
    )
  }, [accountDetailsById])

  const [errorName, setErrorName] = useState('')

  // Update Company Name Req Body
  let name = {
    name: customerName,
  } as Name
  let UpdateCustmerName = {
    id: String(customerId),
    name: name,
    token: Token!,
  } as UpdateCustomerDetailsIdBody
  // Call API
  const updateCompanyName = async () => {
    if (customerName === '') {
      setErrorName('Name should not be empty')
    } else {
      await setErrorName('')
      await dispatch(updateCustomerDetailsId(UpdateCustmerName))
      await dispatch(fetchCustomerDetails(Token!))
    }
  }

  return (
    <Box>
      <Card sx={{ minHeight: '270px', maxHeight: '320px' }}>
        <CardHeader
          avatar={<ApartmentIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Stack direction={'row'}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Company Details
                </Typography>
              </Stack>
            </Stack>
          }
        />
        <CardContent>
          {accountDetailsById.loading === true ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="stretch"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  id="companyDetailsName-id"
                  label="Name"
                  variant="standard"
                  fullWidth
                  value={customerName}
                  onChange={(e) => {
                    setCustomerName(e.target.value)
                  }}
                />
                {errorName !== '' && (
                  <Typography variant="body1" sx={{ color: '#d32f2f' }}>
                    {errorName}
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </CardContent>
        <CardActions>
          <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
            <Grid item xs={12} sm={12} md={1} lg={1} xl={1}>
              <Button
                variant="contained"
                onClick={() => updateCompanyName()}
                fullWidth
                disabled={updateAccountDetailsById.loading}
              >
                <Typography>Save</Typography>
                {updateAccountDetailsById.loading && (
                  <Box pl={2}>
                    <CircularProgress color="inherit" size={18} />
                  </Box>
                )}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>
      <ApplogieSnackbar
        msg={showCompanydetailsMsg}
        type={apiMsgType}
        alertClose={closeCompanyDetailsSnackbar}
      />
    </Box>
  )
}
