import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { DeleteLicenseResponse, DeleteLicenseBody } from './Model'

type InitialState = {
  loading: boolean
  response: DeleteLicenseResponse
  succssMessage: string
  error: string
  enableDialog: boolean
  selectedItem: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as DeleteLicenseResponse,
  succssMessage: '',
  error: '',
  enableDialog: false,
  selectedItem: '',
}

export const deleteLicenseByID = createAsyncThunk<DeleteLicenseResponse, DeleteLicenseBody>(
  'deleteLicenseByID/deleteLicenseByID',
  async (payload: DeleteLicenseBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .delete(Url.backEndURL + `/api/accountlicense/${payload.id}`, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data[0]?.description !== undefined
              ? error?.response?.data[0]?.description
              : error.response.data.message !== undefined
              ? error.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const deleteLicenseByIDSlice = createSlice({
  name: 'deleteLicenseByID',
  initialState,
  reducers: {
    deleteLicenseClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    deleteLicenseClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.response = {} as DeleteLicenseResponse
    },
    enableDeleteLicenseDialog: (state, action) => {
      state.enableDialog = action.payload
    },
    selectItemForDeleteLicense: (state, action) => {
      state.selectedItem = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteLicenseByID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      deleteLicenseByID.fulfilled,
      (state, action: PayloadAction<DeleteLicenseResponse>) => {
        state.loading = false
        state.response = action.payload
        state.succssMessage =
          action?.payload?.succssMessage !== undefined
            ? action?.payload?.succssMessage !== ''
              ? action?.payload?.succssMessage
              : 'License deleted successfully'
            : 'License deleted successfully'
        state.error = ''
      },
    )
    builder.addCase(deleteLicenseByID.rejected, (state, action) => {
      state.loading = false
      state.response = {} as DeleteLicenseResponse
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})
export const {
  deleteLicenseClearMsg,
  deleteLicenseClearRes,
  enableDeleteLicenseDialog,
  selectItemForDeleteLicense,
} = deleteLicenseByIDSlice.actions

export default deleteLicenseByIDSlice.reducer
