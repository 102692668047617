import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import {
  GetAccountAgreementDetailsReq,
  GetAccountAgreementDetails,
} from 'reduxStore/account/accountAgreement/getAccountAgreementDetails/accountCustomerBudgetByIdModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  getAccountAgreementDetailsRes: GetAccountAgreementDetails
  error: string
}
const initialState: InitialState = {
  loading: false,
  getAccountAgreementDetailsRes: {} as GetAccountAgreementDetails,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchAccountAgreementDetailsById = createAsyncThunk<
  GetAccountAgreementDetails,
  GetAccountAgreementDetailsReq
>(
  'accountAgreementDetailsById/fetchAccountAgreementDetailsById',
  (reqParam: GetAccountAgreementDetailsReq, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/accountagreementdetails?account=${reqParam.accountId}`, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data.message || 'Something went wrong'))
  },
)

const accountAgreementDetailsByIdSlice = createSlice({
  name: 'accountAgreementDetailsById',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountAgreementDetailsById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchAccountAgreementDetailsById.fulfilled,
      (state, action: PayloadAction<GetAccountAgreementDetails>) => {
        state.loading = false
        state.getAccountAgreementDetailsRes = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchAccountAgreementDetailsById.rejected, (state, action) => {
      state.loading = false
      state.getAccountAgreementDetailsRes = {} as GetAccountAgreementDetails
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default accountAgreementDetailsByIdSlice.reducer
