import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { AccountDetailsModel, AccountDetailsModelReq } from './accountDetailsModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  accountDetails: AccountDetailsModel
  error: string
}
const initialState: InitialState = {
  loading: false,
  accountDetails: {} as AccountDetailsModel,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchAccountDetailsById = createAsyncThunk<AccountDetailsModel, AccountDetailsModelReq>(
  'vendorDeptDetails/fetchAccountDetailsById',
  (reqParam: AccountDetailsModelReq) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/account/${reqParam.accountId}`, { headers })
      .then((response) => response.data)
  },
)

const accountDetailsSlice = createSlice({
  name: 'vendorDeptDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAccountDetailsById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchAccountDetailsById.fulfilled,
      (state, action: PayloadAction<AccountDetailsModel>) => {
        state.loading = false
        state.accountDetails = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchAccountDetailsById.rejected, (state, action) => {
      state.loading = false
      state.accountDetails = {} as AccountDetailsModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default accountDetailsSlice.reducer
