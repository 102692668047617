import { Autocomplete, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { updateSubscriptionListItemValue } from 'reduxStore/selectSubscriptionForSetDept/selectSubscriptionForSetDeptSlice'
export default function SelectedActionItems() {
  const dispatch = useAppDispatch()
  const [selectedActionItem, setSelectedActionItem] = useState<any | null>(null)
  const actionItemChange = (item: any | null) => {
    setSelectedActionItem(item)
    if (item !== null) {
      let itemVal = item.value
      dispatch(updateSubscriptionListItemValue(itemVal))
    }
  }
  const selectSubscriptionForSetDeptRes = useAppSelector(
    (state) => state.selectSubscriptionForSetDeptRes,
  )

  const subsDepartmentValue = useAppSelector((state) => state.subsdepartment)
  const companyUserList = useAppSelector((state) => state.companyUserList)
  const subsStatusValue = useAppSelector((state) => state.subscriptionstatus)

  const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)

  const [statusList, setStatusList] = useState([] as any[])
  useEffect(() => {
    subsStatusValue?.status?.results !== undefined
      ? subsStatusValue?.status?.results.length > 0
        ? setStatusList(
            subsStatusValue?.status?.results.map((statusItem) => ({
              label: statusItem.name,
              value: statusItem.id,
            })),
          )
        : setStatusList([] as any[])
      : setStatusList([] as any[])
  }, [subsStatusValue])

  const [ownerList, setOwnerList] = useState([] as any[])
  useEffect(() => {
    companyUserList?.companyUserList?.results !== undefined
      ? companyUserList?.companyUserList?.results.length > 0
        ? setOwnerList(
            companyUserList?.companyUserList?.results.map((person) => ({
              label: person?.user?.username !== undefined ? person?.user?.username : '',
              value: person.id,
            })),
          )
        : setOwnerList([] as any[])
      : setOwnerList([] as any[])
  }, [companyUserList])

  const [departmentList, setDepartmentList] = useState([] as any[])
  useEffect(() => {
    subsDepartmentValue?.department?.results !== undefined
      ? subsDepartmentValue?.department?.results.length > 0
        ? setDepartmentList(
            subsDepartmentValue?.department?.results.map((dept) => ({
              label: dept?.name !== undefined ? dept?.name : '',
              value: dept.id,
            })),
          )
        : setDepartmentList([] as any[])
      : setDepartmentList([] as any[])
  }, [subsDepartmentValue])

  return (
    <Autocomplete
      options={
        selectSubscriptionForSetDeptRes?.updateType === 1
          ? ownerList
          : selectSubscriptionForSetDeptRes?.updateType === 2
          ? departmentList
          : selectSubscriptionForSetDeptRes?.updateType === 4
          ? statusList
          : ([] as any[])
      }
      value={selectedActionItem}
      onChange={(_event, value: any) => actionItemChange(value)}
      disableClearable={true as any}
      disabled={editSubscriptionValue?.loading}
      getOptionLabel={(option) => option.label}
      isOptionEqualToValue={(option: any, value) => option.value === value.value}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{pb:1}}
          label={`Select ${
            selectSubscriptionForSetDeptRes?.updateType === 1
              ? 'Owner'
              : selectSubscriptionForSetDeptRes?.updateType === 2
              ? 'Department'
              : selectSubscriptionForSetDeptRes?.updateType === 4
              ? 'Status'
              : 'Item'
          }`}
          variant="standard"
          size="small"
          fullWidth
        />
      )}
    />
  )
}
