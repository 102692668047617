import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../APIConfig'
import { UpdatePasswordReqBody, UpdatePasswordResponse } from './UpdatePasswordModel'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'

type InitialState = {
  loading: boolean
  response: UpdatePasswordResponse
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as UpdatePasswordResponse,
  succssMessage: '',
  error: '',
}

export const updatePassword = createAsyncThunk<UpdatePasswordResponse, UpdatePasswordReqBody>(
  'updatePassword/updatePassword',
  async (payload: UpdatePasswordReqBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .put(Url.backEndURL + '/api/user/changepassword/', payload.payload, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data !== undefined
            ? error.response.data.message !== undefined
              ? error.response.data.message
              : 'Something went wrong'
            : 'Something went wrong',
        ),
      )
  },
)

const updatePasswordSlice = createSlice({
  name: 'updatePassword',
  initialState,
  reducers: {
    updatePasswordClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    updatePasswordClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.response = {} as UpdatePasswordResponse
    },
  },

  extraReducers: (builder) => {
    builder.addCase(updatePassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      updatePassword.fulfilled,
      (state, action: PayloadAction<UpdatePasswordResponse>) => {
        state.loading = false
        state.response = action.payload
        state.succssMessage =
          action?.payload?.status !== undefined
            ? action?.payload?.status !== ''
              ? action?.payload?.status
              : 'Password changed succesfully'
            : 'Password changed succesfully'
        state.error = ''
      },
    )
    builder.addCase(updatePassword.rejected, (state, action) => {
      state.loading = false
      state.response = {} as UpdatePasswordResponse
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})
export const { updatePasswordClearMsg, updatePasswordClearRes } = updatePasswordSlice.actions

export default updatePasswordSlice.reducer
