import React, { useState, useEffect, useCallback } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import ReportIcon from '@mui/icons-material/Report'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { BreachesList } from './Model'
import { BreachesByUserIDBody, Result } from 'reduxStore/users/breaches/Model'
import dayjs from 'dayjs'
import { DataGridPremium, DataGridPremiumProps, GridRowParams } from '@mui/x-data-grid-premium'
import { BreachesTableColumn } from './BreachesTableColumn'
import ExpandBreaches from './ExpandBreaches'
import { checkReturnValue } from 'components/Utility'
import { useParams } from 'react-router-dom'
import { fetchBreachesByUserID } from 'reduxStore/users/breaches/breachesByUserID'

export default function Breaches() {
  // Get the userId param from the URL.
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  let breachesByIdBody = {
    userId: String(id),
    token: Token,
  } as BreachesByUserIDBody

  const breachesListById = useAppSelector((state) => state.brachesByUserID)
  const [breachList, setBreachList] = useState([] as BreachesList[])
  console.log("breachList", breachList)

  useEffect(() => {
    setBreachList(
      checkReturnValue(breachesListById?.response?.results) !== 'Not Set'
        ? breachesListById?.response?.results.map((item: Result) => ({
            id: 'id' in item ? item.id : 0,
            name: checkReturnValue(item?.vendor_name) !== 'Not Set' ? item.vendor_name : null,
            date:
              checkReturnValue(item?.breach_date) !== 'Not Set'
                ? dayjs(item.breach_date).format('ddd, DD MMM YYYY')
                : null,
            description:
              checkReturnValue(item?.description) !== 'Not Set' ? item.description : null,
            dataList: checkReturnValue(item?.data_classes) !== 'Not Set' ? item.data_classes : null,
          }))
        : ([] as any[]),
    )
  }, [breachesListById])

  function DetailPanelContent({ row }: { row: BreachesList }) {
    return <ExpandBreaches item={row} />
  }

  const getDetailPanelContent = useCallback(
    ({ row }: GridRowParams) => <DetailPanelContent row={row} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridPremiumProps['getDetailPanelHeight']>
  >(() => 'auto' as const, [])
  return (
    <Card sx={{ minHeight: '200px' }}>
      <CardHeader
        avatar={<ReportIcon sx={{ fontWeight: 'bold' }} />}
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Accounts
              </Typography>
            </Grid>
            <Grid item>
              <Chip
                label={
                  checkReturnValue(breachesListById.response.results) !== 'Not Set'
                    ? breachesListById.response.results.length
                    : 0
                }
              />
            </Grid>
            <Grid item>
              <IconButton onClick={() => dispatch(fetchBreachesByUserID(breachesByIdBody))}>
                <RefreshIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {breachesListById.loading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress color="inherit" size={30} />
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ height: '30vh' }}>
            <DataGridPremium
              columns={BreachesTableColumn()}
              rows={breachList}
              getDetailPanelHeight={getDetailPanelHeight}
              getDetailPanelContent={getDetailPanelContent}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'name', sort: 'asc' }],
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              hideFooter
              disableRowGrouping
              disableAggregation
              disableRowSelectionOnClick
              // loading={userDetailsById.loading}
              localeText={{ noRowsLabel: 'No Breaches available' }}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
