import { Box, Switch } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React from 'react'
import dayjs from 'dayjs'
import { useAppDispatch } from 'reduxStore/hooks'
import { updateUserStatus } from 'reduxStore/account/inactiveUser/inactiveUserSlice'

export function UserListTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      align: 'left',
      flex: 1,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      flex: 1,
    },
    {
      field: 'isActive',
      headerName: 'Active',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        return (
          <Switch
            checked={
              params.row.isActive !== null || params.row.isActive !== undefined
                ? params.row.isActive
                : false
            }
            onClick={() => {
              dispatch(updateUserStatus(params.row.id))
            }}
          />
        )
      },
    },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>
          {params.row.renewalDate !== null
            ? dayjs(params.row.renewalDate!).format('MMM DD, YYYY')
            : null}
        </Box>
      ),
    },
  ]
  return columns
}
