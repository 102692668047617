import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material'
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-premium'
import React from 'react'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import {
  budgetProgress,
  budgetProgressColor,
  formatCash,
  getBarBackgroundColor,
  isBarNormalBackground,
} from 'components/Utility'
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from 'reduxStore/hooks'
import { fetchOptimizationsDetailsByAccID } from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationSlice'
import { OptimizationsDetailsByAccIdBody } from 'reduxStore/optimizations/optimizationsDetailsbyAccId/optimizationModel'

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
}))

export function SubscriptionTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'alert',
      headerName: 'Alert',
      sortable: true,
      hideable: true,
      width: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        const Token = localStorage.getItem('token')
        let mainBody = {
          id: params.row.accountId,
          token: Token!,
        } as OptimizationsDetailsByAccIdBody
        return (
          <Box>
            {params.row.alert > 0 ? (
              <IconButton
                size="small"
                onClick={() => {
                  dispatch(fetchOptimizationsDetailsByAccID(mainBody))
                }}
              >
                <AccessAlarmIcon fontSize="small" />
              </IconButton>
            ) : null}
          </Box>
        )
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      hideable: false,
      minWidth: 180,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Button
            variant="text"
            style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
            onClick={() => {
              navigate(`/detail/${params.row.accountId}`)
            }}
          >
            {params.row.aliasName !== '---' ? params.row.aliasName : params.row.name}
          </Button>
        )
      },
    },
    {
      field: 'account',
      headerName: 'Account',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
    },
    {
      field: 'modifiedRenewalDate',
      headerName: 'Renewal Date',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>{params.row.modifiedRenewalDate !== null && params.row.modifiedRenewalDate}</Box>
      ),
    },
    {
      field: 'userEmail',
      headerName: 'Owner',
      sortable: true,
      hideable: true,
      minWidth: 180,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <>{ params.row.userEmail !== 'Not Set' && params.row.userEmail && <Button
          href={`mailto:${
            params.row.userEmail !== 'Not Set' && params.row.userEmail
          }`}
          style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
        >
          {params.row.userName}
        </Button>}</>
      ),
    },
    {
      field: 'department',
      headerName: 'Department',
      sortable: true,
      hideable: true,
      minWidth: 180,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>{params.row.department !== 'Not Set' ? params.row.department : null}</Box>
      ),
    },
    {
      field: 'yearlyBudget',
      headerName: '12 Months',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box sx={{ lineHeight: 'normal', height: '100%', display: 'grid', alignItems: 'center' }}>
          {params.row.yearlyBudget !== 'Budget Not Set' && (
            <React.Fragment>
              <Typography sx={{ fontSize: '11px' }}>
                {params.row.yearlySpend !== null
                  ? formatCash(Math.floor(Number(params.row.yearlySpend))) + ' of'
                  : ''}{' '}
                {params.row.yearlyBudget !== 'Budget Not Set'
                  ? ` ` + formatCash(Math.floor(Number(params.row.yearlyBudget))) + ''
                  : null}
              </Typography>
              <BorderLinearProgress
                sx={
                  isBarNormalBackground(
                    Number(params.row.yearlySpend),
                    Number(params.row.yearlyBudget),
                  )
                    ? {
                        backgroundColor: getBarBackgroundColor(
                          budgetProgressColor(
                            Number(params.row.yearlySpend),
                            Number(params.row.yearlyBudget),
                          ),
                        ),
                      }
                    : undefined
                }
                variant="determinate"
                color={budgetProgressColor(
                  Number(params.row.yearlySpend),
                  Number(params.row.yearlyBudget),
                )}
                value={
                  params.row.yearlySpend !== 0 && params.row.yearlyBudget !== 'Budget Not Set'
                    ? budgetProgress(
                        Number(params.row.yearlySpend),
                        Number(params.row.yearlyBudget),
                      )
                    : 0
                }
              />
              <Typography sx={{ fontSize: '11px', fontWeight: 'bold', color: 'gray' }}>
                {params.row.yearlySpend !== 0 && params.row.yearlyBudget !== 'Budget Not Set'
                  ? params.row.yearlySpend! > params.row.yearlyBudget! ||
                    params.row.yearlySpend! < params.row.yearlyBudget! ||
                    params.row.yearlySpend! === params.row.yearlyBudget!
                    ? Math.ceil(
                        Number((params.row.yearlySpend! * 100) / params.row.yearlyBudget!),
                      ) + '%'
                    : '0%'
                  : '0%'}
              </Typography>
            </React.Fragment>
          )}
        </Box>
      ),
    },
    {
      field: 'quarterlyBudget',
      headerName: 'Quarterly',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box
          sx={{
            lineHeight: 'normal',
            width: '100%',
            height: '100%',
            display: 'grid',
            alignItems: 'center',
          }}
        >
          {params.row.quarterlyBudget !== 'Budget Not Set' && (
            <React.Fragment>
              <Typography sx={{ fontSize: '11px' }}>
                {params.row.quarterlySpend !== null
                  ? formatCash(Math.floor(Number(params.row.quarterlySpend))) + ' of'
                  : ''}{' '}
                {params.row.quarterlyBudget !== 'Budget Not Set'
                  ? ` ` + formatCash(Math.floor(Number(params.row.quarterlyBudget))) + ''
                  : null}
              </Typography>
              <BorderLinearProgress
                sx={
                  isBarNormalBackground(
                    Number(params.row.quarterlySpend),
                    Number(params.row.quarterlyBudget),
                  )
                    ? {
                        backgroundColor: getBarBackgroundColor(
                          budgetProgressColor(
                            Number(params.row.quarterlySpend),
                            Number(params.row.quarterlyBudget),
                          ),
                        ),
                      }
                    : undefined
                }
                variant="determinate"
                color={budgetProgressColor(
                  Number(params.row.quarterlySpend),
                  Number(params.row.quarterlyBudget),
                )}
                value={
                  params.row.quarterlySpend !== 0 && params.row.quarterlyBudget !== 'Budget Not Set'
                    ? budgetProgress(
                        Number(params.row.quarterlySpend),
                        Number(params.row.quarterlyBudget),
                      )
                    : 0
                }
              />
              <Typography sx={{ fontSize: '11px', fontWeight: 'bold', color: 'gray' }}>
                {params.row.quarterlySpend !== 0 && params.row.quarterlyBudget !== 'Budget Not Set'
                  ? params.row.quarterlySpend! > params.row.quarterlyBudget! ||
                    params.row.quarterlySpend! < params.row.quarterlyBudget! ||
                    params.row.quarterlySpend! === params.row.quarterlyBudget!
                    ? Math.ceil(
                        Number((params.row.quarterlySpend! * 100) / params.row.quarterlyBudget!),
                      ) + '%'
                    : '0%'
                  : '0%'}
              </Typography>
            </React.Fragment>
          )}
        </Box>
      ),
    },
    {
      field: 'monthlyBudget',
      headerName: 'Monthly',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box
          sx={{
            lineHeight: 'normal',
            width: '100%',
            height: '100%',
            display: 'grid',
            alignItems: 'center',
          }}
        >
          {params.row.monthlyBudget !== 'Budget Not Set' && (
            <React.Fragment>
              <Typography sx={{ fontSize: '11px' }}>
                {params.row.monthlySpend !== null
                  ? formatCash(Math.floor(Number(params.row.monthlySpend))) + ' of'
                  : ''}{' '}
                {params.row.monthlyBudget !== 'Budget Not Set'
                  ? ` ` + formatCash(Math.floor(Number(params.row.monthlyBudget))) + ''
                  : null}
              </Typography>
              <BorderLinearProgress
                sx={
                  isBarNormalBackground(
                    Number(params.row.monthlySpend),
                    Number(params.row.monthlyBudget),
                  )
                    ? {
                        backgroundColor: getBarBackgroundColor(
                          budgetProgressColor(
                            Number(params.row.monthlySpend),
                            Number(params.row.monthlyBudget),
                          ),
                        ),
                      }
                    : undefined
                }
                variant="determinate"
                color={budgetProgressColor(
                  Number(params.row.monthlySpend),
                  Number(params.row.monthlyBudget),
                )}
                value={
                  params.row.monthlySpend !== 0 && params.row.monthlyBudget !== 'Budget Not Set'
                    ? budgetProgress(
                        Number(params.row.monthlySpend),
                        Number(params.row.monthlyBudget),
                      )
                    : 0
                }
              />
              <Typography sx={{ fontSize: '11px', fontWeight: 'bold', color: 'gray' }}>
                {params.row.monthlySpend !== 0 && params.row.monthlyBudget !== 'Budget Not Set'
                  ? params.row.monthlySpend! > params.row.monthlyBudget! ||
                    params.row.monthlySpend! < params.row.monthlyBudget! ||
                    params.row.monthlySpend! === params.row.monthlyBudget!
                    ? Math.ceil(
                        Number((params.row.monthlySpend! * 100) / params.row.monthlyBudget!),
                      ) + '%'
                    : '0%'
                  : '0%'}
              </Typography>
            </React.Fragment>
          )}
        </Box>
      ),
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      hideable: true,
      minWidth: 60,
      align: 'left',
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'center',
      minWidth: 80,
      headerName: 'Action',
      sortable: false,
      hideable: false,
      renderCell: (params: GridCellParams) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
      renderHeader: (_params: GridColumnHeaderParams) => <span>{'Action'}</span>,
    },
  ]
  return columns
}
