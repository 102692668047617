import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Optimization } from './optimizationModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  optimizations: Optimization
  error: string
}
const initialState: InitialState = {
  loading: false,
  optimizations: {} as Optimization,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchOptimization = createAsyncThunk<Optimization, string>('braches/spends', (adminToken) => {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Token ${adminToken}`,
  }
  return setupInterceptorsTo(axios)
    .get(url.backEndURL + '/api/alert?cost_based=true', { headers })
    .then((response) => response.data)
})

const fetchOptimizationSlice = createSlice({
  name: 'monthlyVendorSpend',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOptimization.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchOptimization.fulfilled, (state, action: PayloadAction<Optimization>) => {
      state.loading = false
      state.optimizations = action.payload
      state.error = ''
    })
    builder.addCase(fetchOptimization.rejected, (state, action) => {
      state.loading = false
      state.optimizations = {} as Optimization
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default fetchOptimizationSlice.reducer
