import React from 'react'
import { Box, Grid } from '@mui/material'
import { BreachesList } from 'pages/Breaches/Model'

interface Props {
  item: BreachesList
}
export default function ExpandBreaches({ item }: Props) {
  return (
    <Box sx={{ margin: 1 }}>
      <Grid container direction="row" justifyContent="left" alignItems="left">
        <Grid item>
          <b>Breaches Descriptions</b>
          <Box mt={1} dangerouslySetInnerHTML={{ __html: item.description }} />
        </Grid>
      </Grid>
    </Box>
  )
}
