import * as React from 'react'
import { useAppSelector, useAppDispatch } from '../reduxStore/hooks'
import {
  Box,
  CardContent,
  Typography,
  Chip,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  CircularProgress,
  AlertColor,
} from '@mui/material'
import CardHeader from '@mui/material/CardHeader'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import { Results } from 'reduxStore/alert/Active/Model'
import { HideAlertReq } from 'reduxStore/alert/Hide/Model'
import { hideAlerts, updateHideAlertClearRes } from 'reduxStore/alert/Hide/hideAlertSlice'
import Accordians from './Accordians'
import { fetchAlerts, updateAlertClearMsg } from 'reduxStore/alert/Active/alertSlice'
import ApplogieSnackbar from './ApplogieSnackbar'

type Props = {
  alertSuccessMessage?: (message: string) => void
}

export const AlertView = ({ alertSuccessMessage }: Props) => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  React.useEffect(() => {
    dispatch(fetchAlerts(Token!))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const alert = useAppSelector((state) => state.alert)

  const [alertListErrorMsg, setAlertListErrorMsg] = React.useState('')
  const handleClose = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(updateAlertClearMsg())
    setAlertListErrorMsg('')
  }
  // for alert list api call
  React.useEffect(() => {
    // success-msg toast working
    // if (alert?.successMsg !== '') {
    //   setHideAlertMsgType('success')
    //   setAlertListErrorMsg(alert?.successMsg)
    // }
    if (alert?.error !== '') {
      setHideAlertMsgType('error')
      setAlertListErrorMsg(alert?.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alert])

  const [hideItem, setHideItem] = React.useState(0)

  // get data from child component
  const selectedItem = (passedval: any) => {
    // the callback. Use a better name
    handleAlertViewModalOpen(passedval)
  }
  // Modal
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [alertViewModalOpen, setAlertViewModalOpen] = React.useState(false)
  // Open Modal Action
  const handleAlertViewModalOpen = (id: number) => {
    id !== undefined ? setAlertViewModalOpen(true) : setAlertViewModalOpen(false)
    // setSelectedItem(id)
    setHideItem(id)
  }

  const hideAlert = async () => {
    let hideModalParam = {
      id: String(hideItem),
      token: Token!,
    } as HideAlertReq
    await dispatch(hideAlerts(hideModalParam))
  }
  const hideAlertRes = useAppSelector((state) => state.hideAlert)
  const [hideAlertMsg, setHideAlertMsg] = React.useState('')
  const [hideAlertMsgType, setHideAlertMsgType] = React.useState<AlertColor>('success')
  const closeAddUserSnackbar = () => {
    dispatch(updateHideAlertClearRes())
    setHideAlertMsg('')
  }
  // for alert hide api call
  React.useEffect(() => {
    if (hideAlertRes?.successMsg !== '') {
      setHideAlertMsgType('success')
      setHideAlertMsg(hideAlertRes?.successMsg)
      alertSuccessMessage && alertSuccessMessage(hideAlertRes?.successMsg)
      setAlertViewModalOpen(false)
      dispatch(fetchAlerts(Token!))
    }
    if (hideAlertRes?.error !== '') {
      setHideAlertMsgType('error')
      setHideAlertMsg(hideAlertRes?.error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideAlertRes])

  // Close Modal Action
  const handleAlertViewModalClose = () => {
    setAlertViewModalOpen(false)
  }

  return (
    <Box>
      <Box>
        <CardHeader
          avatar={<AccessAlarmIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={1}
            >
              <Grid item>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  Alerts
                </Typography>
              </Grid>
              <Grid item>
                <Chip
                  label={
                    'alerts' in alert ? (alert.alerts !== undefined ? alert.alerts.length : 0) : 0
                  }
                  sx={{ fontWeight: 'bold' }}
                ></Chip>
              </Grid>
            </Grid>
          }
        />
        <CardContent>
          {alert.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ maxHeight: '340px', overflow: 'auto' }}>
              {alert.alerts.length > 0 ? (
                alert.alerts.map((item: Results) => (
                  <Box p={1} key={item.id}>
                    <Accordians item={item} selectedItem={selectedItem} />
                  </Box>
                ))
              ) : (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item>
                    <Typography variant="subtitle1">No content available</Typography>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
        </CardContent>
      </Box>
      <Dialog
        fullScreen={fullScreen}
        open={alertViewModalOpen}
        onClose={handleAlertViewModalClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          <DialogContentText>
            <strong>Are you sure you want to hide this alert for all users?</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => hideAlert()}
            disabled={hideAlertRes?.loading}
          >
            {hideAlertRes?.loading ? 'Loading...' : 'Hide Alert'}
          </Button>
          <Button
            variant="outlined"
            disabled={hideAlertRes?.loading}
            onClick={handleAlertViewModalClose}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ApplogieSnackbar msg={alertListErrorMsg} type={hideAlertMsgType} alertClose={handleClose} />
      <ApplogieSnackbar
        msg={hideAlertMsg}
        type={hideAlertMsgType}
        alertClose={closeAddUserSnackbar}
      />
    </Box>
  )
}
