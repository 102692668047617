import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Results, PaymentVendorListReq } from '../PaymentVendorList/Model'
import url from 'APIConfig'

type InitialState = {
    loading: boolean
    paymentVendorList: Results
    error: string
}
const initialState: InitialState = {
    loading: false,
    paymentVendorList: {} as Results,
    error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchPaymentVendorList = createAsyncThunk<Results, PaymentVendorListReq>('paymentVendorList/fetchPaymentVendorList', (reqParam: PaymentVendorListReq) => {
    let headers = {
        Accept: 'application/json',
        'Content-type': 'application/json',
        Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
        .get(url.backEndURL + `/api/paymentvendorlist/?results=${reqParam.resultCount}`, { headers })
        .then((response) => response.data)
})

const paymentVendorListSlice = createSlice({
    name: 'paymentVendorList',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchPaymentVendorList.pending, (state) => {
            state.loading = true
        })
        builder.addCase(fetchPaymentVendorList.fulfilled, (state, action: PayloadAction<Results>) => {
            state.loading = false
            state.paymentVendorList = action.payload
            state.error = ''
        })
        builder.addCase(fetchPaymentVendorList.rejected, (state, action) => {
            state.loading = false
            state.paymentVendorList = {} as Results
            state.error = action.error.message || 'Something went wrong'
        })
    },
})

export default paymentVendorListSlice.reducer
