import React from 'react'
import { useAppSelector } from 'reduxStore/hooks'
import { DataGridPremium } from '@mui/x-data-grid-premium'
import { SuspiciousActivityListTableColumn } from './SuspiciousActivityListTableColumn'
import { AllSuspiciousActivityList } from './AllSuspiciousActivityList'

export default function SuspiciousActivityList() {
  const customerPaymentList = useAppSelector((state) => state.customerPaymentList)
  const accountUserDetailsById = useAppSelector((state) => state.accountUserDetailsById)

  return (
    <DataGridPremium
      columns={SuspiciousActivityListTableColumn()}
      rows={AllSuspiciousActivityList()}
      initialState={{
        sorting: {
          sortModel: [{ field: 'firstName', sort: 'asc' }],
        },
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      hideFooter
      disableRowGrouping
      disableAggregation
      disableRowSelectionOnClick
      loading={accountUserDetailsById.loading || customerPaymentList?.loading}
      
    />
  )
}
