import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../../APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { AddCommentResponse, AddCommentErrorResponse, AddCommentBody } from './addCommentModel'

type InitialState = {
  loading: boolean
  response: AddCommentResponse
  succssMessage: string
  error: AddCommentErrorResponse
}
const initialState: InitialState = {
  loading: false,
  response: {} as AddCommentResponse,
  succssMessage: '',
  error: { message: '' } as AddCommentErrorResponse,
}

export const addComment = createAsyncThunk<AddCommentResponse, AddCommentBody>(
  'addComment/addComment',
  async (payload: AddCommentBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + `/api/accountcomment`, payload.updateBody, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data[0].detail))
  },
)

const addCommentSlice = createSlice({
  name: 'addComment',
  initialState,
  reducers: {
    addCommentClearMsg: (state) => {
      state.succssMessage = ''
      state.error = {} as AddCommentErrorResponse
    },
    addCommentClearRes: (state) => {
      state.succssMessage = ''
      state.error = {} as AddCommentErrorResponse
      state.response = {} as AddCommentResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addComment.pending, (state) => {
      state.loading = true
    })
    builder.addCase(addComment.fulfilled, (state, action: PayloadAction<AddCommentResponse>) => {
      state.loading = false
      state.response = action.payload
      state.succssMessage =
        action?.payload?.succssMessage !== undefined
          ? action?.payload?.succssMessage !== ''
            ? action?.payload?.succssMessage
            : 'Comment added successful'
          : 'Comment added successful'
      state.error = {} as any
    })
    builder.addCase(addComment.rejected, (state, action) => {
      state.loading = false
      state.response = {} as AddCommentResponse
      state.error.message = action.error.message || 'Something went wrong'
    })
  },
})
export const { addCommentClearMsg, addCommentClearRes } = addCommentSlice.actions
export default addCommentSlice.reducer
