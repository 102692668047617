import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { useParams } from 'react-router-dom'
import { MicrosoftUsageDetailsModelReq } from 'reduxStore/users/microsoftUsage/Model'
import { fetchMicrosoftUsageDetailsById } from 'reduxStore/users/microsoftUsage/microsoftUsageSlice'
import { MicrosoftUsageList } from '../../userDetailsModel'
import dayjs from 'dayjs'
import { checkReturnValue } from 'components/Utility'
import { DataGridPremium, GridCellParams, GridColDef } from '@mui/x-data-grid-premium'

export default function MicrosoftUsage() {
  // Get the userId param from the URL.
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const microsoftUsageById = useAppSelector((state) => state.microsoftUsageById)
  const [microsoftUsageList, setMicrosoftUsageList] = useState([] as MicrosoftUsageList[])

  let microsoftUsageDetailsByIdBody = {
    userId: String(id),
    token: Token,
  } as MicrosoftUsageDetailsModelReq

  useEffect(() => {
    dispatch(fetchMicrosoftUsageDetailsById(microsoftUsageDetailsByIdBody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  useEffect(() => {
    if (checkReturnValue(microsoftUsageById?.userDetails?.results) !== 'Not Set') {
      let ms_data = []
      for (var i = 0; i < microsoftUsageById.userDetails.results.length; i++) {
        ms_data.push({
          id: 1,
          product: 'Exchange',
          last_activity_date: microsoftUsageById.userDetails.results[i].exchange_last_activity_date,
          license_assign_date:
            microsoftUsageById.userDetails.results[i].exchange_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].exchange_has_license,
        })
        ms_data.push({
          id: 2,
          product: 'One Drive',
          last_activity_date:
            microsoftUsageById.userDetails.results[i].one_drive_last_activity_date,
          license_assign_date:
            microsoftUsageById.userDetails.results[i].one_drive_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].one_drive_has_license,
        })
        ms_data.push({
          id: 3,
          product: 'Sharepoint',
          last_activity_date:
            microsoftUsageById.userDetails.results[i].sharepoint_last_activity_date,
          license_assign_date:
            microsoftUsageById.userDetails.results[i].sharepoint_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].sharepoint_has_license,
        })
        ms_data.push({
          id: 4,
          product: 'Skype for Business',
          last_activity_date: microsoftUsageById.userDetails.results[i].skype_last_activity_date,
          license_assign_date: microsoftUsageById.userDetails.results[i].skype_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].skype_has_license,
        })
        ms_data.push({
          id: 5,
          product: 'Yammer',
          last_activity_date: microsoftUsageById.userDetails.results[i].yammer_last_activity_date,
          license_assign_date: microsoftUsageById.userDetails.results[i].yammer_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].yammer_has_license,
        })
        ms_data.push({
          id: 6,
          product: 'Teams',
          last_activity_date: microsoftUsageById.userDetails.results[i].teams_last_activity_date,
          license_assign_date: microsoftUsageById.userDetails.results[i].teams_license_assign_date,
          has_license: microsoftUsageById.userDetails.results[i].teams_has_license,
        })
      }
      setMicrosoftUsageList(ms_data)
    } else setMicrosoftUsageList([] as any[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microsoftUsageById])

  const columns: GridColDef[] = [
    {
      field: 'product',
      headerName: 'Product',
      sortable: true,
      hideable: false,
      minWidth: 120,
      align: 'left',
      flex: 1,
    },
    {
      field: 'last_activity_date',
      headerName: 'Last Activity',
      sortable: true,
      hideable: false,
      minWidth: 120,
      flex: 1,
      align: 'left',
    },
    {
      field: 'license_assign_date',
      headerName: 'license Assign',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>
          {params.row.renewalDate !== null
            ? dayjs(params.row.renewalDate!).format('ddd, DD MMM YYYY h:mm:ss')
            : null}
        </React.Fragment>
      ),
    },
    {
      field: 'last_login',
      headerName: 'Last Login',
      sortable: true,
      hideable: true,
      minWidth: 200,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>
          {params.row.renewalDate !== null
            ? dayjs(params.row.renewalDate!).format('ddd, DD MMM YYYY h:mm:ss')
            : null}
        </React.Fragment>
      ),
    },
    {
      field: 'has_license',
      headerName: 'Has License?',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <React.Fragment>{params.row.has_license ? 'True' : 'False'}</React.Fragment>
      ),
    },
  ]

  return (
    <Card>
      <CardHeader
        avatar={<SwapVerticalCircleIcon sx={{ fontWeight: 'bold' }} />}
        title={
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Microsoft Usage
              </Typography>
            </Grid>
            <Grid item>
              <Chip label={microsoftUsageList.length} />
            </Grid>
            <Grid item>
              <IconButton
                onClick={() =>
                  dispatch(fetchMicrosoftUsageDetailsById(microsoftUsageDetailsByIdBody))
                }
              >
                <RefreshIcon />
              </IconButton>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        {microsoftUsageById.loading ? (
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <CircularProgress color="inherit" size={30} />
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ height: '33vh' }}>
            <DataGridPremium
              columns={columns}
              rows={microsoftUsageList}
              initialState={{
                sorting: {
                  sortModel: [{ field: 'product', sort: 'asc' }],
                },
              }}
              slotProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
              }}
              hideFooter
              disableRowGrouping
              disableAggregation
              disableRowSelectionOnClick
              localeText={{ noRowsLabel: 'No Microsoft Usage Details available' }}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  )
}
