import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import {
  GenerateDemoPreferenceRes,
  GenerateDemoPreferenceBody,
} from 'reduxStore/customer/DemoPreference/GenerateDemoPreference/Model'

type InitialState = {
  loading: boolean
  generateDemoPreferenceRes: GenerateDemoPreferenceRes
  error: string
}
const initialState: InitialState = {
  loading: false,
  generateDemoPreferenceRes: {} as GenerateDemoPreferenceRes,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const generateDemoPreference = createAsyncThunk<
  GenerateDemoPreferenceRes,
  GenerateDemoPreferenceBody
>('generateDemoPreference/generateDemoPreference', (payload, { rejectWithValue }) => {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Token ${payload.token}`,
  }
  return setupInterceptorsTo(axios)
    .post(url.backEndURL + `/api/process-demodata/`, payload.body, {
      headers,
    })
    .then((response) => response.data)
    .catch((error) =>
      rejectWithValue(
        error?.response?.data?.message !== undefined
          ? error?.response?.data?.message
          : 'Something went wrong',
      ),
    )
})

const generateDemoPreferenceSliceSlice = createSlice({
  name: 'generateDemoPreference',
  initialState,
  reducers: {
    generateDemoPreferenceClearMsg: (state) => {
      state.error = ''
    },
    generateDemoPreferenceClearRes: (state) => {
      state.error = ''
      state.generateDemoPreferenceRes = {} as GenerateDemoPreferenceRes
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateDemoPreference.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      generateDemoPreference.fulfilled,
      (state, action: PayloadAction<GenerateDemoPreferenceRes>) => {
        state.loading = false
        state.generateDemoPreferenceRes = action.payload
        state.error = ''
      },
    )
    builder.addCase(generateDemoPreference.rejected, (state, action) => {
      state.loading = false
      state.generateDemoPreferenceRes = {} as GenerateDemoPreferenceRes
      state.error = String(action.payload)
    })
  },
})
// Action creators are generated for each case reducer function
export const { generateDemoPreferenceClearMsg, generateDemoPreferenceClearRes } = generateDemoPreferenceSliceSlice.actions

export default generateDemoPreferenceSliceSlice.reducer
