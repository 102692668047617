import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import {
  InactiveCustomerPaymentByIDBody,
  InActiveCustomerPaymentByID,
} from 'reduxStore/customer/payment/inactivePayment/Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  inActivePaymentByIDRes: InActiveCustomerPaymentByID
  successMsg: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  inActivePaymentByIDRes: {} as InActiveCustomerPaymentByID,
  successMsg: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const inactiveCustomerPaymentByID = createAsyncThunk<
  InActiveCustomerPaymentByID,
  InactiveCustomerPaymentByIDBody
>(
  'inactiveCustomerPaymentByID/inactiveCustomerPaymentByID',
  (reqParam: InactiveCustomerPaymentByIDBody, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/paymentsource/${reqParam.id}/toggle_active`, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data?.message !== undefined
            ? error.response.data.message
            : 'Something went wrong',
        ),
      )
  },
)

const inactiveCustomerPaymentByIDSlice = createSlice({
  name: 'inactiveCustomerPaymentByID',
  initialState,
  reducers: {
    updateInActivePaymentByIDClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    updateInActivePaymentByIDClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.inActivePaymentByIDRes = {} as InActiveCustomerPaymentByID
    },
  },
  extraReducers: (builder) => {
    builder.addCase(inactiveCustomerPaymentByID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      inactiveCustomerPaymentByID.fulfilled,
      (state, action: PayloadAction<InActiveCustomerPaymentByID>) => {
        state.loading = false
        state.inActivePaymentByIDRes = action.payload
        state.successMsg =
          action?.payload?.status !== undefined
            ? action?.payload?.status !== ''
              ? 'Payment Source Status Changed to True'
              : 'Payment Source Status Changed to True'
            : ''
        state.error = ''
      },
    )
    builder.addCase(inactiveCustomerPaymentByID.rejected, (state, action) => {
      state.loading = false
      state.inActivePaymentByIDRes = {} as InActiveCustomerPaymentByID
      state.successMsg = ''
      state.error = String(action.payload!)
    })
  },
})

export const { updateInActivePaymentByIDClearMsg, updateInActivePaymentByIDClearRes } =
  inactiveCustomerPaymentByIDSlice.actions
export default inactiveCustomerPaymentByIDSlice.reducer
