import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { AddTagListByUserIdReg, AddTagListByUserIdModel } from './AddTagListByUserIdModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  addTagRes: AddTagListByUserIdModel
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  addTagRes: {} as AddTagListByUserIdModel,
  succssMessage: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const addTagByUserID = createAsyncThunk<AddTagListByUserIdModel, AddTagListByUserIdReg>(
  'addTagByUserID/addTagByUserID',
  (reqParam: AddTagListByUserIdReg, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/orguser-tag-add/${reqParam.userId}/${reqParam.tag}`, { headers })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error !== undefined
            ? error.response !== undefined
              ? error.response.data !== undefined
              : 'Something went wrong'
            : 'Something went wrong',
        ),
      )
  },
)

const addTagListSlice = createSlice({
  name: 'addTagByUserID',
  initialState,
  reducers: {
    addTagClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    addTagClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.addTagRes = {} as AddTagListByUserIdModel
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addTagByUserID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      addTagByUserID.fulfilled,
      (state, action: PayloadAction<AddTagListByUserIdModel>) => {
        state.loading = false
        state.addTagRes = action.payload
        state.succssMessage =
          action?.payload?.succssMessage !== undefined
            ? action?.payload?.succssMessage !== ''
              ? action?.payload?.succssMessage
              : 'Tag added successful'
            : 'tag added successful'
        state.error = ''
      },
    )
    builder.addCase(addTagByUserID.rejected, (state, action) => {
      state.loading = false
      state.addTagRes = {} as AddTagListByUserIdModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { addTagClearMsg, addTagClearRes } = addTagListSlice.actions
export default addTagListSlice.reducer
