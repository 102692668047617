import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { MicrosoftIntegrationModel, MicrosoftUserIdModelReq } from './MicrosoftIntegrationModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  accountDetails: MicrosoftIntegrationModel
  error: string
}
const initialState: InitialState = {
  loading: false,
  accountDetails: {} as MicrosoftIntegrationModel,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchMicrosoftIntegrationById = createAsyncThunk<MicrosoftIntegrationModel, MicrosoftUserIdModelReq>(
  'microsofIntegrationClientId/fetchMicrosoftIntegrationById',
  (reqParam: MicrosoftUserIdModelReq) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/integrationsource/${reqParam.accountId}/get_application_id`, { headers })
      .then((response) => response.data)
  },
)

const microsofIntegrationSlice = createSlice({
  name: 'microsofIntegrationClientId',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMicrosoftIntegrationById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchMicrosoftIntegrationById.fulfilled,
      (state, action: PayloadAction<MicrosoftIntegrationModel>) => {
        state.loading = false
        state.accountDetails = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchMicrosoftIntegrationById.rejected, (state, action) => {
      state.loading = false
      state.accountDetails = {} as MicrosoftIntegrationModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default microsofIntegrationSlice.reducer
