import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Result, CompanyAccountDetailsModelReq } from './companyAccountDetailsModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  companyAccountDetails: Result[]
  error: string
}
const initialState: InitialState = {
  loading: false,
  companyAccountDetails: [] as Result[],
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchCompanyAccountDetailsById = createAsyncThunk<
  Result[],
  CompanyAccountDetailsModelReq
>(
  'companyAccountDetails/fetchCompanyAccountDetailsById',
  (reqParam: CompanyAccountDetailsModelReq) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/customers/usage/account-yearly/?account=${reqParam.accountId}`, {
        headers,
      })
      .then((response) => response.data.results)
  },
)

const companyAccountDetailsSlice = createSlice({
  name: 'companyAccountDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCompanyAccountDetailsById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchCompanyAccountDetailsById.fulfilled,
      (state, action: PayloadAction<Result[]>) => {
        state.loading = false
        state.companyAccountDetails = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchCompanyAccountDetailsById.rejected, (state, action) => {
      state.loading = false
      state.companyAccountDetails = [] as Result[]
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default companyAccountDetailsSlice.reducer
