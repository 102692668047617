import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import {
  UpdateLicenseResponse,
  UpdateLicenseBody,
} from 'reduxStore/account/license/updateLicenseByAccountID/Model'

type InitialState = {
  loading: boolean
  response: UpdateLicenseResponse
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: {} as UpdateLicenseResponse,
  succssMessage: '',
  error: '',
}

export const updateLicenseByAccountID = createAsyncThunk<UpdateLicenseResponse, UpdateLicenseBody>(
  'updateLicenseByAccountID/updateLicenseByAccountID',
  async (payload: UpdateLicenseBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + `/api/accountlicense/create_license_and_count`, payload.updateBody, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data[0]?.description !== undefined
              ? error?.response?.data[0]?.description
              : error.response.data.message !== undefined
              ? error.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const updateLicenseByAccountIDSlice = createSlice({
  name: 'updateLicenseByAccountID',
  initialState,
  reducers: {
    updateLicenseClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    updateLicenseClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.response = {} as UpdateLicenseResponse
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateLicenseByAccountID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      updateLicenseByAccountID.fulfilled,
      (state, action: PayloadAction<UpdateLicenseResponse>) => {
        state.loading = false
        state.response = action.payload
        state.succssMessage =
          action?.payload?.succssMessage !== undefined
            ? action?.payload?.succssMessage !== ''
              ? action?.payload?.succssMessage
              : 'License edited successfully'
            : 'License edited successfully'
        state.error = ''
      },
    )
    builder.addCase(updateLicenseByAccountID.rejected, (state, action) => {
      state.loading = false
      state.response = {} as UpdateLicenseResponse
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})
export const { updateLicenseClearMsg, updateLicenseClearRes } =
  updateLicenseByAccountIDSlice.actions

export default updateLicenseByAccountIDSlice.reducer
