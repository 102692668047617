import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { TagDetailsModelReq, TagListByIdModel } from './TagListByIdModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  tagList: TagListByIdModel
  error: string
}
const initialState: InitialState = {
  loading: false,
  tagList: {} as TagListByIdModel,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchTagListById = createAsyncThunk<TagListByIdModel, TagDetailsModelReq>(
  'tagListDetails/fetchTagListById',
  (reqParam: TagDetailsModelReq) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/account-tags/${reqParam.accountId}/`, { headers })
      .then((response) => response.data)
  },
)

const tagListByIdSlice = createSlice({
  name: 'tagListDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTagListById.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchTagListById.fulfilled,
      (state, action: PayloadAction<TagListByIdModel>) => {
        state.loading = false
        state.tagList = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchTagListById.rejected, (state, action) => {
      state.loading = false
      state.tagList = {} as TagListByIdModel
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default tagListByIdSlice.reducer
