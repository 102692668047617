import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../reduxStore/hooks'
import { Alert, AlertColor, Box, Snackbar, Tab, Tabs } from '@mui/material'
import ActiveSubscription from 'pages/Subscription/Active'
import IgnoredSubscription from 'pages/Subscription/Ignored'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
import { editSubscriptionClearRes } from 'reduxStore/subcription/editSubscription/editSubscriptionSlice'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import { useLocation } from 'react-router-dom'
import PageTitle from 'components/PageTitle'

export const SubscriptionLayout = () => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const editSubscriptionValue = useAppSelector((state) => state.editSubscriptionfetch)
  const [successMessage, setSuccessMessage] = React.useState('')

  const location = useLocation()

  const [value, setValue] = React.useState(0)
  const handleTabChange = (newValue: number) => {
    dispatch(editSubscriptionClearRes())
    setSuccessMessage('')
    setValue(newValue)
    localStorage.setItem('selectedSubscriptionListTab', String(newValue))
  }
  let selectedTab = localStorage.getItem('selectedSubscriptionListTab')

  useEffect(() => {
    if (selectedTab !== null) {
      setValue(Number(selectedTab))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])
  useEffect(() => {
    dispatch(fetchAlerts(Token!))
  }, [dispatch, Token])

  useEffect(() => {
    if (location.pathname === '/subscriptions') {
      if (selectedTab === null) {
        handleTabChange(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedTab])

  const closeUserDetailsSnackbar = () => {
    setSuccessMessage('')
  }

  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')
  const [showEditSubscriptionMsg, setShowEditSubscriptionMsg] = React.useState('')
  const [enableAddUserSnackbar, setEnableAddUserSnackbar] = React.useState(false)
  const closeAddUserSnackbar = () => {
    dispatch(editSubscriptionClearRes())
    setShowEditSubscriptionMsg('')
  }

  React.useEffect(() => {
    if (editSubscriptionValue?.succssMessage !== '') {
      setAPIMsgType('success')
      setShowEditSubscriptionMsg(editSubscriptionValue?.succssMessage)
      setEnableAddUserSnackbar(true)
    }
    if (editSubscriptionValue?.error !== '') {
      if (editSubscriptionValue?.error) {
        setAPIMsgType('error')
        setShowEditSubscriptionMsg(editSubscriptionValue?.error)
        setEnableAddUserSnackbar(false)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSubscriptionValue])
  return (
    <Box>
      <PageTitle title={'Subscription'} />
      {successMessage && (
        <ApplogieSnackbar
          msg={successMessage}
          type="success"
          alertClose={closeUserDetailsSnackbar}
        />
      )}
      <Box sx={{ bgcolor: '#fff' }} pt={1}>
        <Tabs
          value={value}
          onChange={(_event: React.SyntheticEvent, value: number) => handleTabChange(value)}
          centered
          TabIndicatorProps={{
            style: {
              backgroundColor: '#134872',
            },
          }}
          sx={{
            '& button:hover': { background: '' },
            '& button.Mui-selected': {
              background: '#fffff',
              color: '#01cba3',
            },
          }}
        >
          <Tab label="SUBSCRIPTIONS"></Tab>
          <Tab label="IGNORED SUBSCRIPTIONS" />
        </Tabs>
      </Box>
      <Box sx={{ padding: 2 }}>
        {value === 0 && (
          <Box>
            <ActiveSubscription />
          </Box>
        )}
        {value === 1 && (
          <Box>
            <IgnoredSubscription />
          </Box>
        )}
      </Box>
      {showEditSubscriptionMsg !== '' ? (
        <Snackbar
          open={enableAddUserSnackbar}
          autoHideDuration={1000}
          onClose={closeAddUserSnackbar}
        >
          <Alert
            variant="filled"
            severity={apiMsgType}
            onClose={closeAddUserSnackbar}
            sx={{ width: '100%' }}
          >
            <strong>{showEditSubscriptionMsg}</strong>
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}
