import * as React from 'react'
import { useEffect } from 'react'
import { useAppSelector, useAppDispatch } from '../../reduxStore/hooks'
import { Card, CardContent, Box, CircularProgress, Grid, CardHeader } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import { fetchAlerts } from 'reduxStore/alert/Active/alertSlice'
import { fetchBreaches } from 'reduxStore/breaches/breachesList/breachSlice'
import BreachesTable from './BreachesTable'
import BreachesHeader from 'components/Breaches/BreachesHeader'
import PageTitle from 'components/PageTitle'

export const Breaches = () => {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const breachVal = useAppSelector((state) => state.breaches)

  function reloadBreaches() {
    dispatch(fetchBreaches(Token!))
  }

  useEffect(() => {
    dispatch(fetchBreaches(Token!))
    dispatch(fetchAlerts(Token!))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  return (
    <Box p={2} sx={{ width: '100%', minHeight: '67vh' }}>
      <PageTitle title={'Breaches'} />
      <Card>
        <CardHeader
          avatar={<InfoIcon sx={{ fontWeight: 'bold' }} />}
          title={<BreachesHeader reloadBreach={reloadBreaches} />}
        />
        <CardContent>
          {breachVal.loading === true ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <CircularProgress color="inherit" size={20} thickness={4} />
            </Grid>
          ) : (
            <Box sx={{ width: '100%', height: '78vh' }}>
              <BreachesTable />
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}
