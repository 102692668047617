import { Box, Switch, Button } from '@mui/material'
import {
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
} from '@mui/x-data-grid-premium'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CustomShowMoreDetailPanelToggle } from './CustomShowMoreDetailPanelToggel'
import { useAppDispatch } from 'reduxStore/hooks'
import { updateDeactivateActiveUser } from 'reduxStore/users/toggleActive/toggleActiveSlice'

export function ActiveAccountTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'firstName',
      headerName: 'First Name',
      sortable: true,
      hideable: true,
      width: 80,
      align: 'left',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Box>
          {params.row.firstName !== "" ? 
          <Button
            variant="text"
            style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
            onClick={() => {
              navigate(`/userdetail/${params.row.id}`)
            }}
          >
            {params.row.firstName}
          </Button>
           : null}
           </Box>
        )
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      sortable: true,
      hideable: true,
      minWidth: 80,
      align: 'left',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Button
            variant="text"
            style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
            onClick={() => {
              navigate(`/userdetail/${params.row.id}`)
            }}
          >
            {params.row.lastName}
          </Button>
        )
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
          <Button
            variant="text"
            style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
            onClick={() => {
              navigate(`/userdetail/${params.row.id}`)
            }}
          >
            {params.row.email}
          </Button>
        )
      },
    },
    {
      field: 'status',
      headerName: 'Active',
      sortable: true,
      hideable: false,
      minWidth: 80,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useAppDispatch()
        return (
          <Switch
            checked={
              params.row.status !== null || params.row.status !== undefined
                ? params.row.status
                : false
            }
            onClick={() => {
              dispatch(updateDeactivateActiveUser(params.row.id))
            }}
          />
        )
      },
    },
    {
      field: 'accountName',
      headerName: 'Accounts',
      sortable: true,
      hideable: true,
      minWidth: 130,
      align: 'left',
    },
    {
      field: 'department',
      headerName: 'Department',
      sortable: true,
      hideable: true,
      minWidth: 130,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>{params.row.department !== 'Not Set' ? params.row?.department : null}</Box>
      ),
    },
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      align: 'center',
      minWidth: 80,
      headerName: 'Action',
      sortable: false,
      hideable: false,
      renderCell: (params) => (
        <CustomShowMoreDetailPanelToggle id={params.id} value={params.value} />
      ),
      renderHeader: (_params: GridColumnHeaderParams) => <span>{'Action'}</span>,
    },
  ]
  return columns
}
