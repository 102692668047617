import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import {
  DeleteCustomerPaymentByID,
  DeleteCustomerPaymentByIDBody,
} from 'reduxStore/customer/payment/deletePayment/Model'

type InitialState = {
  loading: boolean
  deletePaymentByIDRes: DeleteCustomerPaymentByID
  successMsg: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  deletePaymentByIDRes: {} as DeleteCustomerPaymentByID,
  successMsg: '',
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const deleteCustomerPaymentByID = createAsyncThunk<
  DeleteCustomerPaymentByID,
  DeleteCustomerPaymentByIDBody
>('deleteCustomerPaymentByID/deleteCustomerPaymentByID', (payload, { rejectWithValue }) => {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Token ${payload.token}`,
  }
  return setupInterceptorsTo(axios)
    .delete(url.backEndURL + `/api/paymentsource/${payload.id}`, { headers })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data.message || 'Something went wrong'))
})

const deleteCustomerPaymentByIDSlice = createSlice({
  name: 'deleteCustomerPaymentByID',
  initialState,
  reducers: {
    deleteCustomerPaymentByIDClearMsg: (state) => {
      state.successMsg = ''
      state.error = ''
    },
    deleteCustomerPaymentByIDClearRes: (state) => {
      state.successMsg = ''
      state.error = ''
      state.deletePaymentByIDRes = {} as DeleteCustomerPaymentByID
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteCustomerPaymentByID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      deleteCustomerPaymentByID.fulfilled,
      (state, action: PayloadAction<DeleteCustomerPaymentByID>) => {
        state.loading = false
        state.deletePaymentByIDRes = action.payload
        state.successMsg =
          action?.payload?.successMsg !== undefined
            ? action?.payload?.successMsg !== ''
              ? action?.payload?.successMsg
              : 'Payment Delete successfully'
            : 'Payment Delete successfully'
        state.error = ''
      },
    )
    builder.addCase(deleteCustomerPaymentByID.rejected, (state, action) => {
      state.loading = false
      state.deletePaymentByIDRes = {} as DeleteCustomerPaymentByID
      state.successMsg = ''
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { deleteCustomerPaymentByIDClearMsg, deleteCustomerPaymentByIDClearRes } =
  deleteCustomerPaymentByIDSlice.actions
export default deleteCustomerPaymentByIDSlice.reducer
