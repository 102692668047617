import {
  Alert,
  AlertColor,
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  fetchLicense,
  selectedLicenseDetails,
} from 'reduxStore/account/license/licenseListByAccountID/licenseSlice'
import { useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import {
  updateLicenseClearRes,
  updateLicenseByAccountID,
} from 'reduxStore/account/license/updateLicenseByAccountID/updateLicensebyAccountID'
import {
  Account,
  UpdateLicenseBody,
  UpdateLicenseExtendedBody,
} from 'reduxStore/account/license/updateLicenseByAccountID/Model'
import { LisenceListReq } from 'reduxStore/account/license/licenseListByAccountID/Model'
import {
  LicensePeriod,
  LicenseType,
  ModifiedLicenseList,
} from 'pages/Company/License/LicenseTypePeriodList'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { BenchMarkChartListReq } from 'reduxStore/account/benchMark/Model'
import { fetchBenchMarkChart } from 'reduxStore/account/benchMark/benchMarkChartSlice'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
import dayjs from 'dayjs'
import { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import UpdateLicenseUsage from './UpdateLicenseUsage'
import { LisenceChartListReq } from 'reduxStore/account/license/licenseChart/Model'
import { fetchLicenseChart } from 'reduxStore/account/license/licenseChart/licenseChartSlice'
import _ from 'lodash'
import { checkReturnValue } from 'components/Utility'
import { LicensePeriodList, LicenseTypeValueList } from '../LicenseTypePeriodList'
import {
  AddLicenseBody,
  AddLicenseExtendedBody,
} from 'reduxStore/account/license/addLicenseByAccountID/addLicenseModel'
import {
  addLicense,
  addLicenseClearRes,
} from 'reduxStore/account/license/addLicenseByAccountID/addLicenseSlice'
type Props = {
  item?: ModifiedLicenseList
  editPaymentDialogEnable?: boolean
  editPaymentDialogUpdateState?: boolean | any
  successMessage?: (message: string) => void
}

export default function UpdateLicense({
  item,
  editPaymentDialogEnable,
  editPaymentDialogUpdateState,
  successMessage,
}: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const addLicenseRes = useAppSelector((state) => state.addLicense)
  const { id } = useParams()
  const updateLicenseValue = useAppSelector((state) => state.updateLicenseByAccountID)

  const [errorsupportemail, setErrorSupportEmail] = useState('')
  const errorSupportemail = errorsupportemail ? <Typography>{errorsupportemail}</Typography> : ''

  const [openAlert, setAlert] = useState(false)
  const closeAlert = () => {
    setAlert(false)
  }
  useEffect(() => {
    editPaymentDialogEnable !== undefined && setAddLicenseDialogOpen(editPaymentDialogEnable!)
  }, [editPaymentDialogEnable])

  useEffect(() => {
    item !== undefined && setEditItem(item)
    // setLicenseName(item.sku_part_number)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  const [editItem, setEditItem] = useState({} as ModifiedLicenseList)

  const [license_name, setLicenseName] = useState('')
  const [license_value, setLicenseValue] = useState('')
  const [description, setDescription] = useState('')
  const [license_count, setLicenseCount] = useState('')
  const [selectedLicensePeriod, setSelectedLicensePeriod] = useState<LicensePeriod | null>(null)
  const [selectedLicenseType, setSelectedLicenseType] = useState<LicenseType | null>(null)
  const CHARACTER_LIMIT = 250
  const filterLicenseType = (value: LicenseType) => {
    setSelectedLicenseType(value)
  }

  const filterLicensePeriod = (licensePeriod: any) => {
    setSelectedLicensePeriod(licensePeriod)
  }

  const [licenseStartDate, setLicenseStartDate] = useState<Dayjs | null>(null)
  const setLicenseStartDateValue = (newValue: any) => {
    setLicenseStartDate(dayjs(newValue))
  }

  const [licenseEndDate, setLicenseEndDate] = useState<Dayjs | null>(null)
  const setLicenseEndDateValue = (newValue: any) => {
    setLicenseEndDate(dayjs(newValue))
  }

  const [apiMsgType, setAPIMsgType] = useState<AlertColor>('success')
  const [showAddUpdateLicenseMsg, setShowAddUpdateLicenseMsg] = useState('')
  const closeAddUpdateLicenseMsgAlert = () => {
    dispatch(addLicenseClearRes())
    dispatch(updateLicenseClearRes())
    setShowAddUpdateLicenseMsg('')
  }
  const [addLicenseDialogOpen, setAddLicenseDialogOpen] = useState(false)
  useEffect(() => {
    if (addLicenseDialogOpen === true) {
      openDialog()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addLicenseDialogOpen])

  useEffect(() => {
    if (addLicenseRes?.succssMessage !== '') {
      setAPIMsgType('success')
      setShowAddUpdateLicenseMsg(addLicenseRes?.succssMessage)
      // Initiate Benchmark Chart API
      dispatch(
        fetchBenchMarkChart({
          id: String(id),
          token: Token!,
        } as BenchMarkChartListReq),
      )
      // Initiate License Chart API
      dispatch(
        fetchLicenseChart({
          id: String(id),
          token: Token!,
        } as LisenceChartListReq),
      )
      // Initiate License get API
      let getLicensebody = {
        id: String(id),
        token: Token!,
      } as LisenceListReq
      dispatch(fetchLicense(getLicensebody))
      closeAddLicenseDialog()
    }
    if (addLicenseRes?.error !== '') {
      if (addLicenseRes?.error) {
        setAPIMsgType('error')
        setShowAddUpdateLicenseMsg(addLicenseRes?.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addLicenseRes])

  useEffect(() => {
    if (updateLicenseValue?.succssMessage !== '') {
      setAPIMsgType('success')
      setShowAddUpdateLicenseMsg(updateLicenseValue?.succssMessage)
      successMessage && successMessage(updateLicenseValue?.succssMessage)
      let getLicensebody = {
        id: String(id),
        token: Token!,
      } as LisenceListReq
      dispatch(fetchLicense(getLicensebody))
      // Initiate Benchmark Chart API
      dispatch(
        fetchBenchMarkChart({
          id: String(id),
          token: Token!,
        } as BenchMarkChartListReq),
      )
      // Initiate License Chart API
      dispatch(
        fetchLicenseChart({
          id: String(id),
          token: Token!,
        } as LisenceChartListReq),
      )
      closeAddLicenseDialog()
    }
    if (updateLicenseValue?.error !== '') {
      if (updateLicenseValue?.error) {
        setAPIMsgType('error')
        setShowAddUpdateLicenseMsg(updateLicenseValue?.error)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLicenseValue])

  // open Dialog
  const openDialog = () => {
    setLicenseName(item?.name !== undefined ? item?.name : '')
    setLicenseValue(!_.isEmpty(item) ? String(item.value).split('$')[1].replace(/,/g, '') : '')
    setDescription(item?.description !== undefined ? item.description : '')
    setLicenseCount(item?.count !== undefined ? String(item?.count) : '')
    setLicenseStartDate(
      checkReturnValue(item?.startDate) !== 'Not Set' ? dayjs(item?.startDate) : null,
    )
    setLicenseEndDate(checkReturnValue(item?.endDate) !== 'Not Set' ? dayjs(item?.endDate) : null)
    setSelectedLicensePeriod(
      checkReturnValue(item?.period) !== 'Not Set'
        ? LicensePeriodList.find((o) => o.label === String(item?.period))!
        : null,
    )
    setSelectedLicenseType(
      checkReturnValue(item?.period) !== 'Not Set'
        ? LicenseTypeValueList.find((o) => o.value === Number(item?.type))!
        : null,
    )
    setShowAddUpdateLicenseMsg('')
    setAddLicenseDialogOpen(false)
    setAddLicenseDialogOpen(true)
  }
  //close Dialog
  const closeAddLicenseDialog = () => {
    setLicenseName('')
    setLicenseValue('')
    setDescription('')
    setLicenseCount('')
    setLicenseStartDate(null)
    setLicenseEndDate(null)
    setSelectedLicenseType(null)
    setSelectedLicensePeriod(null)
    setShowAddUpdateLicenseMsg('')
    setAddLicenseDialogOpen(false)
    editPaymentDialogUpdateState(false)
    dispatch(addLicenseClearRes())
    dispatch(updateLicenseClearRes())
    dispatch(selectedLicenseDetails({} as any))
  }

  // Initiate Add / Edit License API
  const updateCompanyLicense = async () => {
    let addLicenseBody = {
      sku_part_number: license_name,
      value: license_value,
      license_count: String(license_count),
      licenseType: selectedLicenseType !== null ? Number(selectedLicenseType.value) : null,
      period: selectedLicensePeriod !== null ? Number(selectedLicensePeriod.value) : null,
      description: description,
      license_start_date:
        licenseStartDate !== null ? dayjs(licenseStartDate).format('YYYY-MM-DDTHH:mm:ss') : null,
      license_end_date:
        licenseEndDate !== null ? dayjs(licenseEndDate).format('YYYY-MM-DDTHH:mm:ss') : null, //2025-01-30T18:30:00.000Z
      account: {
        type: 'Account',
        id: id!,
      } as Account,
    } as AddLicenseExtendedBody
    let mainBody = {
      updateBody: addLicenseBody,
      token: Token!,
    } as AddLicenseBody

    if (
      license_name !== '' &&
      license_value !== '' &&
      description !== '' &&
      license_count !== '' &&
      selectedLicenseType !== null &&
      selectedLicenseType !== null &&
      licenseStartDate !== null &&
      licenseEndDate !== null
    ) {
      if (!_.isEmpty(item)) {
        let mainBody = {
          updateBody: Object.assign(addLicenseBody, {
            license_id: Number(editItem.id),
          }) as UpdateLicenseExtendedBody,
          token: Token!,
        } as UpdateLicenseBody
        if (new RegExp(/^.{1,250}$/).test(description) === false) {
          setErrorSupportEmail('Ensure This field has no more than 250 characters')
        } else {
          await dispatch(updateLicenseByAccountID(mainBody))
        }
      } else {
        if (new RegExp(/^.{1,250}$/).test(description) === false) {
          setErrorSupportEmail('Ensure This field has no more than 250 characters')
        } else {
          await dispatch(addLicense(mainBody))
          setErrorSupportEmail('')
        }
      }
    } else {
      setAlert(true)
    }
  }

  return (
    <Box>
      <Dialog maxWidth="xl" open={addLicenseDialogOpen} onClose={closeAddLicenseDialog}>
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction={'row'}>
              <Typography variant="h6">
                <b>{!_.isEmpty(item) ? 'Edit' : 'Add'} License</b>
              </Typography>
            </Stack>
            <Stack direction={'row'}>
              <IconButton aria-label="delete" onClick={closeAddLicenseDialog}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch">
            <Collapse in={openAlert} timeout="auto" unmountOnExit>
              <Box my={2} sx={{ width: '100%' }}>
                <Alert severity="error" onClose={closeAlert}>
                  <b>Error:</b> All fields are mandatory, Please provide all the Values.
                </Alert>
              </Box>
            </Collapse>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  id="standard-basic"
                  label="License Name"
                  variant="standard"
                  value={license_name}
                  onChange={(e) => {
                    setLicenseName(e.target.value)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  id="standard-basic"
                  label="License Count"
                  variant="standard"
                  type="number"
                  value={license_count}
                  onChange={(e) => {
                    setLicenseCount(e.target.value)
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Autocomplete
                  id="license-period"
                  disableClearable
                  value={selectedLicensePeriod!}
                  options={LicensePeriodList}
                  onChange={(_event: any, newValue: any) => {
                    filterLicensePeriod(newValue)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="License Period" variant="standard" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Start Date"
                    value={licenseStartDate}
                    onChange={(newValue) => {
                      setLicenseStartDateValue(newValue)
                    }}
                    slotProps={{ textField: { variant: 'standard' } }}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="End Date"
                    value={licenseEndDate}
                    onChange={(newValue) => {
                      setLicenseEndDateValue(newValue)
                    }}
                    slotProps={{ textField: { variant: 'standard' } }}
                    sx={{ width: '100%' }}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <Autocomplete
                  id="license-type"
                  disableClearable
                  value={selectedLicenseType!}
                  options={LicenseTypeValueList}
                  onChange={(_event: any, value: any) => {
                    filterLicenseType(value)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="License Type" variant="standard" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <TextField
                  id="standard-basic"
                  label="License Value"
                  variant="standard"
                  type="number"
                  value={license_value}
                  onChange={(e) => {
                    setLicenseValue(e.target.value)
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon sx={{ fontSize: 18 }} />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                <TextField
                  id="standard-basic"
                  label="Description"
                  variant="standard"
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value)
                  }}
                  helperText={
                    errorSupportemail === ''
                      ? `${description.length}/${CHARACTER_LIMIT}`
                      : errorSupportemail
                  }
                  error={errorSupportemail === '' ? false : true}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Grid item>
                    <Button
                      onClick={closeAddLicenseDialog}
                      autoFocus
                      variant="outlined"
                      fullWidth
                      disabled={addLicenseRes.loading || updateLicenseValue?.loading}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={1} md={1} lg={1} xl={1}>
                    <Button
                      onClick={updateCompanyLicense}
                      autoFocus
                      variant="contained"
                      fullWidth
                      disabled={addLicenseRes.loading || updateLicenseValue?.loading}
                    >
                      {!addLicenseRes.loading || !updateLicenseValue?.loading ? (
                        <Typography>{!_.isEmpty(item) ? 'Update' : 'Add'}</Typography>
                      ) : (
                        <CircularProgress color="inherit" size={25} />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Box p={2}>
              {showAddUpdateLicenseMsg !== '' ? (
                <Alert
                  icon={false}
                  severity={apiMsgType}
                  sx={{ mb: 2 }}
                  onClose={() => {
                    closeAddUpdateLicenseMsgAlert()
                  }}
                >
                  {showAddUpdateLicenseMsg}
                </Alert>
              ) : null}
            </Box>
            {!_.isEmpty(item) ? (
              <Box my={2}>
                <Divider />
              </Box>
            ) : null}
            {!_.isEmpty(item) ? <UpdateLicenseUsage item={item} /> : null}
          </Stack>
        </DialogContent>
      </Dialog>
      <ApplogieSnackbar
        msg={showAddUpdateLicenseMsg}
        type={apiMsgType}
        alertClose={closeAddUpdateLicenseMsgAlert}
      />
    </Box>
  )
}
