import { Card, CardContent, CardHeader, Grid } from '@mui/material'
import React from 'react'
import DashboardPreference from './DashboardPreference'
import SubscriptionPreference from './SubscriptionPreference'
export default function userPreference() {
  return (
    <Card>
      <CardHeader title="User Preference"  />
      <CardContent>
        <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <DashboardPreference />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SubscriptionPreference />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
