export interface ModifiedLicenseList {
  id?: number
  name: string
  value: number | string
  count: number | string
  type: number | string
  period: number | string
  description: string
  startDate: string | null
  endDate: string | null
}

export interface LicenseType {
  label: string
  value: number
}
export interface LicensePeriod {
  label: string
  value: number
}
export const LicenseTypeValueList = [
  { label: 'Unknown', value: 0 },
  { label: 'per User', value: 1 },
  { label: 'per Host', value: 2 },
  { label: 'per GB', value: 3 },
  { label: 'Support', value: 4 },
  { label: 'Others', value: 5 },
] as LicenseType[]

export const LicensePeriodList = [
  { label: 'Unknown', value: 0 },
  { label: 'Annual', value: 1 },
  { label: 'Monthly', value: 2 },
] as LicensePeriod[]
