import { Box, Button } from '@mui/material'
import { GridCellParams, GridColDef } from '@mui/x-data-grid-premium'
import React from 'react'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'

export function OptimizationTableColumn() {
  const columns: GridColDef[] = [
    {
      field: 'vendorName',
      headerName: 'Vendor Name',
      sortable: true,
      hideable: false,
      minWidth: 260,
      flex: 1,
      align: 'left',
      renderCell: (params: GridCellParams) => {
        // Use react-router's useNavigate hook to navigate
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const navigate = useNavigate()
        return (
        <Button
          variant="text"
          style={{ justifyContent: 'flex-start', fontWeight: 'bold' }}
          onClick={() => {
            navigate(`/detail/${params.row.vendorID}`)
          }}
        >
          {params.row.vendorName}
        </Button>
        )
      },
    },
    {
      field: 'message',
      headerName: 'Message',
      sortable: true,
      hideable: false,
      minWidth: 150,
      align: 'left',
      flex: 1,
    },
    {
      field: 'createDate',
      headerName: 'Create Date',
      sortable: true,
      hideable: true,
      minWidth: 120,
      align: 'left',
      renderCell: (params: GridCellParams) => (
        <Box>
          {params.row.renewalDate !== '---' ? 
           dayjs(params.row.renewalDate!).format('MMM DD, YYYY'): null}
        </Box>
      ),
    },

    {
      field: 'overage',
      headerName: 'Overage',
      sortable: true,
      hideable: false,
      minWidth: 60,
      align: 'right',
    },
    {
      field: 'potentialSavings',
      headerName: 'Potential Savings',
      sortable: true,
      hideable: true,
      minWidth: 140,
      align: 'right',
    },
  ]
  return columns
}
