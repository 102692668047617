import * as React from 'react'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import UpdateUserDetails from './UpdateUserDetails/Index'
import { updateUserDetailsClearRes } from 'reduxStore/users/updateUserDetails/updateUserDetailsSlice'

export default function UserDetail() {
  const userDetailsById = useAppSelector((state) => state.userDetailsById)
  const dispatch = useAppDispatch()
  const [showUpdateUserDetailsDialog, setShowUpdateUserDetailsDialog] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState('')
  const updateDialogState = () => {
    setShowUpdateUserDetailsDialog(true)
    dispatch(updateUserDetailsClearRes())
  }

  const updateUserDetailsDialogValFromChild = (passedVal: boolean) => {
    setShowUpdateUserDetailsDialog(passedVal)
  }

  const handleSuccessMessage = (message: string) => {
    // Handle success message in the parent component
    setSuccessMessage(message)
  }

  return (
    <Box>
      {successMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={Boolean(successMessage)}
          autoHideDuration={3000}
          onClose={() => setSuccessMessage('')}
        >
          <Alert onClose={() => setSuccessMessage('')} severity="success" variant="filled">
            {successMessage}
          </Alert>
        </Snackbar>
      )}
      {showUpdateUserDetailsDialog ? (
        <UpdateUserDetails
          udpateUserDetailsDialogEnable={showUpdateUserDetailsDialog}
          udpateUserDetailsDialogUpdateState={updateUserDetailsDialogValFromChild}
          onSuccessUpdateUserDetailsMsg={handleSuccessMessage}
        />
      ) : null}
      <Card>
        <CardHeader
          avatar={<AccountBoxIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction={'row'}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Stack direction={'row'}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      User Details
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={'row'}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<BorderColorIcon />}
                  onClick={() => updateDialogState()}
                >
                  Edit
                </Button>
              </Stack>
            </Stack>
          }
        ></CardHeader>
        <CardContent>
          <Box sx={{ bgcolor: '#F2F2F2' }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              p={1}
              spacing={1}
            >
              <Grid item md={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {userDetailsById.userDetails.first_name !== ''
                    ? userDetailsById.userDetails.first_name
                    : 'Not Set'}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '10px', fontStyle: 'italic' }}>
                  First Name
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {userDetailsById.userDetails.last_name !== ''
                    ? userDetailsById.userDetails.last_name
                    : 'Not Set'}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '10px', fontStyle: 'italic' }}>
                  Last Name
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold', wordWrap: 'break-word' }}>
                  {userDetailsById.userDetails.email}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '10px', fontStyle: 'italic' }}>
                  Email
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {dayjs(userDetailsById.userDetails.create_date!).format(
                    'ddd, DD MMM YYYY h:mm:ss',
                  )}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '10px', fontStyle: 'italic' }}>
                  Create Date
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {userDetailsById.userDetails.active === true ? 'Active' : 'Inactive'}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '10px', fontStyle: 'italic' }}>
                  Status
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {'userDetails' in userDetailsById
                    ? userDetailsById.userDetails !== undefined
                      ? userDetailsById.userDetails !== null
                        ? 'department' in userDetailsById.userDetails
                          ? userDetailsById.userDetails.department !== undefined
                            ? userDetailsById.userDetails.department !== null
                              ? 'name' in userDetailsById.userDetails.department
                                ? userDetailsById.userDetails.department.name !== undefined
                                  ? userDetailsById.userDetails.department.name !== null
                                    ? userDetailsById.userDetails.department.name
                                    : 'Not Set'
                                  : 'Not Set'
                                : 'Not Set'
                              : 'Not Set'
                            : 'Not Set'
                          : 'Not Set'
                        : 'Not Set'
                      : 'Not Set'
                    : 'Not Set'}
                </Typography>
                <Typography variant="subtitle1" sx={{ fontSize: '10px', fontStyle: 'italic' }}>
                  Department
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
    </Box>
  )
}
