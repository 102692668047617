import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useAppSelector } from 'reduxStore/hooks'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import dayjs from 'dayjs'
import { Box, Grid, Typography } from '@mui/material'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)
export default function GcpChart() {
  const gcpListValue = useAppSelector((state) => state.gcpList)

  // Data
  let data: any[] = []
  var desPosArray: any[] = []

  const [value, setValue] = useState(false)

  useEffect(() => {
    if (gcpListValue.gcpList.results !== undefined) {
      var definedValue = ''
      var index: number = -1
      var pos: number = 0
      var storeValue: any[] = []

      gcpListValue.gcpList.results.forEach((item) => {
        if (definedValue !== String(item.end_time)) {
          index = index + 1
          definedValue = String(item.end_time)
          storeValue.push({ end_time: dayjs(item.end_time).format('ddd, DD MMM YYYY') })
          storeValue[index]['total'] = 0
        }
        if (desPosArray.includes(item.description)) {
          pos = desPosArray.indexOf(item.description) + 1
          storeValue[index]['cost_amount' + String(pos)] = Number(item.cost_amount)
          storeValue[index]['description' + String(pos)] = item.description
        } else {
          desPosArray.push(item.description)
          storeValue[index]['cost_amount' + String(desPosArray.length)] = Number(item.cost_amount)
          storeValue[index]['description' + String(desPosArray.length)] = item.description
        }
        storeValue[index]['total'] += Number(item.cost_amount)
      })
      storeValue.reverse()
      // eslint-disable-next-line react-hooks/exhaustive-deps
      data = storeValue
    }

    if (data.length > 0) {
      setValue(true)
    }

    let root = am5.Root.new('gcpChart')
    var modal = am5.Modal.new(root, {
      content: 'Gcp chart has no data',
    })
    root.setThemes([am5themes_Animated.new(root)])
    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
        pinchZoomX: true,
      }),
    )
    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        behavior: 'none',
      }),
    )
    cursor.lineY.set('visible', false)

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'end_time',
        startLocation: 0.5,
        endLocation: 0.5,
        renderer: am5xy.AxisRendererX.new(root, {}),
        tooltip: am5.Tooltip.new(root, {}),
      }),
    )
    xAxis.data.setAll(data)

    let yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    )

    const createSeries = (name: any, field: any) => {
      let series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          stacked: false,
          valueYField: field,
          categoryXField: 'end_time',
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: 'horizontal',
            labelText: `[bold]{name}[/]: $ {valueY}`,
          }),
          // legendLabelText: '[bold]{name}',
          legendValueText: `[bold]$ {valueY}`,
        }),
      )

      series.fills.template.setAll({
        fillOpacity: 0.5,
        visible: true,
      })

      series.data.setAll(data)
      series.appear(1000)
    }
    createSeries('Total', 'total')
    for (var k = 0; k < desPosArray.length; k++) {
      createSeries(desPosArray[k], 'cost_amount' + String(k + 1))
    }

    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
      }),
    )

    let exporting = am5plugins_exporting.Exporting.new(root, {})
    exporting.setAll({
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: 'GcpChart',
      dataSource: data,
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
    })

    let legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
        layout: root.gridLayout,
      }),
    )

    legend.labels.template.setAll({
      fontSize: 9,
    })

    legend.valueLabels.template.setAll({
      fontSize: 10,
    })

    legend.data.setAll(chart.series.values)

    if (data.length === 0) {
      modal.open()
    } else {
      modal.close()
    }
    chart.appear(1000, 100)
    return () => root.dispose()
  }, [data])

  return (
    <Box>
      {value ? (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Grid item>
            <Typography sx={{ fontSize: 20, fontWeight: 'bold' }}>GCP Usage</Typography>
          </Grid>
        </Grid>
      ) : null}
      <Box id="gcpChart" style={{ width: '100%', height: '100%' }} />
    </Box>
  )
}
