import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import {
  ToggleCompnayUserByIDRes,
  ToggleCompnayUserByIDBody,
} from 'reduxStore/customer/companyUser/toggleCompanyUserByID/Model'

type InitialState = {
  loading: boolean
  toggleCompanyUserByIDRes: ToggleCompnayUserByIDRes
  succssMessage: string
  error: string
  deActivateUser: string
}
const initialState: InitialState = {
  loading: false,
  toggleCompanyUserByIDRes: {} as ToggleCompnayUserByIDRes,
  succssMessage: '',
  error: '',
  deActivateUser: '',
}

// Generates pending, fulfilled and rejected action types
export const toggleCompnayUserByID = createAsyncThunk<
  ToggleCompnayUserByIDRes,
  ToggleCompnayUserByIDBody
>('toggleCompnayUserByID/toggleCompnayUserByID', (payload, { rejectWithValue }) => {
  let headers = {
    Accept: 'application/json',
    'Content-type': 'application/json',
    Authorization: `Token ${payload.token}`,
  }
  return setupInterceptorsTo(axios)
    .get(url.backEndURL + `/api/users/${payload.id}/toggle_active_company_user`, { headers })
    .then((response) => response.data)
    .catch((error) => rejectWithValue(error.response.data.message || 'Something went wrong'))
})

const toggleCompnayUserByIDSlice = createSlice({
  name: 'toggleCompnayUserByID',
  initialState,
  reducers: {
    toggleCompnayUserClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.deActivateUser = ''
    },
    toggleCompnayUserClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.deActivateUser = ''
      state.toggleCompanyUserByIDRes = {} as ToggleCompnayUserByIDRes
    },
    updateDeactivateUser: (state, action) => {
      state.deActivateUser = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(toggleCompnayUserByID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      toggleCompnayUserByID.fulfilled,
      (state, action: PayloadAction<ToggleCompnayUserByIDRes>) => {
        state.loading = false
        state.toggleCompanyUserByIDRes = action.payload
        state.succssMessage =
          action?.payload?.succssMessage !== undefined
            ? action?.payload?.succssMessage !== ''
              ? action?.payload?.succssMessage
              : 'Company User Status Changed to False'
            : 'Company User Status Changed to False'
        state.error = ''
      },
    )
    builder.addCase(toggleCompnayUserByID.rejected, (state, action) => {
      state.loading = false
      state.toggleCompanyUserByIDRes = {} as ToggleCompnayUserByIDRes
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { toggleCompnayUserClearMsg, toggleCompnayUserClearRes, updateDeactivateUser } =
  toggleCompnayUserByIDSlice.actions
export default toggleCompnayUserByIDSlice.reducer
