import {
  AlertColor,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@mui/material'
import * as React from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import {
  deleteLicenseByID,
  deleteLicenseClearRes,
  enableDeleteLicenseDialog,
  selectItemForDeleteLicense,
} from 'reduxStore/account/license/deleteLicensebyID/deleteLicenseByIDSlice'
import { DeleteCustomerPaymentByIDBody } from 'reduxStore/customer/payment/deletePayment/Model'
import { LisenceListReq } from 'reduxStore/account/license/licenseListByAccountID/Model'
import { fetchLicense } from 'reduxStore/account/license/licenseListByAccountID/licenseSlice'
import { useParams } from 'react-router-dom'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import _ from 'lodash'

export default function DeleteLicense() {
  const dispatch = useAppDispatch()
  const Token = localStorage.getItem('token')

  const deleteLicenseByIDRes = useAppSelector((state) => state.deleteLicenseByID)
  // Get the userId param from the URL.
  let { id } = useParams()

  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')
  const [showDeleteLicenseMsg, setShowDeleteLicenseMsg] = React.useState('')
  const [selectedItem, setSelectedItem] = React.useState('')
  const [dialogState, setDialogState] = React.useState(false)
  const handleClose = () => {
    setDialogState(false)
    setShowDeleteLicenseMsg('')
    dispatch(deleteLicenseClearRes())
    dispatch(enableDeleteLicenseDialog(false))
    dispatch(selectItemForDeleteLicense(''))
  }
  let deletePaymentByIDBody = {
    id: selectedItem,
    token: Token!,
  } as DeleteCustomerPaymentByIDBody

  let licenseListBody = {
    id: String(id),
    token: Token!,
  } as LisenceListReq

  const deleteActivePayment = async () => {
    if (navigator.onLine) {
      await dispatch(deleteLicenseByID(deletePaymentByIDBody))
      await dispatch(fetchLicense(licenseListBody))
    } else {
      await dispatch(deleteLicenseClearRes())
      await setShowDeleteLicenseMsg('')
    }
  }

  React.useEffect(() => {
    setSelectedItem(deleteLicenseByIDRes?.selectedItem)
    setDialogState(deleteLicenseByIDRes?.enableDialog)
    if (deleteLicenseByIDRes?.succssMessage !== '') {
      setAPIMsgType('success')
      setShowDeleteLicenseMsg(deleteLicenseByIDRes?.succssMessage)
      handleClose()
    }
    if (deleteLicenseByIDRes?.error !== '') {
      if (deleteLicenseByIDRes?.error) {
        setAPIMsgType('error')
        setShowDeleteLicenseMsg(deleteLicenseByIDRes?.error)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLicenseByIDRes])

  const closeDeleteLicenseSnackbar = () => {
    dispatch(deleteLicenseClearRes())
    setShowDeleteLicenseMsg('')
  }

  return (
    <Box>
      <Dialog open={dialogState} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogContent>
          <DialogContentText>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              Are you sure you want to delete this license?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            autoFocus
            disabled={deleteLicenseByIDRes?.loading}
          >
            No
          </Button>
          <Button
            variant="contained"
            onClick={() => deleteActivePayment()}
            disabled={deleteLicenseByIDRes?.loading}
          >
            {deleteLicenseByIDRes.loading ? (
              <CircularProgress color="inherit" size={25} />
            ) : (
              <Typography>Yes</Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>
      <ApplogieSnackbar
        msg={showDeleteLicenseMsg}
        type={apiMsgType}
        alertClose={closeDeleteLicenseSnackbar}
      />
    </Box>
  )
}
