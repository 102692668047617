import React, { useEffect, useState } from 'react'
import { DataGridPremium, GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { BreachesDetailsTableColumn } from './BreachesTableColumn'
import DataGridTableHeader from 'components/Subscripiton/DataGridTableHeader'
import { TableListData } from './TableListData'
import { useLocation } from 'react-router-dom'

export default function BreachesDetailsTable() {
  const location = useLocation()
  const [inVisibleColumnModel, setInVisibleColumnModel] = useState<GridColumnVisibilityModel>()
  useEffect(() => {
    location?.pathname === '/dashboard'
      ? setInVisibleColumnModel({
          password: false,
          creditCard: false,
          authToken: false,
          passwordHint: false,
          securityQA: false,
        })
      : setInVisibleColumnModel({})
  }, [location])

  return (
    <DataGridPremium
      columns={BreachesDetailsTableColumn()}
      rows={TableListData()}
      disableColumnSelector
      initialState={{
        sorting: {
          sortModel: [{ field: 'fName', sort: 'asc' }],
        },
      }}
      slots={{
        toolbar: DataGridTableHeader,
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
        },
      }}
      hideFooter
      columnVisibilityModel={inVisibleColumnModel}
      disableRowGrouping
      getRowHeight={() => 'auto'}
    />
  );
}
