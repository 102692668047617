import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useAppSelector, useAppDispatch } from 'reduxStore/hooks'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  Autocomplete,
  TextField,
  Stack,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material'
import VendorSpendDeptDetails from 'pages/Dashboard/VendorSpendDept/VendorSpendDeptDetails'
import { VendorDeptDetailsReq } from 'reduxStore/vendorDeptDetails/vendorDeptDetailsModel'
import { fetchVendorDeptDetails, updateVendorDeptDetailsClearRes } from 'reduxStore/vendorDeptDetails/vendorDeptDetailsSlice'
import CloseIcon from '@mui/icons-material/Close'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'

am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)
interface FilteredDept {
  cost: number | undefined
  projected_cost: number | undefined
  dept_short_name: string | undefined
  dept_name: string | undefined
  department_id: string | undefined
}

interface deptLimitData {
  limit: string
}

const tfStyle = {
  '& .MuiButtonBase-root.MuiAutocomplete-clearIndicator': {
    color: 'inherit',
    visibility: 'visible',
  },
}

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function VendorDept() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const venDept = useAppSelector((state) => state.vendorDept)

  const [selectedItem, setSelectedItem] = useState({} as FilteredDept)
  // Data
  let modifiedvendeptArray: FilteredDept[] = []

  const [modalOpen, setModalOpen] = React.useState(false)

  function selectChartItem(item: FilteredDept) {
    setSelectedItem(item)
    let deptDetailsParam = {
      deptId: String(item.department_id),
      length: String(10),
      token: Token!,
    } as VendorDeptDetailsReq
    dispatch(fetchVendorDeptDetails(deptDetailsParam))
  }

  // Close Modal Action
  const handleModalClose = () => {
    setModalOpen(false)
    dispatch (updateVendorDeptDetailsClearRes())
  }

  const myTrails = [
    {
      limit: '10',
    },
    {
      limit: '20',
    },
    {
      limit: '30',
    },
  ] as deptLimitData[]
  function handleLimitChange(limit: deptLimitData) {
    let deptDetailsParam = {
      deptId: String(selectedItem.department_id),
      length: limit.limit,
      token: Token!,
    } as VendorDeptDetailsReq
    dispatch(fetchVendorDeptDetails(deptDetailsParam))
    dispatch (updateVendorDeptDetailsClearRes())
  }

  useEffect(() => {
    if (venDept.vendorSpends !== undefined && venDept.vendorSpends.length > 0) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      modifiedvendeptArray = venDept.vendorSpends.map((item) => {
        return {
          cost: item?.cost !== null ? Math.round(Number(item.cost)) : 0,
          projected_cost:
            item?.projected_cost !== null ? Math.round(Number(item.projected_cost)) : 0,
          dept_short_name: item?.dept_short_name !== null ? item.dept_short_name : 'Not set',
          dept_name: item?.department_name !== null ? item.department_name : 'Not set',
          department_id: item?.department_id !== null ? String(item.department_id) : 'Not Set',
        }
      })
    }

    let root = am5.Root.new('vendorDeptDiv')
    var modal = am5.Modal.new(root, {
      content: 'Vendor Spend by Department (Last 12 months) chart has no data',
    })
    root.setThemes([am5themes_Animated.new(root)])

    root.dateFormatter.setAll({
      dateFormat: 'yyyy-MM-dd',
      dateFields: ['valueX'],
    })

    let chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: root.verticalLayout,
      }),
    )

    chart.children.unshift(
      am5.Label.new(root, {
        text: 'Vendor Spend by Department (Last 12 months)',
        fontSize: 20,
        fontWeight: '600',
        textAlign: 'center',
        x: am5.percent(50),
        centerX: am5.percent(50),
        paddingTop: 0,
        paddingBottom: 0,
      }),
    )

    chart.set(
      'scrollbarX',
      am5.Scrollbar.new(root, {
        orientation: 'horizontal',
      }),
    )

    let cursor = chart.set(
      'cursor',
      am5xy.XYCursor.new(root, {
        //behavior: 'zoomX',
      }),
    )
    cursor.lineX.setAll({
      stroke: am5.Color.fromString('#13beda'),
      strokeWidth: 2,
    })
    cursor.lineY.set('visible', false)

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: 'dept_short_name',
        renderer: am5xy.AxisRendererX.new(root, { minGridDistance: 10 }),
        tooltip: am5.Tooltip.new(root, {
          themeTags: ['axis'],
          animationDuration: 200,
        }),
      }),
    )

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        numberFormat: "'$'#0a",
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      }),
    )

    yAxis.children.unshift(
      am5.Label.new(root, {
        rotation: -90,
        text: 'Spend to Date',
        fontSize: 12,
        fontWeight: 'bold',
        y: am5.p50,
        centerX: am5.p50,
      }),
    )

    var series0 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Department Forecast',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'projected_cost',
        categoryXField: 'dept_short_name',
        fill: am5.Color.fromString('#01cba3'),
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          // eslint-disable-next-line no-template-curly-in-string
          labelText: '{dept_name}: ${valueY}',
        }),
        // eslint-disable-next-line no-template-curly-in-string
        legendValueText: '${valueY}',
      }),
    )

    series0.columns.template.setAll({
      width: am5.percent(50),
      height: am5.percent(5000),
      fillOpacity: 0.3,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    })

    series0.data.setAll(modifiedvendeptArray)

    var series1 = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: 'Department Spend',
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: 'cost',
        categoryXField: 'dept_short_name',
        fill: am5.Color.fromString('#134872'),
        clustered: false,
        tooltip: am5.Tooltip.new(root, {
          // eslint-disable-next-line no-template-curly-in-string
          labelText: '{dept_name}: ${valueY}',
        }),
        // eslint-disable-next-line no-template-curly-in-string
        legendValueText: '${valueY}',
      }),
    )

    series1.columns.template.setAll({
      width: am5.percent(25),
      fillOpacity: 1,
      cornerRadiusTL: 5,
      cornerRadiusTR: 5,
    })

    let legend = chart.children.push(
      am5.Legend.new(root, {
        x: am5.p50,
        centerX: am5.p50,
      }),
    )
    legend.valueLabels.template.setAll({
      width: 100,
      textAlign: 'right',
    })
    legend.data.setAll(chart.series.values)

    if (modifiedvendeptArray.length === 0) {
      modal.open()
    } else {
      modal.close()
    }

    // Add event listeners on click event
    series0.columns.template.events.on('click', function (ev: any) {
      // setSelectedItem(ev.target.dataItem.dataContext)
      selectChartItem(ev.target.dataItem.dataContext)
      setModalOpen(true)
    })
    series1.columns.template.events.on('click', function (ev: any) {
      // setSelectedItem(ev.target.dataItem.dataContext)
      selectChartItem(ev.target.dataItem.dataContext)
      setModalOpen(true)
    })
    let exporting = am5plugins_exporting.Exporting.new(root, {})
    exporting.setAll({
      menu: am5plugins_exporting.ExportingMenu.new(root, {}),
      filePrefix: 'MonthlyVendorSpend',
      dataSource: modifiedvendeptArray,
      dateFields: ['date'],
      dateFormat: 'yyyy-MM-dd',
    })

    xAxis.data.setAll(modifiedvendeptArray)
    series1.data.setAll(modifiedvendeptArray)
    chart.appear(1000, 100)
    series0.appear()
    series1.appear()
    return () => root.dispose()
  }, [modifiedvendeptArray, selectedItem])

  return (
    <div>
      <div id="vendorDeptDiv" style={{ width: '100%', height: '550px' }} />
      <Dialog
        fullScreen
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="responsive-dialog-title"
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleModalClose} />
        <DialogContent>
          <DialogContentText>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              pt={1}
            >
              <Typography variant="h6">
                Vendor Spend Department- {selectedItem.dept_name}
              </Typography>
              <Autocomplete
                onChange={(_event, value: any) => handleLimitChange(value!)}
                options={myTrails}
                id="autocomplete-1"
                getOptionLabel={(option) => `${option.limit}`}
                defaultValue={myTrails[0]}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Limit Graph to Top"
                      sx={tfStyle}
                    />
                  )
                }}
                sx={{ mb: 2, minWidth: 400 }}
              />
            </Stack>
            <VendorSpendDeptDetails />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}
