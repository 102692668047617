import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import url from 'APIConfig'
import { BenchMarkChartListReq, BenchMarkChartListRes } from './Model'

type InitialState = {
  loading: boolean
  benchmarkChart: BenchMarkChartListRes
  error: string
}
const initialState: InitialState = {
  loading: false,
  benchmarkChart: {} as BenchMarkChartListRes,
  error: '',
}

export const fetchBenchMarkChart = createAsyncThunk<BenchMarkChartListRes, BenchMarkChartListReq>(
  'fetchBenchMarkChart/fetchBenchMarkChart',
  async (payload: BenchMarkChartListReq, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/benchmarkdata?account=${payload.id}`, { headers })
      .then((response) => response.data)
      .catch(
        (error) =>
          rejectWithValue(error.response.data[0].detail) ||
          error.response.data.message ||
          'Something went wrong',
      )
  },
)

const benchmarkChartSlice = createSlice({
  name: 'fetchBenchMarkChart',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBenchMarkChart.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchBenchMarkChart.fulfilled, (state, action: PayloadAction<BenchMarkChartListRes>) => {
      state.loading = false
      state.benchmarkChart = action.payload
      state.error = ''
    })
    builder.addCase(fetchBenchMarkChart.rejected, (state, action) => {
      state.loading = false
      state.benchmarkChart = {} as BenchMarkChartListRes
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default benchmarkChartSlice.reducer
