import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { BreachesListByIDRes, BreachesListByIDBody, BreachesListByIDErrRes } from './Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  breachesDetailsByID: BreachesListByIDRes
  error: BreachesListByIDErrRes
}
const initialState: InitialState = {
  loading: false,
  breachesDetailsByID: {} as BreachesListByIDRes,
  error: { message: '' } as BreachesListByIDErrRes,
}

// Generates pending, fulfilled and rejected action types
export const fetchBreachesDetailsByID = createAsyncThunk<BreachesListByIDRes, BreachesListByIDBody>(
  'fetchBreachesDetailsByID/fetchBreachesDetailsByID',
  async (reqParam: BreachesListByIDBody, { rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/breachvendor?breach_vendor_id=${reqParam.id}`, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data))
  },
)

const fetchBreachesDetailsByIDSlice = createSlice({
  name: 'monthlyVendorSpend',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBreachesDetailsByID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchBreachesDetailsByID.fulfilled,
      (state, action: PayloadAction<BreachesListByIDRes>) => {
        state.loading = false
        state.breachesDetailsByID = action.payload
        state.error = {} as BreachesListByIDErrRes
      },
    )
    builder.addCase(fetchBreachesDetailsByID.rejected, (state, action) => {
      state.loading = false
      state.breachesDetailsByID = {} as BreachesListByIDRes
      state.error.message = action.error.message || 'Something went wrong'
    })
  },
})

export default fetchBreachesDetailsByIDSlice.reducer
