import * as React from 'react'
import { Box, Grid, Stack } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { fetchUserDetailsById } from 'reduxStore/users/userDetails/userDetailsSlice'
import { UserDetailsModelReq } from 'reduxStore/users/userDetails/Model'
import Breaches from './Breaches/Index'
import UserDetail from './UserDetail/Index'
import MicrosoftUsage from './MicrosoftUsage/index'
import Tags from './Tags/Index'
import Accounts from './Accounts/Index'
import { BreachesByUserIDBody } from 'reduxStore/users/breaches/Model'
import { fetchBreachesByUserID } from 'reduxStore/users/breaches/breachesByUserID'
import { fetchDepartment } from 'reduxStore/subcription/Department/DepartmentSlice'
import { getTagByUserId } from 'reduxStore/users/tags/tagList/getTagListByIdSlice'
import PageTitle from 'components/PageTitle'
import { checkReturnValue } from 'components/Utility'

export default function UserDetails() {
  // Get the userId param from the URL.
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  let userDetailsByIdBody = {
    userId: String(id),
    token: Token,
  } as UserDetailsModelReq

  let breachesByIdBody = {
    userId: String(id),
    token: Token,
  } as BreachesByUserIDBody

  React.useEffect(() => {
    dispatch(fetchUserDetailsById(userDetailsByIdBody))
    dispatch(fetchBreachesByUserID(breachesByIdBody))
    dispatch(fetchDepartment(Token!))
    dispatch(getTagByUserId(breachesByIdBody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])

  const userDetailsById = useAppSelector((state) => state.userDetailsById)

  return (
    <Box sx={{ flexGrow: 1 }} p={2}>
      <PageTitle
        title={
          checkReturnValue(userDetailsById?.userDetails?.first_name) !== 'Not Set' &&
          checkReturnValue(userDetailsById?.userDetails?.last_name) !== 'Not Set'
            ? `${checkReturnValue(userDetailsById?.userDetails?.first_name)}  ${checkReturnValue(
                userDetailsById?.userDetails?.last_name,
              )}`
            : checkReturnValue(userDetailsById?.userDetails?.first_name) !== 'Not Set' &&
              checkReturnValue(userDetailsById?.userDetails?.last_name) === 'Not Set'
            ? checkReturnValue(userDetailsById?.userDetails?.first_name)
            : checkReturnValue(userDetailsById?.userDetails?.first_name) === 'Not Set' &&
              checkReturnValue(userDetailsById?.userDetails?.last_name) !== 'Not Set'
            ? checkReturnValue(userDetailsById?.userDetails?.last_name)
            : 'User Details'
        }
      />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <UserDetail />
            <Breaches />
            <Tags />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={2}>
            <Accounts />
            <MicrosoftUsage />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  )
}
