import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from 'APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Results, SearchUserBody } from '../searchUser/Model'

type InitialState = {
  loading: boolean
  response: Results[]
  succssMessage: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  response: [] as Results[],
  succssMessage: '',
  error: '',
}

export const fetchSearchUser = createAsyncThunk<Results[], SearchUserBody>(
  'searchUser/searchUser',
  async (payload: SearchUserBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .post(Url.backEndURL + `/api/org-user-search/`, payload.updateBody, {
        headers,
      })
      .then((response) => response.data)
      .catch((error) =>
        rejectWithValue(
          error?.response?.data !== undefined
            ? error?.response?.data[0]?.description !== undefined
              ? error?.response?.data[0]?.description
              : error.response.data.message !== undefined
              ? error.response.data.message
              : 'Something Went Wrong.'
            : 'Something Went Wrong.',
        ),
      )
  },
)

const searchUserSlice = createSlice({
  name: 'searchUser',
  initialState,
  reducers: {
    searchUserClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
    },
    searchUserClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.response = [] as Results[]
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSearchUser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchSearchUser.fulfilled, (state, action: PayloadAction<Results[]>) => {
      state.loading = false
      state.response = action.payload
      state.succssMessage = 'Search User successfully'
      state.error = ''
    })
    builder.addCase(fetchSearchUser.rejected, (state, action) => {
      state.loading = false
      state.response = [] as Results[]
      state.succssMessage = ''
      state.error = String(action.payload!)
    })
  },
})
export const { searchUserClearMsg, searchUserClearRes } = searchUserSlice.actions

export default searchUserSlice.reducer
