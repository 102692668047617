import React, { useEffect, useState } from 'react'
import * as am5 from '@amcharts/amcharts5'
import * as am5xy from '@amcharts/amcharts5/xy'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { LisenceChartListReq } from 'reduxStore/account/license/licenseChart/Model'
import { fetchLicenseChart } from 'reduxStore/account/license/licenseChart/licenseChartSlice'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import { monthNames } from '../SpendChart/Model'
import * as am5plugins_exporting from '@amcharts/amcharts5/plugins/exporting'
am5.addLicense(process.env.REACT_APP_AMCHARTS_LICENSE_KEY!)

export default function LicenseChart() {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const licenseChartValue = useAppSelector((state) => state.licenseChart)
  // Get the userId param from the URL.
  let { id } = useParams()
  useEffect(() => {
    let getLicenseChartbody = {
      id: String(id),
      token: Token!,
    } as LisenceChartListReq
    dispatch(fetchLicenseChart(getLicenseChartbody))
  }, [dispatch, Token, id])

  let modifiedArray = [] as any[]
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const [chartBasicData, setChartBasicData] = useState([] as any[])
  // Prepare Basic dataset for chart
  const ChartUpdate = (
    name: string,
    year: string,
    month: number,
    active: number,
    inactive: number,
    _total: number,
    _productCount: number,
  ) => {
    modifiedArray.push({
      modifiedYear:
        monthNames[dayjs(new Date(year + '/' + month + '/01')).month()].slice(0, 3) +
        ' ' +
        dayjs(new Date(year + '/' + month + '/01')).year(),
      // eslint-disable-next-line no-useless-concat
      [name + ' ' + 'active']: active,
      // eslint-disable-next-line no-useless-concat
      [name + ' ' + 'inactive']: inactive,
      month: monthNames[dayjs(new Date(year + '/' + month + '/01')).month()].slice(0, 3),
      year: dayjs(new Date(year + '/' + month + '/01')).year(),
    })
    setChartBasicData(modifiedArray)
  }
  useEffect(() => {
    var productCount: number = 0
    if (licenseChartValue?.licensesChart.results !== undefined) {
      if (licenseChartValue?.licensesChart.results.length > 0) {
        for (var iter = 0; iter < licenseChartValue?.licensesChart.results.length; iter++) {
          var license_name =
            licenseChartValue?.licensesChart.results[iter].account_license.sku_part_number
          if (license_name === '') {
            license_name = 'License Usage'
          }
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            1,
            Number(licenseChartValue?.licensesChart.results[iter].jan_usage),
            Number(licenseChartValue?.licensesChart.results[iter].jan_count) -
              Number(licenseChartValue?.licensesChart.results[iter].jan_usage),
            Number(licenseChartValue?.licensesChart.results[iter].jan_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            2,
            Number(licenseChartValue?.licensesChart.results[iter].feb_usage),
            Number(licenseChartValue?.licensesChart.results[iter].feb_count) -
              Number(licenseChartValue?.licensesChart.results[iter].feb_usage),
            Number(licenseChartValue?.licensesChart.results[iter].feb_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            3,
            Number(licenseChartValue?.licensesChart.results[iter].mar_usage),
            Number(licenseChartValue?.licensesChart.results[iter].mar_count) -
              Number(licenseChartValue?.licensesChart.results[iter].mar_usage),
            Number(licenseChartValue?.licensesChart.results[iter].mar_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            4,
            Number(licenseChartValue?.licensesChart.results[iter].apr_usage),
            Number(licenseChartValue?.licensesChart.results[iter].apr_count) -
              Number(licenseChartValue?.licensesChart.results[iter].apr_usage),
            Number(licenseChartValue?.licensesChart.results[iter].apr_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            5,
            Number(licenseChartValue?.licensesChart.results[iter].may_usage),
            Number(licenseChartValue?.licensesChart.results[iter].may_count) -
              Number(licenseChartValue?.licensesChart.results[iter].may_usage),
            Number(licenseChartValue?.licensesChart.results[iter].may_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            6,
            Number(licenseChartValue?.licensesChart.results[iter].jun_usage),
            Number(licenseChartValue?.licensesChart.results[iter].jun_count) -
              Number(licenseChartValue?.licensesChart.results[iter].jun_usage),
            Number(licenseChartValue?.licensesChart.results[iter].jun_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            7,
            Number(licenseChartValue?.licensesChart.results[iter].jul_usage),
            Number(licenseChartValue?.licensesChart.results[iter].jul_count) -
              Number(licenseChartValue?.licensesChart.results[iter].jul_usage),
            Number(licenseChartValue?.licensesChart.results[iter].jul_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            8,
            Number(licenseChartValue?.licensesChart.results[iter].aug_usage),
            Number(licenseChartValue?.licensesChart.results[iter].aug_count) -
              Number(licenseChartValue?.licensesChart.results[iter].aug_usage),
            Number(licenseChartValue?.licensesChart.results[iter].aug_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            9,
            Number(licenseChartValue?.licensesChart.results[iter].sep_usage),
            Number(licenseChartValue?.licensesChart.results[iter].sep_count) -
              Number(licenseChartValue?.licensesChart.results[iter].sep_usage),
            Number(licenseChartValue?.licensesChart.results[iter].sep_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            10,
            Number(licenseChartValue?.licensesChart.results[iter].oct_usage),
            Number(licenseChartValue?.licensesChart.results[iter].oct_count) -
              Number(licenseChartValue?.licensesChart.results[iter].oct_usage),
            Number(licenseChartValue?.licensesChart.results[iter].oct_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            11,
            Number(licenseChartValue?.licensesChart.results[iter].nov_usage),
            Number(licenseChartValue?.licensesChart.results[iter].nov_count) -
              Number(licenseChartValue?.licensesChart.results[iter].nov_usage),
            Number(licenseChartValue?.licensesChart.results[iter].nov_count),
            productCount,
          )
          ChartUpdate(
            license_name,
            String(licenseChartValue?.licensesChart.results[iter].year),
            12,
            Number(licenseChartValue?.licensesChart.results[iter].dec_usage),
            Number(licenseChartValue?.licensesChart.results[iter].dec_count) -
              Number(licenseChartValue?.licensesChart.results[iter].dec_usage),
            Number(licenseChartValue?.licensesChart.results[iter].dec_count),
            productCount,
          )
          // eslint-disable-next-line no-self-compare
          if (productCount === productCount) {
            productCount = productCount + 2
          }
        }
      } else setChartBasicData([] as any[])
    } else setChartBasicData([] as any[])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseChartValue])

  const [modifiedChartData, setModifiedChartData] = useState([] as any[])

  const [arrObj, setArrObj] = useState({} as any)
  useEffect(() => {
    if (modifiedChartData.length > 0) {
      const result = {} as any
      modifiedChartData.forEach((item: any) => {
        const year = item.modifiedYear.split(' ')[1]
        if (!result[year]) {
          result[year] = []
        }
        result[year].push(item)
      })
      setArrObj(result)
    }
  }, [modifiedChartData])
  const [yearList, setYearList] = useState([] as any[])

  const [filteredLicenseListByYear, setFilteredLicenseListByYear] = useState([] as any[])
  const [selectedYear, setSelectedYear] = React.useState('')
  const [enableYearSelection, setEnableYearSelection] = useState(true)
  const selectedYearChange = (event: string) => {
    setSelectedYear(event)
    setFilteredLicenseListByYear(arrObj[event])
  }

  // Set latest available year as selected
  useEffect(() => {
    if (yearList.length > 0) {
      let currentYear = new Date().getFullYear()
      let mostRecentYear = Math.max(...yearList.filter((year) => year <= currentYear))
      selectedYearChange(String(mostRecentYear))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yearList])

  useEffect(() => {
    if (chartBasicData.length > 0) {
      // Create year list from chart Basic-Data-Set
      const uniqueYears = [...new Set(chartBasicData.map((item) => item.year))]
      // Sort array items from highest to lowest
      uniqueYears.sort(function (a, b) {
        return b - a
      })
      setYearList(uniqueYears)
      setEnableYearSelection(false)

      // Create array where item will be filtered by year
      const resultArray = chartBasicData.reduce((acc: any, obj: any) => {
        const year = obj.modifiedYear
        const existingYearObject = acc.find((item: any) => item.modifiedYear === year)

        if (existingYearObject) {
          // Add values to existing year object
          Object.keys(obj).forEach((key) => {
            if (key !== 'modifiedYear' && key !== 'month') {
              existingYearObject[key] = (existingYearObject[key] || 0) + obj[key]
            }
          })
        } else {
          // Create a new year object
          acc.push({ ...obj })
        }
        return acc
      }, [])

      if (resultArray.length > 0) {
        setModifiedChartData(resultArray)
      } else {
        setModifiedChartData([] as any[])
      }
    } else {
      setEnableYearSelection(true)
      setYearList([] as any[])
      setModifiedChartData([] as any[])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartBasicData])

  // Chart Code
  useEffect(() => {
    if (typeof filteredLicenseListByYear !== 'undefined') {
      // Create root element
      // https://www.amcharts.com/docs/v5/getting-started/#Root_element
      var root = am5.Root.new('licenseChartDiv')
      // Set themes
      // https://www.amcharts.com/docs/v5/concepts/themes/
      root.setThemes([am5themes_Animated.new(root)])
      var modal = am5.Modal.new(root, {
        content: 'License chart has no data',
      })
      if (filteredLicenseListByYear.length <= 0) {
        modal.open()
      } else {
        modal.close()
      }
      let exporting = am5plugins_exporting.Exporting.new(root, {})
      exporting.setAll({
        menu: am5plugins_exporting.ExportingMenu.new(root, {}),
        filePrefix: 'AmazonChart',
        dataSource: filteredLicenseListByYear,
        dateFields: ['date'],
        dateFormat: 'yyyy-MM-dd',
      })

      // Create chart
      // https://www.amcharts.com/docs/v5/charts/xy-chart/
      var chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'panX',
          wheelY: 'zoomX',
          paddingLeft: 0,
          layout: root.verticalLayout,
        }),
      )
      chart.children.unshift(
        am5.Label.new(root, {
          text: 'License Usage',
          fontSize: 20,
          fontWeight: '600',
          textAlign: 'center',
          x: am5.percent(50),
          centerX: am5.percent(50),
          paddingTop: 0,
          paddingBottom: 0,
        }),
      )
      chart.set(
        'scrollbarX',
        am5xy.XYChartScrollbar.new(root, {
          orientation: 'horizontal',
          height: 10,
          background: am5.Rectangle.new(root, {
            fill: am5.Color.fromString('#DEECF3'),
            fillOpacity: 0.7,
          }),
        }),
      )
      // Add legend
      // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
      var legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50,
        }),
      )
      // Create axes
      // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
      var xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.1,
        cellEndLocation: 0.9,
        // minorGridEnabled: true,
        minGridDistance: 2,
      })
      var xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'modifiedYear',
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        }),
      )
      xRenderer.grid.template.setAll({
        location: 1,
      })
      xAxis.data.setAll(filteredLicenseListByYear)
      var yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1,
          }),
        }),
      )
      // Add series
      // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
      const makeSeries = (name: any, fieldName: any, stacked: any) => {
        var series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            stacked: stacked,
            name: name,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: fieldName,
            categoryXField: 'modifiedYear',
          }),
        )
        series.columns.template.setAll({
          tooltipText: '{name}: {valueY}',
          // tooltipText: "{name}, {categoryX}:{valueY}",
          width: am5.percent(90),
          tooltipY: am5.percent(10),
        })
        series.data.setAll(filteredLicenseListByYear)
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear()
        series.bullets.push(function () {
          return am5.Bullet.new(root, {
            locationY: 0.5,
            sprite: am5.Label.new(root, {
              // text: "{valueY}",
              fill: root.interfaceColors.get('alternativeText'),
              centerY: am5.percent(50),
              centerX: am5.percent(50),
              populateText: true,
            }),
          })
        })
        legend.data.push(series)
      }

      // Create an array where the source array of object's key name consists "active" text at the end of key
      const findUniqueActiveKeys = (arr: any) => {
        const uniqueKeys = {} as any
        arr.forEach((obj: any) => {
          Object.keys(obj).forEach((key) => {
            if (!key.endsWith('inactive') && key.endsWith('active')) {
              uniqueKeys[key] = true
            }
          })
        })
        //   return Object.keys(uniqueKeys);
        return [...new Set(Object.keys(uniqueKeys))]
      }
      const uniqueActiveKeys = findUniqueActiveKeys(filteredLicenseListByYear)

      // We put the active column at the base of the chart
      const makeLegend = (data: any) => {
        return makeSeries(data, data, false)
      }

      // Create an array where the source array of object's key name consists "inactive" text at the end of key
      const findUniqueInActiveKeys = (arr: any) => {
        const uniqueKeys = {} as any
        arr.forEach((obj: any) => {
          Object.keys(obj).forEach((key) => {
            if (key.endsWith('inactive')) {
              uniqueKeys[key] = true
            }
          })
        })
        //   return Object.keys(uniqueKeys);
        return [...new Set(Object.keys(uniqueKeys))]
      }
      const uniqueInActiveKeys = findUniqueInActiveKeys(filteredLicenseListByYear)

      // We put the inactive column at the top of it's corresponding active column of the chart
      const makeLegendForInactive = (data: any) => {
        return makeSeries(data, data, true)
      }
      if (uniqueInActiveKeys.length > 0 && uniqueActiveKeys.length > 0) {
        for (var j = 0; j < uniqueInActiveKeys.length; j++) {
          makeLegend(uniqueActiveKeys[j])
          makeLegendForInactive(uniqueInActiveKeys[j])
        }
      }
      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      chart.appear(1000, 100)
      return () => root.dispose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredLicenseListByYear])

  return (
    <Stack direction="column" justifyContent="flex-start" alignItems="stretch" spacing={0.5}>
      <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start">
        <Grid item>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id="demo-simple-select-standard-label">Year</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={selectedYear}
              onChange={(e) => selectedYearChange(e?.target.value)}
              label="Year"
              disabled={enableYearSelection}
            >
              {yearList.length > 0
                ? yearList.map(function (option: any) {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    )
                  })
                : null}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box id="licenseChartDiv" style={{ width: '100%', minHeight: '50vh' }} />
    </Stack>
  )
}
