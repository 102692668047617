import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { ToggleActiveByUserId, ToggleActiveByUserIdReq } from 'reduxStore/users/toggleActive/model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  toggleActivateByUserID: ToggleActiveByUserId
  succssMessage: string
  error: string
  deActivatedUsers: string
}
const initialState: InitialState = {
  loading: false,
  toggleActivateByUserID: {} as ToggleActiveByUserId,
  succssMessage: '',
  error: '',
  deActivatedUsers:''
}

// Generates pending, fulfilled and rejected action types
export const getToggleActivateByUserID = createAsyncThunk<ToggleActiveByUserId, ToggleActiveByUserIdReq>(
  'userDetailsById/getToggleActivateByUserID',
  (reqParam: ToggleActiveByUserIdReq,{ rejectWithValue }) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${reqParam.token}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + `/api/organizationuser/${reqParam.userId}/toggle_active`, { headers }) 
      .then((response) => response.data)
      .catch((error) =>
      rejectWithValue(
        error !== undefined
          ? error.response !== undefined
            ? error.response.data !== undefined
            : 'Something went wrong'
          : 'Something went wrong',
      ),
    )
  },
)

const getToggleActivateByUserIdSlice = createSlice({
  name: 'toggleActivateByUserID',
  initialState,
  reducers: {
    toggleActiveByUserIdClearMsg: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.deActivatedUsers = ''
    },
    toggleActiveByUserIdClearRes: (state) => {
      state.succssMessage = ''
      state.error = ''
      state.deActivatedUsers = ''
      state.toggleActivateByUserID = {} as ToggleActiveByUserId
    },
    updateDeactivateActiveUser: (state, action) => {
      state.deActivatedUsers = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getToggleActivateByUserID.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getToggleActivateByUserID.fulfilled, (state, action: PayloadAction<ToggleActiveByUserId>) => {
      state.loading = false
      state.toggleActivateByUserID = action.payload
      state.succssMessage =
      action?.payload?.succssMessage !== undefined
        ? action?.payload?.succssMessage !== ''
          ? action?.payload?.succssMessage
          : 'Organization User Status Changed'
        : 'Organization User Status Changed'
      state.error = ''
    })
    builder.addCase(getToggleActivateByUserID.rejected, (state, action) => {
      state.loading = false
      state.toggleActivateByUserID = {} as ToggleActiveByUserId
      state.error = action.error.message || 'Something went wrong'
    })
  },
})
export const { toggleActiveByUserIdClearMsg, toggleActiveByUserIdClearRes, updateDeactivateActiveUser } =
getToggleActivateByUserIdSlice.actions
export default getToggleActivateByUserIdSlice.reducer
