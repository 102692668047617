import {
  Alert,
  AlertColor,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import * as React from 'react'
import AddIcon from '@mui/icons-material/Add'
import ChatIcon from '@mui/icons-material/Chat'
import CancelIcon from '@mui/icons-material/Cancel'
import { useParams } from 'react-router-dom'
import { apiBody, Result } from 'reduxStore/comments/Model'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { fetchComments } from 'reduxStore/comments/commentsSlice'
import {
  AddCommentBody,
  AddCommentExtendedBody,
} from 'reduxStore/comments/addComment/addCommentModel'
import { addComment, addCommentClearRes } from 'reduxStore/comments/addComment/addCommentSlice'
import dayjs from 'dayjs'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { hideComment, hideCommentClearRes } from 'reduxStore/comments/Hide/hideCommentSlice'
import { HideCommentReq } from 'reduxStore/comments/Hide/Model'
import { apiReqParam } from 'reduxStore/comments/commentVisibility/Model'
import { fetchCommentsVisibility } from 'reduxStore/comments/commentVisibility/commentVisibilitySlice'
import ApplogieSnackbar from 'components/ApplogieSnackbar'
const searchkey = [
  { label: 'Overview', value: 10 },
  { label: 'Chart', value: 20 },
  { label: 'Card', value: 30 },
]

const YearOption = [
  { label: '2024', value: 0 },
  { label: '2023', value: 1 },
  { label: '2022', value: 2 },
  { label: '2021', value: 3 },
]

const monthOption = [
  { label: 'January', value: 1 },
  { label: 'February', value: 2 },
  { label: 'March', value: 3 },
  { label: 'April', value: 4 },
  { label: 'May', value: 5 },
  { label: 'June', value: 6 },
  { label: 'July', value: 7 },
  { label: 'August', value: 8 },
  { label: 'September', value: 9 },
  { label: 'October', value: 10 },
  { label: 'November', value: 11 },
  { label: 'December', value: 12 },
]

export default function CommentsList() {
  const [open, setOpen] = React.useState(false)
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const commentsValue = useAppSelector((state) => state.comments)
  const addCommentValue = useAppSelector((state) => state.addComment)
  const hideCommentList = useAppSelector((state) => state.hideComment)
  const { id } = useParams()
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const [visibility_type, setVisibilityType] = React.useState(0)
  const filterVisibilityType = (visibilityType: any, _e: any) => {
    setVisibilityType(visibilityType.value)
  }
  const [year, setYear] = React.useState(0)
  const filterYear = (years: any, _e: any) => {
    setYear(years.label)
  }
  const [successHideFieldMsg, setSuccessHideFieldMsg] = React.useState('')
  const [successAddFieldMsg, setSuccessAddFieldMsg] = React.useState('')
  const [apiMsgType, setAPIMsgType] = React.useState<AlertColor>('success')
  const handleHideSuccess = (_event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(addCommentClearRes())
    dispatch(hideCommentClearRes())
    setSuccessHideFieldMsg('')
    setSuccessAddFieldMsg('')
  }

  const [month, setMonth] = React.useState(0)
  const filterMonth = (months: any, _e: any) => {
    setMonth(months.value)
  }
  const [comment_value, setCommentValue] = React.useState('')
  const [commentViewModalOpen, setCommentViewModalOpen] = React.useState(false)
  const [hideId, setHideId] = React.useState(0)
  const handleHideCommentClose = () => {
    setCommentViewModalOpen(false)
  }
  const hideCommentDialog = async () => {
    let hidebody = {
      id: hideId,
      token: Token!,
    } as HideCommentReq
    await dispatch(hideComment(hidebody))
    // setOpenHide(true)
    let commentbody = {
      id: String(id),
      token: Token!,
    } as apiBody
    await dispatch(fetchComments(commentbody))
    let commentVisibilitybody = {
      count: '10',
      token: Token!,
    } as apiReqParam
    await dispatch(fetchCommentsVisibility(commentVisibilitybody))
    await setCommentViewModalOpen(false)
  }

  const handleCommentModalClose = () => {
    setCommentViewModalOpen(false)
  }

  const handleHidecommentPopup = (id: number) => {
    setHideId(id)
    setCommentViewModalOpen(true)
  }
  const AddCommentApiCall = async () => {
    let updatedbody = {
      year: year,
      month: month,
      comment: comment_value,
      visibility: visibility_type,
      account: id,
    } as AddCommentExtendedBody
    let mainbody = {
      updateBody: updatedbody,
      token: Token!,
    } as AddCommentBody

    if (comment_value !== '') {
      await dispatch(addComment(mainbody))
      let commentbody = {
        id: String(id),
        token: Token!,
      } as apiBody
      await dispatch(fetchComments(commentbody))
      setOpen(false)
      // setOpenAdd(true)
      setCommentValue('')
    } else {
    }
  }
  React.useEffect(() => {
    let commentbody = {
      id: String(id),
      token: Token!,
    } as apiBody
    dispatch(fetchComments(commentbody))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, Token])
  //For Add Comment
  React.useEffect(() => {
    if (addCommentValue?.succssMessage !== '') {
      setAPIMsgType('success')
      setSuccessAddFieldMsg(addCommentValue?.succssMessage)
    }
    if (addCommentValue?.error?.message !== '') {
      if (addCommentValue?.error?.message) {
        setAPIMsgType('error')
        setSuccessAddFieldMsg(addCommentValue?.error?.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCommentValue])

  //For Hide Comment
  React.useEffect(() => {
    if (hideCommentList?.succssMessage !== '') {
      setAPIMsgType('success')
      setSuccessHideFieldMsg(hideCommentList?.succssMessage)
    }
    if (hideCommentList?.error?.message !== '') {
      if (hideCommentList?.error?.message) {
        setAPIMsgType('error')
        setSuccessHideFieldMsg(hideCommentList?.error?.message)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hideCommentList])
  return (
    <Box>
      <Card sx={{ minHeight: '250px' }}>
        <CardHeader
          avatar={<ChatIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction={'row'}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Stack direction={'row'}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      Comments
                    </Typography>
                  </Stack>
                  <Stack direction={'row'}>
                    <Chip
                      label={
                        'count' in commentsValue.comments
                          ? String(commentsValue.comments.count)
                          : null
                      }
                    ></Chip>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={'row'}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={handleClickOpen}
                >
                  Add Comment
                </Button>
              </Stack>
            </Stack>
          }
        ></CardHeader>
        <CardContent sx={{ maxHeight: '175px', overflow: 'auto' }}>
          {commentsValue.loading ? (
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>
                <CircularProgress color="inherit" size={30} />
              </Grid>
            </Grid>
          ) : (
            <Box>
              {/* {'comments' in commentsValue ? ( */}
              {'results' in commentsValue.comments ? (
                commentsValue.comments.results.length > 0 ? (
                  commentsValue.comments.results.map((item: Result) => (
                    <Box m={1} key={item.id}>
                      <Alert
                        sx={{
                          backgroundColor: '#08687D',
                          color: 'white',
                          '& .MuiAlert-icon': {
                            color: 'white',
                          },
                        }}
                        icon={<ArrowForwardIosIcon />}
                        onClose={() => {
                          handleHidecommentPopup(item.id)
                        }}
                      >
                        <AlertTitle sx={{ color: 'white' }}>{item.comment}</AlertTitle>
                        On &nbsp;
                        {dayjs(item.create_date!).format('ddd, DD MMM YYYY')}
                        &nbsp; by &nbsp;
                        {item.owner.user.first_name + ' ' + item.owner.user.last_name}
                      </Alert>
                    </Box>
                  ))
                ) : (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    pt={1}
                  >
                    <Grid item>
                      <Typography variant="subtitle1">No content available</Typography>
                    </Grid>
                  </Grid>
                )
              ) : null}
            </Box>
          )}
        </CardContent>
      </Card>

      {/* dialog for Add  comment */}

      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '700px',
            },
          },
        }}
      >
        <DialogTitle>
          <Grid id="top-row" container spacing={24}>
            <Grid item xs={9}>
              <strong> Add a comment:</strong>
            </Grid>
            <Grid item xs={3}>
              <IconButton aria-label="delete" onClick={handleClose}>
                <CancelIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="stretch"
            spacing={1}
          >
            <Grid item xs={4} sm={4}>
              <Autocomplete
                id="visibility"
                options={searchkey}
                onChange={(event: any, newValue: any) => {
                  filterVisibilityType(newValue, event)
                }}
                defaultValue={searchkey[0]}
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} label="Visibility" variant="standard" />
                )}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <Autocomplete
                id="year"
                options={YearOption}
                onChange={(event: any, newValue: any) => {
                  filterYear(newValue, event)
                }}
                defaultValue={YearOption[0]}
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="Year" variant="standard" />}
              />
            </Grid>
            <Grid item xs={4} sm={4}>
              <Autocomplete
                id="month"
                options={monthOption}
                onChange={(event: any, newValue: any) => {
                  filterMonth(newValue, event)
                }}
                defaultValue={monthOption[0]}
                sx={{ width: 200 }}
                renderInput={(params) => <TextField {...params} label="Month" variant="standard" />}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Comment"
                type="tag"
                fullWidth
                variant="standard"
                value={comment_value}
                onChange={(e) => {
                  setCommentValue(e.target.value)
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={AddCommentApiCall} autoFocus variant="contained">
            {addCommentValue.loading === true ? (
              <Stack direction="row" spacing={1}>
                <Typography>Add Comment</Typography>
                <Box pl={1}>
                  <CircularProgress color="inherit" size={18} />
                </Box>
              </Stack>
            ) : (
              <Typography>Add Comment</Typography>
            )}
          </Button>
        </DialogActions>
      </Dialog>

      {/* dialog for close  comment */}

      <Dialog open={commentViewModalOpen} onClose={handleHideCommentClose} fullWidth maxWidth="sm">
        <DialogContent>
          <DialogContentText>
            <strong>Are you sure you want to hide this comment for all users.</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => hideCommentDialog()}>
            {hideCommentList.loading === true ? (
              <Stack direction="row" spacing={1}>
                <Typography>Hide Comment</Typography>
                <Box pl={1}>
                  <CircularProgress color="inherit" size={18} />
                </Box>
              </Stack>
            ) : (
              'Hide Comment'
            )}
          </Button>
          <Button variant="outlined" onClick={handleCommentModalClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ApplogieSnackbar
        msg={successHideFieldMsg}
        type={apiMsgType}
        alertClose={handleHideSuccess}
      />

      <ApplogieSnackbar msg={successAddFieldMsg} type={apiMsgType} alertClose={handleHideSuccess} />
    </Box>
  )
}
