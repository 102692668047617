import * as React from 'react'
import AddIcon from '@mui/icons-material/Add'
import {
  Alert,
  AlertColor,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import CancelIcon from '@mui/icons-material/Cancel'
import { AddTagListByUserIdReg } from 'reduxStore/users/tags/addTagListById/AddTagListByUserIdModel'
import { getTagByUserId } from 'reduxStore/users/tags/tagList/getTagListByIdSlice'
import { AddTagListByUserIdReg as GetTagListByIDReq } from 'reduxStore/users/tags/addTagListById/AddTagListByUserIdModel'
import { useNavigate, useParams } from 'react-router-dom'
import {
  addTagByUserID,
  addTagClearRes,
} from 'reduxStore/users/tags/addTagListById/addTagListByUserIdSlice'

export default function Tags() {
  // Get the userId param from the URL.
  let { id } = useParams()
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()
  const tagListByUserId = useAppSelector((state) => state.tagListByUserId)

  const addTagsbyUserID = useAppSelector((state) => state.addTagsbyUserID)
  const [tag, setTag] = React.useState('')
  const [dialogOpen, setDialogOpen] = React.useState(false)
  // open Dialog
  const openDialog = () => {
    setTag('')
    setDialogOpen(false)
    setDialogOpen(true)
  }
  //close Dialog
  const closeDialog = () => {
    setTag('')
    setDialogOpen(false)
    dispatch(addTagClearRes())
    setTagMsg('')
    setTagMsgView(false)
  }

  let navigate = useNavigate()
  const passTagName = (item: string) => {
    navigate(`/users/${item}`)
  }

  React.useEffect(() => {
    //   // eslint-disable-next-line no-lone-blocks

    // handle addTag API success task
    if ('addTagRes' in addTagsbyUserID) {
      if (addTagsbyUserID.addTagRes !== undefined) {
        if (addTagsbyUserID.addTagRes !== null) {
          if ('tags' in addTagsbyUserID.addTagRes) {
            if (addTagsbyUserID.addTagRes.tags !== undefined) {
              if (addTagsbyUserID.addTagRes.tags !== null) {
              }
            }
          }
        }
      }
    }
    // handle task add Tag API error block
    if ('addTagRes' in addTagsbyUserID) {
      if (addTagsbyUserID.error !== undefined) {
        if (addTagsbyUserID.error !== '') {
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTagsbyUserID])

  const [tagMsgView, setTagMsgView] = React.useState(false)
  const [tagMsg, setTagMsg] = React.useState('')
  const [tagMsgType, setTagMsgType] = React.useState<AlertColor>('success')
  const [enableAddOrgTagSnackbar, setEnableAddOrgTagSnackbar] = React.useState(false)
  const closeAddOrgTagSnackbar = () => {
    dispatch(addTagClearRes())
    setTagMsg('')
  }
  React.useEffect(() => {
    if (addTagsbyUserID?.succssMessage !== '') {
      setEnableAddOrgTagSnackbar(true)
      setDialogOpen(false)
      setTagMsg(addTagsbyUserID?.succssMessage)
      dispatch(getTagByUserId(getTagListByIDBody))
    }
    if (addTagsbyUserID?.error !== '') {
      setTagMsgView(true)
      setTagMsg(addTagsbyUserID?.error)
      setTagMsgType('error')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addTagsbyUserID])

  const showTags = () => {
    // Get the userId param from the URL.
    // let { id } = useParams()
    return 'taglistByUserID' in tagListByUserId
      ? tagListByUserId.taglistByUserID !== undefined || tagListByUserId.taglistByUserID !== null
        ? 'tags' in tagListByUserId.taglistByUserID
          ? tagListByUserId.taglistByUserID.tags !== undefined ||
            tagListByUserId.taglistByUserID.tags !== null ||
            String(tagListByUserId.taglistByUserID.tags).length > 0
            ? tagListByUserId.taglistByUserID.tags.map((item: any) => {
                return (
                  <Chip
                    label={item}
                    color="success"
                    sx={{ margin: '2px' }}
                    key={item}
                    onClick={() => passTagName(item)}
                  />
                )
              })
            : null
          : null
        : null
      : null
  }

  // addTag Body
  let addTagByUserIDBody = {
    token: Token!,
    userId: id,
    tag: tag,
  } as AddTagListByUserIdReg
  //
  let getTagListByIDBody = {
    userId: id,
    token: Token!,
  } as GetTagListByIDReq

  const addTags = () => {
    if (tag !== '') {
      dispatch(addTagByUserID(addTagByUserIDBody))
    }
  }

  return (
    <Box>
      <Card sx={{ minHeight: '167px' }}>
        <CardHeader
          avatar={<LocalOfferIcon sx={{ fontWeight: 'bold' }} />}
          title={
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction={'row'}>
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={1}
                >
                  <Stack direction={'row'}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      Tags
                    </Typography>
                  </Stack>
                  <Stack direction={'row'}>
                    <Chip
                      label={
                        'taglistByUserID' in tagListByUserId
                          ? 'tags' in tagListByUserId.taglistByUserID
                            ? tagListByUserId.taglistByUserID.tags.length
                            : 0
                          : 0
                      }
                    ></Chip>
                  </Stack>
                </Stack>
              </Stack>
              <Stack direction={'row'}>
                <Button
                  variant="contained"
                  size="small"
                  startIcon={<AddIcon />}
                  onClick={openDialog}
                >
                  Add Tag
                </Button>
              </Stack>
            </Stack>
          }
        ></CardHeader>
        <CardContent>
          <Box>{showTags()}</Box>
          <Dialog open={dialogOpen} onClose={closeDialog}>
            <DialogTitle id="alert-dialog-title">
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction={'row'}>
                  <Typography variant="h6">
                    <b>Add Tag:</b>
                  </Typography>
                </Stack>
                <Stack direction={'row'}>
                  <IconButton aria-label="delete" onClick={closeDialog}>
                    <CancelIcon />
                  </IconButton>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Tag Name"
                type="tag"
                fullWidth
                variant="standard"
                value={tag}
                onChange={(e) => setTag(e.target.value)}
              />
              {tagMsgView ? (
                <Collapse in={tagMsgView}>
                  <Alert
                    icon={false}
                    severity={tagMsgType}
                    onClose={() => {
                      dispatch(addTagClearRes())
                      setTagMsg('')
                      setTagMsgView(false)
                    }}
                    sx={{ mb: 2 }}
                  >
                    {tagMsg}
                  </Alert>
                </Collapse>
              ) : null}
            </DialogContent>
            <DialogActions>
              <Button onClick={addTags} autoFocus variant="contained">
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item>
                    <Typography>SUBMIT</Typography>
                  </Grid>
                  {addTagsbyUserID.loading ? (
                    <Grid item>
                      <CircularProgress color="inherit" size={18} />
                    </Grid>
                  ) : null}
                </Grid>
              </Button>
            </DialogActions>
          </Dialog>
        </CardContent>
      </Card>
      {addTagsbyUserID.succssMessage !== '' ? (
        <Snackbar
          open={enableAddOrgTagSnackbar}
          autoHideDuration={5000}
          onClose={closeAddOrgTagSnackbar}
        >
          <Alert onClose={closeAddOrgTagSnackbar} severity="success" sx={{ width: '100%' }}>
            <strong>{tagMsg}</strong>
          </Alert>
        </Snackbar>
      ) : null}
    </Box>
  )
}
