import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import Url from '../../../APIConfig'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { updateSubscriptionBody, UpdateSubscriptionErrorResponse, UpdateSubscriptionResponse } from './updateSubscriptionModel'

type InitialState = {
  loading: boolean
  response: UpdateSubscriptionResponse
  error: UpdateSubscriptionErrorResponse
}
const initialState: InitialState = {
  loading: false,
  response: {} as UpdateSubscriptionResponse,
  error: { message: '' } as UpdateSubscriptionErrorResponse,
}

export const updateSubscription = createAsyncThunk<
  UpdateSubscriptionResponse,
  updateSubscriptionBody
>(
  'updateSubscription/updateSubscription',
  async (payload: updateSubscriptionBody, { rejectWithValue }) => {
    const headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${payload.token}`,
    }
    return await setupInterceptorsTo(axios)
      .put(Url.backEndURL + `/api/account/${payload.id}`, payload.updateBody, { headers })
      .then((response) => response.data)
      .catch((error) => rejectWithValue(error.response.data[0].detail))
  },
)

const updateSubscriptionSlice = createSlice({
  name: 'updateSubscription',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateSubscription.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      updateSubscription.fulfilled,
      (state, action: PayloadAction<UpdateSubscriptionResponse>) => {
        state.loading = false
        state.response = action.payload
        state.error = {} as any
      },
    )
    builder.addCase(updateSubscription.rejected, (state, action) => {
      state.loading = false
      state.response = {} as UpdateSubscriptionResponse
      state.error.message = action.payload!
    })
  },
})

export default updateSubscriptionSlice.reducer
