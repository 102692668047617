import { Chip, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useAppSelector } from 'reduxStore/hooks'
interface Props {
  reloadOptimization: () => void
}
export default function OptimizationCardHeader({ reloadOptimization }: Props) {
  const optimizationVal = useAppSelector((state) => state.optimization)
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          Optimizations
        </Typography>
      </Grid>
      <Grid item>
        <Chip
          label={
            'count' in optimizationVal.optimizations ? optimizationVal.optimizations.count : null
          }
        ></Chip>
      </Grid>
      <Grid item>
        <IconButton onClick={reloadOptimization}>
          <RefreshIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
