import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { Results } from './usageModel'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  monthlyUsageSpends: Results[]
  error: string
}
const initialState: InitialState = {
  loading: false,
  monthlyUsageSpends: [],
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchMonthlyUsageSpends = createAsyncThunk<Results[], string>(
  'monthlyVendorSpendUsage/fetchMonthlyUsageSpends',
  async (adminToken) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${adminToken}`,
    }
    return await setupInterceptorsTo(axios)
      .get(url.backEndURL + '/api/customers/usage/', { headers })
      .then((response) => response.data.results)
  },
)

const monthlyVendorSpendUsageSlice = createSlice({
  name: 'monthlyVendorSpendUsage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMonthlyUsageSpends.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetchMonthlyUsageSpends.fulfilled,
      (state, action: PayloadAction<Results[]>) => {
        state.loading = false
        state.monthlyUsageSpends = action.payload
        state.error = ''
      },
    )
    builder.addCase(fetchMonthlyUsageSpends.rejected, (state, action) => {
      state.loading = false
      state.monthlyUsageSpends = []
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default monthlyVendorSpendUsageSlice.reducer
