import axios from 'axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { setupInterceptorsTo } from 'reduxStore/Authentication/Interceptors'
import { UserTagList } from './Model'
import url from 'APIConfig'

type InitialState = {
  loading: boolean
  tagList: UserTagList
  error: string
}
const initialState: InitialState = {
  loading: false,
  tagList: {} as UserTagList,
  error: '',
}

// Generates pending, fulfilled and rejected action types
export const fetchUserTagList = createAsyncThunk<UserTagList, string>(
  'userTagList/fetchSubscription',
  (adminToken) => {
    let headers = {
      Accept: 'application/json',
      'Content-type': 'application/json',
      Authorization: `Token ${adminToken}`,
    }
    return setupInterceptorsTo(axios)
      .get(url.backEndURL + '/api/orguser-tags-list/', { headers })
      .then((response) => response.data)
  },
)

const getUserTagListSlice = createSlice({
  name: 'userTagList',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserTagList.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetchUserTagList.fulfilled, (state, action: PayloadAction<UserTagList>) => {
      state.loading = false
      state.tagList = action.payload
      state.error = ''
    })
    builder.addCase(fetchUserTagList.rejected, (state, action) => {
      state.loading = false
      state.tagList = {} as UserTagList
      state.error = action.error.message || 'Something went wrong'
    })
  },
})

export default getUserTagListSlice.reducer
