import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'reduxStore/hooks'
import { useParams } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { AccountDetailsModelReq } from 'reduxStore/account/accountPaymentList/AccountPaymentListByIdModel'
import {
  UpdatePaymentBody,
  UpdatePaymentReq,
} from 'reduxStore/account/accountUpdatePayment/AccountUpdatePaymentModel'
import {
  selectedSubscriptionPaymentDetails,
  updatePayment,
  updatePaymentClearMsg,
  updatePaymentClearRes,
  updateSubscriptionPaymentEditDialogState,
} from 'reduxStore/account/accountUpdatePayment/accountUpdatePaymentSlice'

import utc from 'dayjs/plugin/utc'
import { fetchAccountPaymentListById } from 'reduxStore/account/accountPaymentList/accountPaymentListByIdSlice'
import { fetchAccountCustomerSpendById } from 'reduxStore/account/accountCustomerSpend/accountCustomerSpendByIdSlice'
import { CustomerBudgetByAccountIdModelReq } from 'reduxStore/account/accountCustomerSpend/accountCustomerSpendModel'
import { fetchAccountCustomerBudgetById } from 'reduxStore/account/accountCustomerBudgetById/accountCustomerBudgetByIdSlice'
import { ModifiedPaymentList } from '../Model'
import { checkReturnValue } from 'components/Utility'

dayjs.extend(utc)

type Props = {
  selectedItem?: ModifiedPaymentList
  updateSelectedItem: (value: ModifiedPaymentList) => void
  updatePaymentDialogEnable?: boolean
  updatePaymentDialogUpdateState: (value: boolean) => void
}

export default function UpdatePayment({
  selectedItem,
  updateSelectedItem,
  updatePaymentDialogEnable,
  updatePaymentDialogUpdateState,
}: Props) {
  const Token = localStorage.getItem('token')
  const dispatch = useAppDispatch()

  const { id } = useParams()
  const [openAlert, setAlert] = useState(false)
  const closeAlert = () => {
    setAlert(false)
  }

  const [updatePaymentDialogOpen, setUpdatePaymentDialogOpen] = useState(false)
  useEffect(() => {
    setUpdatePaymentDialogOpen(updatePaymentDialogEnable!)
  }, [updatePaymentDialogEnable])

  const [paymentDate, setDate] = useState<Dayjs | null>(null)
  const dateConvert = (changedDate: Dayjs | null) => {
    setDate(dayjs(changedDate))
  }
  const [cost, setCost] = useState('')
  const [memo, setMemo] = useState('')

  // Update field value from parent component
  useEffect(() => {
    setDate(checkReturnValue(selectedItem?.date!) !== 'Not Set' ? dayjs(selectedItem?.date) : null)
    setCost(
      checkReturnValue(selectedItem?.amount!) !== 'Not Set' ? String(selectedItem?.amount) : '',
    )
    setMemo(checkReturnValue(selectedItem?.memo!) !== 'Not Set' ? String(selectedItem?.memo) : '')
  }, [selectedItem])

  //close Dialog
  const closeUpdatePaymentDialog = () => {
    setDate(null)
    setCost('')
    setMemo('')
    setUpdatePaymentDialogOpen(false)
    updatePaymentDialogUpdateState(false)
    updateSelectedItem({} as ModifiedPaymentList)
    dispatch(updatePaymentClearMsg())
    dispatch(updatePaymentClearRes())
    dispatch(updateSubscriptionPaymentEditDialogState(false))
    dispatch(selectedSubscriptionPaymentDetails({} as any))
  }

  let updatePaymentBody = {
    account_id: Number(id),
    cost: cost,
    memo: memo,
    data_date: paymentDate !== null ? dayjs(paymentDate).utc().format('YYYY-MM-DDTHH:mm') : null,
  } as UpdatePaymentBody
  let updatePaymentMainBody = {
    token: Token!,
    updateBody: updatePaymentBody,
  } as UpdatePaymentReq
  console.log('updatePaymentBody', updatePaymentBody)
  // update Payment API Call
  const updateAccountPayment = async () => {
    if (selectedItem?.id === undefined) {
      if (cost !== '' && memo !== '' && paymentDate !== null) {
        setAlert(false)
        dispatch(updatePayment(updatePaymentMainBody))
      } else {
        setAlert(true)
      }
    } else {
      if (cost !== '' && memo !== '' && paymentDate !== null) {
        setAlert(false)
        let editPaymentBody = Object.assign(updatePaymentBody, {
          payment_id: selectedItem?.id,
        }) as UpdatePaymentBody
        let editPaymentMainBody = { ...updatePaymentMainBody }
        editPaymentMainBody.updateBody = editPaymentBody
        dispatch(updatePayment(editPaymentMainBody))
      } else {
        setAlert(true)
      }
    }
  }
  // Call Update Subscription Payment Store
  const updatePaymentRes = useAppSelector((state) => state.updatePaymentRes)
  // Do the following action when Update subscription Payment store has been changed

  useEffect(() => {
    if (updatePaymentRes?.succssMessage !== '') {
      dispatch(
        fetchAccountPaymentListById({
          accountId: String(id),
          token: Token,
        } as AccountDetailsModelReq),
      )
      dispatch(
        fetchAccountCustomerSpendById({
          accountId: String(id),
          token: Token,
        } as CustomerBudgetByAccountIdModelReq),
      )
      dispatch(
        fetchAccountCustomerBudgetById({
          accountId: String(id),
          token: Token,
        } as CustomerBudgetByAccountIdModelReq),
      )
      setTimeout(() => {
        closeUpdatePaymentDialog()
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePaymentRes])

  return (
    <Box>
      <Dialog open={updatePaymentDialogOpen} onClose={closeUpdatePaymentDialog}>
        <DialogTitle id="alert-dialog-title">
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction={'row'}>
              <Typography variant="h6" fontWeight="bold">
                {selectedItem?.id === undefined ? 'Add' : 'Edit'} Payment
              </Typography>
            </Stack>
            <Stack direction={'row'}>
              <IconButton aria-label="delete" onClick={closeUpdatePaymentDialog}>
                <CloseIcon />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <Collapse in={openAlert} timeout="auto" unmountOnExit>
          <Box m={3}>
            <Alert severity="error" onClose={closeAlert}>
              <b>Error:</b> All fields are mandatory, Please provide all the Values.
            </Alert>
          </Box>
        </Collapse>
        <DialogContent>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
            pb={2}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Payment Date"
                  disableFuture
                  value={paymentDate}
                  onChange={(newValue) => {
                    dateConvert(newValue)
                  }}
                  slotProps={{ textField: { variant: 'standard' } }}
                  sx={{ width: '100%' }}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <TextField
                id="standard-basic"
                label="Cost"
                variant="standard"
                value={cost}
                onChange={(e) => {
                  setCost(e.target.value)
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField
                id="standard-basic"
                label="Memo"
                variant="standard"
                value={memo}
                onChange={(e) => {
                  setMemo(e.target.value)
                }}
                fullWidth
                multiline
                maxRows={2}
                minRows={2}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ margin: 3 }}>
          <Button onClick={updateAccountPayment} autoFocus variant="contained">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography sx={{ color: '#fff', fontSize: '15px' }}>
                  {selectedItem?.id === undefined ? 'Add ' : 'Edit '}Payment
                </Typography>
              </Grid>
              {updatePaymentRes.loading === true ? (
                <Grid item>
                  <CircularProgress color="inherit" size={18} />
                </Grid>
              ) : null}
            </Grid>
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
