import { Chip, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useAppSelector } from 'reduxStore/hooks'
interface Props {
  reloadBreach: () => void
}
export default function BreachDetailsHeader({ reloadBreach }: Props) {
  const breachesDetailsByID = useAppSelector((state) => state.breachesDetailsByID)
  return (
    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          {breachesDetailsByID?.breachesDetailsByID?.results!
            ? breachesDetailsByID?.breachesDetailsByID?.results[0]?.vendor_name!
            : 'Not Set'}
        </Typography>
      </Grid>
      <Grid item>
        <Chip
          label={
            'count' in breachesDetailsByID?.breachesDetailsByID
              ? String(breachesDetailsByID?.breachesDetailsByID?.count)
              : null
          }
        />
      </Grid>
      <Grid item>
        <IconButton onClick={reloadBreach}>
          <RefreshIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}
