import { createSlice } from '@reduxjs/toolkit'

type InitialState = {
  subscriptionLists: any[]
  updateType: number
  value: string
  renewalDate?: string
}
const initialState: InitialState = {
  subscriptionLists: [],
  updateType: 0,
  value: '',
  renewalDate: '',
}

const selectSubscriptionForSetDeptSlice = createSlice({
  name: 'selectSubscriptionForSetDept',
  initialState,
  reducers: {
    updateSubscriptionList: (state, action) => {
      state.subscriptionLists = action.payload
    },
    updateSubscriptionListClear: (state) => {
      state.subscriptionLists = []
      state.updateType = 0
      state.value = ''
      state.renewalDate = ''
    },
    updateSubscriptionListItem: (state, action) => {
      state.updateType = action.payload
      state.renewalDate = action.payload === 3 ? state.renewalDate : ''
    },
    updateSubscriptionListItemValue: (state, action) => {
      state.value = action.payload
    },
    updateSubscriptionListItemRenewalDate: (state, action) => {
      state.renewalDate = action.payload
    },
  },
})
export const {
  updateSubscriptionList,
  updateSubscriptionListClear,
  updateSubscriptionListItem,
  updateSubscriptionListItemValue,
  updateSubscriptionListItemRenewalDate,
} = selectSubscriptionForSetDeptSlice.actions
export default selectSubscriptionForSetDeptSlice.reducer
