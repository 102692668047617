import * as React from 'react'
import {
  Alert,
  Collapse,
  FormControl,
  Grid,
  IconButton,
  TextField,
  Button,
  Typography,
  Card,
  CardActions,
  CardContent,
  Stack,
  CircularProgress,
  Box,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'reduxStore/store'
import { forgotPassword } from 'reduxStore/forgotPassword/forgotPasswordSlice'
import { useAppSelector } from 'reduxStore/hooks'
import PageTitle from 'components/PageTitle'

export default function ForgottenPassword() {
  const dispatch = useDispatch<AppDispatch>()
  // Call Admin Login store
  const forgotPasswordDetails = useAppSelector((state) => state.forgotPassword)

  const [showErrorMsgView, setShowErrorMsgView] = React.useState(false)
  const [emailFieldError, setEmailFieldError] = React.useState(false)
  const emailFieldNullMsg = 'Please complete this required field'
  const [email, setEmail] = React.useState('')

  const userForgotPassword = () => {
    if (email !== "") {
      dispatch(forgotPassword(email))
      setEmailFieldError(false);
    }
    else {
      setEmailFieldError(true);
    }
  }
  const pageLayout = (
    <Grid
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
      columns={{ xs: 4, sm: 8, md: 12 }}
      p={1}
    >
      <Grid item xs={4} sm={12} md={8}>
        <Card>
          <CardContent>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
              <LockOpenIcon /> Forgotten Password
            </Typography>
            <Collapse in={showErrorMsgView}>
              <Alert
                icon={false}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setShowErrorMsgView(false)
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                sx={{ mb: 2 }}
              >
                {forgotPasswordDetails.response.message}
              </Alert>
            </Collapse>
            <FormControl variant="standard" fullWidth>
              <TextField
                id="userName"
                label="Email Address"
                variant="standard"
                fullWidth
                error={emailFieldError}
                sx={{ mt: '10' }}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
              {emailFieldError === true ? (
                <Typography style={{ color: '#d32f2f' }} sx={{ fontSize: 13 }}>
                  {emailFieldNullMsg}
                </Typography>
              ) : null}
            </FormControl>
          </CardContent>
          <CardActions>
            <Button
              variant="contained"
              sx={{ ml: 'auto' }}
              onClick={() => {
                userForgotPassword()
              }}
              disabled={forgotPasswordDetails?.loading}
            >
              {forgotPasswordDetails.loading === true ? (
                <Stack direction="row" spacing={1}>
                  <Typography> Send</Typography>
                  <Box pl={1}>
                    <CircularProgress color="inherit" size={18} />
                  </Box>
                </Stack>
              ) : (
                'Send'
              )}
            </Button>
          </CardActions>
        </Card>
      </Grid>
    </Grid>
  )

  React.useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line no-lone-blocks
    {
      forgotPasswordDetails !== undefined
        ? forgotPasswordDetails.response !== undefined
          ? forgotPasswordDetails.response.message !== undefined
            ? forgotPasswordDetails.response.message!.length > 0
              ? setShowErrorMsgView(true)
              : setShowErrorMsgView(false)
            : setShowErrorMsgView(false)
          : setShowErrorMsgView(false)
        : setShowErrorMsgView(false)
    }
  }, [forgotPasswordDetails])

  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={6}>
        <PageTitle title={'Forgotten Password'} />
        {pageLayout}
      </Grid>
    </Grid>
  )
}
